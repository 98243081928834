import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

import { Text, Menu, toaster } from "evergreen-ui";

import logout from "../../images/log-out.svg";

function LogOutBtn() {
  const { getLoggedIn, localization } = useContext(AuthContext);

  const history = useNavigate();

  async function logOut() {
    localStorage.removeItem("Token");
    toaster.warning("You have been successfully logged out!", { duration: 1 });
    getLoggedIn();
    history("/login");
  }

  return (
    <Menu.Item
      className="log-out-inner-wrapper"
      data-customClass="custom-design"
      onClick={logOut}
    >
      <img className="icons" src={logout} alt="logout" height="auto" />
      <Text className="title-box" data-customClass="custom-design">
        {localization.logout ? localization.logout : "Log out"}
      </Text>
    </Menu.Item>
  );
}

export default LogOutBtn;
