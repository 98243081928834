import React, { useContext, memo } from "react";
import LogOutBtn from "../../components/auth/LogOutBtn";
import { Link } from "react-router-dom";
import {
  Avatar,
  Pane,
  Heading,
  Popover,
  Position,
  Menu,
  Text,
  Spinner,
} from "evergreen-ui";
import "./Header.css";
import pic from "../../images/user-logo-hello.svg";

import setting from "../../images/settings.svg";

import AuthContext from "../../context/AuthContext";
function Header() {
  const { firstName, lastName, localization, loading, avatarImg } =
    useContext(AuthContext);

  const replaceProfile = (error) => {
    error.target.src = "/default/user.png";
  };

  return (
    <>
      <Pane className="header-top-right" data-customClass="custom-design">
        <Pane className="part-one">
          <Heading>
            <Text className="user-hello" data-customClass="custom-design">
              {localization.hello ? localization.hello : "Hello "}&nbsp;
            </Text>
            <Text className="user-name" data-customClass="custom-design">
              {firstName.charAt(0).toUpperCase() + firstName.slice(1)}&nbsp;
              {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
            </Text>
            <img
              className="header-text-img"
              src={pic}
              alt="user"
              width="100%"
              height="auto"
            />
          </Heading>
        </Pane>
        <Pane className="header-search-bar"></Pane>
        <Pane className="part-two">
          {/* <NotificationsIcon className="notification" size={14} />
          <Pill
            className="notification-count"
            style={{ right: "149px", position: "absolute", top: "12px" }}
          >
            12
          </Pill> */}
          <Pane className="profile-picture" data-customClass="custom-design">
            <Popover
              minWidth="40px"
              minHeight="40px"
              padding="0px"
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu className="log-out-container">
                  <Menu.Group className="log-out-inner-container">
                    <Menu.Item
                      className="log-out-inner-wrapper"
                      data-customClass="custom-design"
                    >
                      <Link to="/account-settings">
                        <Pane className="account-setting-wrapper">
                          <img
                            className="icons"
                            src={setting}
                            alt="setting"
                            height="auto"
                          />
                          <Text
                            className="title-box"
                            data-customClass="custom-design"
                          >
                            {localization.accountSettings
                              ? localization.accountSettings
                              : "Account Details"}
                          </Text>
                        </Pane>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="log-out-inner-wrapper"
                      data-customClass="custom-design"
                    >
                      <Link to="/change-password">
                        <Pane className="account-setting-wrapper">
                          <img
                            className="icons"
                            src={setting}
                            alt="setting"
                            height="auto"
                          />
                          <Text
                            className="title-box"
                            data-customClass="custom-design"
                          >
                            {localization.changePassword
                              ? localization.changePassword
                              : "Change Password"}
                          </Text>
                        </Pane>
                      </Link>
                    </Menu.Item>
                    <LogOutBtn />
                  </Menu.Group>
                </Menu>
              }
            >
              <Avatar
                className="avatar-design"
                data-customClass="custom-design"
                src={`${process.env.REACT_APP_API_URL}/${avatarImg}`}
                onError={replaceProfile}
                name={firstName.split(" ")[0] + " " + lastName}
                size={30}
                style={{ cursor: "pointer", top: "2px" }}
                color="#a6ced6"
                fontSize="14px"
              />
            </Popover>
          </Pane>
          <Pane className="header-logout-button"></Pane>
        </Pane>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default memo(Header);
