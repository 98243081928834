import axios from "../../../helper/axiosInstance";
import React, { useEffect, useState, useCallback, useRef } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import {
  Pane,
  Button,
  SelectField,
  MoreIcon,
  CalendarIcon,
  Label,
  FileUploader,
  FileCard,
  SideSheet,
  Paragraph,
  Heading,
  TextInput,
  TextInputField,
  Text,
  Popover,
  Position,
  Menu,
  EditIcon,
  TextareaField,
  toaster,
  Dialog,
  Tablist,
  Tab,
  AddIcon,
  ImportIcon,
  MimeType,
  Spinner,
  SelectMenu,
  Icon,
  SortIcon,
  DoubleChevronRightIcon,
  DoubleChevronLeftIcon,
} from "evergreen-ui";

import "./tenant.css";

import userPoster from "../../../images/empty-podcast-poster.svg";

function Tenant() {
  const [loading, setLoading] = useState(false);
  const [tenantLoader, setTenantLoader] = useState(false);
  const [tenantInfoLoader, setTenantInfoLoader] = useState(false);
  const [activeRenderList, setActiveRenderList] = useState(true);
  
  const [submittingSaveTenant, setSubmittingSaveTenant] = useState(false);
  const [submittingUpdateTenant, setSubmittingUpdateTenant] = useState(false);
  const [submittingSaveUser, setSubmittingSaveUser] = useState(false);
  const [submittingImportUser, setSubmittingImportUser] = useState(false);
  const [submittingUpdateAccount, setSubmittingUpdateAccount] = useState(false);

  const tenantRef = useRef(null);
  const tenantInfoRef = useRef(null);

  const scrollToTopTenant = () => {
    tenantRef.current.scrollTop = 0;
  };

  const scrollToTopTenantInfo = () => {
    tenantInfoRef.current.scrollTop = 0;
  };

  const replaceTenant = (error) => {
    error.target.src = "/default/tenant.png";
  };

  const [selectedIndex, setSelectedIndex] = useState("ttab1");
  const [languages, setLanguages] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [btnTenant, setBtnTenant] = useState(false);

  const [btnUser, setBtnUser] = useState(false);

  const resetImportUsersClose = (e) => {
    setIsShownImportUser(false);
  };

  const [csvFile, setCsvFile] = useState([]);
  const [csvFileRejections, setCsvFileRejections] = useState([]);

  const handleChangeCsv = useCallback((files) => setCsvFile([files[0]]), []);
  const handleRejectedCsv = useCallback(
    (csvFileRejections) => setCsvFileRejections([csvFileRejections[0]]),
    []
  );
  const handleRemoveCsv = useCallback(() => {
    setCsvFile([]);
    setCsvFileRejections([]);
  }, []);

  const [isShownUser, setIsShownUser] = useState(false);
  const [isShownImportUser, setIsShownImportUser] = useState(false);

  const [isShownTenant, setIsShownTenant] = useState(false);
  const [isShownEditTenant, setIsShownEditTenant] = useState(false);
  const [isShownEditAccount, setIsShownEditAccount] = useState(false);

  const resetUser = (e) => {
    setInputs({
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);
  };

  const resetUserClose = (e) => {
    setInputs({
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);

    setIsShownUser(false);
  };

  const [companyFile, setCompanyFile] = useState([]);
  const [companyFileRejections, setCompanyFileRejections] = useState([]);

  const handleChangeCompany = useCallback(
    (files) => setCompanyFile([files[0]]),
    []
  );
  const handleRejectedCompany = useCallback(
    (companyFileRejections) =>
      setCompanyFileRejections([companyFileRejections[0]]),
    []
  );
  const handleRemoveCompany = useCallback(() => {
    setCompanyFile([]);
    setCompanyFileRejections([]);
  }, []);

  const [profileFile, setProfileFile] = useState([]);
  const [profileFileRejections, setProfileFileRejections] = useState([]);

  const handleChangeProfile = useCallback(
    (files) => setProfileFile([files[0]]),
    []
  );
  const handleRejectedProfile = useCallback(
    (profileFileRejections) =>
      setProfileFileRejections([profileFileRejections[0]]),
    []
  );
  const handleRemoveProfile = useCallback(() => {
    setProfileFile([]);
    setProfileFileRejections([]);
  }, []);

  const [validCompanyFile, setValidCompanyFile] = useState(true);
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileImage: "",
    tenantTitle: "",
    tenantLanguage: "",
    tenantDescription: "",
    tenantBrandingPrimary: "#A6CED6",
    tenantBrandingSecondary: "#000000",
    tenantImage: "",
  });

  const [editInputs, setEditInputs] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    profileImage: "",
    tenantId: "",
    tenantTitle: "",
    tenantLanguage: "",
    tenantDescription: "",
    tenantBrandingPrimary: "#A6CED6",
    tenantBrandingSecondary: "#000000",
    tenantImage: "",
  });

  const handleInput = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputEdit = (e) => {
    setEditInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetTenant = (e) => {
    setInputs({
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
      tenantId: "",
      tenantTitle: "",
      tenantLanguage: "",
      tenantDescription: "",
      tenantBrandingPrimary: "#A6CED6",
      tenantBrandingSecondary: "#000000",
      tenantImage: "",
    });

    setSelectedIndex("ttab1");
    setCompanyFile([]);
    setCompanyFileRejections([]);

    setProfileFile([]);
    setProfileFileRejections([]);
  };

  const resetTenantClose = (e) => {
    setInputs({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
      tenantId: "",
      tenantTitle: "",
      tenantLanguage: "",
      tenantDescription: "",
      tenantBrandingPrimary: "#A6CED6",
      tenantBrandingSecondary: "#000000",
      tenantImage: "",
    });

    setSelectedIndex("ttab1");
    setCompanyFile([]);
    setCompanyFileRejections([]);

    setProfileFile([]);
    setProfileFileRejections([]);

    setIsShownTenant(false);
  };

  const resetEditTenant = (e) => {
    setEditInputs({
      tenantId: "",
      tenantTitle: "",
      tenantLanguage: "",
      tenantDescription: "",
      tenantBrandingPrimary: "#A6CED6",
      tenantBrandingSecondary: "#000000",
      tenantImage: "",
    });

    setCompanyFile([]);
    setCompanyFileRejections([]);

    setProfileFile([]);
    setProfileFileRejections([]);
    setIsShownEditTenant(false);
  };

  const resetEditAccount = (e) => {
    setEditInputs({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);
    setIsShownEditAccount(false);
  };

  async function handleSubmitUser(e) {
    e.preventDefault();
    if (submittingSaveUser) {
      return;
    }
    setSubmittingSaveUser(true);
    setLoading(true);
    const id = activeTenant._id;

    const url = `/admin/user/${id}`;
    const formData = new FormData();

    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    formData.append("firstName", inputs.firstName);
    formData.append("lastName", inputs.lastName);
    formData.append("email", inputs.email);

    await axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSaveUser(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        setProfileFile([]);
        setProfileFileRejections([]);

        if (btnUser) {
          resetUserClose();
          setBtnUser(false);
        } else {
          resetUser();
        }
      })
      .catch((error) => {
        setSubmittingSaveUser(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const handleClickOpenCreateUser = (id) => () => {
    setIsShownUser(true);
  };

  const handleClickOpenImportUser = (id) => () => {
    setIsShownImportUser(true);
  };

  const handleClickOpenEditAccount = (id) => () => {
    axios
      .get(`/admin/userByTenant/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          id: response?._id,
          firstName: response?.firstName,
          lastName: response?.lastName,
          email: response?.email,
          profileImage: response?.profile_image,
        });

        setIsShownEditAccount(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSubmitTenant(e) {
    e.preventDefault();
    if (submittingSaveTenant) {
      return;
    }
    setSubmittingSaveTenant(true);
    setLoading(true);

    const url = "/admin/tenant";
    const formData = new FormData();

    if (companyFile[0] !== undefined) {
      formData.append("company_logo", companyFile[0]);
    }
    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    formData.append("title", inputs.tenantTitle);
    formData.append("language_id", inputs.tenantLanguage);
    formData.append("description", inputs.tenantDescription);
    formData.append("brandingPrimary", inputs.tenantBrandingPrimary);
    formData.append("brandingSecondary", inputs.tenantBrandingSecondary);
    formData.append("firstName", inputs.firstName);
    formData.append("lastName", inputs.lastName);
    formData.append("email", inputs.email);

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSaveTenant(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });

        getTenants();

        if (btnTenant) {
          resetTenantClose();
          setBtnTenant(false);
        } else {
          resetTenant();
        }
      })
      .catch((error) => {
        setSubmittingSaveTenant(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitEditTenant(e) {
    e.preventDefault();
    if (submittingUpdateTenant) {
      return;
    }
    setSubmittingUpdateTenant(true);
    setLoading(true);

    const id = editInputs.tenantId;

    const url = `/admin/tenant/${id}`;
    const formData = new FormData();

    if (companyFile[0] !== undefined) {
      formData.append("company_logo", companyFile[0]);
    }

    formData.append("title", editInputs.tenantTitle);
    formData.append("language_id", editInputs.tenantLanguage);
    formData.append("description", editInputs.tenantDescription);
    formData.append("brandingPrimary", editInputs.tenantBrandingPrimary);
    formData.append("brandingSecondary", editInputs.tenantBrandingSecondary);

    axios
      .put(url, formData)
      .then((res) => {
    setSubmittingUpdateTenant(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });

        resetEditTenant();
        getTenants();
        onClickDisplayTenantById(id);
        setIsShownEditTenant(false);
      })
      .catch((error) => {
        setSubmittingUpdateTenant(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const handleClickOpenEditTenant = (id) => () => {
    axios
      .get(`/admin/tenant/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          tenantId: response?._id,
          tenantTitle: response?.title,
          tenantDescription: response?.description,
          tenantBrandingPrimary: response?.brandingPrimary,
          tenantBrandingSecondary: response?.brandingSecondary,
          tenantImage: response?.company_logo,
          tenantLanguage: response?.language_id?._id,
        });

        setIsShownEditTenant(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSubmitEditAccount(e) {
    e.preventDefault();
    if (submittingUpdateAccount) {
      return;
    }
    setSubmittingUpdateAccount(true);
    setLoading(true);
    const id = editInputs.id;

    const url = `/admin/user/${id}`;
    const formData = new FormData();

    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    formData.append("firstName", editInputs.firstName);
    formData.append("lastName", editInputs.lastName);

    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdateAccount(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });

        resetEditAccount();
        getTenants();
        setIsShownEditAccount(false);
      })
      .catch((error) => {
        setSubmittingUpdateAccount(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteTenant = (id) => () => {
    setLoading(true);
    axios
      .delete(`/admin/tenant/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getTenants();
        setActiveTenant("");
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response?.data?.message, { duration: 2 });
      });
  };

  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialog(true);
  };

  function returnDate(data) {
    try {
      const date = new Date(data);
      const isoDate = date.toISOString().substring(0, 10);
      return isoDate;
    } catch (error) {
      console.log("Date error:", error);
    }
  }

  const [activeTenant, setActiveTenant] = useState("");
  const [focusTenant, setFocusTenant] = useState(true);

  const activeTenantTab = (id) => {
    if (activeTenant !== "") {
      if (id === activeTenant?._id && focusTenant) {
        return "tenant-list-panel active";
      } else {
        return "tenant-list-panel";
      }
    } else {
      return "tenant-list-panel";
    }
  };

  async function onClickDisplayTenantById(id) {
    setTenantInfoLoader(true);
    setFocusTenant(false);

    await axios
      .get(`/admin/tenant/${id}`)
      .then((res) => {
        const response = res?.data?.data;
        setActiveTenant(response);

        setTenantInfoLoader(false);
        setFocusTenant(true);

        scrollToTopTenantInfo();
      })
      .catch((error) => {
        setTenantInfoLoader(false);
        setFocusTenant(true);
        console.log(error);
      });
  }

  function handleSubmitImportUsers(e) {
    const id = activeTenant._id;
    e.preventDefault();
    if (submittingImportUser) {
      return;
    }
    setSubmittingImportUser(true);
    setLoading(true);

    const url = `/admin/bulkimport/${id}`;
    const formData = new FormData();
    formData.append("bulk_users_csv", csvFile[0]);
    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingImportUser(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        setIsShownImportUser(false);
      })
      .catch((error) => {
        setSubmittingImportUser(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error?.message, { duration: 2 });
        }
        toaster.warning(error?.response?.data?.message, { duration: 2 });
      })
      .finally(() => {
        setCsvFile([]);
      });
  }

  function resetImportUser(e) {
    setCsvFile([]);
  }

  const renderTenantInfo = () => {
    if (activeTenant !== "") {
      return (
        <>
          <Pane className="tenant-header-panel">
            <Pane className="tenant-header-list-panel">
              <Pane className="tenant-header-img">
                <img
                  className="header-img"
                  src={`${process.env.REACT_APP_API_URL}/${activeTenant?.company_logo}`}
                  onError={replaceTenant}
                  width="100%"
                  height="auto"
                  alt=""
                />
              </Pane>
              <Pane className="tenant-header-heading">
                <Text
                  className="tenant-header-name"
                  data-customClass="custom-design"
                >
                  {activeTenant?.title}
                </Text>
              </Pane>
              <Pane className="tenant-edit-main-container">
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  padding="0px"
                  position={Position.BOTTOM_LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item
                          padding="0"
                          className="tenant-edit-container"
                          data-customClass="custom-design"
                          icon={<EditIcon size={12} color="white" />}
                          onClick={handleClickOpenEditTenant(activeTenant?._id)}
                        >
                          <Text
                            className="tenant-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            Edit Tenant
                          </Text>
                        </Menu.Item>
                        <Menu.Item
                          padding="0"
                          className="tenant-edit-container"
                          data-customClass="custom-design"
                          icon={<EditIcon size={12} color="white" />}
                          onClick={handleClickOpenEditAccount(
                            activeTenant?._id
                          )}
                        >
                          <Text
                            className="tenant-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            Edit Account
                          </Text>
                        </Menu.Item>
                        <Menu.Item
                          padding="0"
                          className="tenant-edit-container"
                          data-customClass="custom-design"
                          icon={<AddIcon size={12} color="white" />}
                          onClick={handleClickOpenCreateUser(activeTenant?._id)}
                        >
                          <Text
                            className="tenant-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            Create
                          </Text>
                        </Menu.Item>
                        <Menu.Item
                          padding="0"
                          className="tenant-edit-container"
                          data-customClass="custom-design"
                          icon={<ImportIcon size={12} color="white" />}
                          onClick={handleClickOpenImportUser(activeTenant?._id)}
                        >
                          <Text
                            className="tenant-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            Import Users
                          </Text>
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <MoreIcon
                    className="middle-panel-header-moreicon"
                    size={12}
                  />
                </Popover>
              </Pane>
            </Pane>
          </Pane>
          <SimpleBar
            style={{ maxHeight: "calc(100vh - 220px)" }}
            scrollableNodeProps={{ ref: tenantInfoRef }}
          >
            <Pane className="tenant-content-panel">
              <Text
                className="tenant-content-description"
                data-customClass="custom-design"
              >
                <p className="tenant-desc">{activeTenant?.description}</p>
              </Text>

              <Pane className="tenant-content-description-list">
                <Pane>
                  <p className="tenant-content-description-list-one">
                    Created on
                  </p>
                  <p className="tenant-content-description-list-two">
                    {returnDate(activeTenant?.createdAt)}
                  </p>
                </Pane>
                <Pane>
                  <p className="tenant-content-description-list-one">
                    Language
                  </p>
                  <p className="tenant-content-description-list-two">
                    {activeTenant?.language_id?.languageName}
                  </p>
                </Pane>
                <Pane>
                  <p className="tenant-content-description-list-one">
                    Colour Branding
                  </p>
                  <p className="tenant-content-description-list-two">
                    <Pane
                      className="brandingBox"
                      style={{
                        backgroundColor: activeTenant?.brandingPrimary
                          ? activeTenant?.brandingPrimary
                          : inputs.tenantBrandingPrimary,
                      }}
                    />
                    <Pane
                      className="brandingBox"
                      style={{
                        backgroundColor: activeTenant?.brandingSecondary
                          ? activeTenant?.brandingSecondary
                          : inputs.tenantBrandingSecondary,
                      }}
                    />
                  </p>
                </Pane>
              </Pane>
            </Pane>
          </SimpleBar>
        </>
      );
    } else {
      return (
        <>
          <Pane className="series-empty-poster-container">
            <Pane className="series-empty-poster-circle">
              <img
                alt=""
                className="series-poster"
                src={userPoster}
                width="100%"
                height="auto"
              />
            </Pane>

            <Text fontSize="12px" color="#343434">
              Select item to view data.
            </Text>
          </Pane>
        </>
      );
    }
  };

  const [byOrder, setByOrder] = useState();
  const [searchBy, setSearchBy] = useState("");

  function renderTenants() {
    if (activeRenderList) {
      return "";
    }
    const filteredOptions = tenants?.filter((tenant) =>
      tenant?.title?.toLowerCase().includes(searchBy.toLowerCase())
    );

    if (filteredOptions?.length > 0) {
      return filteredOptions
        .sort((a, b) => {
          if (byOrder === "A to Z")
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
          if (byOrder === "Z to A")
            return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
          if (byOrder === "Newest") return a.createdAt < b.createdAt ? 1 : -1;
          if (byOrder === "Oldest") return a.createdAt > b.createdAt ? 1 : -1;
        })
        .map((tenant, i) => {
          return (
            <Pane
              tabIndex={i}
              className={activeTenantTab(tenant?._id)}
              key={tenant?._id}
              onClick={() => onClickDisplayTenantById(tenant?._id)}
              style={{ cursor: "pointer" }}
            >
              <Pane className="tenant-user-img">
                <img
                  className="tenant-img"
                  src={`${process.env.REACT_APP_API_URL}/${tenant.company_logo}`}
                  onError={replaceTenant}
                  width="100%"
                  height="auto"
                  alt=""
                />
              </Pane>
              <Pane className="tenant-user-inner-container">
                <Text
                  className="tenant-user-name"
                  data-customClass="custom-design"
                >
                  {tenant.title}
                </Text>
                <Pane className="date-time">
                  <p>
                    <CalendarIcon
                      size={12}
                      marginRight={5}
                      marginTop={5}
                      marginBottom={-1}
                    />
                    {returnDate(tenant.createdAt)}
                  </p>
                </Pane>
              </Pane>

              <Pane
                className="tenant-edit-main-container"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  padding="0px"
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <Menu>
                      <Menu.Group>
                        <Pane onClick={handleClickOpenEditTenant(tenant._id)}>
                          <Menu.Item
                            padding="0"
                            className="tenant-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="tenant-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Edit Tenant
                            </Text>
                          </Menu.Item>
                        </Pane>
                        <Pane onClick={handleClickOpenEditAccount(tenant._id)}>
                          <Menu.Item
                            padding="0"
                            className="tenant-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="tenant-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Edit Account
                            </Text>
                          </Menu.Item>
                        </Pane>
                        <Pane onClick={handleClickOpenCreateUser(tenant._id)}>
                          <Menu.Item
                            padding="0"
                            className="tenant-edit-container"
                            data-customClass="custom-design"
                            icon={<AddIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="tenant-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Create
                            </Text>
                          </Menu.Item>
                        </Pane>
                        <Pane onClick={handleClickOpenImportUser(tenant._id)}>
                          <Menu.Item
                            padding="0"
                            className="tenant-edit-container"
                            data-customClass="custom-design"
                            icon={<ImportIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="tenant-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Import Users
                            </Text>
                          </Menu.Item>
                        </Pane>
                      </Menu.Group>
                    </Menu>
                  )}
                >
                  <MoreIcon className="tenant-list-more-icon" size={12} />
                </Popover>
              </Pane>
            </Pane>
          );
        });
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title="Select tenant to manage user data"
        >
          No record found.
        </Text>
      );
    }
  }

  async function getLanguages() {
    const response = await axios.get("/admin/languages/");
    setLanguages(response?.data?.data);
  }

  async function getTenants() {
    setTenantLoader(true);
    await axios
      .get("/admin/tenants/")
      .then((response) => {
        setTenants(response?.data?.data);
        setTenantLoader(false);
        setActiveRenderList(false);
      })
      .catch((error) => {
        setTenantLoader(false);
        setActiveRenderList(false);
      });
  }

  useEffect(() => {
    getLanguages();
    getTenants();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="left-panel">
              <Pane className="left-panel-header">
                <Pane className="left-panel-header-first-line">
                  <Label
                    className="tenant-header-title"
                    data-customClass="custom-design"
                  >
                    Tenant
                  </Label>
                  <Pane>
                    <Pane>
                      <React.Fragment>
                        <SideSheet
                          preventBodyScrolling={true}
                          isShown={isShownTenant}
                          onCloseComplete={resetTenantClose}
                          shouldCloseOnOverlayClick={false}
                        >
                          <Paragraph className="tenant-form-header">
                            <Text className="tenant-form-heading">
                              Create tenant
                            </Text>
                            <Text className="tenant-form-sub-heading">
                              Please fill the details and create tenant.
                            </Text>
                          </Paragraph>
                          <form
                            className="tenant-form-panel"
                            onSubmit={handleSubmitTenant}
                            onReset={resetTenantClose}
                          >
                            <Pane className="tenant-form-container-inner">
                              <Pane className="tenant-form-container-inner-wrapper">
                                <Tablist
                                  flexBasis={240}
                                  marginRight={24}
                                  className="tenantTablist"
                                >
                                  <Tab
                                    key="ttab1"
                                    id="ttab1"
                                    onSelect={() => setSelectedIndex("ttab1")}
                                    isSelected={"ttab1" === selectedIndex}
                                    aria-controls={`panel-ttab1`}
                                  >
                                    Tenant Details
                                  </Tab>
                                  <Tab
                                    key="ttab2"
                                    id="ttab2"
                                    onSelect={() => setSelectedIndex("ttab2")}
                                    isSelected={"ttab2" === selectedIndex}
                                    aria-controls={`panel-ttab2`}
                                  >
                                    Tenant Admin Details
                                  </Tab>
                                </Tablist>
                              </Pane>
                              <SimpleBar
                                style={{ maxHeight: "calc(100vh - 154px)" }}
                              >
                                <Pane padding={16} background="tint1" flex="1">
                                  <Pane
                                    key="ttab1"
                                    id="panel-ttab1"
                                    role="tabpanel"
                                    aria-labelledby="ttab1"
                                    aria-hidden={"ttab1" !== selectedIndex}
                                    display={
                                      "ttab1" === selectedIndex
                                        ? "block"
                                        : "none"
                                    }
                                  >
                                    <Pane className="tenant-detail-form">
                                      <Pane className="tenant-form-heading">
                                        <Pane>
                                          <TextInputField
                                            type="text"
                                            name="tenantTitle"
                                            label="Title"
                                            value={inputs.tenantTitle}
                                            onChange={handleInput}
                                            width={350}
                                            marginBottom={3}
                                            required
                                          />
                                        </Pane>
                                        <Pane>
                                          <SelectField
                                            label="Select Language"
                                            width={196}
                                            name="tenantLanguage"
                                            value={inputs.tenantLanguage}
                                            onChange={handleInput}
                                            required
                                          >
                                            <option value="">
                                              Select Language
                                            </option>
                                            {languages?.map((language, i) => {
                                              return (
                                                <option
                                                  key={language?._id}
                                                  value={language._id}
                                                >
                                                  {language.languageName}
                                                </option>
                                              );
                                            })}
                                          </SelectField>
                                        </Pane>
                                      </Pane>
                                      <Pane>
                                        <TextareaField
                                          className="global-text-area-padding"
                                          label="Description"
                                          id="forSeriesDescription"
                                          name="tenantDescription"
                                          value={inputs.tenantDescription}
                                          onChange={handleInput}
                                          required
                                          resize="none"
                                          inputWidth={570}
                                        />
                                      </Pane>
                                      <Pane maxWidth={570}>
                                        <FileUploader
                                          acceptedMimeTypes={[
                                            MimeType.jpeg,
                                            MimeType.png,
                                          ]}
                                          className="create-series-file-uploader"
                                          label="Company Logo *"
                                          description="Maximum upload file size: 2MB. (jpg, png format)."
                                          maxSizeInBytes={2 * 1024 ** 2}
                                          maxFiles={1}
                                          onChange={handleChangeCompany}
                                          onRejected={handleRejectedCompany}
                                          renderFile={(file) => {
                                            const { name, size, type } = file;
                                            const fileRejection =
                                              companyFileRejections.find(
                                                (fileRejection) =>
                                                  fileRejection.file === file
                                              );
                                            const { message } =
                                              fileRejection || {};
                                            setValidCompanyFile(fileRejection);
                                            return (
                                              <FileCard
                                                key={name}
                                                src={URL.createObjectURL(
                                                  companyFile[0]
                                                )}
                                                isInvalid={
                                                  fileRejection != null
                                                }
                                                name={name}
                                                onRemove={handleRemoveCompany}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                              />
                                            );
                                          }}
                                          values={companyFile}
                                          required
                                        />
                                      </Pane>

                                      <Label>Branding</Label>
                                      <Pane className="tenant-branding-panel">
                                        <TextInputField
                                          className="brandingInput"
                                          type="color"
                                          hint="Accent colour"
                                          name="tenantBrandingPrimary"
                                          value={inputs.tenantBrandingPrimary}
                                          onChange={handleInput}
                                        />
                                        <TextInputField
                                          className="brandingInput"
                                          type="color"
                                          hint="Accent inverted colour"
                                          name="tenantBrandingSecondary"
                                          value={inputs.tenantBrandingSecondary}
                                          onChange={handleInput}
                                        />
                                      </Pane>
                                      <Button
                                        className="disabled-button"
                                        data-customClass="custom-design"
                                        type="button"
                                        onClick={() =>
                                          setSelectedIndex("ttab2")
                                        }
                                        disabled={
                                          !inputs.tenantTitle ||
                                          !inputs.tenantLanguage ||
                                          !inputs.tenantDescription ||
                                          !companyFile[0] ||
                                          validCompanyFile
                                        }
                                      >
                                        Continue
                                        <DoubleChevronRightIcon></DoubleChevronRightIcon>
                                      </Button>
                                    </Pane>
                                  </Pane>
                                  <Pane
                                    key="ttab2"
                                    id="panel-ttab2"
                                    role="tabpanel"
                                    aria-labelledby="ttab2"
                                    aria-hidden={"ttab2" !== selectedIndex}
                                    display={
                                      "ttab2" === selectedIndex
                                        ? "block"
                                        : "none"
                                    }
                                  >
                                    <Pane>
                                      <Pane className="tenant-user-detail-form">
                                        <Pane className="tenant-form-heading">
                                          <TextInputField
                                            width={272}
                                            type="text"
                                            name="firstName"
                                            label="First Name"
                                            value={inputs.firstName}
                                            onChange={handleInput}
                                            required
                                          />
                                          <TextInputField
                                            width={272}
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            value={inputs.lastName}
                                            onChange={handleInput}
                                            required
                                          />
                                        </Pane>
                                        <Pane className="tenant-form-heading">
                                          <TextInputField
                                            width={568}
                                            type="email"
                                            name="email"
                                            label="Email"
                                            value={inputs.email}
                                            onChange={handleInput}
                                            required
                                          />
                                        </Pane>
                                        <Pane maxWidth={570}>
                                          <FileUploader
                                            acceptedMimeTypes={[
                                              MimeType.jpeg,
                                              MimeType.png,
                                            ]}
                                            className="create-series-file-uploader"
                                            label="Profile Photo"
                                            description="Maximum upload file size: 2MB. (jpg, png format)."
                                            maxSizeInBytes={2 * 1024 ** 2}
                                            maxFiles={1}
                                            onChange={handleChangeProfile}
                                            onRejected={handleRejectedProfile}
                                            renderFile={(file) => {
                                              const { name, size, type } = file;
                                              const fileRejection =
                                                profileFileRejections.find(
                                                  (fileRejection) =>
                                                    fileRejection.file === file
                                                );
                                              const { message } =
                                                fileRejection || {};
                                              return (
                                                <FileCard
                                                  key={name}
                                                  src={URL.createObjectURL(
                                                    profileFile[0]
                                                  )}
                                                  isInvalid={
                                                    fileRejection != null
                                                  }
                                                  name={name}
                                                  onRemove={handleRemoveProfile}
                                                  sizeInBytes={size}
                                                  type={type}
                                                  validationMessage={message}
                                                />
                                              );
                                            }}
                                            values={profileFile}
                                          />
                                        </Pane>

                                        <Button
                                          className="back-button-tenant"
                                          data-customClass="custom-design"
                                          type="button"
                                          onClick={() =>
                                            setSelectedIndex("ttab1")
                                          }
                                        >
                                          <DoubleChevronLeftIcon></DoubleChevronLeftIcon>
                                          Back
                                        </Button>
                                      </Pane>
                                    </Pane>
                                  </Pane>
                                </Pane>
                              </SimpleBar>
                            </Pane>
                            <Pane className="tenant-form-buttons-panel">
                              <Pane padding={16}>
                                <Heading size={600}>
                                  <Button
                                    className="cancel-button"
                                    data-customClass="custom-design"
                                    type="reset"
                                    onClick={resetTenantClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="save-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.tenantTitle ||
                                      !inputs.tenantDescription ||
                                      !inputs.tenantLanguage ||
                                      !inputs.firstName ||
                                      !inputs.lastName ||
                                      !inputs.email ||
                                      !companyFile[0] || 
                                      submittingSaveTenant
                                    }
                                    onClick={() => {
                                      setBtnTenant(true);
                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    className="save-new-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.tenantTitle ||
                                      !inputs.tenantDescription ||
                                      !inputs.tenantLanguage ||
                                      !inputs.firstName ||
                                      !inputs.lastName ||
                                      !inputs.email ||
                                      !companyFile[0] || 
                                      submittingSaveTenant
                                    }
                                  >
                                    Save &amp; Add New
                                  </Button>
                                </Heading>
                              </Pane>
                            </Pane>
                          </form>
                        </SideSheet>
                        <Button
                          className="create-tenant-button"
                          data-customClass="custom-design"
                          onClick={() => setIsShownTenant(true)}
                          textTransform={"capitalize"}
                          title="create tenant"
                        >
                          create
                        </Button>
                      </React.Fragment>
                    </Pane>
                  </Pane>
                </Pane>
                {/* <Pane>
                  <Select
                    options={searchTenants}
                    onChange={handleChangeSearch}
                    className="reactSearchbar"
                    placeholder="Search..."
                  />
                </Pane> */}
                <Pane
                  className="select-dropdown-box-design"
                  data-customClass="custom-design"
                >
                  <Pane className="search-panel-bottom"></Pane>
                  <Pane className="search-panel" style={{ marginTop: "10px" }}>
                    <TextInput
                      className="searchPod"
                      data-customClass="custom-design"
                      placeholder="Search..."
                      type="text"
                      onChange={(e) => setSearchBy(e.target.value)}
                    />
                    <SelectMenu
                      className="filter-menu"
                      height={132}
                      width={100}
                      data-customClass="custom-design"
                      options={["A to Z", "Z to A", "Newest", "Oldest"].map(
                        (label) => ({ label, value: label })
                      )}
                      selected={byOrder}
                      hasFilter={false}
                      hasTitle={false}
                      onSelect={(item) => {
                        setByOrder(item.value);
                        scrollToTopTenant();
                      }}
                      closeOnSelect={true}
                    >
                      <Button>
                        <Icon icon={SortIcon} size={12} /> &nbsp;
                        {byOrder || "Sort by"}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>

              <Pane className="left-panel-content">
                <SimpleBar
                  style={{ maxHeight: "calc(100vh - 176px)" }}
                  scrollableNodeProps={{ ref: tenantRef }}
                >
                  {tenantLoader ? (
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={400}
                    >
                      <Spinner />
                    </Pane>
                  ) : (
                    renderTenants()
                  )}
                </SimpleBar>
              </Pane>
              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownUser}
                    onCloseComplete={resetUserClose}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="user-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="user-form-heading-title"
                        data-customClass="custom-design"
                      >
                        Create User
                      </Text>
                      <Text
                        className="user-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        Please fill the details and create user.
                      </Text>
                    </Paragraph>
                    <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                      <form
                        className="user-form-panel"
                        data-customClass="custom-design"
                        onSubmit={handleSubmitUser}
                        onReset={resetUserClose}
                      >
                        <Pane className="user-form-heading">
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="firstName"
                            label="First Name"
                            value={inputs.firstName}
                            onChange={handleInput}
                            required
                          />
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="lastName"
                            label="Last Name"
                            value={inputs.lastName}
                            onChange={handleInput}
                            required
                          />
                        </Pane>
                        <Pane className="user-form-heading">
                          <Pane>
                            <TextInputField
                              inputWidth={580}
                              type="email"
                              name="email"
                              label="Email"
                              value={inputs.email}
                              onChange={handleInput}
                              required
                            />
                          </Pane>
                        </Pane>
                        <Pane className="user-detail-form">
                          <Pane maxWidth={578}>
                            <FileUploader
                              className="create-series-file-uploader"
                              acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                              label="Profile Photo"
                              description="Maximum upload file size: 2MB. (jpg, png format)."
                              maxSizeInBytes={2 * 1024 ** 2}
                              maxFiles={1}
                              onChange={handleChangeProfile}
                              onRejected={handleRejectedProfile}
                              renderFile={(file) => {
                                const { name, size, type } = file;
                                const fileRejection =
                                  profileFileRejections.find(
                                    (fileRejection) =>
                                      fileRejection.file === file
                                  );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    src={URL.createObjectURL(profileFile[0])}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveProfile}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              }}
                              values={profileFile}
                            />
                          </Pane>
                        </Pane>
                        <Pane className="user-form-buttons-panel">
                          <Pane padding={16}>
                            <Heading size={600}>
                              <Button
                                className="cancel-button"
                                data-customClass="custom-design"
                                type="reset"
                                onClick={resetUserClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="save-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={
                                  !inputs.firstName ||
                                  !inputs.lastName ||
                                  !inputs.email || 
                                  submittingSaveUser
                                }
                                onClick={() => {
                                  setBtnUser(true);
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                className="save-new-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={
                                  !inputs.firstName ||
                                  !inputs.lastName ||
                                  !inputs.email || 
                                  submittingSaveUser
                                }
                              >
                                Save &amp; Add New
                              </Button>
                            </Heading>
                          </Pane>
                        </Pane>
                      </form>
                    </SimpleBar>
                  </SideSheet>
                </React.Fragment>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownEditTenant}
                    onCloseComplete={resetEditTenant}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph className="tenant-edit-form-header">
                      <Text
                        className="tenant-edit-form-heading"
                        data-customClass="custom-design"
                      >
                        Edit tenant
                      </Text>
                      <Text
                        className="tenant-edit-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        Please fill the details and update tenant.
                      </Text>
                    </Paragraph>
                    <form
                      className="edit-tenant-form-panel"
                      onSubmit={handleSubmitEditTenant}
                      onReset={resetEditTenant}
                    >
                      <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                        <Pane className="tenant-form-heading">
                          <Pane>
                            <TextInputField
                              type="text"
                              name="tenantTitle"
                              label="Title"
                              value={editInputs.tenantTitle}
                              onChange={handleInputEdit}
                              required
                              width={350}
                              marginBottom={3}
                            />
                          </Pane>
                          <Pane>
                            <SelectField
                              label="Select Language"
                              width={200}
                              name="tenantLanguage"
                              value={editInputs.tenantLanguage}
                              onChange={handleInputEdit}
                            >
                              {languages?.map((language, i) => {
                                return (
                                  <option
                                    key={language?.languageName}
                                    value={language._id}
                                  >
                                    {language.languageName}
                                  </option>
                                );
                              })}
                            </SelectField>
                          </Pane>
                        </Pane>
                        <Pane>
                          <TextareaField
                            className="global-text-area-padding"
                            label="Description"
                            id="forTenantDescription"
                            name="tenantDescription"
                            value={editInputs.tenantDescription}
                            onChange={handleInputEdit}
                            required
                            resize="none"
                            inputWidth={570}
                          />
                        </Pane>
                        <Pane maxWidth={570}>
                          <FileUploader
                            className="create-series-file-uploader"
                            acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                            label="Company Logo"
                            description="Maximum upload file size: 2MB. (jpg, png format)."
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeCompany}
                            onRejected={handleRejectedCompany}
                            renderFile={(file) => {
                              if (file) {
                                const { name, size, type } = file;
                                const fileRejection =
                                  companyFileRejections.find(
                                    (fileRejection) =>
                                      fileRejection.file === file
                                  );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    src={URL.createObjectURL(companyFile[0])}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveCompany}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              } else {
                                setCompanyFile([]);
                              }
                            }}
                            values={companyFile}
                            required
                          />
                        </Pane>
                        {editInputs.tenantImage && (
                          <Pane maxWidth={100} className="defaultImageWrapper">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${editInputs.tenantImage}`}
                              onError={replaceTenant}
                              className="company-logo"
                              alt=""
                            />
                          </Pane>
                        )}

                        <Pane>
                          <Label>Branding</Label>
                        </Pane>
                        <Pane className="tenant-branding-panel">
                          <TextInputField
                            type="color"
                            className="brandingInput"
                            hint="Accent colour"
                            name="tenantBrandingPrimary"
                            value={editInputs.tenantBrandingPrimary}
                            onChange={handleInputEdit}
                          />
                          <TextInputField
                            type="color"
                            className="brandingInput"
                            hint="Accent inverted colour"
                            name="tenantBrandingSecondary"
                            value={editInputs.tenantBrandingSecondary}
                            onChange={handleInputEdit}
                          />
                        </Pane>
                      </SimpleBar>
                      <Pane className="tenant-form-buttons-panel">
                        <Pane padding={16}>
                          <Heading size={600}>
                            <Button
                              marginRight={16}
                              padding={10}
                              type="reset"
                              onClick={resetEditTenant}
                              className="cancel-button"
                              data-customClass="custom-design"
                            >
                              Cancel
                            </Button>
                            <Button
                              marginRight={16}
                              className="save-new-button"
                              data-customClass="custom-design"
                              padding={10}
                              name="update"
                              type="submit"
                              disabled={
                                !editInputs.tenantTitle ||
                                !editInputs.tenantDescription ||
                                !editInputs.tenantLanguage ||  
                                submittingUpdateTenant
                              }
                            >
                              Update
                            </Button>
                          </Heading>
                        </Pane>
                      </Pane>
                    </form>
                  </SideSheet>
                </React.Fragment>

                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownImportUser}
                    onCloseComplete={resetImportUsersClose}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="edit-user-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="edit-user-form-heading"
                        data-customClass="custom-design"
                      >
                        Import Users
                      </Text>
                      <Text
                        className="edit-user-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        Upload the users csv file.
                      </Text>
                    </Paragraph>
                    <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                      <form
                        className="user-form-panel"
                        id="import-user-panel"
                        data-customClass="custom-design"
                        onSubmit={handleSubmitImportUsers}
                        onReset={resetImportUser}
                      >
                        <Pane maxWidth={570}>
                          <FileUploader
                            className="upload-csv-file-design create-series-file-uploader"
                            acceptedMimeTypes={[MimeType.csv]}
                            label="Upload CSV file"
                            description="Files can be up to 2MB. You can upload .csv file formats."
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeCsv}
                            onRejected={handleRejectedCsv}
                            renderFile={(file) => {
                              if (file) {
                                const { name, size, type } = file;
                                const fileRejection = csvFileRejections.find(
                                  (fileRejection) => fileRejection.file === file
                                );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveCsv}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              } else {
                                setCsvFile([]);
                              }
                            }}
                            values={csvFile}
                          />
                        </Pane>
                        <Pane className="user-form-buttons-panel">
                          <Pane padding={16}>
                            <Heading size={600}>
                              <Button
                                className="cancel-button"
                                data-customClass="custom-design"
                                type="reset"
                                onClick={resetImportUsersClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="save-new-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={!csvFile[0] || submittingImportUser}
                              >
                                Import Users
                              </Button>
                            </Heading>
                          </Pane>
                        </Pane>
                      </form>
                    </SimpleBar>
                  </SideSheet>
                </React.Fragment>
              </Pane>
              {/* edit account panel */}
              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownEditAccount}
                    onCloseComplete={resetEditAccount}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph className="tenant-edit-form-header">
                      <Text
                        className="tenant-edit-form-heading"
                        data-customClass="custom-design"
                      >
                        Edit Account
                      </Text>
                      <Text
                        className="tenant-edit-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        Please fill the details and update account.
                      </Text>
                    </Paragraph>
                    <form
                      className="edit-tenant-form-panel"
                      onSubmit={handleSubmitEditAccount}
                      onReset={resetEditAccount}
                    >
                      <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                        <Pane className="tenant-form-heading">
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="firstName"
                            label="First Name"
                            value={editInputs.firstName}
                            onChange={handleInputEdit}
                            required
                          />
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="lastName"
                            label="Last Name"
                            value={editInputs.lastName}
                            onChange={handleInputEdit}
                            required
                          />
                        </Pane>
                        <Pane className="tenant-form-heading">
                          <Pane>
                            <TextInputField
                              inputWidth={568}
                              type="email"
                              name="email"
                              label="Email"
                              value={editInputs.email}
                              onChange={handleInputEdit}
                              required
                              disabled
                            />
                          </Pane>
                        </Pane>
                        <Pane maxWidth={570}>
                          <FileUploader
                            acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                            className="create-series-file-uploader"
                            label="Profile Photo"
                            description="Maximum upload file size: 2MB. (jpg, png format)."
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeProfile}
                            onRejected={handleRejectedProfile}
                            renderFile={(file) => {
                              if (file) {
                                const { name, size, type } = file;
                                const fileRejection =
                                  profileFileRejections.find(
                                    (fileRejection) =>
                                      fileRejection.file === file
                                  );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    src={URL.createObjectURL(profileFile[0])}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveProfile}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              } else {
                                setProfileFile([]);
                              }
                            }}
                            values={profileFile}
                          />
                        </Pane>

                        {editInputs.profileImage && (
                          <Pane maxWidth={100} className="defaultImageWrapper">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${editInputs.profileImage}`}
                              onError={replaceTenant}
                              className="profile-image"
                              alt=""
                            />
                          </Pane>
                        )}
                      </SimpleBar>
                      <Pane className="tenant-form-buttons-panel">
                        <Pane padding={16}>
                          <Heading size={600}>
                            <Button
                              marginRight={16}
                              padding={10}
                              type="reset"
                              onClick={resetEditAccount}
                              className="cancel-button"
                              data-customClass="custom-design"
                            >
                              Cancel
                            </Button>
                            <Button
                              marginRight={16}
                              className="save-new-button"
                              data-customClass="custom-design"
                              padding={10}
                              name="update"
                              type="submit"
                              disabled={
                                !editInputs.firstName ||
                                !editInputs.lastName ||
                                !editInputs.email || 
                                submittingUpdateAccount
                              }
                            >
                              Update
                            </Button>
                          </Heading>
                        </Pane>
                      </Pane>
                    </form>
                  </SideSheet>
                </React.Fragment>
              </Pane>
            </Pane>
            <Pane className="middle-panel">
              <Pane className="middle-panner-wrapper">
                {tenantInfoLoader ? (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={400}
                  >
                    <Spinner />
                  </Pane>
                ) : (
                  renderTenantInfo()
                )}
              </Pane>
            </Pane>
            <Pane className="right-panel-tenant-content">
              <SimpleBar
                style={{
                  height: "100%",
                }}
              ></SimpleBar>
            </Pane>
          </Pane>
          <Pane className="right-panel"></Pane>
        </Pane>
      </Pane>
      <Dialog
        isShown={isShownDeleteDialog}
        title={deleteTitle}
        onCloseComplete={() => setIsShownDeleteDialog(false)}
        confirmLabel="Custom Label"
        hasFooter={false}
      >
        <Pane style={{ margin: "15px 0" }}>
          <Text> Are you sure you want to delete this language?</Text>
        </Pane>
        <Pane style={{ margin: "10px", textAlign: "right" }}>
          <Button
            marginRight={16}
            onClick={() => setIsShownDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button
            marginRight={16}
            appearance="primary"
            intent="danger"
            onClick={deleteTenant(deleteId)}
          >
            Delete
          </Button>
        </Pane>
      </Dialog>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default Tenant;
