import axios from "../../helper/axiosInstance";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import "./forgotpassword.css";
import Logo from "../../images/logo-scroll.png";

import {
  Pane,
  TextInputField,
  Button,
  toaster,
  Text,
  Spinner,
  Icon,
  EyeOffIcon,
  EyeOpenIcon,
} from "evergreen-ui";

function Login() {
  const [loading, setLoading] = useState(false);
  const { getLoggedIn } = useContext(AuthContext);

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useNavigate();

  async function login(e) {
    setLoading(true);
    e.preventDefault();

    const loginData = {
      email,
      password,
    };

    await axios
      .post("/auth/signInWeb", loginData)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          const token = res.data.data;
          localStorage.setItem("Token", token);
          getLoggedIn();
          history("/dashboard");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, {
            duration: 2,
          });
        }
        toaster.warning(error.response.data.message, {
          duration: 2,
        });
      });
  }

  return (
    <>
      <Pane className="flex-container">
        <Pane className="flex-item">
          <Pane className="login-wrapper">
            <Pane className="login-wrapper-inner-body">
              <Pane className="login-logo-wrapper">
                <img
                  className="login-logo-img"
                  src={Logo}
                  alt="logo"
                  width="100%"
                  height="auto"
                />
              </Pane>
              <p className="Login-text">Login</p>
              <Text className="Login-sub-text">Welcome to RadioSkills</Text>
              <form sx={{ mt: 1 }} onSubmit={login} className="login-form">
                <TextInputField
                  className="input-field"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={"Email"}
                  label="Email"
                  required
                />
                <TextInputField
                  className="input-field ms-clear"
                  type={isRevealPwd ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder={"Password"}
                  label="Password"
                  required
                />
                <Icon
                  icon={isRevealPwd ? EyeOffIcon : EyeOpenIcon}
                  className="show-hide"
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />

                <Link to="/forgot-password" className="frm-btm-link">
                  Forgot password
                </Link>
                <Button
                  className="Login-form-button"
                  type="submit"
                  width="300px"
                  textTransform="capitalize"
                >
                  Log In
                </Button>
              </form>
            </Pane>
          </Pane>
        </Pane>
        <Pane className="right-side-container">
          <Pane className="login-img-right-side">
            <Pane className="img-container">
              <Pane className="login-right-side-img"></Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default Login;
