import axios from "../../../helper/axiosInstance";
import React, { useEffect, useContext, useState } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";

import {
  Pane,
  Text,
  toaster,
  TextInputField,
  Button,
  Spinner,
  Icon,
  EyeOffIcon,
  EyeOpenIcon,
} from "evergreen-ui";

import "./changePassword.css";

import AuthContext from "../../../context/AuthContext";

function ChangePassword() {
  const { userEmail, userId, localization } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);

  async function changePassword(e) {
    e.preventDefault();
    if (submittingUpdate) {
      return;
    }
    setSubmittingUpdate(true);    
    setLoading(true);

    const data = {
      email: userEmail,
      oldPassword,
      password,
      confirmPassword,
    };

    const url = `/auth/changepassword/${userId}`;

    await axios
      .put(url, data)
      .then((res) => {
        setSubmittingUpdate(false); 
        setLoading(false);
        if (res.data) {
          toaster.success(res.data.message, { duration: 2 });
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
        }
      })
      .catch((error) => {
        setSubmittingUpdate(false); 
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  useEffect(() => {}, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="right-panel">
              <Pane
                className="language-container"
                data-customClass="custom-design"
              >
                <Pane
                  className="language-header-panel"
                  data-customClass="custom-design"
                >
                  <Pane className="language-header-list-panel">
                    <Pane className="language-header-heading">
                      <Text
                        className="language-header-name"
                        data-customClass="custom-design"
                      >
                        {localization.changePassword
                          ? localization.changePassword
                          : "Change Password"}
                      </Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane
                  className="language-content-panel"
                  data-customClass="custom-design"
                >
                  <Pane>
                    <Pane>
                      <form
                        sx={{ mt: 1 }}
                        onSubmit={changePassword}
                        className="login-form"
                      >
                        <TextInputField
                          inputWidth={'100%'}
                          type={isRevealOldPwd ? "text" : "password"}
                          name="oldPassword"
                          label={
                            localization.oldPassword
                              ? localization.oldPassword
                              : "Old Password"
                          }
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        />
                        <Icon
                          icon={isRevealOldPwd ? EyeOffIcon : EyeOpenIcon}
                          className="show-hide-one"
                          title={
                            isRevealOldPwd ? "Hide password" : "Show password"
                          }
                          onClick={() =>
                            setIsRevealOldPwd((prevState) => !prevState)
                          }
                        />
                        <TextInputField
                          inputWidth={'100%'}
                          type={isRevealPwd ? "text" : "password"}
                          name="password"
                          label={
                            localization.password
                              ? localization.password
                              : "New Password"
                          }
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          hint={
                            localization.passwordHint
                              ? localization.passwordHint
                              : "Must contain one number, one uppercase, one lowercase, and a minimum of eight characters."
                          }
                          required
                        />
                        <Icon
                          icon={isRevealPwd ? EyeOffIcon : EyeOpenIcon}
                          className="show-hide-two"
                          title={
                            isRevealPwd ? "Hide password" : "Show password"
                          }
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                        <TextInputField
                          inputWidth={'100%'}
                          type={isRevealConfirmPwd ? "text" : "password"}
                          name="confirmPassword"
                          label={
                            localization.confirmPassword
                              ? localization.confirmPassword
                              : "Confirm Password"
                          }
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          hint={
                            localization.confirmPasswordHint
                              ? localization.confirmPasswordHint
                              : "Both passwords must match."
                          }
                          required
                        />
                        <Icon
                          icon={isRevealConfirmPwd ? EyeOffIcon : EyeOpenIcon}
                          className="show-hide-three"
                          title={
                            isRevealConfirmPwd
                              ? "Hide password"
                              : "Show password"
                          }
                          onClick={() =>
                            setIsRevealConfirmPwd((prevState) => !prevState)
                          }
                        />
                        <br />
                        <Button
                          className="save-button"
                          type="submit"
                          width="300px"
                          textTransform="capitalize"
                          data-customClass="custom-design"
                          disabled={!oldPassword || !password || !confirmPassword || submittingUpdate }
                        >
                          {localization.btnUpdate
                            ? localization.btnUpdate
                            : "Update"}
                        </Button>
                      </form>
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default ChangePassword;
