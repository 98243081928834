import axios from "../../../helper/axiosInstance";

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import userPoster from "../../../images/empty-podcast-poster.svg";

import {
  Tablist,
  Tab,
  Badge,
  Pane,
  Button,
  TextInput,
  MoreIcon,
  TrashIcon,
  CalendarIcon,
  Dialog,
  Label,
  SideSheet,
  Paragraph,
  Heading,
  TextInputField,
  Text,
  Popover,
  Position,
  Menu,
  EditIcon,
  Table,
  toaster,
  WarningSignIcon,
  Spinner,
  SelectMenu,
  Icon,
  SortIcon,
} from "evergreen-ui";

import "./language.css";

function Language() {
  const [loading, setLoading] = useState(false);
  const [languageLoader, setLanguageLoader] = useState(false);
  const [languageInfoLoader, setLanguageInfoLoader] = useState(false);
  const [activeRenderList, setActiveRenderList] = useState(true);

  const [submittingSave, setSubmittingSave] = useState(false);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexE, setSelectedIndexE] = useState(0);
  const [selectedIndexV, setSelectedIndexV] = useState(0);

  const languageRef = useRef(null);
  const languageInfoRef = useRef(null);
  const languageCreateRef = useRef(null);
  const languageEditRef = useRef(null);

  const scrollToToplanguage = () => {
    languageRef.current.scrollTop = 0;
  };

  const scrollToToplanguageInfo = () => {
    languageInfoRef.current.scrollTop = 0;
  };

  const scrollToToplanguageCreate = () => {
    languageCreateRef.current.scrollTop = 0;
  };

  const scrollToToplanguageEdit = () => {
    languageEditRef.current.scrollTop = 0;
  };

  const [btnLanguage, setBtnLanguage] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [isShownLanguage, setIsShownLanguage] = useState(false);
  const [isShownEditLanguage, setIsShownEditLanguage] = useState(false);

  const [inputs, setInputs] = useState({
    languageName: "",
    languageCode: "",
    home: "",
    podcast: "",
    podcasts: "",
    downloads: "",
    profile: "",
    goodEvening: "",
    recentlyPlayed: "",
    dashboard: "",
    library: "",
    libraries: "",
    tenant: "",
    tenants: "",
    user: "",
    users: "",
    series: "",
    serieses: "",
    episode: "",
    episodes: "",
    language: "",
    languages: "",
    announcement: "",
    announcements: "",
    hello: "",
    accountSettings: "",
    logout: "",
    nowTrending: "",
    mostListened: "",
    newReleases: "",
    trendingCategories: "",
    podcastSeries: "",
    createSeries: "",
    seriesInfo: "",
    createEpisode: "",
    createEpisodeDesc: "",
    createCategory: "",
    createUser: "",
    typeHereToSearch: "",
    createdOn: "",
    listeningTime: "",
    listeningLevel: "",
    badge: "",
    changePassword: "",
    password: "",
    confirmPassword: "",
    passwordHint: "",
    confirmPasswordHint: "",
    btnSave: "",
    btnSaveAndAddNew: "",
    btnUpdate: "",
    btnCancel: "",
    importUser: "",
    importUserDesc: "",
    uploadCsv: "",
    uploadCsvDesc: "",
    createSeriesDesc: "",
    title: "",
    category: "",
    seriesDescription: "",
    bannerImage: "",
    bannerImageDesc: "",
    seriesImage: "",
    seriesImageDesc: "",
    selectCategory: "",
    editSeries: "",
    editSeriesDesc: "",
    createCategoryDesc: "",
    categoryImage: "",
    categoryImageDesc: "",
    editCategory: "",
    editCategoryDesc: "",
    podcastVisibility: "",
    listened: "",
    noOfEpisode: "",
    duration: "",
    editEpisode: "",
    editEpisodeDesc: "",
    podcastDuration: "",
    podcastFile: "",
    podcastFileDesc: "",
    posterImage: "",
    posterImageDesc: "",
    delete: "",
    edit: "",
    news: "",
    editAnnouncement: "",
    editAnnouncementDesc: "",
    createAnnouncement: "",
    createAnnouncementDesc: "",
    description: "",
    editNews: "",
    editPodcastUpdate: "",
    editUser: "",
    editUserDesc: "",
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "",
    deleteTitleUser: "",
    deleteTitleAnnouncement: "",
    deleteTitleCategory: "",
    deleteTitleEpisode: "",
    deleteTitleSeries: "",
    goodMorning: "",
    continueListening: "",
    favourite: "",
    topCategories: "",
    discover: "",
    categories: "",
    search: "",
    all: "",
    allEpisodes: "",
    seeMore: "",
    myLibrary: "",
    noFavourite: "",
    recent: "",
    noRecent: "",
    noDownloads: "",
    achievements: "",
    week: "",
    month: "",
    year: "",
    average: "",
    labelCollection: "",
    badges: "",
    account: "",
    companyInformation: "",
    updates: "",
    pleaseConfirm: "",
    confirm: "",
    seeAll: "",
    logoutConfirmDesc: "",
    notifications: "",
    clearAll: "",
    sortBy: "",
    createUserDesc: "",
    podcastAudioPlayer: "",
    noSeriesMessage: "",
    noAnnouncementMessage: "",
    noUserMessage: "",
    newsImage: "",
    editProfilePhoto: "",
    editProfilePhotoDesc: "",
    profilePhotoDesc: "",
    oldPassword: "",
    share: "",
    minute: "",
    minutes: "",
    hour: "",
    hours: "",
    atoz: "",
    ztoa: "",
    newest: "",
    oldest: "",
    badgeDetails: "",
    badgeLevel: "",
    podcastUpdate: "",
    create: "",
    import: "",
    goodAfternoon: "",
    latestNews: "",
    enterAssociatedEmail: "",
    welcomeTo: "",
    resetYourPassword: "",
    played: "",
    submit: "",
    LogIn: "",
    forgotPassword: "",
    getStarted: "",
    skip: "",
    continue: "",
    badgeCollections: "",
    beginner: "",
    regular: "",
    veteran: "",
    professional: "",
    advanced: "",
    newPassword: "",
    updatedSuccess: "",
    onBoardingMobileOneTitle: "",
    onBoardingMobileTwoTitle: "",
    onBoardingMobileThreeTitle: "",
    onBoardingMobileOneDesc: "",
    onBoardingMobileTwoDesc: "",
    onBoardingMobileThreeDesc: "",
    queue: "",
    nowPlaying: "",
    scaleUp: "",
    noRecords: "",
    noRecordsMatch: "",
    seeLess: "",
    downloadStarted: "",
    downloadCompleted: "",
    noNews: "",
    noUpdates: "",
    pleaseEnterInput: "",
    errorGettingData: "",
    checkInternet: "",
    selectItemToView: "",
    allSeries: "",
    notificationAnnouncementTitle: "",
    notificationAnnouncementMessage: "",
    notificationNewsTitle: "",
    notificationNewsMessage: "",
    notificationPodcastTitle: "",
    notificationPodcastMessage: "",
    errorHashingPassword: "",
    userRegistration: "",
    tenantRegistration: "",
    userNotFound: "",
    newPasswordSubject: "",
    requiredOldPassword: "",
    requiredNewPassword: "",
    passwordNotMatchAlert: "",
    oldPasswordNotMatch: "",
    badResponse: "",
    passwordUpdated: "",
    changePasswordSuccess: "",
    changePasswordFail: "",
    uploadValidImageFile: "",
    uploadAllFiles: "",
    validProfileImage: "",
    requiredFirstname: "",
    requiredLastname: "",
    requiredEmail: "",
    requiredProfileImage: "",
    emailExists: "",
    requiredConfirmPassword: "",
    requiredFields: "",
    passwordNotMatch: "",
    tokenNotProvided: "",
    uploadValidCsvFiles: "",
    requiredImportCsvFile: "",
    requiredDeviceToken: "",
    userCreatedSuccess: "",
    userCreatedFail: "",
    userUpdatedSuccess: "",
    userUpdatedFail: "",
    userDeletedSuccess: "",
    userDeletedFail: "",
    userImportSuccess: "",
    userImportFail: "",
    userProfileUpdatedSuccess: "",
    userProfileUpdatedFail: "",
    profilePictureUpdatedSuccess: "",
    profilePictureUpdatedFail: "",
    tokenUpdatedSuccess: "",
    tokenUpdatedFail: "",
    requiredTitle: "",
    requiredDescription: "",
    requiredCategory: "",
    requiredBannerImage: "",
    requiredSeriesImage: "",
    validSeriesImage: "",
    validBannerImage: "",
    seriesCreatedSuccess: "",
    seriesCreatedFail: "",
    seriesUpdatedSuccess: "",
    seriesUpdatedFail: "",
    seriesDeletedSuccess: "",
    seriesDeletedFail: "",
    uploadValidAudioFile: "",
    requiredPosterImage: "",
    validPosterImage: "",
    requiredPodcastFile: "",
    episodeCreatedSuccess: "",
    episodeCreatedFail: "",
    episodeUpdatedSuccess: "",
    episodeUpdatedFail: "",
    episodeDeletedSuccess: "",
    episodeDeletedFail: "",
    requiredCategoryImage: "",
    validCategoryImage: "",
    categoryCreatedSuccess: "",
    categoryCreatedFail: "",
    categoryUpdatedSuccess: "",
    categoryUpdatedFail: "",
    categoryDeletedSuccess: "",
    categoryDeletedFail: "",
    announcementCreatedSuccess: "",
    announcementCreatedFail: "",
    announcementUpdatedSuccess: "",
    announcementUpdatedFail: "",
    announcementDeletedSuccess: "",
    announcementDeletedFail: "",
    dataCreatedSuccess: "",
    dataCreatedFail: "",
    dataUpdatedSuccess: "",
    dataUpdatedFail: "",
    dataDeletedSuccess: "",
    dataDeletedFail: "",
    dataGetSuccess: "",
    dataGetFail: "",
    emailRequiredRecipient: "",
    emailRequiredSubject: "",
    emailRequiredMessage: "",
    emailSentSuccess: "",
    emailSentFail: "",
    internalError: "",
    tempHello: "",
    tempUsername: "",
    tempPassword: "",
    tempNote: "",
    tempRegards: "",
    tempTeam: "",
    tempLineQuery: "",
    tempDear: "",
    userTempLineFirst: "",
    userTempLineSecond: "",
    userTempLineThird: "",
    userTempLineFourth: "",
    userTempLineFifth: "",
    tenantTempLineFirst: "",
    tenantTempLineSecond: "",
    tenantTempLineThird: "",
    tenantTempLineFourth: "",
    tenantTempLineFifth: "",
    tenantTempLineSixth: "",
    forgotPassTempLineFirst: "",
    forgotPassTempLineSecond: "",
    forgotPassTempLineThird: "",
    forgotPassTempLineFourth: "",
    loggingOut: "",
    AverageWeekly: "",
    AverageMonthly: "",
    AverageYearly: "",
    AlreadyDownloaded: "",
    activateTitle: "",
    activate: "",
    userActivatedSuccess: "",
    userActivatedFail: "",
    noInternet: "",
    notifyUpdateDesc: "",
    noAnnouncementDesc: "",
    noDiscoverDesc: "",
    noDownloadsDesc: "",
    noFavouritesDesc: "",
    noInternetDesc: "",
    noExploreDesc: "",
    noSeriesDesc: "",
    isoDate: "",
    tryAnotherKeyword: "",
  });

  const [editInputs, setEditInputs] = useState({
    id: "",
    languageName: "",
    languageCode: "",
    home: "",
    podcast: "",
    podcasts: "",
    downloads: "",
    profile: "",
    goodEvening: "",
    recentlyPlayed: "",
    dashboard: "",
    library: "",
    libraries: "",
    tenant: "",
    tenants: "",
    user: "",
    users: "",
    series: "",
    serieses: "",
    episode: "",
    episodes: "",
    language: "",
    languages: "",
    announcement: "",
    announcements: "",
    hello: "",
    accountSettings: "",
    logout: "",
    nowTrending: "",
    mostListened: "",
    newReleases: "",
    trendingCategories: "",
    podcastSeries: "",
    createSeries: "",
    seriesInfo: "",
    createEpisode: "",
    createEpisodeDesc: "",
    createCategory: "",
    createUser: "",
    typeHereToSearch: "",
    createdOn: "",
    listeningTime: "",
    listeningLevel: "",
    badge: "",
    changePassword: "",
    password: "",
    confirmPassword: "",
    passwordHint: "",
    confirmPasswordHint: "",
    btnSave: "",
    btnSaveAndAddNew: "",
    btnUpdate: "",
    btnCancel: "",
    importUser: "",
    importUserDesc: "",
    uploadCsv: "",
    uploadCsvDesc: "",
    createSeriesDesc: "",
    title: "",
    category: "",
    seriesDescription: "",
    bannerImage: "",
    bannerImageDesc: "",
    seriesImage: "",
    seriesImageDesc: "",
    selectCategory: "",
    editSeries: "",
    editSeriesDesc: "",
    createCategoryDesc: "",
    categoryImage: "",
    categoryImageDesc: "",
    editCategory: "",
    editCategoryDesc: "",
    podcastVisibility: "",
    listened: "",
    noOfEpisode: "",
    duration: "",
    editEpisode: "",
    editEpisodeDesc: "",
    podcastDuration: "",
    podcastFile: "",
    podcastFileDesc: "",
    posterImage: "",
    posterImageDesc: "",
    delete: "",
    edit: "",
    news: "",
    editAnnouncement: "",
    editAnnouncementDesc: "",
    createAnnouncement: "",
    createAnnouncementDesc: "",
    description: "",
    editNews: "",
    editPodcastUpdate: "",
    editUser: "",
    editUserDesc: "",
    firstName: "",
    lastName: "",
    email: "",
    profilePhoto: "",
    deleteTitleUser: "",
    deleteTitleAnnouncement: "",
    deleteTitleCategory: "",
    deleteTitleEpisode: "",
    deleteTitleSeries: "",
    goodMorning: "",
    continueListening: "",
    favourite: "",
    topCategories: "",
    discover: "",
    categories: "",
    search: "",
    all: "",
    allEpisodes: "",
    seeMore: "",
    myLibrary: "",
    noFavourite: "",
    recent: "",
    noRecent: "",
    noDownloads: "",
    achievements: "",
    week: "",
    month: "",
    year: "",
    average: "",
    labelCollection: "",
    badges: "",
    account: "",
    companyInformation: "",
    updates: "",
    pleaseConfirm: "",
    confirm: "",
    seeAll: "",
    logoutConfirmDesc: "",
    notifications: "",
    clearAll: "",
    sortBy: "",
    createUserDesc: "",
    podcastAudioPlayer: "",
    noSeriesMessage: "",
    noAnnouncementMessage: "",
    noUserMessage: "",
    newsImage: "",
    editProfilePhoto: "",
    editProfilePhotoDesc: "",
    profilePhotoDesc: "",
    oldPassword: "",
    share: "",
    minute: "",
    minutes: "",
    hour: "",
    hours: "",
    atoz: "",
    ztoa: "",
    newest: "",
    oldest: "",
    badgeDetails: "",
    badgeLevel: "",
    podcastUpdate: "",
    create: "",
    import: "",
    goodAfternoon: "",
    latestNews: "",
    enterAssociatedEmail: "",
    welcomeTo: "",
    resetYourPassword: "",
    played: "",
    submit: "",
    LogIn: "",
    forgotPassword: "",
    getStarted: "",
    skip: "",
    continue: "",
    badgeCollections: "",
    beginner: "",
    regular: "",
    veteran: "",
    professional: "",
    advanced: "",
    newPassword: "",
    updatedSuccess: "",
    onBoardingMobileOneTitle: "",
    onBoardingMobileTwoTitle: "",
    onBoardingMobileThreeTitle: "",
    onBoardingMobileOneDesc: "",
    onBoardingMobileTwoDesc: "",
    onBoardingMobileThreeDesc: "",
    queue: "",
    nowPlaying: "",
    scaleUp: "",
    noRecords: "",
    noRecordsMatch: "",
    seeLess: "",
    downloadStarted: "",
    downloadCompleted: "",
    noNews: "",
    noUpdates: "",
    pleaseEnterInput: "",
    errorGettingData: "",
    checkInternet: "",
    selectItemToView: "",
    allSeries: "",
    notificationAnnouncementTitle: "",
    notificationAnnouncementMessage: "",
    notificationNewsTitle: "",
    notificationNewsMessage: "",
    notificationPodcastTitle: "",
    notificationPodcastMessage: "",
    errorHashingPassword: "",
    userRegistration: "",
    tenantRegistration: "",
    userNotFound: "",
    newPasswordSubject: "",
    requiredOldPassword: "",
    requiredNewPassword: "",
    passwordNotMatchAlert: "",
    oldPasswordNotMatch: "",
    badResponse: "",
    passwordUpdated: "",
    changePasswordSuccess: "",
    changePasswordFail: "",
    uploadValidImageFile: "",
    uploadAllFiles: "",
    validProfileImage: "",
    requiredFirstname: "",
    requiredLastname: "",
    requiredEmail: "",
    requiredProfileImage: "",
    emailExists: "",
    requiredConfirmPassword: "",
    requiredFields: "",
    passwordNotMatch: "",
    tokenNotProvided: "",
    uploadValidCsvFiles: "",
    requiredImportCsvFile: "",
    requiredDeviceToken: "",
    userCreatedSuccess: "",
    userCreatedFail: "",
    userUpdatedSuccess: "",
    userUpdatedFail: "",
    userDeletedSuccess: "",
    userDeletedFail: "",
    userImportSuccess: "",
    userImportFail: "",
    userProfileUpdatedSuccess: "",
    userProfileUpdatedFail: "",
    profilePictureUpdatedSuccess: "",
    profilePictureUpdatedFail: "",
    tokenUpdatedSuccess: "",
    tokenUpdatedFail: "",
    requiredTitle: "",
    requiredDescription: "",
    requiredCategory: "",
    requiredBannerImage: "",
    requiredSeriesImage: "",
    validSeriesImage: "",
    validBannerImage: "",
    seriesCreatedSuccess: "",
    seriesCreatedFail: "",
    seriesUpdatedSuccess: "",
    seriesUpdatedFail: "",
    seriesDeletedSuccess: "",
    seriesDeletedFail: "",
    uploadValidAudioFile: "",
    requiredPosterImage: "",
    validPosterImage: "",
    requiredPodcastFile: "",
    episodeCreatedSuccess: "",
    episodeCreatedFail: "",
    episodeUpdatedSuccess: "",
    episodeUpdatedFail: "",
    episodeDeletedSuccess: "",
    episodeDeletedFail: "",
    requiredCategoryImage: "",
    validCategoryImage: "",
    categoryCreatedSuccess: "",
    categoryCreatedFail: "",
    categoryUpdatedSuccess: "",
    categoryUpdatedFail: "",
    categoryDeletedSuccess: "",
    categoryDeletedFail: "",
    announcementCreatedSuccess: "",
    announcementCreatedFail: "",
    announcementUpdatedSuccess: "",
    announcementUpdatedFail: "",
    announcementDeletedSuccess: "",
    announcementDeletedFail: "",
    dataCreatedSuccess: "",
    dataCreatedFail: "",
    dataUpdatedSuccess: "",
    dataUpdatedFail: "",
    dataDeletedSuccess: "",
    dataDeletedFail: "",
    dataGetSuccess: "",
    dataGetFail: "",
    emailRequiredRecipient: "",
    emailRequiredSubject: "",
    emailRequiredMessage: "",
    emailSentSuccess: "",
    emailSentFail: "",
    internalError: "",
    tempHello: "",
    tempUsername: "",
    tempPassword: "",
    tempNote: "",
    tempRegards: "",
    tempTeam: "",
    tempLineQuery: "",
    tempDear: "",
    userTempLineFirst: "",
    userTempLineSecond: "",
    userTempLineThird: "",
    userTempLineFourth: "",
    userTempLineFifth: "",
    tenantTempLineFirst: "",
    tenantTempLineSecond: "",
    tenantTempLineThird: "",
    tenantTempLineFourth: "",
    tenantTempLineFifth: "",
    tenantTempLineSixth: "",
    forgotPassTempLineFirst: "",
    forgotPassTempLineSecond: "",
    forgotPassTempLineThird: "",
    forgotPassTempLineFourth: "",
    loggingOut: "",
    AverageWeekly: "",
    AverageMonthly: "",
    AverageYearly: "",
    AlreadyDownloaded: "",
    activateTitle: "",
    activate: "",
    userActivatedSuccess: "",
    userActivatedFail: "",
    noInternet: "",
    notifyUpdateDesc: "",
    noAnnouncementDesc: "",
    noDiscoverDesc: "",
    noDownloadsDesc: "",
    noFavouritesDesc: "",
    noInternetDesc: "",
    noExploreDesc: "",
    noSeriesDesc: "",
    isoDate: "",
    tryAnotherKeyword: "",
  });

  const handleInput = useCallback((e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleInputEdit = useCallback((e) => {
    setEditInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const resetLanguage = (e) => {
    setInputs({
      languageName: "",
      languageCode: "",
      home: "",
      podcast: "",
      podcasts: "",
      downloads: "",
      profile: "",
      goodEvening: "",
      recentlyPlayed: "",
      dashboard: "",
      library: "",
      libraries: "",
      tenant: "",
      tenants: "",
      user: "",
      users: "",
      series: "",
      serieses: "",
      episode: "",
      episodes: "",
      language: "",
      languages: "",
      announcement: "",
      announcements: "",
      hello: "",
      accountSettings: "",
      logout: "",
      nowTrending: "",
      mostListened: "",
      newReleases: "",
      trendingCategories: "",
      podcastSeries: "",
      createSeries: "",
      seriesInfo: "",
      createEpisode: "",
      createEpisodeDesc: "",
      createCategory: "",
      createUser: "",
      typeHereToSearch: "",
      createdOn: "",
      listeningTime: "",
      listeningLevel: "",
      badge: "",
      changePassword: "",
      password: "",
      confirmPassword: "",
      passwordHint: "",
      confirmPasswordHint: "",
      btnSave: "",
      btnSaveAndAddNew: "",
      btnUpdate: "",
      btnCancel: "",
      importUser: "",
      importUserDesc: "",
      uploadCsv: "",
      uploadCsvDesc: "",
      createSeriesDesc: "",
      title: "",
      category: "",
      seriesDescription: "",
      bannerImage: "",
      bannerImageDesc: "",
      seriesImage: "",
      seriesImageDesc: "",
      selectCategory: "",
      editSeries: "",
      editSeriesDesc: "",
      createCategoryDesc: "",
      categoryImage: "",
      categoryImageDesc: "",
      editCategory: "",
      editCategoryDesc: "",
      podcastVisibility: "",
      listened: "",
      noOfEpisode: "",
      duration: "",
      editEpisode: "",
      editEpisodeDesc: "",
      podcastDuration: "",
      podcastFile: "",
      podcastFileDesc: "",
      posterImage: "",
      posterImageDesc: "",
      delete: "",
      edit: "",
      news: "",
      editAnnouncement: "",
      editAnnouncementDesc: "",
      createAnnouncement: "",
      createAnnouncementDesc: "",
      description: "",
      editNews: "",
      editPodcastUpdate: "",
      editUser: "",
      editUserDesc: "",
      firstName: "",
      lastName: "",
      email: "",
      profilePhoto: "",
      deleteTitleUser: "",
      deleteTitleAnnouncement: "",
      deleteTitleCategory: "",
      deleteTitleEpisode: "",
      deleteTitleSeries: "",
      goodMorning: "",
      continueListening: "",
      favourite: "",
      topCategories: "",
      discover: "",
      categories: "",
      search: "",
      all: "",
      allEpisodes: "",
      seeMore: "",
      myLibrary: "",
      noFavourite: "",
      recent: "",
      noRecent: "",
      noDownloads: "",
      achievements: "",
      week: "",
      month: "",
      year: "",
      average: "",
      labelCollection: "",
      badges: "",
      account: "",
      companyInformation: "",
      updates: "",
      pleaseConfirm: "",
      confirm: "",
      seeAll: "",
      logoutConfirmDesc: "",
      notifications: "",
      clearAll: "",
      sortBy: "",
      createUserDesc: "",
      podcastAudioPlayer: "",
      noSeriesMessage: "",
      noAnnouncementMessage: "",
      noUserMessage: "",
      newsImage: "",
      editProfilePhoto: "",
      editProfilePhotoDesc: "",
      profilePhotoDesc: "",
      oldPassword: "",
      share: "",
      minute: "",
      minutes: "",
      hour: "",
      hours: "",
      atoz: "",
      ztoa: "",
      newest: "",
      oldest: "",
      badgeDetails: "",
      badgeLevel: "",
      podcastUpdate: "",
      create: "",
      import: "",
      goodAfternoon: "",
      latestNews: "",
      enterAssociatedEmail: "",
      welcomeTo: "",
      resetYourPassword: "",
      played: "",
      submit: "",
      LogIn: "",
      forgotPassword: "",
      getStarted: "",
      skip: "",
      continue: "",
      badgeCollections: "",
      beginner: "",
      regular: "",
      veteran: "",
      professional: "",
      advanced: "",
      newPassword: "",
      updatedSuccess: "",
      onBoardingMobileOneTitle: "",
      onBoardingMobileTwoTitle: "",
      onBoardingMobileThreeTitle: "",
      onBoardingMobileOneDesc: "",
      onBoardingMobileTwoDesc: "",
      onBoardingMobileThreeDesc: "",
      queue: "",
      nowPlaying: "",
      scaleUp: "",
      noRecords: "",
      noRecordsMatch: "",
      seeLess: "",
      downloadStarted: "",
      downloadCompleted: "",
      noNews: "",
      noUpdates: "",
      pleaseEnterInput: "",
      errorGettingData: "",
      checkInternet: "",
      selectItemToView: "",
      allSeries: "",
      notificationAnnouncementTitle: "",
      notificationAnnouncementMessage: "",
      notificationNewsTitle: "",
      notificationNewsMessage: "",
      notificationPodcastTitle: "",
      notificationPodcastMessage: "",
      errorHashingPassword: "",
      userRegistration: "",
      tenantRegistration: "",
      userNotFound: "",
      newPasswordSubject: "",
      requiredOldPassword: "",
      requiredNewPassword: "",
      passwordNotMatchAlert: "",
      oldPasswordNotMatch: "",
      badResponse: "",
      passwordUpdated: "",
      changePasswordSuccess: "",
      changePasswordFail: "",
      uploadValidImageFile: "",
      uploadAllFiles: "",
      validProfileImage: "",
      requiredFirstname: "",
      requiredLastname: "",
      requiredEmail: "",
      requiredProfileImage: "",
      emailExists: "",
      requiredConfirmPassword: "",
      requiredFields: "",
      passwordNotMatch: "",
      tokenNotProvided: "",
      uploadValidCsvFiles: "",
      requiredImportCsvFile: "",
      requiredDeviceToken: "",
      userCreatedSuccess: "",
      userCreatedFail: "",
      userUpdatedSuccess: "",
      userUpdatedFail: "",
      userDeletedSuccess: "",
      userDeletedFail: "",
      userImportSuccess: "",
      userImportFail: "",
      userProfileUpdatedSuccess: "",
      userProfileUpdatedFail: "",
      profilePictureUpdatedSuccess: "",
      profilePictureUpdatedFail: "",
      tokenUpdatedSuccess: "",
      tokenUpdatedFail: "",
      requiredTitle: "",
      requiredDescription: "",
      requiredCategory: "",
      requiredBannerImage: "",
      requiredSeriesImage: "",
      validSeriesImage: "",
      validBannerImage: "",
      seriesCreatedSuccess: "",
      seriesCreatedFail: "",
      seriesUpdatedSuccess: "",
      seriesUpdatedFail: "",
      seriesDeletedSuccess: "",
      seriesDeletedFail: "",
      uploadValidAudioFile: "",
      requiredPosterImage: "",
      validPosterImage: "",
      requiredPodcastFile: "",
      episodeCreatedSuccess: "",
      episodeCreatedFail: "",
      episodeUpdatedSuccess: "",
      episodeUpdatedFail: "",
      episodeDeletedSuccess: "",
      episodeDeletedFail: "",
      requiredCategoryImage: "",
      validCategoryImage: "",
      categoryCreatedSuccess: "",
      categoryCreatedFail: "",
      categoryUpdatedSuccess: "",
      categoryUpdatedFail: "",
      categoryDeletedSuccess: "",
      categoryDeletedFail: "",
      announcementCreatedSuccess: "",
      announcementCreatedFail: "",
      announcementUpdatedSuccess: "",
      announcementUpdatedFail: "",
      announcementDeletedSuccess: "",
      announcementDeletedFail: "",
      dataCreatedSuccess: "",
      dataCreatedFail: "",
      dataUpdatedSuccess: "",
      dataUpdatedFail: "",
      dataDeletedSuccess: "",
      dataDeletedFail: "",
      dataGetSuccess: "",
      dataGetFail: "",
      emailRequiredRecipient: "",
      emailRequiredSubject: "",
      emailRequiredMessage: "",
      emailSentSuccess: "",
      emailSentFail: "",
      internalError: "",
      tempHello: "",
      tempUsername: "",
      tempPassword: "",
      tempNote: "",
      tempRegards: "",
      tempTeam: "",
      tempLineQuery: "",
      tempDear: "",
      userTempLineFirst: "",
      userTempLineSecond: "",
      userTempLineThird: "",
      userTempLineFourth: "",
      userTempLineFifth: "",
      tenantTempLineFirst: "",
      tenantTempLineSecond: "",
      tenantTempLineThird: "",
      tenantTempLineFourth: "",
      tenantTempLineFifth: "",
      tenantTempLineSixth: "",
      forgotPassTempLineFirst: "",
      forgotPassTempLineSecond: "",
      forgotPassTempLineThird: "",
      forgotPassTempLineFourth: "",
      loggingOut: "",
      AverageWeekly: "",
      AverageMonthly: "",
      AverageYearly: "",
      AlreadyDownloaded: "",
      activateTitle: "",
      activate: "",
      userActivatedSuccess: "",
      userActivatedFail: "",
      noInternet: "",
      notifyUpdateDesc: "",
      noAnnouncementDesc: "",
      noDiscoverDesc: "",
      noDownloadsDesc: "",
      noFavouritesDesc: "",
      noInternetDesc: "",
      noExploreDesc: "",
      noSeriesDesc: "",
      isoDate: "",
      tryAnotherKeyword: "",
    });
  };

  const resetLanguageClose = (e) => {
    setInputs({
      languageName: "",
      languageCode: "",
      home: "",
      podcast: "",
      podcasts: "",
      downloads: "",
      profile: "",
      goodEvening: "",
      recentlyPlayed: "",
      dashboard: "",
      library: "",
      libraries: "",
      tenant: "",
      tenants: "",
      user: "",
      users: "",
      series: "",
      serieses: "",
      episode: "",
      episodes: "",
      language: "",
      languages: "",
      announcement: "",
      announcements: "",
      hello: "",
      accountSettings: "",
      logout: "",
      nowTrending: "",
      mostListened: "",
      newReleases: "",
      trendingCategories: "",
      podcastSeries: "",
      createSeries: "",
      seriesInfo: "",
      createEpisode: "",
      createEpisodeDesc: "",
      createCategory: "",
      createUser: "",
      typeHereToSearch: "",
      createdOn: "",
      listeningTime: "",
      listeningLevel: "",
      badge: "",
      changePassword: "",
      password: "",
      confirmPassword: "",
      passwordHint: "",
      confirmPasswordHint: "",
      btnSave: "",
      btnSaveAndAddNew: "",
      btnUpdate: "",
      btnCancel: "",
      importUser: "",
      importUserDesc: "",
      uploadCsv: "",
      uploadCsvDesc: "",
      createSeriesDesc: "",
      title: "",
      category: "",
      seriesDescription: "",
      bannerImage: "",
      bannerImageDesc: "",
      seriesImage: "",
      seriesImageDesc: "",
      selectCategory: "",
      editSeries: "",
      editSeriesDesc: "",
      createCategoryDesc: "",
      categoryImage: "",
      categoryImageDesc: "",
      editCategory: "",
      editCategoryDesc: "",
      podcastVisibility: "",
      listened: "",
      noOfEpisode: "",
      duration: "",
      editEpisode: "",
      editEpisodeDesc: "",
      podcastDuration: "",
      podcastFile: "",
      podcastFileDesc: "",
      posterImage: "",
      posterImageDesc: "",
      delete: "",
      edit: "",
      news: "",
      editAnnouncement: "",
      editAnnouncementDesc: "",
      createAnnouncement: "",
      createAnnouncementDesc: "",
      description: "",
      editNews: "",
      editPodcastUpdate: "",
      editUser: "",
      editUserDesc: "",
      firstName: "",
      lastName: "",
      email: "",
      profilePhoto: "",
      deleteTitleUser: "",
      deleteTitleAnnouncement: "",
      deleteTitleCategory: "",
      deleteTitleEpisode: "",
      deleteTitleSeries: "",
      goodMorning: "",
      continueListening: "",
      favourite: "",
      topCategories: "",
      discover: "",
      categories: "",
      search: "",
      all: "",
      allEpisodes: "",
      seeMore: "",
      myLibrary: "",
      noFavourite: "",
      recent: "",
      noRecent: "",
      noDownloads: "",
      achievements: "",
      week: "",
      month: "",
      year: "",
      average: "",
      labelCollection: "",
      badges: "",
      account: "",
      companyInformation: "",
      updates: "",
      pleaseConfirm: "",
      confirm: "",
      seeAll: "",
      logoutConfirmDesc: "",
      notifications: "",
      clearAll: "",
      sortBy: "",
      createUserDesc: "",
      podcastAudioPlayer: "",
      noSeriesMessage: "",
      noAnnouncementMessage: "",
      noUserMessage: "",
      newsImage: "",
      editProfilePhoto: "",
      editProfilePhotoDesc: "",
      profilePhotoDesc: "",
      oldPassword: "",
      share: "",
      minute: "",
      minutes: "",
      hour: "",
      hours: "",
      atoz: "",
      ztoa: "",
      newest: "",
      oldest: "",
      badgeDetails: "",
      badgeLevel: "",
      podcastUpdate: "",
      create: "",
      import: "",
      goodAfternoon: "",
      latestNews: "",
      enterAssociatedEmail: "",
      welcomeTo: "",
      resetYourPassword: "",
      played: "",
      submit: "",
      LogIn: "",
      forgotPassword: "",
      getStarted: "",
      skip: "",
      continue: "",
      badgeCollections: "",
      beginner: "",
      regular: "",
      veteran: "",
      professional: "",
      advanced: "",
      newPassword: "",
      updatedSuccess: "",
      onBoardingMobileOneTitle: "",
      onBoardingMobileTwoTitle: "",
      onBoardingMobileThreeTitle: "",
      onBoardingMobileOneDesc: "",
      onBoardingMobileTwoDesc: "",
      onBoardingMobileThreeDesc: "",
      queue: "",
      nowPlaying: "",
      scaleUp: "",
      noRecords: "",
      noRecordsMatch: "",
      seeLess: "",
      downloadStarted: "",
      downloadCompleted: "",
      noNews: "",
      noUpdates: "",
      pleaseEnterInput: "",
      errorGettingData: "",
      checkInternet: "",
      selectItemToView: "",
      allSeries: "",
      notificationAnnouncementTitle: "",
      notificationAnnouncementMessage: "",
      notificationNewsTitle: "",
      notificationNewsMessage: "",
      notificationPodcastTitle: "",
      notificationPodcastMessage: "",
      errorHashingPassword: "",
      userRegistration: "",
      tenantRegistration: "",
      userNotFound: "",
      newPasswordSubject: "",
      requiredOldPassword: "",
      requiredNewPassword: "",
      passwordNotMatchAlert: "",
      oldPasswordNotMatch: "",
      badResponse: "",
      passwordUpdated: "",
      changePasswordSuccess: "",
      changePasswordFail: "",
      uploadValidImageFile: "",
      uploadAllFiles: "",
      validProfileImage: "",
      requiredFirstname: "",
      requiredLastname: "",
      requiredEmail: "",
      requiredProfileImage: "",
      emailExists: "",
      requiredConfirmPassword: "",
      requiredFields: "",
      passwordNotMatch: "",
      tokenNotProvided: "",
      uploadValidCsvFiles: "",
      requiredImportCsvFile: "",
      requiredDeviceToken: "",
      userCreatedSuccess: "",
      userCreatedFail: "",
      userUpdatedSuccess: "",
      userUpdatedFail: "",
      userDeletedSuccess: "",
      userDeletedFail: "",
      userImportSuccess: "",
      userImportFail: "",
      userProfileUpdatedSuccess: "",
      userProfileUpdatedFail: "",
      profilePictureUpdatedSuccess: "",
      profilePictureUpdatedFail: "",
      tokenUpdatedSuccess: "",
      tokenUpdatedFail: "",
      requiredTitle: "",
      requiredDescription: "",
      requiredCategory: "",
      requiredBannerImage: "",
      requiredSeriesImage: "",
      validSeriesImage: "",
      validBannerImage: "",
      seriesCreatedSuccess: "",
      seriesCreatedFail: "",
      seriesUpdatedSuccess: "",
      seriesUpdatedFail: "",
      seriesDeletedSuccess: "",
      seriesDeletedFail: "",
      uploadValidAudioFile: "",
      requiredPosterImage: "",
      validPosterImage: "",
      requiredPodcastFile: "",
      episodeCreatedSuccess: "",
      episodeCreatedFail: "",
      episodeUpdatedSuccess: "",
      episodeUpdatedFail: "",
      episodeDeletedSuccess: "",
      episodeDeletedFail: "",
      requiredCategoryImage: "",
      validCategoryImage: "",
      categoryCreatedSuccess: "",
      categoryCreatedFail: "",
      categoryUpdatedSuccess: "",
      categoryUpdatedFail: "",
      categoryDeletedSuccess: "",
      categoryDeletedFail: "",
      announcementCreatedSuccess: "",
      announcementCreatedFail: "",
      announcementUpdatedSuccess: "",
      announcementUpdatedFail: "",
      announcementDeletedSuccess: "",
      announcementDeletedFail: "",
      dataCreatedSuccess: "",
      dataCreatedFail: "",
      dataUpdatedSuccess: "",
      dataUpdatedFail: "",
      dataDeletedSuccess: "",
      dataDeletedFail: "",
      dataGetSuccess: "",
      dataGetFail: "",
      emailRequiredRecipient: "",
      emailRequiredSubject: "",
      emailRequiredMessage: "",
      emailSentSuccess: "",
      emailSentFail: "",
      internalError: "",
      tempHello: "",
      tempUsername: "",
      tempPassword: "",
      tempNote: "",
      tempRegards: "",
      tempTeam: "",
      tempLineQuery: "",
      tempDear: "",
      userTempLineFirst: "",
      userTempLineSecond: "",
      userTempLineThird: "",
      userTempLineFourth: "",
      userTempLineFifth: "",
      tenantTempLineFirst: "",
      tenantTempLineSecond: "",
      tenantTempLineThird: "",
      tenantTempLineFourth: "",
      tenantTempLineFifth: "",
      tenantTempLineSixth: "",
      forgotPassTempLineFirst: "",
      forgotPassTempLineSecond: "",
      forgotPassTempLineThird: "",
      forgotPassTempLineFourth: "",
      loggingOut: "",
      AverageWeekly: "",
      AverageMonthly: "",
      AverageYearly: "",
      AlreadyDownloaded: "",
      activateTitle: "",
      activate: "",
      userActivatedSuccess: "",
      userActivatedFail: "",
      noInternet: "",
      notifyUpdateDesc: "",
      noAnnouncementDesc: "",
      noDiscoverDesc: "",
      noDownloadsDesc: "",
      noFavouritesDesc: "",
      noInternetDesc: "",
      noExploreDesc: "",
      noSeriesDesc: "",
      isoDate: "",
      tryAnotherKeyword: "",
    });

    setIsShownLanguage(false);
  };

  const resetEditLanguage = (e) => {
    setEditInputs({
      id: "",
      languageName: "",
      languageCode: "",
      home: "",
      podcast: "",
      podcasts: "",
      downloads: "",
      profile: "",
      goodEvening: "",
      recentlyPlayed: "",
      dashboard: "",
      library: "",
      libraries: "",
      tenant: "",
      tenants: "",
      user: "",
      users: "",
      series: "",
      serieses: "",
      episode: "",
      episodes: "",
      language: "",
      languages: "",
      announcement: "",
      announcements: "",
      hello: "",
      accountSettings: "",
      logout: "",
      nowTrending: "",
      mostListened: "",
      newReleases: "",
      trendingCategories: "",
      podcastSeries: "",
      createSeries: "",
      seriesInfo: "",
      createEpisode: "",
      createEpisodeDesc: "",
      createCategory: "",
      createUser: "",
      typeHereToSearch: "",
      createdOn: "",
      listeningTime: "",
      listeningLevel: "",
      badge: "",
      changePassword: "",
      password: "",
      confirmPassword: "",
      passwordHint: "",
      confirmPasswordHint: "",
      btnSave: "",
      btnSaveAndAddNew: "",
      btnUpdate: "",
      btnCancel: "",
      importUser: "",
      importUserDesc: "",
      uploadCsv: "",
      uploadCsvDesc: "",
      createSeriesDesc: "",
      title: "",
      category: "",
      seriesDescription: "",
      bannerImage: "",
      bannerImageDesc: "",
      seriesImage: "",
      seriesImageDesc: "",
      selectCategory: "",
      editSeries: "",
      editSeriesDesc: "",
      createCategoryDesc: "",
      categoryImage: "",
      categoryImageDesc: "",
      editCategory: "",
      editCategoryDesc: "",
      podcastVisibility: "",
      listened: "",
      noOfEpisode: "",
      duration: "",
      editEpisode: "",
      editEpisodeDesc: "",
      podcastDuration: "",
      podcastFile: "",
      podcastFileDesc: "",
      posterImage: "",
      posterImageDesc: "",
      delete: "",
      edit: "",
      news: "",
      editAnnouncement: "",
      editAnnouncementDesc: "",
      createAnnouncement: "",
      createAnnouncementDesc: "",
      description: "",
      editNews: "",
      editPodcastUpdate: "",
      editUser: "",
      editUserDesc: "",
      firstName: "",
      lastName: "",
      email: "",
      profilePhoto: "",
      deleteTitleUser: "",
      deleteTitleAnnouncement: "",
      deleteTitleCategory: "",
      deleteTitleEpisode: "",
      deleteTitleSeries: "",
      goodMorning: "",
      continueListening: "",
      favourite: "",
      topCategories: "",
      discover: "",
      categories: "",
      search: "",
      all: "",
      allEpisodes: "",
      seeMore: "",
      myLibrary: "",
      noFavourite: "",
      recent: "",
      noRecent: "",
      noDownloads: "",
      achievements: "",
      week: "",
      month: "",
      year: "",
      average: "",
      labelCollection: "",
      badges: "",
      account: "",
      companyInformation: "",
      updates: "",
      pleaseConfirm: "",
      confirm: "",
      seeAll: "",
      logoutConfirmDesc: "",
      notifications: "",
      clearAll: "",
      sortBy: "",
      createUserDesc: "",
      podcastAudioPlayer: "",
      noSeriesMessage: "",
      noAnnouncementMessage: "",
      noUserMessage: "",
      newsImage: "",
      editProfilePhoto: "",
      editProfilePhotoDesc: "",
      profilePhotoDesc: "",
      oldPassword: "",
      share: "",
      minute: "",
      minutes: "",
      hour: "",
      hours: "",
      atoz: "",
      ztoa: "",
      newest: "",
      oldest: "",
      badgeDetails: "",
      badgeLevel: "",
      podcastUpdate: "",
      create: "",
      import: "",
      goodAfternoon: "",
      latestNews: "",
      enterAssociatedEmail: "",
      welcomeTo: "",
      resetYourPassword: "",
      played: "",
      submit: "",
      LogIn: "",
      forgotPassword: "",
      getStarted: "",
      skip: "",
      continue: "",
      badgeCollections: "",
      beginner: "",
      regular: "",
      veteran: "",
      professional: "",
      advanced: "",
      newPassword: "",
      updatedSuccess: "",
      onBoardingMobileOneTitle: "",
      onBoardingMobileTwoTitle: "",
      onBoardingMobileThreeTitle: "",
      onBoardingMobileOneDesc: "",
      onBoardingMobileTwoDesc: "",
      onBoardingMobileThreeDesc: "",
      queue: "",
      nowPlaying: "",
      scaleUp: "",
      noRecords: "",
      noRecordsMatch: "",
      seeLess: "",
      downloadStarted: "",
      downloadCompleted: "",
      noNews: "",
      noUpdates: "",
      pleaseEnterInput: "",
      errorGettingData: "",
      checkInternet: "",
      selectItemToView: "",
      allSeries: "",
      notificationAnnouncementTitle: "",
      notificationAnnouncementMessage: "",
      notificationNewsTitle: "",
      notificationNewsMessage: "",
      notificationPodcastTitle: "",
      notificationPodcastMessage: "",
      errorHashingPassword: "",
      userRegistration: "",
      tenantRegistration: "",
      userNotFound: "",
      newPasswordSubject: "",
      requiredOldPassword: "",
      requiredNewPassword: "",
      passwordNotMatchAlert: "",
      oldPasswordNotMatch: "",
      badResponse: "",
      passwordUpdated: "",
      changePasswordSuccess: "",
      changePasswordFail: "",
      uploadValidImageFile: "",
      uploadAllFiles: "",
      validProfileImage: "",
      requiredFirstname: "",
      requiredLastname: "",
      requiredEmail: "",
      requiredProfileImage: "",
      emailExists: "",
      requiredConfirmPassword: "",
      requiredFields: "",
      passwordNotMatch: "",
      tokenNotProvided: "",
      uploadValidCsvFiles: "",
      requiredImportCsvFile: "",
      requiredDeviceToken: "",
      userCreatedSuccess: "",
      userCreatedFail: "",
      userUpdatedSuccess: "",
      userUpdatedFail: "",
      userDeletedSuccess: "",
      userDeletedFail: "",
      userImportSuccess: "",
      userImportFail: "",
      userProfileUpdatedSuccess: "",
      userProfileUpdatedFail: "",
      profilePictureUpdatedSuccess: "",
      profilePictureUpdatedFail: "",
      tokenUpdatedSuccess: "",
      tokenUpdatedFail: "",
      requiredTitle: "",
      requiredDescription: "",
      requiredCategory: "",
      requiredBannerImage: "",
      requiredSeriesImage: "",
      validSeriesImage: "",
      validBannerImage: "",
      seriesCreatedSuccess: "",
      seriesCreatedFail: "",
      seriesUpdatedSuccess: "",
      seriesUpdatedFail: "",
      seriesDeletedSuccess: "",
      seriesDeletedFail: "",
      uploadValidAudioFile: "",
      requiredPosterImage: "",
      validPosterImage: "",
      requiredPodcastFile: "",
      episodeCreatedSuccess: "",
      episodeCreatedFail: "",
      episodeUpdatedSuccess: "",
      episodeUpdatedFail: "",
      episodeDeletedSuccess: "",
      episodeDeletedFail: "",
      requiredCategoryImage: "",
      validCategoryImage: "",
      categoryCreatedSuccess: "",
      categoryCreatedFail: "",
      categoryUpdatedSuccess: "",
      categoryUpdatedFail: "",
      categoryDeletedSuccess: "",
      categoryDeletedFail: "",
      announcementCreatedSuccess: "",
      announcementCreatedFail: "",
      announcementUpdatedSuccess: "",
      announcementUpdatedFail: "",
      announcementDeletedSuccess: "",
      announcementDeletedFail: "",
      dataCreatedSuccess: "",
      dataCreatedFail: "",
      dataUpdatedSuccess: "",
      dataUpdatedFail: "",
      dataDeletedSuccess: "",
      dataDeletedFail: "",
      dataGetSuccess: "",
      dataGetFail: "",
      emailRequiredRecipient: "",
      emailRequiredSubject: "",
      emailRequiredMessage: "",
      emailSentSuccess: "",
      emailSentFail: "",
      internalError: "",
      tempHello: "",
      tempUsername: "",
      tempPassword: "",
      tempNote: "",
      tempRegards: "",
      tempTeam: "",
      tempLineQuery: "",
      tempDear: "",
      userTempLineFirst: "",
      userTempLineSecond: "",
      userTempLineThird: "",
      userTempLineFourth: "",
      userTempLineFifth: "",
      tenantTempLineFirst: "",
      tenantTempLineSecond: "",
      tenantTempLineThird: "",
      tenantTempLineFourth: "",
      tenantTempLineFifth: "",
      tenantTempLineSixth: "",
      forgotPassTempLineFirst: "",
      forgotPassTempLineSecond: "",
      forgotPassTempLineThird: "",
      forgotPassTempLineFourth: "",
      loggingOut: "",
      AverageWeekly: "",
      AverageMonthly: "",
      AverageYearly: "",
      AlreadyDownloaded: "",
      activateTitle: "",
      activate: "",
      userActivatedSuccess: "",
      userActivatedFail: "",
      noInternet: "",
      notifyUpdateDesc: "",
      noAnnouncementDesc: "",
      noDiscoverDesc: "",
      noDownloadsDesc: "",
      noFavouritesDesc: "",
      noInternetDesc: "",
      noExploreDesc: "",
      noSeriesDesc: "",
      isoDate: "",
      tryAnotherKeyword: "",
    });
  };

  const handleClickOpenEditLanguage = (id) => () => {
    setLoading(true);
    setSelectedIndexE(0);
    axios
      .get(`/admin/language/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          id: response._id,
          languageName: response.languageName,
          languageCode: response.languageCode,
          home: response.home,
          podcast: response.podcast,
          podcasts: response.podcasts,
          downloads: response.downloads,
          profile: response.profile,
          goodEvening: response.goodEvening,
          recentlyPlayed: response.recentlyPlayed,
          dashboard: response.dashboard,
          library: response.library,
          libraries: response.libraries,
          tenant: response.tenant,
          tenants: response.tenants,
          user: response.user,
          users: response.users,
          series: response.series,
          serieses: response.serieses,
          episode: response.episode,
          episodes: response.episodes,
          language: response.language,
          languages: response.languages,
          announcement: response.announcement,
          announcements: response.announcements,
          hello: response.hello,
          accountSettings: response.accountSettings,
          logout: response.logout,
          nowTrending: response.nowTrending,
          mostListened: response.mostListened,
          newReleases: response.newReleases,
          trendingCategories: response.trendingCategories,
          podcastSeries: response.podcastSeries,
          createSeries: response.createSeries,
          seriesInfo: response.seriesInfo,
          createEpisode: response.createEpisode,
          createEpisodeDesc: response.createEpisodeDesc,
          createCategory: response.createCategory,
          createUser: response.createUser,
          typeHereToSearch: response.typeHereToSearch,
          createdOn: response.createdOn,
          listeningTime: response.listeningTime,
          listeningLevel: response.listeningLevel,
          badge: response.badge,
          changePassword: response.changePassword,
          password: response.password,
          confirmPassword: response.confirmPassword,
          passwordHint: response.passwordHint,
          confirmPasswordHint: response.confirmPasswordHint,
          btnSave: response.btnSave,
          btnSaveAndAddNew: response.btnSaveAndAddNew,
          btnUpdate: response.btnUpdate,
          btnCancel: response.btnCancel,
          importUser: response.importUser,
          importUserDesc: response.importUserDesc,
          uploadCsv: response.uploadCsv,
          uploadCsvDesc: response.uploadCsvDesc,
          createSeriesDesc: response.createSeriesDesc,
          title: response.title,
          category: response.category,
          seriesDescription: response.seriesDescription,
          bannerImage: response.bannerImage,
          bannerImageDesc: response.bannerImageDesc,
          seriesImage: response.seriesImage,
          seriesImageDesc: response.seriesImageDesc,
          selectCategory: response.selectCategory,
          editSeries: response.editSeries,
          editSeriesDesc: response.editSeriesDesc,
          createCategoryDesc: response.createCategoryDesc,
          categoryImage: response.categoryImage,
          categoryImageDesc: response.categoryImageDesc,
          editCategory: response.editCategory,
          editCategoryDesc: response.editCategoryDesc,
          podcastVisibility: response.podcastVisibility,
          listened: response.listened,
          noOfEpisode: response.noOfEpisode,
          duration: response.duration,
          editEpisode: response.editEpisode,
          editEpisodeDesc: response.editEpisodeDesc,
          podcastDuration: response.podcastDuration,
          podcastFile: response.podcastFile,
          podcastFileDesc: response.podcastFileDesc,
          posterImage: response.posterImage,
          posterImageDesc: response.posterImageDesc,
          delete: response.delete,
          edit: response.edit,
          news: response.news,
          editAnnouncement: response.editAnnouncement,
          editAnnouncementDesc: response.editAnnouncementDesc,
          createAnnouncement: response.createAnnouncement,
          createAnnouncementDesc: response.createAnnouncementDesc,
          description: response.description,
          editNews: response.editNews,
          editPodcastUpdate: response.editPodcastUpdate,
          editUser: response.editUser,
          editUserDesc: response.editUserDesc,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          profilePhoto: response.profilePhoto,
          deleteTitleUser: response.deleteTitleUser,
          deleteTitleAnnouncement: response.deleteTitleAnnouncement,
          deleteTitleCategory: response.deleteTitleCategory,
          deleteTitleEpisode: response.deleteTitleEpisode,
          deleteTitleSeries: response.deleteTitleSeries,
          goodMorning: response.goodMorning,
          continueListening: response.continueListening,
          favourite: response.favourite,
          topCategories: response.topCategories,
          discover: response.discover,
          categories: response.categories,
          search: response.search,
          all: response.all,
          allEpisodes: response.allEpisodes,
          seeMore: response.seeMore,
          myLibrary: response.myLibrary,
          noFavourite: response.noFavourite,
          recent: response.recent,
          noRecent: response.noRecent,
          noDownloads: response.noDownloads,
          achievements: response.achievements,
          week: response.week,
          month: response.month,
          year: response.year,
          average: response.average,
          labelCollection: response.labelCollection,
          badges: response.badges,
          account: response.account,
          companyInformation: response.companyInformation,
          updates: response.updates,
          pleaseConfirm: response.pleaseConfirm,
          confirm: response.confirm,
          seeAll: response.seeAll,
          logoutConfirmDesc: response.logoutConfirmDesc,
          notifications: response.notifications,
          clearAll: response.clearAll,
          sortBy: response.sortBy,
          createUserDesc: response.createUserDesc,
          podcastAudioPlayer: response.podcastAudioPlayer,
          noSeriesMessage: response.noSeriesMessage,
          noAnnouncementMessage: response.noAnnouncementMessage,
          noUserMessage: response.noUserMessage,
          newsImage: response.newsImage,
          editProfilePhoto: response.editProfilePhoto,
          editProfilePhotoDesc: response.editProfilePhotoDesc,
          profilePhotoDesc: response.profilePhotoDesc,
          oldPassword: response.oldPassword,
          share: response.share,
          minute: response.minute,
          minutes: response.minutes,
          hour: response.hour,
          hours: response.hours,
          atoz: response.atoz,
          ztoa: response.ztoa,
          newest: response.newest,
          oldest: response.oldest,
          badgeDetails: response.badgeDetails,
          badgeLevel: response.badgeLevel,
          podcastUpdate: response.podcastUpdate,
          create: response.create,
          import: response.import,
          goodAfternoon: response.goodAfternoon,
          latestNews: response.latestNews,
          enterAssociatedEmail: response.enterAssociatedEmail,
          welcomeTo: response.welcomeTo,
          resetYourPassword: response.resetYourPassword,
          played: response.played,
          submit: response.submit,
          LogIn: response.LogIn,
          forgotPassword: response.forgotPassword,
          getStarted: response.getStarted,
          skip: response.skip,
          continue: response.continue,
          badgeCollections: response.badgeCollections,
          beginner: response.beginner,
          regular: response.regular,
          veteran: response.veteran,
          professional: response.professional,
          advanced: response.advanced,
          newPassword: response.newPassword,
          updatedSuccess: response.updatedSuccess,
          onBoardingMobileOneTitle: response.onBoardingMobileOneTitle,
          onBoardingMobileTwoTitle: response.onBoardingMobileTwoTitle,
          onBoardingMobileThreeTitle: response.onBoardingMobileThreeTitle,
          onBoardingMobileOneDesc: response.onBoardingMobileOneDesc,
          onBoardingMobileTwoDesc: response.onBoardingMobileTwoDesc,
          onBoardingMobileThreeDesc: response.onBoardingMobileThreeDesc,
          queue: response.queue,
          nowPlaying: response.nowPlaying,
          scaleUp: response.scaleUp,
          noRecords: response.noRecords,
          noRecordsMatch: response.noRecordsMatch,
          seeLess: response.seeLess,
          downloadStarted: response.downloadStarted,
          downloadCompleted: response.downloadCompleted,
          noNews: response.noNews,
          noUpdates: response.noUpdates,
          pleaseEnterInput: response.pleaseEnterInput,
          errorGettingData: response.errorGettingData,
          checkInternet: response.checkInternet,
          selectItemToView: response.selectItemToView,
          allSeries: response.allSeries,
          notificationAnnouncementTitle: response.notificationAnnouncementTitle,
          notificationAnnouncementMessage:
            response.notificationAnnouncementMessage,
          notificationNewsTitle: response.notificationNewsTitle,
          notificationNewsMessage: response.notificationNewsMessage,
          notificationPodcastTitle: response.notificationPodcastTitle,
          notificationPodcastMessage: response.notificationPodcastMessage,
          errorHashingPassword: response.errorHashingPassword,
          userRegistration: response.userRegistration,
          tenantRegistration: response.tenantRegistration,
          userNotFound: response.userNotFound,
          newPasswordSubject: response.newPasswordSubject,
          requiredOldPassword: response.requiredOldPassword,
          requiredNewPassword: response.requiredNewPassword,
          passwordNotMatchAlert: response.passwordNotMatchAlert,
          oldPasswordNotMatch: response.oldPasswordNotMatch,
          badResponse: response.badResponse,
          passwordUpdated: response.passwordUpdated,
          changePasswordSuccess: response.changePasswordSuccess,
          changePasswordFail: response.changePasswordFail,
          uploadValidImageFile: response.uploadValidImageFile,
          uploadAllFiles: response.uploadAllFiles,
          validProfileImage: response.validProfileImage,
          requiredFirstname: response.requiredFirstname,
          requiredLastname: response.requiredLastname,
          requiredEmail: response.requiredEmail,
          requiredProfileImage: response.requiredProfileImage,
          emailExists: response.emailExists,
          requiredConfirmPassword: response.requiredConfirmPassword,
          requiredFields: response.requiredFields,
          passwordNotMatch: response.passwordNotMatch,
          tokenNotProvided: response.tokenNotProvided,
          uploadValidCsvFiles: response.uploadValidCsvFiles,
          requiredImportCsvFile: response.requiredImportCsvFile,
          requiredDeviceToken: response.requiredDeviceToken,
          userCreatedSuccess: response.userCreatedSuccess,
          userCreatedFail: response.userCreatedFail,
          userUpdatedSuccess: response.userUpdatedSuccess,
          userUpdatedFail: response.userUpdatedFail,
          userDeletedSuccess: response.userDeletedSuccess,
          userDeletedFail: response.userDeletedFail,
          userImportSuccess: response.userImportSuccess,
          userImportFail: response.userImportFail,
          userProfileUpdatedSuccess: response.userProfileUpdatedSuccess,
          userProfileUpdatedFail: response.userProfileUpdatedFail,
          profilePictureUpdatedSuccess: response.profilePictureUpdatedSuccess,
          profilePictureUpdatedFail: response.profilePictureUpdatedFail,
          tokenUpdatedSuccess: response.tokenUpdatedSuccess,
          tokenUpdatedFail: response.tokenUpdatedFail,
          requiredTitle: response.requiredTitle,
          requiredDescription: response.requiredDescription,
          requiredCategory: response.requiredCategory,
          requiredBannerImage: response.requiredBannerImage,
          requiredSeriesImage: response.requiredSeriesImage,
          validSeriesImage: response.validSeriesImage,
          validBannerImage: response.validBannerImage,
          seriesCreatedSuccess: response.seriesCreatedSuccess,
          seriesCreatedFail: response.seriesCreatedFail,
          seriesUpdatedSuccess: response.seriesUpdatedSuccess,
          seriesUpdatedFail: response.seriesUpdatedFail,
          seriesDeletedSuccess: response.seriesDeletedSuccess,
          seriesDeletedFail: response.seriesDeletedFail,
          uploadValidAudioFile: response.uploadValidAudioFile,
          requiredPosterImage: response.requiredPosterImage,
          validPosterImage: response.validPosterImage,
          requiredPodcastFile: response.requiredPodcastFile,
          episodeCreatedSuccess: response.episodeCreatedSuccess,
          episodeCreatedFail: response.episodeCreatedFail,
          episodeUpdatedSuccess: response.episodeUpdatedSuccess,
          episodeUpdatedFail: response.episodeUpdatedFail,
          episodeDeletedSuccess: response.episodeDeletedSuccess,
          episodeDeletedFail: response.episodeDeletedFail,
          requiredCategoryImage: response.requiredCategoryImage,
          validCategoryImage: response.validCategoryImage,
          categoryCreatedSuccess: response.categoryCreatedSuccess,
          categoryCreatedFail: response.categoryCreatedFail,
          categoryUpdatedSuccess: response.categoryUpdatedSuccess,
          categoryUpdatedFail: response.categoryUpdatedFail,
          categoryDeletedSuccess: response.categoryDeletedSuccess,
          categoryDeletedFail: response.categoryDeletedFail,
          announcementCreatedSuccess: response.announcementCreatedSuccess,
          announcementCreatedFail: response.announcementCreatedFail,
          announcementUpdatedSuccess: response.announcementUpdatedSuccess,
          announcementUpdatedFail: response.announcementUpdatedFail,
          announcementDeletedSuccess: response.announcementDeletedSuccess,
          announcementDeletedFail: response.announcementDeletedFail,
          dataCreatedSuccess: response.dataCreatedSuccess,
          dataCreatedFail: response.dataCreatedFail,
          dataUpdatedSuccess: response.dataUpdatedSuccess,
          dataUpdatedFail: response.dataUpdatedFail,
          dataDeletedSuccess: response.dataDeletedSuccess,
          dataDeletedFail: response.dataDeletedFail,
          dataGetSuccess: response.dataGetSuccess,
          dataGetFail: response.dataGetFail,
          emailRequiredRecipient: response.emailRequiredRecipient,
          emailRequiredSubject: response.emailRequiredSubject,
          emailRequiredMessage: response.emailRequiredMessage,
          emailSentSuccess: response.emailSentSuccess,
          emailSentFail: response.emailSentFail,
          internalError: response.internalError,
          tempHello: response.tempHello,
          tempUsername: response.tempUsername,
          tempPassword: response.tempPassword,
          tempNote: response.tempNote,
          tempRegards: response.tempRegards,
          tempTeam: response.tempTeam,
          tempLineQuery: response.tempLineQuery,
          tempDear: response.tempDear,
          userTempLineFirst: response.userTempLineFirst,
          userTempLineSecond: response.userTempLineSecond,
          userTempLineThird: response.userTempLineThird,
          userTempLineFourth: response.userTempLineFourth,
          userTempLineFifth: response.userTempLineFifth,
          tenantTempLineFirst: response.tenantTempLineFirst,
          tenantTempLineSecond: response.tenantTempLineSecond,
          tenantTempLineThird: response.tenantTempLineThird,
          tenantTempLineFourth: response.tenantTempLineFourth,
          tenantTempLineFifth: response.tenantTempLineFifth,
          tenantTempLineSixth: response.tenantTempLineSixth,
          forgotPassTempLineFirst: response.forgotPassTempLineFirst,
          forgotPassTempLineSecond: response.forgotPassTempLineSecond,
          forgotPassTempLineThird: response.forgotPassTempLineThird,
          forgotPassTempLineFourth: response.forgotPassTempLineFourth,
          loggingOut: response.loggingOut,
          AverageWeekly: response.AverageWeekly,
          AverageMonthly: response.AverageMonthly,
          AverageYearly: response.AverageYearly,
          AlreadyDownloaded: response.AlreadyDownloaded,
          activateTitle: response.activateTitle,
          activate: response.activate,
          userActivatedSuccess: response.userActivatedSuccess,
          userActivatedFail: response.userActivatedFail,
          noInternet: response.noInternet,
          notifyUpdateDesc: response.notifyUpdateDesc,
          noAnnouncementDesc: response.noAnnouncementDesc,
          noDiscoverDesc: response.noDiscoverDesc,
          noDownloadsDesc: response.noDownloadsDesc,
          noFavouritesDesc: response.noFavouritesDesc,
          noInternetDesc: response.noInternetDesc,
          noExploreDesc: response.noExploreDesc,
          noSeriesDesc: response.noSeriesDesc,
          isoDate: response.isoDate,
          tryAnotherKeyword: response.tryAnotherKeyword,
        });

        setLoading(false);
        setIsShownEditLanguage(true);
      })
      .catch((error) => {
        setLoading(false);
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  async function handleSubmitLanguage(e) {
    e.preventDefault();
    if (submittingSave) {
      return;
    }
    setSubmittingSave(true);
    setLoading(true);

    const data = {
      languageName: inputs.languageName,
      languageCode: inputs.languageCode,
      home: inputs.home,
      podcast: inputs.podcast,
      podcasts: inputs.podcasts,
      downloads: inputs.downloads,
      profile: inputs.profile,
      goodEvening: inputs.goodEvening,
      recentlyPlayed: inputs.recentlyPlayed,
      dashboard: inputs.dashboard,
      library: inputs.library,
      libraries: inputs.libraries,
      tenant: inputs.tenant,
      tenants: inputs.tenants,
      user: inputs.user,
      users: inputs.users,
      series: inputs.series,
      serieses: inputs.serieses,
      episode: inputs.episode,
      episodes: inputs.episodes,
      language: inputs.language,
      languages: inputs.languages,
      announcement: inputs.announcement,
      announcements: inputs.announcements,
      hello: inputs.hello,
      accountSettings: inputs.accountSettings,
      logout: inputs.logout,
      nowTrending: inputs.nowTrending,
      mostListened: inputs.mostListened,
      newReleases: inputs.newReleases,
      trendingCategories: inputs.trendingCategories,
      podcastSeries: inputs.podcastSeries,
      createSeries: inputs.createSeries,
      seriesInfo: inputs.seriesInfo,
      createEpisode: inputs.createEpisode,
      createEpisodeDesc: inputs.createEpisodeDesc,
      createCategory: inputs.createCategory,
      createUser: inputs.createUser,
      typeHereToSearch: inputs.typeHereToSearch,
      createdOn: inputs.createdOn,
      listeningTime: inputs.listeningTime,
      listeningLevel: inputs.listeningLevel,
      badge: inputs.badge,
      changePassword: inputs.changePassword,
      password: inputs.password,
      confirmPassword: inputs.confirmPassword,
      passwordHint: inputs.passwordHint,
      confirmPasswordHint: inputs.confirmPasswordHint,
      btnSave: inputs.btnSave,
      btnSaveAndAddNew: inputs.btnSaveAndAddNew,
      btnUpdate: inputs.btnUpdate,
      btnCancel: inputs.btnCancel,
      importUser: inputs.importUser,
      importUserDesc: inputs.importUserDesc,
      uploadCsv: inputs.uploadCsv,
      uploadCsvDesc: inputs.uploadCsvDesc,
      createSeriesDesc: inputs.createSeriesDesc,
      title: inputs.title,
      category: inputs.category,
      seriesDescription: inputs.seriesDescription,
      bannerImage: inputs.bannerImage,
      bannerImageDesc: inputs.bannerImageDesc,
      seriesImage: inputs.seriesImage,
      seriesImageDesc: inputs.seriesImageDesc,
      selectCategory: inputs.selectCategory,
      editSeries: inputs.editSeries,
      editSeriesDesc: inputs.editSeriesDesc,
      createCategoryDesc: inputs.createCategoryDesc,
      categoryImage: inputs.categoryImage,
      categoryImageDesc: inputs.categoryImageDesc,
      editCategory: inputs.editCategory,
      editCategoryDesc: inputs.editCategoryDesc,
      podcastVisibility: inputs.podcastVisibility,
      listened: inputs.listened,
      noOfEpisode: inputs.noOfEpisode,
      duration: inputs.duration,
      editEpisode: inputs.editEpisode,
      editEpisodeDesc: inputs.editEpisodeDesc,
      podcastDuration: inputs.podcastDuration,
      podcastFile: inputs.podcastFile,
      podcastFileDesc: inputs.podcastFileDesc,
      posterImage: inputs.posterImage,
      posterImageDesc: inputs.posterImageDesc,
      delete: inputs.delete,
      edit: inputs.edit,
      news: inputs.news,
      editAnnouncement: inputs.editAnnouncement,
      editAnnouncementDesc: inputs.editAnnouncementDesc,
      createAnnouncement: inputs.createAnnouncement,
      createAnnouncementDesc: inputs.createAnnouncementDesc,
      description: inputs.description,
      editNews: inputs.editNews,
      editPodcastUpdate: inputs.editPodcastUpdate,
      editUser: inputs.editUser,
      editUserDesc: inputs.editUserDesc,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      profilePhoto: inputs.profilePhoto,
      deleteTitleUser: inputs.deleteTitleUser,
      deleteTitleAnnouncement: inputs.deleteTitleAnnouncement,
      deleteTitleCategory: inputs.deleteTitleCategory,
      deleteTitleEpisode: inputs.deleteTitleEpisode,
      deleteTitleSeries: inputs.deleteTitleSeries,
      goodMorning: inputs.goodMorning,
      continueListening: inputs.continueListening,
      favourite: inputs.favourite,
      topCategories: inputs.topCategories,
      discover: inputs.discover,
      categories: inputs.categories,
      search: inputs.search,
      all: inputs.all,
      allEpisodes: inputs.allEpisodes,
      seeMore: inputs.seeMore,
      myLibrary: inputs.myLibrary,
      noFavourite: inputs.noFavourite,
      recent: inputs.recent,
      noRecent: inputs.noRecent,
      noDownloads: inputs.noDownloads,
      achievements: inputs.achievements,
      week: inputs.week,
      month: inputs.month,
      year: inputs.year,
      average: inputs.average,
      labelCollection: inputs.labelCollection,
      badges: inputs.badges,
      account: inputs.account,
      companyInformation: inputs.companyInformation,
      updates: inputs.updates,
      pleaseConfirm: inputs.pleaseConfirm,
      confirm: inputs.confirm,
      seeAll: inputs.seeAll,
      logoutConfirmDesc: inputs.logoutConfirmDesc,
      notifications: inputs.notifications,
      clearAll: inputs.clearAll,
      sortBy: inputs.sortBy,
      createUserDesc: inputs.createUserDesc,
      podcastAudioPlayer: inputs.podcastAudioPlayer,
      noSeriesMessage: inputs.noSeriesMessage,
      noAnnouncementMessage: inputs.noAnnouncementMessage,
      noUserMessage: inputs.noUserMessage,
      newsImage: inputs.newsImage,
      editProfilePhoto: inputs.editProfilePhoto,
      editProfilePhotoDesc: inputs.editProfilePhotoDesc,
      profilePhotoDesc: inputs.profilePhotoDesc,
      oldPassword: inputs.oldPassword,
      share: inputs.share,
      minute: inputs.minute,
      minutes: inputs.minutes,
      hour: inputs.hour,
      hours: inputs.hours,
      atoz: inputs.atoz,
      ztoa: inputs.ztoa,
      newest: inputs.newest,
      oldest: inputs.oldest,
      badgeDetails: inputs.badgeDetails,
      badgeLevel: inputs.badgeLevel,
      podcastUpdate: inputs.podcastUpdate,
      create: inputs.create,
      import: inputs.import,
      goodAfternoon: inputs.goodAfternoon,
      latestNews: inputs.latestNews,
      enterAssociatedEmail: inputs.enterAssociatedEmail,
      welcomeTo: inputs.welcomeTo,
      resetYourPassword: inputs.resetYourPassword,
      played: inputs.played,
      submit: inputs.submit,
      LogIn: inputs.LogIn,
      forgotPassword: inputs.forgotPassword,
      getStarted: inputs.getStarted,
      skip: inputs.skip,
      continue: inputs.continue,
      badgeCollections: inputs.badgeCollections,
      beginner: inputs.beginner,
      regular: inputs.regular,
      veteran: inputs.veteran,
      professional: inputs.professional,
      advanced: inputs.advanced,
      newPassword: inputs.newPassword,
      updatedSuccess: inputs.updatedSuccess,
      onBoardingMobileOneTitle: inputs.onBoardingMobileOneTitle,
      onBoardingMobileTwoTitle: inputs.onBoardingMobileTwoTitle,
      onBoardingMobileThreeTitle: inputs.onBoardingMobileThreeTitle,
      onBoardingMobileOneDesc: inputs.onBoardingMobileOneDesc,
      onBoardingMobileTwoDesc: inputs.onBoardingMobileTwoDesc,
      onBoardingMobileThreeDesc: inputs.onBoardingMobileThreeDesc,
      queue: inputs.queue,
      nowPlaying: inputs.nowPlaying,
      scaleUp: inputs.scaleUp,
      noRecords: inputs.noRecords,
      noRecordsMatch: inputs.noRecordsMatch,
      seeLess: inputs.seeLess,
      downloadStarted: inputs.downloadStarted,
      downloadCompleted: inputs.downloadCompleted,
      noNews: inputs.noNews,
      noUpdates: inputs.noUpdates,
      pleaseEnterInput: inputs.pleaseEnterInput,
      errorGettingData: inputs.errorGettingData,
      checkInternet: inputs.checkInternet,
      selectItemToView: inputs.selectItemToView,
      allSeries: inputs.allSeries,
      notificationAnnouncementTitle: inputs.notificationAnnouncementTitle,
      notificationAnnouncementMessage: inputs.notificationAnnouncementMessage,
      notificationNewsTitle: inputs.notificationNewsTitle,
      notificationNewsMessage: inputs.notificationNewsMessage,
      notificationPodcastTitle: inputs.notificationPodcastTitle,
      notificationPodcastMessage: inputs.notificationPodcastMessage,
      errorHashingPassword: inputs.errorHashingPassword,
      userRegistration: inputs.userRegistration,
      tenantRegistration: inputs.tenantRegistration,
      userNotFound: inputs.userNotFound,
      newPasswordSubject: inputs.newPasswordSubject,
      requiredOldPassword: inputs.requiredOldPassword,
      requiredNewPassword: inputs.requiredNewPassword,
      passwordNotMatchAlert: inputs.passwordNotMatchAlert,
      oldPasswordNotMatch: inputs.oldPasswordNotMatch,
      badResponse: inputs.badResponse,
      passwordUpdated: inputs.passwordUpdated,
      changePasswordSuccess: inputs.changePasswordSuccess,
      changePasswordFail: inputs.changePasswordFail,
      uploadValidImageFile: inputs.uploadValidImageFile,
      uploadAllFiles: inputs.uploadAllFiles,
      validProfileImage: inputs.validProfileImage,
      requiredFirstname: inputs.requiredFirstname,
      requiredLastname: inputs.requiredLastname,
      requiredEmail: inputs.requiredEmail,
      requiredProfileImage: inputs.requiredProfileImage,
      emailExists: inputs.emailExists,
      requiredConfirmPassword: inputs.requiredConfirmPassword,
      requiredFields: inputs.requiredFields,
      passwordNotMatch: inputs.passwordNotMatch,
      tokenNotProvided: inputs.tokenNotProvided,
      uploadValidCsvFiles: inputs.uploadValidCsvFiles,
      requiredImportCsvFile: inputs.requiredImportCsvFile,
      requiredDeviceToken: inputs.requiredDeviceToken,
      userCreatedSuccess: inputs.userCreatedSuccess,
      userCreatedFail: inputs.userCreatedFail,
      userUpdatedSuccess: inputs.userUpdatedSuccess,
      userUpdatedFail: inputs.userUpdatedFail,
      userDeletedSuccess: inputs.userDeletedSuccess,
      userDeletedFail: inputs.userDeletedFail,
      userImportSuccess: inputs.userImportSuccess,
      userImportFail: inputs.userImportFail,
      userProfileUpdatedSuccess: inputs.userProfileUpdatedSuccess,
      userProfileUpdatedFail: inputs.userProfileUpdatedFail,
      profilePictureUpdatedSuccess: inputs.profilePictureUpdatedSuccess,
      profilePictureUpdatedFail: inputs.profilePictureUpdatedFail,
      tokenUpdatedSuccess: inputs.tokenUpdatedSuccess,
      tokenUpdatedFail: inputs.tokenUpdatedFail,
      requiredTitle: inputs.requiredTitle,
      requiredDescription: inputs.requiredDescription,
      requiredCategory: inputs.requiredCategory,
      requiredBannerImage: inputs.requiredBannerImage,
      requiredSeriesImage: inputs.requiredSeriesImage,
      validSeriesImage: inputs.validSeriesImage,
      validBannerImage: inputs.validBannerImage,
      seriesCreatedSuccess: inputs.seriesCreatedSuccess,
      seriesCreatedFail: inputs.seriesCreatedFail,
      seriesUpdatedSuccess: inputs.seriesUpdatedSuccess,
      seriesUpdatedFail: inputs.seriesUpdatedFail,
      seriesDeletedSuccess: inputs.seriesDeletedSuccess,
      seriesDeletedFail: inputs.seriesDeletedFail,
      uploadValidAudioFile: inputs.uploadValidAudioFile,
      requiredPosterImage: inputs.requiredPosterImage,
      validPosterImage: inputs.validPosterImage,
      requiredPodcastFile: inputs.requiredPodcastFile,
      episodeCreatedSuccess: inputs.episodeCreatedSuccess,
      episodeCreatedFail: inputs.episodeCreatedFail,
      episodeUpdatedSuccess: inputs.episodeUpdatedSuccess,
      episodeUpdatedFail: inputs.episodeUpdatedFail,
      episodeDeletedSuccess: inputs.episodeDeletedSuccess,
      episodeDeletedFail: inputs.episodeDeletedFail,
      requiredCategoryImage: inputs.requiredCategoryImage,
      validCategoryImage: inputs.validCategoryImage,
      categoryCreatedSuccess: inputs.categoryCreatedSuccess,
      categoryCreatedFail: inputs.categoryCreatedFail,
      categoryUpdatedSuccess: inputs.categoryUpdatedSuccess,
      categoryUpdatedFail: inputs.categoryUpdatedFail,
      categoryDeletedSuccess: inputs.categoryDeletedSuccess,
      categoryDeletedFail: inputs.categoryDeletedFail,
      announcementCreatedSuccess: inputs.announcementCreatedSuccess,
      announcementCreatedFail: inputs.announcementCreatedFail,
      announcementUpdatedSuccess: inputs.announcementUpdatedSuccess,
      announcementUpdatedFail: inputs.announcementUpdatedFail,
      announcementDeletedSuccess: inputs.announcementDeletedSuccess,
      announcementDeletedFail: inputs.announcementDeletedFail,
      dataCreatedSuccess: inputs.dataCreatedSuccess,
      dataCreatedFail: inputs.dataCreatedFail,
      dataUpdatedSuccess: inputs.dataUpdatedSuccess,
      dataUpdatedFail: inputs.dataUpdatedFail,
      dataDeletedSuccess: inputs.dataDeletedSuccess,
      dataDeletedFail: inputs.dataDeletedFail,
      dataGetSuccess: inputs.dataGetSuccess,
      dataGetFail: inputs.dataGetFail,
      emailRequiredRecipient: inputs.emailRequiredRecipient,
      emailRequiredSubject: inputs.emailRequiredSubject,
      emailRequiredMessage: inputs.emailRequiredMessage,
      emailSentSuccess: inputs.emailSentSuccess,
      emailSentFail: inputs.emailSentFail,
      internalError: inputs.internalError,
      tempHello: inputs.tempHello,
      tempUsername: inputs.tempUsername,
      tempPassword: inputs.tempPassword,
      tempNote: inputs.tempNote,
      tempRegards: inputs.tempRegards,
      tempTeam: inputs.tempTeam,
      tempLineQuery: inputs.tempLineQuery,
      tempDear: inputs.tempDear,
      userTempLineFirst: inputs.userTempLineFirst,
      userTempLineSecond: inputs.userTempLineSecond,
      userTempLineThird: inputs.userTempLineThird,
      userTempLineFourth: inputs.userTempLineFourth,
      userTempLineFifth: inputs.userTempLineFifth,
      tenantTempLineFirst: inputs.tenantTempLineFirst,
      tenantTempLineSecond: inputs.tenantTempLineSecond,
      tenantTempLineThird: inputs.tenantTempLineThird,
      tenantTempLineFourth: inputs.tenantTempLineFourth,
      tenantTempLineFifth: inputs.tenantTempLineFifth,
      tenantTempLineSixth: inputs.tenantTempLineSixth,
      forgotPassTempLineFirst: inputs.forgotPassTempLineFirst,
      forgotPassTempLineSecond: inputs.forgotPassTempLineSecond,
      forgotPassTempLineThird: inputs.forgotPassTempLineThird,
      forgotPassTempLineFourth: inputs.forgotPassTempLineFourth,
      loggingOut: inputs.loggingOut,
      AverageWeekly: inputs.AverageWeekly,
      AverageMonthly: inputs.AverageMonthly,
      AverageYearly: inputs.AverageYearly,
      AlreadyDownloaded: inputs.AlreadyDownloaded,
      activateTitle: inputs.activateTitle,
      activate: inputs.activate,
      userActivatedSuccess: inputs.userActivatedSuccess,
      userActivatedFail: inputs.userActivatedFail,
      noInternet: inputs.noInternet,
      notifyUpdateDesc: inputs.notifyUpdateDesc,
      noAnnouncementDesc: inputs.noAnnouncementDesc,
      noDiscoverDesc: inputs.noDiscoverDesc,
      noDownloadsDesc: inputs.noDownloadsDesc,
      noFavouritesDesc: inputs.noFavouritesDesc,
      noInternetDesc: inputs.noInternetDesc,
      noExploreDesc: inputs.noExploreDesc,
      noSeriesDesc: inputs.noSeriesDesc,
      isoDate: inputs.isoDate, 
      tryAnotherKeyword: inputs.tryAnotherKeyword, 
    };

    await axios
      .post("/admin/language", data)
      .then((res) => {
        if (res.data) {
          getLanguages();
          if (btnLanguage) {
            resetLanguageClose();
            setBtnLanguage(false);
          } else {
            resetLanguage();
            setSelectedIndex(0);
          }
        }
        setSubmittingSave(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
      })
      .catch((error) => {
        setSubmittingSave(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  async function handleSubmitEditLanguage(e) {
    e.preventDefault();
    if (submittingUpdate) {
      return;
    }
    setSubmittingUpdate(true);
    setLoading(true);
    const id = editInputs.id;

    const data = {
      languageName: editInputs.languageName,
      languageCode: editInputs.languageCode,
      home: editInputs.home,
      podcast: editInputs.podcast,
      podcasts: editInputs.podcasts,
      downloads: editInputs.downloads,
      profile: editInputs.profile,
      goodEvening: editInputs.goodEvening,
      recentlyPlayed: editInputs.recentlyPlayed,
      dashboard: editInputs.dashboard,
      library: editInputs.library,
      libraries: editInputs.libraries,
      tenant: editInputs.tenant,
      tenants: editInputs.tenants,
      user: editInputs.user,
      users: editInputs.users,
      series: editInputs.series,
      serieses: editInputs.serieses,
      episode: editInputs.episode,
      episodes: editInputs.episodes,
      language: editInputs.language,
      languages: editInputs.languages,
      announcement: editInputs.announcement,
      announcements: editInputs.announcements,
      hello: editInputs.hello,
      accountSettings: editInputs.accountSettings,
      logout: editInputs.logout,
      nowTrending: editInputs.nowTrending,
      mostListened: editInputs.mostListened,
      newReleases: editInputs.newReleases,
      trendingCategories: editInputs.trendingCategories,
      podcastSeries: editInputs.podcastSeries,
      createSeries: editInputs.createSeries,
      seriesInfo: editInputs.seriesInfo,
      createEpisode: editInputs.createEpisode,
      createEpisodeDesc: editInputs.createEpisodeDesc,
      createCategory: editInputs.createCategory,
      createUser: editInputs.createUser,
      typeHereToSearch: editInputs.typeHereToSearch,
      createdOn: editInputs.createdOn,
      listeningTime: editInputs.listeningTime,
      listeningLevel: editInputs.listeningLevel,
      badge: editInputs.badge,
      changePassword: editInputs.changePassword,
      password: editInputs.password,
      confirmPassword: editInputs.confirmPassword,
      passwordHint: editInputs.passwordHint,
      confirmPasswordHint: editInputs.confirmPasswordHint,
      btnSave: editInputs.btnSave,
      btnSaveAndAddNew: editInputs.btnSaveAndAddNew,
      btnUpdate: editInputs.btnUpdate,
      btnCancel: editInputs.btnCancel,
      importUser: editInputs.importUser,
      importUserDesc: editInputs.importUserDesc,
      uploadCsv: editInputs.uploadCsv,
      uploadCsvDesc: editInputs.uploadCsvDesc,
      createSeriesDesc: editInputs.createSeriesDesc,
      title: editInputs.title,
      category: editInputs.category,
      seriesDescription: editInputs.seriesDescription,
      bannerImage: editInputs.bannerImage,
      bannerImageDesc: editInputs.bannerImageDesc,
      seriesImage: editInputs.seriesImage,
      seriesImageDesc: editInputs.seriesImageDesc,
      selectCategory: editInputs.selectCategory,
      editSeries: editInputs.editSeries,
      editSeriesDesc: editInputs.editSeriesDesc,
      createCategoryDesc: editInputs.createCategoryDesc,
      categoryImage: editInputs.categoryImage,
      categoryImageDesc: editInputs.categoryImageDesc,
      editCategory: editInputs.editCategory,
      editCategoryDesc: editInputs.editCategoryDesc,
      podcastVisibility: editInputs.podcastVisibility,
      listened: editInputs.listened,
      noOfEpisode: editInputs.noOfEpisode,
      duration: editInputs.duration,
      editEpisode: editInputs.editEpisode,
      editEpisodeDesc: editInputs.editEpisodeDesc,
      podcastDuration: editInputs.podcastDuration,
      podcastFile: editInputs.podcastFile,
      podcastFileDesc: editInputs.podcastFileDesc,
      posterImage: editInputs.posterImage,
      posterImageDesc: editInputs.posterImageDesc,
      delete: editInputs.delete,
      edit: editInputs.edit,
      news: editInputs.news,
      editAnnouncement: editInputs.editAnnouncement,
      editAnnouncementDesc: editInputs.editAnnouncementDesc,
      createAnnouncement: editInputs.createAnnouncement,
      createAnnouncementDesc: editInputs.createAnnouncementDesc,
      description: editInputs.description,
      editNews: editInputs.editNews,
      editPodcastUpdate: editInputs.editPodcastUpdate,
      editUser: editInputs.editUser,
      editUserDesc: editInputs.editUserDesc,
      firstName: editInputs.firstName,
      lastName: editInputs.lastName,
      email: editInputs.email,
      profilePhoto: editInputs.profilePhoto,
      deleteTitleUser: editInputs.deleteTitleUser,
      deleteTitleAnnouncement: editInputs.deleteTitleAnnouncement,
      deleteTitleCategory: editInputs.deleteTitleCategory,
      deleteTitleEpisode: editInputs.deleteTitleEpisode,
      deleteTitleSeries: editInputs.deleteTitleSeries,
      goodMorning: editInputs.goodMorning,
      continueListening: editInputs.continueListening,
      favourite: editInputs.favourite,
      topCategories: editInputs.topCategories,
      discover: editInputs.discover,
      categories: editInputs.categories,
      search: editInputs.search,
      all: editInputs.all,
      allEpisodes: editInputs.allEpisodes,
      seeMore: editInputs.seeMore,
      myLibrary: editInputs.myLibrary,
      noFavourite: editInputs.noFavourite,
      recent: editInputs.recent,
      noRecent: editInputs.noRecent,
      noDownloads: editInputs.noDownloads,
      achievements: editInputs.achievements,
      week: editInputs.week,
      month: editInputs.month,
      year: editInputs.year,
      average: editInputs.average,
      labelCollection: editInputs.labelCollection,
      badges: editInputs.badges,
      account: editInputs.account,
      companyInformation: editInputs.companyInformation,
      updates: editInputs.updates,
      pleaseConfirm: editInputs.pleaseConfirm,
      confirm: editInputs.confirm,
      seeAll: editInputs.seeAll,
      logoutConfirmDesc: editInputs.logoutConfirmDesc,
      notifications: editInputs.notifications,
      clearAll: editInputs.clearAll,
      sortBy: editInputs.sortBy,
      createUserDesc: editInputs.createUserDesc,
      podcastAudioPlayer: editInputs.podcastAudioPlayer,
      noSeriesMessage: editInputs.noSeriesMessage,
      noAnnouncementMessage: editInputs.noAnnouncementMessage,
      noUserMessage: editInputs.noUserMessage,
      newsImage: editInputs.newsImage,
      editProfilePhoto: editInputs.editProfilePhoto,
      editProfilePhotoDesc: editInputs.editProfilePhotoDesc,
      profilePhotoDesc: editInputs.profilePhotoDesc,
      oldPassword: editInputs.oldPassword,
      share: editInputs.share,
      minute: editInputs.minute,
      minutes: editInputs.minutes,
      hour: editInputs.hour,
      hours: editInputs.hours,
      atoz: editInputs.atoz,
      ztoa: editInputs.ztoa,
      newest: editInputs.newest,
      oldest: editInputs.oldest,
      badgeDetails: editInputs.badgeDetails,
      badgeLevel: editInputs.badgeLevel,
      podcastUpdate: editInputs.podcastUpdate,
      create: editInputs.create,
      import: editInputs.import,
      goodAfternoon: editInputs.goodAfternoon,
      latestNews: editInputs.latestNews,
      enterAssociatedEmail: editInputs.enterAssociatedEmail,
      welcomeTo: editInputs.welcomeTo,
      resetYourPassword: editInputs.resetYourPassword,
      played: editInputs.played,
      submit: editInputs.submit,
      LogIn: editInputs.LogIn,
      forgotPassword: editInputs.forgotPassword,
      getStarted: editInputs.getStarted,
      skip: editInputs.skip,
      continue: editInputs.continue,
      badgeCollections: editInputs.badgeCollections,
      beginner: editInputs.beginner,
      regular: editInputs.regular,
      veteran: editInputs.veteran,
      professional: editInputs.professional,
      advanced: editInputs.advanced,
      newPassword: editInputs.newPassword,
      updatedSuccess: editInputs.updatedSuccess,
      onBoardingMobileOneTitle: editInputs.onBoardingMobileOneTitle,
      onBoardingMobileTwoTitle: editInputs.onBoardingMobileTwoTitle,
      onBoardingMobileThreeTitle: editInputs.onBoardingMobileThreeTitle,
      onBoardingMobileOneDesc: editInputs.onBoardingMobileOneDesc,
      onBoardingMobileTwoDesc: editInputs.onBoardingMobileTwoDesc,
      onBoardingMobileThreeDesc: editInputs.onBoardingMobileThreeDesc,
      queue: editInputs.queue,
      nowPlaying: editInputs.nowPlaying,
      scaleUp: editInputs.scaleUp,
      noRecords: editInputs.noRecords,
      noRecordsMatch: editInputs.noRecordsMatch,
      seeLess: editInputs.seeLess,
      downloadStarted: editInputs.downloadStarted,
      downloadCompleted: editInputs.downloadCompleted,
      noNews: editInputs.noNews,
      noUpdates: editInputs.noUpdates,
      pleaseEnterInput: editInputs.pleaseEnterInput,
      errorGettingData: editInputs.errorGettingData,
      checkInternet: editInputs.checkInternet,
      selectItemToView: editInputs.selectItemToView,
      allSeries: editInputs.allSeries,
      notificationAnnouncementTitle: editInputs.notificationAnnouncementTitle,
      notificationAnnouncementMessage:
        editInputs.notificationAnnouncementMessage,
      notificationNewsTitle: editInputs.notificationNewsTitle,
      notificationNewsMessage: editInputs.notificationNewsMessage,
      notificationPodcastTitle: editInputs.notificationPodcastTitle,
      notificationPodcastMessage: editInputs.notificationPodcastMessage,
      errorHashingPassword: editInputs.errorHashingPassword,
      userRegistration: editInputs.userRegistration,
      tenantRegistration: editInputs.tenantRegistration,
      userNotFound: editInputs.userNotFound,
      newPasswordSubject: editInputs.newPasswordSubject,
      requiredOldPassword: editInputs.requiredOldPassword,
      requiredNewPassword: editInputs.requiredNewPassword,
      passwordNotMatchAlert: editInputs.passwordNotMatchAlert,
      oldPasswordNotMatch: editInputs.oldPasswordNotMatch,
      badResponse: editInputs.badResponse,
      passwordUpdated: editInputs.passwordUpdated,
      changePasswordSuccess: editInputs.changePasswordSuccess,
      changePasswordFail: editInputs.changePasswordFail,
      uploadValidImageFile: editInputs.uploadValidImageFile,
      uploadAllFiles: editInputs.uploadAllFiles,
      validProfileImage: editInputs.validProfileImage,
      requiredFirstname: editInputs.requiredFirstname,
      requiredLastname: editInputs.requiredLastname,
      requiredEmail: editInputs.requiredEmail,
      requiredProfileImage: editInputs.requiredProfileImage,
      emailExists: editInputs.emailExists,
      requiredConfirmPassword: editInputs.requiredConfirmPassword,
      requiredFields: editInputs.requiredFields,
      passwordNotMatch: editInputs.passwordNotMatch,
      tokenNotProvided: editInputs.tokenNotProvided,
      uploadValidCsvFiles: editInputs.uploadValidCsvFiles,
      requiredImportCsvFile: editInputs.requiredImportCsvFile,
      requiredDeviceToken: editInputs.requiredDeviceToken,
      userCreatedSuccess: editInputs.userCreatedSuccess,
      userCreatedFail: editInputs.userCreatedFail,
      userUpdatedSuccess: editInputs.userUpdatedSuccess,
      userUpdatedFail: editInputs.userUpdatedFail,
      userDeletedSuccess: editInputs.userDeletedSuccess,
      userDeletedFail: editInputs.userDeletedFail,
      userImportSuccess: editInputs.userImportSuccess,
      userImportFail: editInputs.userImportFail,
      userProfileUpdatedSuccess: editInputs.userProfileUpdatedSuccess,
      userProfileUpdatedFail: editInputs.userProfileUpdatedFail,
      profilePictureUpdatedSuccess: editInputs.profilePictureUpdatedSuccess,
      profilePictureUpdatedFail: editInputs.profilePictureUpdatedFail,
      tokenUpdatedSuccess: editInputs.tokenUpdatedSuccess,
      tokenUpdatedFail: editInputs.tokenUpdatedFail,
      requiredTitle: editInputs.requiredTitle,
      requiredDescription: editInputs.requiredDescription,
      requiredCategory: editInputs.requiredCategory,
      requiredBannerImage: editInputs.requiredBannerImage,
      requiredSeriesImage: editInputs.requiredSeriesImage,
      validSeriesImage: editInputs.validSeriesImage,
      validBannerImage: editInputs.validBannerImage,
      seriesCreatedSuccess: editInputs.seriesCreatedSuccess,
      seriesCreatedFail: editInputs.seriesCreatedFail,
      seriesUpdatedSuccess: editInputs.seriesUpdatedSuccess,
      seriesUpdatedFail: editInputs.seriesUpdatedFail,
      seriesDeletedSuccess: editInputs.seriesDeletedSuccess,
      seriesDeletedFail: editInputs.seriesDeletedFail,
      uploadValidAudioFile: editInputs.uploadValidAudioFile,
      requiredPosterImage: editInputs.requiredPosterImage,
      validPosterImage: editInputs.validPosterImage,
      requiredPodcastFile: editInputs.requiredPodcastFile,
      episodeCreatedSuccess: editInputs.episodeCreatedSuccess,
      episodeCreatedFail: editInputs.episodeCreatedFail,
      episodeUpdatedSuccess: editInputs.episodeUpdatedSuccess,
      episodeUpdatedFail: editInputs.episodeUpdatedFail,
      episodeDeletedSuccess: editInputs.episodeDeletedSuccess,
      episodeDeletedFail: editInputs.episodeDeletedFail,
      requiredCategoryImage: editInputs.requiredCategoryImage,
      validCategoryImage: editInputs.validCategoryImage,
      categoryCreatedSuccess: editInputs.categoryCreatedSuccess,
      categoryCreatedFail: editInputs.categoryCreatedFail,
      categoryUpdatedSuccess: editInputs.categoryUpdatedSuccess,
      categoryUpdatedFail: editInputs.categoryUpdatedFail,
      categoryDeletedSuccess: editInputs.categoryDeletedSuccess,
      categoryDeletedFail: editInputs.categoryDeletedFail,
      announcementCreatedSuccess: editInputs.announcementCreatedSuccess,
      announcementCreatedFail: editInputs.announcementCreatedFail,
      announcementUpdatedSuccess: editInputs.announcementUpdatedSuccess,
      announcementUpdatedFail: editInputs.announcementUpdatedFail,
      announcementDeletedSuccess: editInputs.announcementDeletedSuccess,
      announcementDeletedFail: editInputs.announcementDeletedFail,
      dataCreatedSuccess: editInputs.dataCreatedSuccess,
      dataCreatedFail: editInputs.dataCreatedFail,
      dataUpdatedSuccess: editInputs.dataUpdatedSuccess,
      dataUpdatedFail: editInputs.dataUpdatedFail,
      dataDeletedSuccess: editInputs.dataDeletedSuccess,
      dataDeletedFail: editInputs.dataDeletedFail,
      dataGetSuccess: editInputs.dataGetSuccess,
      dataGetFail: editInputs.dataGetFail,
      emailRequiredRecipient: editInputs.emailRequiredRecipient,
      emailRequiredSubject: editInputs.emailRequiredSubject,
      emailRequiredMessage: editInputs.emailRequiredMessage,
      emailSentSuccess: editInputs.emailSentSuccess,
      emailSentFail: editInputs.emailSentFail,
      internalError: editInputs.internalError,
      tempHello: editInputs.tempHello,
      tempUsername: editInputs.tempUsername,
      tempPassword: editInputs.tempPassword,
      tempNote: editInputs.tempNote,
      tempRegards: editInputs.tempRegards,
      tempTeam: editInputs.tempTeam,
      tempLineQuery: editInputs.tempLineQuery,
      tempDear: editInputs.tempDear,
      userTempLineFirst: editInputs.userTempLineFirst,
      userTempLineSecond: editInputs.userTempLineSecond,
      userTempLineThird: editInputs.userTempLineThird,
      userTempLineFourth: editInputs.userTempLineFourth,
      userTempLineFifth: editInputs.userTempLineFifth,
      tenantTempLineFirst: editInputs.tenantTempLineFirst,
      tenantTempLineSecond: editInputs.tenantTempLineSecond,
      tenantTempLineThird: editInputs.tenantTempLineThird,
      tenantTempLineFourth: editInputs.tenantTempLineFourth,
      tenantTempLineFifth: editInputs.tenantTempLineFifth,
      tenantTempLineSixth: editInputs.tenantTempLineSixth,
      forgotPassTempLineFirst: editInputs.forgotPassTempLineFirst,
      forgotPassTempLineSecond: editInputs.forgotPassTempLineSecond,
      forgotPassTempLineThird: editInputs.forgotPassTempLineThird,
      forgotPassTempLineFourth: editInputs.forgotPassTempLineFourth,
      loggingOut: editInputs.loggingOut,
      AverageWeekly: editInputs.AverageWeekly,
      AverageMonthly: editInputs.AverageMonthly,
      AverageYearly: editInputs.AverageYearly,
      AlreadyDownloaded: editInputs.AlreadyDownloaded,
      activateTitle: editInputs.activateTitle,
      activate: editInputs.activate,
      userActivatedSuccess: editInputs.userActivatedSuccess,
      userActivatedFail: editInputs.userActivatedFail,
      noInternet: editInputs.noInternet,
      notifyUpdateDesc: editInputs.notifyUpdateDesc,
      noAnnouncementDesc: editInputs.noAnnouncementDesc,
      noDiscoverDesc: editInputs.noDiscoverDesc,
      noDownloadsDesc: editInputs.noDownloadsDesc,
      noFavouritesDesc: editInputs.noFavouritesDesc,
      noInternetDesc: editInputs.noInternetDesc,
      noExploreDesc: editInputs.noExploreDesc,
      noSeriesDesc: editInputs.noSeriesDesc,
      isoDate: editInputs.isoDate,
      tryAnotherKeyword: editInputs.tryAnotherKeyword,
    };

    await axios
      .put(`/admin/language/${id}`, data)
      .then((res) => {
        if (res.data) {
          resetEditLanguage();
          getLanguages();
          onClickDisplayLanguageById(id);
          setIsShownEditLanguage(false);
        }
        setSubmittingUpdate(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
      })
      .catch((error) => {
        setSubmittingUpdate(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteLanguage = (id) => () => {
    setLoading(true);
    axios
      .delete(`/admin/language/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getLanguages();
        setActiveLanguage("");
        setIsShownDeleteDialog(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialog(true);
  };

  function returnDate(data) {
    try {
      const date = new Date(data);
      const isoDate = date.toISOString().substring(0, 10);
      return isoDate;
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const [activeLanguage, setActiveLanguage] = useState("");
  const [focusLanguage, setFocusLanguage] = useState(true);

  const activeLanguageTab = (id) => {
    if (activeLanguage !== "" && focusLanguage) {
      if (id === activeLanguage?._id) {
        return "language-list-panel active";
      } else {
        return "language-list-panel";
      }
    } else {
      return "language-list-panel";
    }
  };

  async function onClickDisplayLanguageById(id) {
    setLanguageInfoLoader(true);
    setFocusLanguage(false);
    setSelectedIndexV(0);
    await axios
      .get(`/admin/language/${id}`)
      .then((res) => {
        const response = res?.data?.data;
        setActiveLanguage(response);

        setLanguageInfoLoader(false);
        setFocusLanguage(true);

        scrollToToplanguageInfo();
      })
      .catch((error) => {
        setLanguageInfoLoader(false);
        setFocusLanguage(true);
      });
  }

  const renderLanguageInfo = () => {
    if (activeLanguage !== "") {
      return (
        <>
          <Pane className="language-header-panel">
            <Pane className="language-header-list-panel">
              <Pane className="language-header-heading">
                <Text
                  className="language-header-name tenant-header-title"
                  data-customClass="custom-design"
                >
                  {activeLanguage?.languageName}
                  <span style={{ textTransform: "initial" }}>
                    {activeLanguage?.languageCode
                      ? `(${activeLanguage?.languageCode})`
                      : ""}
                  </span>
                </Text>
              </Pane>
              <Pane className="language-edit-main-container">
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  padding="0px"
                  position={Position.BOTTOM_LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item
                          padding="0"
                          className="language-edit-container"
                          data-customClass="custom-design"
                          icon={<EditIcon size={12} color="white" />}
                          onClick={handleClickOpenEditLanguage(
                            activeLanguage?._id
                          )}
                        >
                          <Text
                            className="language-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            Edit
                          </Text>
                        </Menu.Item>
                        {activeLanguage?.languageName !== "English" ? (
                          <Menu.Item
                            padding="0"
                            className="language-edit-container"
                            data-customClass="custom-design"
                            icon={<TrashIcon size={12} color="white" />}
                            onClick={handleClickOpenDeleteDialog(
                              activeLanguage?._id,
                              activeLanguage?.languageName
                            )}
                          >
                            <Text
                              className="language-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Delete
                            </Text>
                          </Menu.Item>
                        ) : (
                          ""
                        )}
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <MoreIcon
                    className="middle-panel-header-moreicon"
                    size={12}
                  />
                </Popover>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="language-content-panel">
            <Pane>
              <Pane>
                <Tablist className="languageTablist viewTab">
                  <Tab
                    aria-controls="panel-tab1"
                    isSelected={0 === selectedIndexV}
                    onSelect={() => {
                      setSelectedIndexV(0);
                      scrollToToplanguageInfo();
                    }}
                    title="Web App"
                  >
                    Web App
                  </Tab>
                  <Tab
                    aria-controls="panel-tab1"
                    isSelected={1 === selectedIndexV}
                    onSelect={() => {
                      setSelectedIndexV(1);
                      scrollToToplanguageInfo();
                    }}
                    title="Mobile App"
                  >
                    Mobile App
                  </Tab>
                  <Tab
                    aria-controls="panel-tab1"
                    isSelected={2 === selectedIndexV}
                    onSelect={() => {
                      setSelectedIndexV(2);
                      scrollToToplanguageInfo();
                    }}
                    title="Alert Message"
                  >
                    Alert Message
                  </Tab>
                  <Tab
                    aria-controls="panel-tab1"
                    isSelected={3 === selectedIndexV}
                    onSelect={() => {
                      setSelectedIndexV(3);
                      scrollToToplanguageInfo();
                    }}
                    title="Email Template"
                  >
                    Email Template
                  </Tab>
                </Tablist>
                <Table.Head
                  className="language-header"
                  background="#f1f1f1"
                  marginTop={0}
                >
                  <Table.TextHeaderCell color={"#a8a4a4"} fontWeight={700}>
                    English
                  </Table.TextHeaderCell>
                  <Table.TextHeaderCell color={"black"} fontWeight={700}>
                    {activeLanguage?.languageName}
                    <span style={{ textTransform: "initial" }}>
                      {activeLanguage?.languageCode
                        ? `(${activeLanguage?.languageCode})`
                        : ""}
                    </span>
                  </Table.TextHeaderCell>
                </Table.Head>
                <SimpleBar
                  style={{ maxHeight: "calc(100vh - 250px)" }}
                  scrollableNodeProps={{ ref: languageInfoRef }}
                >
                  <Pane>
                    <Pane
                      aria-labelledby="tab1"
                      aria-hidden={0 !== selectedIndexV}
                      display={0 === selectedIndexV ? "block" : "none"}
                      role="tabpanel"
                    >
                      <Pane className="languages-list-block">
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Home
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.home}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcast}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcasts
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcasts}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            dashboard
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dashboard}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            library
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.library}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            libraries
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.libraries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            tenant
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenant}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            tenants
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenants}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.user}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            users
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.users}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.series}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            serieses
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.serieses}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episode}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episodes
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodes}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            language
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.language}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            languages
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.languages}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcement}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcements
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcements}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            hello
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.hello}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            account settings
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.accountSettings}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            logout
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.logout}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            now trending
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.nowTrending}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            most listened
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.mostListened}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            new releases
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.newReleases}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            trending category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.trendingCategories}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastSeries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createSeries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series info
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesInfo}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create episode
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createEpisode}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create episode description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createEpisodeDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createCategory}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create user
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createUser}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            type here to search
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.typeHereToSearch}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            created on
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createdOn}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            listening time
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.listeningTime}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            listening level
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.listeningLevel}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            badge
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badge}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            change password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.changePassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.password}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            confirm password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.confirmPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            password hint
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.passwordHint}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            confirm password Hint
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.confirmPasswordHint}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            save
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.btnSave}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            save and add new
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.btnSaveAndAddNew}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            update
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.btnUpdate}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            cancel
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.btnCancel}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            import User
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.importUser}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Import user description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.importUserDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Upload CSV
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadCsv}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Upload CSV description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadCsvDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Create series description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createSeriesDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.title}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.category}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesDescription}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            banner Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.bannerImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            banner Image description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.bannerImageDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Image description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesImageDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            select Category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.selectCategory}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editSeries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Series description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editSeriesDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create Category description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createCategoryDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Image description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryImageDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editCategory}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Category description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editCategoryDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast Visibility
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastVisibility}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            listened
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.listened}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no. Of Episode
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noOfEpisode}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            duration
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.duration}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Episode
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editEpisode}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Episode description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editEpisodeDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast Duration
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastDuration}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast File
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastFile}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast File description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastFileDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            poster Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.posterImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            poster Image description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.posterImageDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.delete}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.edit}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            news
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.news}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Announcement
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editAnnouncement}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            editAnnouncementDesc
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editAnnouncementDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create Announcement
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createAnnouncement}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create Announcement description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createAnnouncementDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.description}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit News
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editNews}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Podcast Update
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editPodcastUpdate}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit User
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editUser}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit User description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editUserDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text fontWeight={600}>first Name</Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.firstName}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text fontWeight={600}>last Name</Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.lastName}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            email
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.email}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            profile Photo
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profilePhoto}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete Title User
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.deleteTitleUser}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete Title Announcement
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.deleteTitleAnnouncement}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete Title Category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.deleteTitleCategory}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete Title Episode
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.deleteTitleEpisode}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            delete Title Series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.deleteTitleSeries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            categories
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categories}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            search
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.search}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            badges
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badges}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            account
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.account}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            company Information
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.companyInformation}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            sort By
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profilePhoto}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create User Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.createUserDesc}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast Audio Player
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastAudioPlayer}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Series Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noSeriesMessage}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Announcement Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noAnnouncementMessage}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no User Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noUserMessage}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            news image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.newsImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Profile Photo
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editProfilePhoto}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            edit Profile Photo Desc
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.editProfilePhotoDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            profile Photo Desc
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profilePhotoDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            old Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.oldPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            shared
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.share}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            minute
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.minute}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            minutes
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.minutes}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            hour
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.hour}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            hours
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.hours}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            a to z
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.atoz}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            z to a
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.ztoa}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            newest
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.newest}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            oldest
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.oldest}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            badge Details
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badgeDetails}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            badge Level
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badgeLevel}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            podcast Update
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.podcastUpdate}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            create
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.create}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            import
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.import}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            submit
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.submit}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Log In
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.LogIn}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            forgot Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.forgotPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            beginner
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.beginner}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            regular
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.regular}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            veteran
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.veteran}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            professional
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.professional}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            advanced
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.advanced}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            new Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.newPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no records
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noRecords}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no records match
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noRecordsMatch}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            select Item To View
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.selectItemToView}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            all Series
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.allSeries}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            activate Title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.activateTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Activate
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.activate}
                          </Text>
                        </Pane>
                      </Pane>
                    </Pane>
                    <Pane
                      aria-labelledby="tab2"
                      aria-hidden={1 !== selectedIndexV}
                      display={1 === selectedIndexV ? "block" : "none"}
                      role="tabpanel"
                    >
                      <Pane className="languages-list-block">
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            downloads
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.downloads}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            profile
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profile}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            good Morning
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.goodMorning}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            good Afternoon
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.goodAfternoon}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            good evening
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.goodEvening}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            recently played
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.recentlyPlayed}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            continue Listening
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.continueListening}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            favourite
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.favourite}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            top Categories
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.topCategories}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            discover
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.discover}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            all
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.all}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            all Episodes
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.allEpisodes}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            see More
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seeMore}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            my Library
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.myLibrary}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Favourite
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noFavourite}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            recent
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.recent}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Recent
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noRecent}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Downloads
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noDownloads}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            achievements
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.achievements}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            week
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.week}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            month
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.month}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            year
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.year}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            average
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.average}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            collection
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.labelCollection}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            updates
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.updates}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            please Confirm
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.pleaseConfirm}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            confirm
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.confirm}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            see All
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seeAll}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            logout Confirm Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.logoutConfirmDesc}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            notifications
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notifications}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            clear All
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.clearAll}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            latest News
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.latestNews}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            enter Associated Email
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.enterAssociatedEmail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            welcome To
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.welcomeTo}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            reset Your Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.resetYourPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            played
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.played}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            get Started
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.getStarted}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            skip
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.skip}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            continue
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.continue}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            badge Collections
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badgeCollections}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.updatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile title first
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileOneTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile title second
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileTwoTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile title third
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileThreeTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile Desc first
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileOneDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile Desc second
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileTwoDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            on Boarding Mobile Desc third
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.onBoardingMobileThreeDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            queue
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.queue}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            now playing
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.nowPlaying}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            scale up
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.scaleUp}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            see Less
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seeLess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            download Started
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.downloadStarted}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            download Completed
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.downloadCompleted}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no News
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noNews}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            no Updates
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noUpdates}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            please Enter Input
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.pleaseEnterInput}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            error Getting Data
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.errorGettingData}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            check Internet
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.checkInternet}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            logging Out
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.loggingOut}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Average Weekly
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.AverageWeekly}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Average Monthly
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.AverageMonthly}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Average Yearly
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.AverageYearly}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Already Downloaded
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.AlreadyDownloaded}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Internet
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noInternet}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notify Update Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notifyUpdateDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Announcement Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noAnnouncementDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Discover Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noDiscoverDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Downloads Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noDownloadsDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Favourites Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noFavouritesDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Internet Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noInternetDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Explore Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noExploreDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            No Series Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.noSeriesDesc}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            ISO Date
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.isoDate}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Try Another Keyword
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tryAnotherKeyword}
                          </Text>
                        </Pane>
                      </Pane>
                    </Pane>
                    <Pane
                      aria-labelledby="tab3"
                      aria-hidden={2 !== selectedIndexV}
                      display={2 === selectedIndexV ? "block" : "none"}
                      role="tabpanel"
                    >
                      <Pane className="languages-list-block">
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification Announcement Title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationAnnouncementTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification Announcement Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationAnnouncementMessage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification News Title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationNewsTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification News Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationNewsMessage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification Podcast Title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationPodcastTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Notification Podcast Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.notificationPodcastMessage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Error Hashing Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.errorHashingPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Registration
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userRegistration}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Registration
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantRegistration}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Not Found
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userNotFound}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            New Password Subject
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.newPasswordSubject}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Old Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredOldPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required New Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredNewPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Password Not Match
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.passwordNotMatchAlert}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Old Password Not Match
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.oldPasswordNotMatch}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Bad Response
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.badResponse}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Password Updated
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.passwordUpdated}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Change Password Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.changePasswordSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Change Password Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.changePasswordFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Upload Valid Image File
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadValidImageFile}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Upload All Files
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadAllFiles}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Valid Profile Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.validProfileImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Firstname
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredFirstname}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Lastname
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredLastname}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Email
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredEmail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Profile Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredProfileImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Email Exists
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailExists}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Confirm Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredConfirmPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required Fields
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredFields}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Password Not Match
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.passwordNotMatch}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Token Not Provided
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tokenNotProvided}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Upload Valid Csv Files
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadValidCsvFiles}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Import Csv File
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredImportCsvFile}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Device Token
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredDeviceToken}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Import Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userImportSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Import Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userImportFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Profile Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userProfileUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            user Profile Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userProfileUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            profile Picture Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profilePictureUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            profile Picture Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.profilePictureUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            token Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tokenUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            token Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tokenUpdatedFail}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Title
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredTitle}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Description
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredDescription}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Category
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredCategory}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Banner Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredBannerImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Series Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredSeriesImage}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            valid Series Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.validSeriesImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            valid Banner Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.validBannerImage}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            series Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.seriesDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            upload Valid Audio File
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.uploadValidAudioFile}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Poster Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredPosterImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            valid Poster Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.validPosterImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Podcast File
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredPodcastFile}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            episode Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.episodeDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            required Category Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.requiredCategoryImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            valid Category Image
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.validCategoryImage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            category Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.categoryDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            announcement Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.announcementDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Created Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataCreatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Created Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataCreatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Updated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataUpdatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Updated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataUpdatedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Deleted Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataDeletedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Deleted Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataDeletedFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Get Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataGetSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            data Get Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.dataGetFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required email Recipient
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailRequiredRecipient}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required email Subject
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailRequiredSubject}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Required email Message
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailRequiredMessage}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            email Sent Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailSentSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            email Sent Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.emailSentFail}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            internal Error
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.internalError}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Activated Success
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userActivatedSuccess}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Activated Fail
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userActivatedFail}
                          </Text>
                        </Pane>
                      </Pane>
                    </Pane>
                    <Pane
                      aria-labelledby="tab1"
                      aria-hidden={3 !== selectedIndexV}
                      display={3 === selectedIndexV ? "block" : "none"}
                      role="tabpanel"
                    >
                      <Pane className="languages-list-block">
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Hello
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempHello}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Username
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempUsername}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Password
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempPassword}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Note
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempNote}
                          </Text>
                        </Pane>
                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Regards
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempRegards}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Team
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempTeam}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Query Line
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempLineQuery}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Dear
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tempDear}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Template Line First
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userTempLineFirst}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Template Line Second
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userTempLineSecond}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Template Line Third
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userTempLineThird}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Template Line Fourth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userTempLineFourth}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            User Template Line Fifth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.userTempLineFifth}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line First
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineFirst}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line Second
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineSecond}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line Third
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineThird}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line Fourth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineFourth}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line Fifth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineFifth}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            Tenant Template Line Sixth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.tenantTempLineSixth}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            forgot Password Line First
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.forgotPassTempLineFirst}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            forgot Password Line Second
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.forgotPassTempLineSecond}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            forgot Password Line Third
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.forgotPassTempLineThird}
                          </Text>
                        </Pane>

                        <Pane className="language-list-child-block">
                          <Text color={"#a8a4a4"} fontWeight={600}>
                            forgot Password Line Fourth
                          </Text>
                          <Text color={"black"} fontWeight={600}>
                            {activeLanguage?.forgotPassTempLineFourth}
                          </Text>
                        </Pane>
                      </Pane>
                    </Pane>
                  </Pane>
                </SimpleBar>
              </Pane>
            </Pane>
          </Pane>
        </>
      );
    } else {
      return (
        <>
          <Pane className="series-empty-poster-container">
            <Pane className="series-empty-poster-circle">
              <img
                alt=""
                className="series-poster"
                src={userPoster}
                width="100%"
                height="auto"
              />
            </Pane>

            <Text fontSize="12px" color="#343434">
              Select item to view data.
            </Text>
          </Pane>
        </>
      );
    }
  };

  const [byOrder, setByOrder] = useState();
  const [searchBy, setSearchBy] = useState("");

  const renderLanguage = () => {
    if (activeRenderList) {
      return "";
    }
    const filteredOptions = languages?.filter((language) =>
      language?.languageName?.toLowerCase().includes(searchBy.toLowerCase())
    );

    if (filteredOptions?.length > 0) {
      return filteredOptions
        .sort((a, b) => {
          if (byOrder === "A to Z")
            return a.languageName.toLowerCase() > b.languageName.toLowerCase()
              ? 1
              : -1;
          if (byOrder === "Z to A")
            return a.languageName.toLowerCase() < b.languageName.toLowerCase()
              ? 1
              : -1;
          if (byOrder === "Newest") return a.createdAt < b.createdAt ? 1 : -1;
          if (byOrder === "Oldest") return a.createdAt > b.createdAt ? 1 : -1;
        })
        .map((language, i) => {
          return (
            <Pane
              tabIndex={i}
              key={language?._id}
              className={activeLanguageTab(language?._id)}
              onClick={() => onClickDisplayLanguageById(language?._id)}
              style={{ cursor: "pointer" }}
            >
              <Pane className="language-inner-panel-container">
                <p className="language-name-container">
                  {language?.languageName}
                </p>
                <Pane className="language-date-time-list">
                  <p>
                    <CalendarIcon
                      size={12}
                      marginRight={5}
                      marginTop={5}
                      marginBottom={-1}
                    />
                    {returnDate(language?.createdAt)}
                  </p>
                </Pane>
              </Pane>
              <Pane
                className="create-language-main-container"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <Menu>
                      <Menu.Group>
                        <Pane
                          onClick={handleClickOpenEditLanguage(language?._id)}
                        >
                          <Menu.Item
                            className="create-language-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="create-language-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              Edit
                            </Text>
                          </Menu.Item>
                        </Pane>
                        {language?.languageName !== "English" ? (
                          <Pane
                            onClick={handleClickOpenDeleteDialog(
                              language?._id,
                              language?.languageName
                            )}
                          >
                            <Menu.Item
                              className="create-language-edit-container"
                              data-customClass="custom-design"
                              icon={<TrashIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="create-language-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                Delete
                              </Text>
                            </Menu.Item>
                          </Pane>
                        ) : (
                          ""
                        )}
                      </Menu.Group>
                    </Menu>
                  )}
                >
                  <MoreIcon className="language-list-more-icon" size={12} />
                </Popover>
              </Pane>
            </Pane>
          );
        });
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title="Select tenant to manage user data"
        >
          No record found.
        </Text>
      );
    }
  };

  async function getLanguages() {
    setLanguageLoader(true);
    await axios
      .get("admin/languages/")
      .then((response) => {
        setLanguages(response?.data?.data);
        setLanguageLoader(false);
        setActiveRenderList(false);
      })
      .catch((error) => {
        setLanguageLoader(false);
        setActiveRenderList(false);
      });
  }

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="left-panel">
              <Pane className="left-panel-header">
                <Pane className="language-header-container">
                  <Label
                    className="language-header-name tenant-header-title"
                    data-customClass="custom-design"
                  >
                    Languages
                  </Label>
                  <Pane>
                    <Pane>
                      <React.Fragment>
                        <SideSheet
                          preventBodyScrolling={true}
                          isShown={isShownLanguage}
                          onCloseComplete={resetLanguageClose}
                          shouldCloseOnOverlayClick={false}
                        >
                          <Paragraph className="language-form-header">
                            <Text className="language-form-heading">
                              Create language
                            </Text>
                            <Text className="language-form-sub-heading">
                              Please fill the details and create language.
                            </Text>
                          </Paragraph>
                          <form
                            className="language-form-panel"
                            onSubmit={handleSubmitLanguage}
                            onReset={resetLanguageClose}
                          >
                            <Pane className="language-name">
                              <TextInputField
                                type="text"
                                name="languageName"
                                label="Language Name"
                                value={inputs.languageName}
                                required
                                onChange={handleInput}
                                width={350}
                                marginBottom={3}
                              />
                              <TextInputField
                                type="text"
                                name="languageCode"
                                label="Code"
                                value={inputs.languageCode}
                                required
                                onChange={handleInput}
                                width={120}
                                marginBottom={3}
                                maxlength={6}
                              />
                            </Pane>
                            <Tablist className="languageTablist-create formTab">
                              <Tab
                                aria-controls="panel-tab1"
                                isSelected={0 === selectedIndex}
                                onSelect={() => {
                                  setSelectedIndex(0);
                                  scrollToToplanguageCreate();
                                }}
                                title="Web App"
                              >
                                Web App
                              </Tab>
                              <Tab
                                aria-controls="panel-tab1"
                                isSelected={1 === selectedIndex}
                                onSelect={() => {
                                  setSelectedIndex(1);
                                  scrollToToplanguageCreate();
                                }}
                                title="Mobile App"
                              >
                                Mobile App
                              </Tab>
                              <Tab
                                aria-controls="panel-tab1"
                                isSelected={2 === selectedIndex}
                                onSelect={() => {
                                  setSelectedIndex(2);
                                  scrollToToplanguageCreate();
                                }}
                                title="Alert Message"
                              >
                                Alert Message
                              </Tab>
                              <Tab
                                aria-controls="panel-tab1"
                                isSelected={3 === selectedIndex}
                                onSelect={() => {
                                  setSelectedIndex(3);
                                  scrollToToplanguageCreate();
                                }}
                                title="Email Template"
                              >
                                Email Template
                              </Tab>
                            </Tablist>
                            <Pane className="select-language-container">
                              <Pane className="select-langauge-list-container">
                                <Table.Head
                                  className="language-header"
                                  background="#f1f1f1"
                                  marginTop={40}
                                >
                                  <Table.TextHeaderCell
                                    color={"#a8a4a4"}
                                    fontWeight={700}
                                  >
                                    English
                                  </Table.TextHeaderCell>
                                  <Table.TextHeaderCell
                                    color={"black"}
                                    fontWeight={700}
                                  >
                                    Selected langauge
                                  </Table.TextHeaderCell>
                                </Table.Head>

                                <Pane>
                                  <SimpleBar
                                    style={{
                                      maxHeight: "calc(100vh - 310px)",
                                    }}
                                    scrollableNodeProps={{
                                      ref: languageCreateRef,
                                    }}
                                  >
                                    <Pane
                                      aria-labelledby="tab1"
                                      aria-hidden={0 !== selectedIndex}
                                      display={
                                        0 === selectedIndex ? "block" : "none"
                                      }
                                      role="tabpanel"
                                    >
                                      <Pane className="languages-list-block">
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Home
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="home"
                                            value={inputs.home}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Podcast
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcast"
                                            value={inputs.podcast}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Podcasts
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcasts"
                                            value={inputs.podcasts}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            dashboard
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="dashboard"
                                            value={inputs.dashboard}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            library
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="library"
                                            value={inputs.library}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            libraries
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="libraries"
                                            value={inputs.libraries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            tenant
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="tenant"
                                            value={inputs.tenant}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            tenants
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="tenants"
                                            value={inputs.tenants}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            user
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="user"
                                            value={inputs.user}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            users
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="users"
                                            value={inputs.users}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="series"
                                            value={inputs.series}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            serieses
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="serieses"
                                            value={inputs.serieses}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            episode
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="episode"
                                            value={inputs.episode}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            episodes
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="episodes"
                                            value={inputs.episodes}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            language
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="language"
                                            value={inputs.language}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            languages
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="languages"
                                            value={inputs.languages}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            announcement
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="announcement"
                                            value={inputs.announcement}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            announcements
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="announcements"
                                            value={inputs.announcements}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            hello
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="hello"
                                            value={inputs.hello}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            account settings
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="accountSettings"
                                            value={inputs.accountSettings}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            logout
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="logout"
                                            value={inputs.logout}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            now trending
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="nowTrending"
                                            value={inputs.nowTrending}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            most listened
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="mostListened"
                                            value={inputs.mostListened}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            new releases
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="newReleases"
                                            value={inputs.newReleases}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            trending categories
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="trendingCategories"
                                            value={inputs.trendingCategories}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastSeries"
                                            value={inputs.podcastSeries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createSeries"
                                            value={inputs.createSeries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            series info
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seriesInfo"
                                            value={inputs.seriesInfo}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create episode
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createEpisode"
                                            value={inputs.createEpisode}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create episode description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createEpisodeDesc"
                                            value={inputs.createEpisodeDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create category
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createCategory"
                                            value={inputs.createCategory}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create user
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createUser"
                                            value={inputs.createUser}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            type here to search
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="typeHereToSearch"
                                            value={inputs.typeHereToSearch}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            created on
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createdOn"
                                            value={inputs.createdOn}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            listening time
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="listeningTime"
                                            value={inputs.listeningTime}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            listening level
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="listeningLevel"
                                            value={inputs.listeningLevel}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            badge
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="badge"
                                            value={inputs.badge}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            change password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="changePassword"
                                            value={inputs.changePassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="password"
                                            value={inputs.password}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            confirm password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="confirmPassword"
                                            value={inputs.confirmPassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            password hint
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="passwordHint"
                                            value={inputs.passwordHint}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            confirm password hint
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="confirmPasswordHint"
                                            value={inputs.confirmPasswordHint}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            save
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="btnSave"
                                            value={inputs.btnSave}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            save and add new
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="btnSaveAndAddNew"
                                            value={inputs.btnSaveAndAddNew}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            cancel
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="btnCancel"
                                            value={inputs.btnCancel}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            update
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="btnUpdate"
                                            value={inputs.btnUpdate}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        {/* new */}
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Import User
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="importUser"
                                            value={inputs.importUser}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            import User description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="importUserDesc"
                                            value={inputs.importUserDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            upload CSV
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="uploadCsv"
                                            value={inputs.uploadCsv}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            upload CSV description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="uploadCsvDesc"
                                            value={inputs.uploadCsvDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create Series description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createSeriesDesc"
                                            value={inputs.createSeriesDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            title
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="title"
                                            value={inputs.title}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            category
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="category"
                                            value={inputs.category}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            series Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seriesDescription"
                                            value={inputs.seriesDescription}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            banner Image
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="bannerImage"
                                            value={inputs.bannerImage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            banner image description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="bannerImageDesc"
                                            value={inputs.bannerImageDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            series image
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seriesImage"
                                            value={inputs.seriesImage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            series image description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seriesImageDesc"
                                            value={inputs.seriesImageDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            select category
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="selectCategory"
                                            value={inputs.selectCategory}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editSeries"
                                            value={inputs.editSeries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit series description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editSeriesDesc"
                                            value={inputs.editSeriesDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create category description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createCategoryDesc"
                                            value={inputs.createCategoryDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            category image
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="categoryImage"
                                            value={inputs.categoryImage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            category image description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="categoryImageDesc"
                                            value={inputs.categoryImageDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit category
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editCategory"
                                            value={inputs.editCategory}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit category description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editCategoryDesc"
                                            value={inputs.editCategoryDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast visibility
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastVisibility"
                                            value={inputs.podcastVisibility}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            listened
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="listened"
                                            value={inputs.listened}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            number of episode
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noOfEpisode"
                                            value={inputs.noOfEpisode}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            duration
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="duration"
                                            value={inputs.duration}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit episode
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editEpisode"
                                            value={inputs.editEpisode}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit episode description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editEpisodeDesc"
                                            value={inputs.editEpisodeDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast duration
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastDuration"
                                            value={inputs.podcastDuration}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast file
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastFile"
                                            value={inputs.podcastFile}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast file description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastFileDesc"
                                            value={inputs.podcastFileDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            poster image
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="posterImage"
                                            value={inputs.posterImage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            poster image description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="posterImageDesc"
                                            value={inputs.posterImageDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="delete"
                                            value={inputs.delete}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="edit"
                                            value={inputs.edit}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            news
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="news"
                                            value={inputs.news}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit Announcement
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editAnnouncement"
                                            value={inputs.editAnnouncement}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit Announcement description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editAnnouncementDesc"
                                            value={inputs.editAnnouncementDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create Announcement
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createAnnouncement"
                                            value={inputs.createAnnouncement}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create Announcement description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createAnnouncementDesc"
                                            value={
                                              inputs.createAnnouncementDesc
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="description"
                                            value={inputs.description}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit News
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editNews"
                                            value={inputs.editNews}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit Podcast Update
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editPodcastUpdate"
                                            value={inputs.editPodcastUpdate}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit User
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editUser"
                                            value={inputs.editUser}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit User description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editUserDesc"
                                            value={inputs.editUserDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text fontWeight={600}>
                                            first Name
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="firstName"
                                            value={inputs.firstName}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text fontWeight={600}>
                                            last Name
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="lastName"
                                            value={inputs.lastName}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            email
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="email"
                                            value={inputs.email}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            profile Photo
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="profilePhoto"
                                            value={inputs.profilePhoto}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete Title User
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="deleteTitleUser"
                                            value={inputs.deleteTitleUser}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete Title Announcement
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="deleteTitleAnnouncement"
                                            value={
                                              inputs.deleteTitleAnnouncement
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete Title Category
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="deleteTitleCategory"
                                            value={inputs.deleteTitleCategory}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete Title Episode
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="deleteTitleEpisode"
                                            value={inputs.deleteTitleEpisode}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            delete Title Series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="deleteTitleSeries"
                                            value={inputs.deleteTitleSeries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            categories
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="categories"
                                            value={inputs.categories}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            search
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="search"
                                            value={inputs.search}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            badges
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="badges"
                                            value={inputs.badges}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            sort By
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="sortBy"
                                            value={inputs.sortBy}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create User description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="createUserDesc"
                                            value={inputs.createUserDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast Audio Player
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastAudioPlayer"
                                            value={inputs.podcastAudioPlayer}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Series Message
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noSeriesMessage"
                                            value={inputs.noSeriesMessage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Announcement Message
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noAnnouncementMessage"
                                            value={inputs.noAnnouncementMessage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no User Message
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noUserMessage"
                                            value={inputs.noUserMessage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            News Image
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="newsImage"
                                            value={inputs.newsImage}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit Profile Photo
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editProfilePhoto"
                                            value={inputs.editProfilePhoto}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            edit Profile Photo Desc
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="editProfilePhotoDesc"
                                            value={inputs.editProfilePhotoDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            profile Photo Desc
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="profilePhotoDesc"
                                            value={inputs.profilePhotoDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            old Password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="oldPassword"
                                            value={inputs.oldPassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            shared
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="share"
                                            value={inputs.share}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            minute
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="minute"
                                            value={inputs.minute}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            minutes
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="minutes"
                                            value={inputs.minutes}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            hour
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="hour"
                                            value={inputs.hour}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            hours
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="hours"
                                            value={inputs.hours}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            a to z
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="atoz"
                                            value={inputs.atoz}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            z to a
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="ztoa"
                                            value={inputs.ztoa}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            newest
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="newest"
                                            value={inputs.newest}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            oldest
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="oldest"
                                            value={inputs.oldest}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            badge Details
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="badgeDetails"
                                            value={inputs.badgeDetails}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            badge Level
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="badgeLevel"
                                            value={inputs.badgeLevel}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            podcast Update
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="podcastUpdate"
                                            value={inputs.podcastUpdate}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            create
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="create"
                                            value={inputs.create}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            import
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="import"
                                            value={inputs.import}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            submit
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="submit"
                                            value={inputs.submit}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Log In
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="LogIn"
                                            value={inputs.LogIn}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            forgot Password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="forgotPassword"
                                            value={inputs.forgotPassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            beginner
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="beginner"
                                            value={inputs.beginner}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            regular
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="regular"
                                            value={inputs.regular}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            veteran
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="veteran"
                                            value={inputs.veteran}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            professional
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="professional"
                                            value={inputs.professional}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            advanced
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="advanced"
                                            value={inputs.advanced}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            new Password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="newPassword"
                                            value={inputs.newPassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            updated Success
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="updatedSuccess"
                                            value={inputs.updatedSuccess}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no records
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noRecords"
                                            value={inputs.noRecords}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no records match
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noRecordsMatch"
                                            value={inputs.noRecordsMatch}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            select Item To View
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="selectItemToView"
                                            value={inputs.selectItemToView}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            all Series
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="allSeries"
                                            value={inputs.allSeries}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Activate Title
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="activateTitle"
                                            value={inputs.activateTitle}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Activate
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="activate"
                                            value={inputs.activate}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                      </Pane>
                                    </Pane>
                                    <Pane
                                      aria-labelledby="tab1"
                                      aria-hidden={1 !== selectedIndex}
                                      display={
                                        1 === selectedIndex ? "block" : "none"
                                      }
                                      role="tabpanel"
                                    >
                                      <Pane className="languages-list-block">
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            downloads
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="downloads"
                                            value={inputs.downloads}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            profile
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="profile"
                                            value={inputs.profile}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            good evening
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="goodEvening"
                                            value={inputs.goodEvening}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            recently played
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="recentlyPlayed"
                                            value={inputs.recentlyPlayed}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            good Morning
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="goodMorning"
                                            value={inputs.goodMorning}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            continue Listening
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="continueListening"
                                            value={inputs.continueListening}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            favourite
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="favourite"
                                            value={inputs.favourite}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            top Categories
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="topCategories"
                                            value={inputs.topCategories}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            discover
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="discover"
                                            value={inputs.discover}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            all
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="all"
                                            value={inputs.all}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            all Episodes
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="allEpisodes"
                                            value={inputs.allEpisodes}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            see More
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seeMore"
                                            value={inputs.seeMore}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            my Library
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="myLibrary"
                                            value={inputs.myLibrary}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Favourite
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noFavourite"
                                            value={inputs.noFavourite}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            recent
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="recent"
                                            value={inputs.recent}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Recent
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noRecent"
                                            value={inputs.noRecent}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Downloads
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noDownloads"
                                            value={inputs.noDownloads}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            achievements
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="achievements"
                                            value={inputs.achievements}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            week
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="week"
                                            value={inputs.week}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            month
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="month"
                                            value={inputs.month}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            year
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="year"
                                            value={inputs.year}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            average
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="average"
                                            value={inputs.average}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Collection
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="labelCollection"
                                            value={inputs.labelCollection}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            account
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="account"
                                            value={inputs.account}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            company Information
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="companyInformation"
                                            value={inputs.companyInformation}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            updates
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="updates"
                                            value={inputs.updates}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            please Confirm
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="pleaseConfirm"
                                            value={inputs.pleaseConfirm}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            confirm
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="confirm"
                                            value={inputs.confirm}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            see All
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seeAll"
                                            value={inputs.seeAll}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            logout Confirm description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="logoutConfirmDesc"
                                            value={inputs.logoutConfirmDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            notifications
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="notifications"
                                            value={inputs.notifications}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            clear All
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="clearAll"
                                            value={inputs.clearAll}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            good Afternoon
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="goodAfternoon"
                                            value={inputs.goodAfternoon}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            latest News
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="latestNews"
                                            value={inputs.latestNews}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            enter Associated Email
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="enterAssociatedEmail"
                                            value={inputs.enterAssociatedEmail}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            welcome To
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="welcomeTo"
                                            value={inputs.welcomeTo}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            reset Your Password
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="resetYourPassword"
                                            value={inputs.resetYourPassword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            played
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="played"
                                            value={inputs.played}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            get Started
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="getStarted"
                                            value={inputs.getStarted}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            skip
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="skip"
                                            value={inputs.skip}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            continue
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="continue"
                                            value={inputs.continue}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            badge Collections
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="badgeCollections"
                                            value={inputs.badgeCollections}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Title First
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileOneTitle"
                                            value={
                                              inputs.onBoardingMobileOneTitle
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Title Second
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileTwoTitle"
                                            value={
                                              inputs.onBoardingMobileTwoTitle
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Title Third
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileThreeTitle"
                                            value={
                                              inputs.onBoardingMobileThreeTitle
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Desc First
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileOneDesc"
                                            value={
                                              inputs.onBoardingMobileOneDesc
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Desc Second
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileTwoDesc"
                                            value={
                                              inputs.onBoardingMobileTwoDesc
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            on Boarding Mobile Desc Third
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="onBoardingMobileThreeDesc"
                                            value={
                                              inputs.onBoardingMobileThreeDesc
                                            }
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            queue
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="queue"
                                            value={inputs.queue}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            now playing
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="nowPlaying"
                                            value={inputs.nowPlaying}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            scale up
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="scaleUp"
                                            value={inputs.scaleUp}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            see Less
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="seeLess"
                                            value={inputs.seeLess}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            download Started
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="downloadStarted"
                                            value={inputs.downloadStarted}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            download Completed
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="downloadCompleted"
                                            value={inputs.downloadCompleted}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no News
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noNews"
                                            value={inputs.noNews}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            no Updates
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noUpdates"
                                            value={inputs.noUpdates}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            please Enter Input
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="pleaseEnterInput"
                                            value={inputs.pleaseEnterInput}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            error Getting Data
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="errorGettingData"
                                            value={inputs.errorGettingData}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            check Internet
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="checkInternet"
                                            value={inputs.checkInternet}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>

                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            logging Out
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="loggingOut"
                                            value={inputs.loggingOut}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Average Weekly
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="AverageWeekly"
                                            value={inputs.AverageWeekly}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Average Monthly
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="AverageMonthly"
                                            value={inputs.AverageMonthly}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Average Yearly
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="AverageYearly"
                                            value={inputs.AverageYearly}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Already Downloaded
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="AlreadyDownloaded"
                                            value={inputs.AlreadyDownloaded}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Internet
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noInternet"
                                            value={inputs.noInternet}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Notify Update Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="notifyUpdateDesc"
                                            value={inputs.notifyUpdateDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Announcement Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noAnnouncementDesc"
                                            value={inputs.noAnnouncementDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Discover Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noDiscoverDesc"
                                            value={inputs.noDiscoverDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Downloads Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noDownloadsDesc"
                                            value={inputs.noDownloadsDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Favourites Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noFavouritesDesc"
                                            value={inputs.noFavouritesDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Internet Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noInternetDesc"
                                            value={inputs.noInternetDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Explore Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noExploreDesc"
                                            value={inputs.noExploreDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            No Series Description
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="noSeriesDesc"
                                            value={inputs.noSeriesDesc}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            ISO Date
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="isoDate"
                                            value={inputs.isoDate}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                        <Pane className="language-list-child-block">
                                          <Text
                                            color={"#a8a4a4"}
                                            fontWeight={600}
                                          >
                                            Try Another Keyword
                                          </Text>
                                          <TextInput
                                            type="text"
                                            name="tryAnotherKeyword"
                                            value={inputs.tryAnotherKeyword}
                                            onChange={handleInput}
                                            marginBottom={7}
                                            width={250}
                                          />
                                        </Pane>
                                      </Pane>
                                    </Pane>
                                    <Pane
                                      aria-labelledby="tab1"
                                      aria-hidden={2 !== selectedIndex}
                                      display={
                                        2 === selectedIndex ? "block" : "none"
                                      }
                                      role="tabpanel"
                                    >
                                      {/* alertmessage start -------------------------------------------- */}

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification Announcement Title
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationAnnouncementTitle"
                                          value={
                                            inputs.notificationAnnouncementTitle
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification Announcement Message
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationAnnouncementMessage"
                                          value={
                                            inputs.notificationAnnouncementMessage
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification News Title
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationNewsTitle"
                                          value={inputs.notificationNewsTitle}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification News Message
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationNewsMessage"
                                          value={inputs.notificationNewsMessage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification Podcast Title
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationPodcastTitle"
                                          value={
                                            inputs.notificationPodcastTitle
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          notification Podcast Message
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="notificationPodcastMessage"
                                          value={
                                            inputs.notificationPodcastMessage
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          error Hashing Password
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="errorHashingPassword"
                                          value={inputs.errorHashingPassword}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Registration
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userRegistration"
                                          value={inputs.userRegistration}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          tenant Registration
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantRegistration"
                                          value={inputs.tenantRegistration}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Not Found
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userNotFound"
                                          value={inputs.userNotFound}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          new Password Subject
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="newPasswordSubject"
                                          value={inputs.newPasswordSubject}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Old Password
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredOldPassword"
                                          value={inputs.requiredOldPassword}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required New Password
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredNewPassword"
                                          value={inputs.requiredNewPassword}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          password Not Match Alert
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="passwordNotMatchAlert"
                                          value={inputs.passwordNotMatchAlert}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          old Password Not Match
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="oldPasswordNotMatch"
                                          value={inputs.oldPasswordNotMatch}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          bad Response
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="badResponse"
                                          value={inputs.badResponse}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          password Updated
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="passwordUpdated"
                                          value={inputs.passwordUpdated}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          change Password Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="changePasswordSuccess"
                                          value={inputs.changePasswordSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          change Password Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="changePasswordFail"
                                          value={inputs.changePasswordFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          upload Valid Image File
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="uploadValidImageFile"
                                          value={inputs.uploadValidImageFile}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          upload All Files
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="uploadAllFiles"
                                          value={inputs.uploadAllFiles}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          valid Profile Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="validProfileImage"
                                          value={inputs.validProfileImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Firstname
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredFirstname"
                                          value={inputs.requiredFirstname}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Lastname
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredLastname"
                                          value={inputs.requiredLastname}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Email
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredEmail"
                                          value={inputs.requiredEmail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Profile Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredProfileImage"
                                          value={inputs.requiredProfileImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Exists
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailExists"
                                          value={inputs.emailExists}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Confirm Password
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredConfirmPassword"
                                          value={inputs.requiredConfirmPassword}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Fields
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredFields"
                                          value={inputs.requiredFields}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          password Not Match
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="passwordNotMatch"
                                          value={inputs.passwordNotMatch}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          token Not Provided
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tokenNotProvided"
                                          value={inputs.tokenNotProvided}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          upload Valid Csv Files
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="uploadValidCsvFiles"
                                          value={inputs.uploadValidCsvFiles}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Import Csv File
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredImportCsvFile"
                                          value={inputs.requiredImportCsvFile}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Device Token
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredDeviceToken"
                                          value={inputs.requiredDeviceToken}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userCreatedSuccess"
                                          value={inputs.userCreatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userCreatedFail"
                                          value={inputs.userCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userUpdatedSuccess"
                                          value={inputs.userUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userUpdatedFail"
                                          value={inputs.userUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userDeletedSuccess"
                                          value={inputs.userDeletedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userDeletedFail"
                                          value={inputs.userDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Import Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userImportSuccess"
                                          value={inputs.userImportSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Import Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userImportFail"
                                          value={inputs.userImportFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Profile Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userProfileUpdatedSuccess"
                                          value={
                                            inputs.userProfileUpdatedSuccess
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          user Profile Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userProfileUpdatedFail"
                                          value={inputs.userProfileUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          profile Picture Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="profilePictureUpdatedSuccess"
                                          value={
                                            inputs.profilePictureUpdatedSuccess
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          profile Picture Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="profilePictureUpdatedFail"
                                          value={
                                            inputs.profilePictureUpdatedFail
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          token Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tokenUpdatedSuccess"
                                          value={inputs.tokenUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          token Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tokenUpdatedFail"
                                          value={inputs.tokenUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Title
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredTitle"
                                          value={inputs.requiredTitle}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Description
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredDescription"
                                          value={inputs.requiredDescription}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Category
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredCategory"
                                          value={inputs.requiredCategory}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Banner Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredBannerImage"
                                          value={inputs.requiredBannerImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Series Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredSeriesImage"
                                          value={inputs.requiredSeriesImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          valid Series Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="validSeriesImage"
                                          value={inputs.validSeriesImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          valid Banner Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="validBannerImage"
                                          value={inputs.validBannerImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesCreatedSuccess"
                                          value={inputs.seriesCreatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesCreatedFail"
                                          value={inputs.seriesCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesUpdatedSuccess"
                                          value={inputs.seriesUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesUpdatedFail"
                                          value={inputs.seriesUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesDeletedSuccess"
                                          value={inputs.seriesDeletedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          series Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="seriesDeletedFail"
                                          value={inputs.seriesDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          upload Valid Audio File
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="uploadValidAudioFile"
                                          value={inputs.uploadValidAudioFile}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Poster Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredPosterImage"
                                          value={inputs.requiredPosterImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          valid Poster Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="validPosterImage"
                                          value={inputs.validPosterImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Podcast File
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredPodcastFile"
                                          value={inputs.requiredPodcastFile}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeCreatedSuccess"
                                          value={inputs.episodeCreatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeCreatedFail"
                                          value={inputs.episodeCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeUpdatedSuccess"
                                          value={inputs.episodeUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeUpdatedFail"
                                          value={inputs.episodeUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeDeletedSuccess"
                                          value={inputs.episodeDeletedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          episode Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="episodeDeletedFail"
                                          value={inputs.episodeDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          required Category Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="requiredCategoryImage"
                                          value={inputs.requiredCategoryImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          valid Category Image
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="validCategoryImage"
                                          value={inputs.validCategoryImage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryCreatedSuccess"
                                          value={inputs.categoryCreatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryCreatedFail"
                                          value={inputs.categoryCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryUpdatedSuccess"
                                          value={inputs.categoryUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryUpdatedFail"
                                          value={inputs.categoryUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryDeletedSuccess"
                                          value={inputs.categoryDeletedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          category Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="categoryDeletedFail"
                                          value={inputs.categoryDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementCreatedSuccess"
                                          value={
                                            inputs.announcementCreatedSuccess
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementCreatedFail"
                                          value={inputs.announcementCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementUpdatedSuccess"
                                          value={
                                            inputs.announcementUpdatedSuccess
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementUpdatedFail"
                                          value={inputs.announcementUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementDeletedSuccess"
                                          value={
                                            inputs.announcementDeletedSuccess
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          announcement Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="announcementDeletedFail"
                                          value={inputs.announcementDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Created Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataCreatedSuccess"
                                          value={inputs.dataCreatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Created Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataCreatedFail"
                                          value={inputs.dataCreatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Updated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataUpdatedSuccess"
                                          value={inputs.dataUpdatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Updated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataUpdatedFail"
                                          value={inputs.dataUpdatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Deleted Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataDeletedSuccess"
                                          value={inputs.dataDeletedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Deleted Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataDeletedFail"
                                          value={inputs.dataDeletedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Get Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataGetSuccess"
                                          value={inputs.dataGetSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          data Get Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="dataGetFail"
                                          value={inputs.dataGetFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Required Recipient
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailRequiredRecipient"
                                          value={inputs.emailRequiredRecipient}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Required Subject
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailRequiredSubject"
                                          value={inputs.emailRequiredSubject}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Required Message
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailRequiredMessage"
                                          value={inputs.emailRequiredMessage}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Sent Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailSentSuccess"
                                          value={inputs.emailSentSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          email Sent Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="emailSentFail"
                                          value={inputs.emailSentFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          internal Error
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="internalError"
                                          value={inputs.internalError}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Activated Success
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userActivatedSuccess"
                                          value={inputs.userActivatedSuccess}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Activated Fail
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userActivatedFail"
                                          value={inputs.userActivatedFail}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>
                                      {/* alertmessage end -------------------------------------------- */}
                                    </Pane>
                                    <Pane
                                      aria-labelledby="tab1"
                                      aria-hidden={3 !== selectedIndex}
                                      display={
                                        3 === selectedIndex ? "block" : "none"
                                      }
                                      role="tabpanel"
                                    >
                                      {/* email template start -------------------------------------------- */}

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Hello
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempHello"
                                          value={inputs.tempHello}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Username
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempUsername"
                                          value={inputs.tempUsername}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Password
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempPassword"
                                          value={inputs.tempPassword}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Note
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempNote"
                                          value={inputs.tempNote}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Regards
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempRegards"
                                          value={inputs.tempRegards}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Team
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempTeam"
                                          value={inputs.tempTeam}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Line Query
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempLineQuery"
                                          value={inputs.tempLineQuery}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Dear
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tempDear"
                                          value={inputs.tempDear}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Template Line First
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userTempLineFirst"
                                          value={inputs.userTempLineFirst}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Template Line Second
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userTempLineSecond"
                                          value={inputs.userTempLineSecond}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Template Line Third
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userTempLineThird"
                                          value={inputs.userTempLineThird}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Template Line Fourth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userTempLineFourth"
                                          value={inputs.userTempLineFourth}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          User Template Line Fifth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="userTempLineFifth"
                                          value={inputs.userTempLineFifth}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line First
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineFirst"
                                          value={inputs.tenantTempLineFirst}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line Second
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineSecond"
                                          value={inputs.tenantTempLineSecond}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line Third
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineThird"
                                          value={inputs.tenantTempLineThird}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line Fourth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineFourth"
                                          value={inputs.tenantTempLineFourth}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line Fifth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineFifth"
                                          value={inputs.tenantTempLineFifth}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Tenant Template Line Sixth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="tenantTempLineSixth"
                                          value={inputs.tenantTempLineSixth}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Forgot Password Line First
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="forgotPassTempLineFirst"
                                          value={inputs.forgotPassTempLineFirst}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Forgot Password Line Second
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="forgotPassTempLineSecond"
                                          value={
                                            inputs.forgotPassTempLineSecond
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Forgot Password Line Third
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="forgotPassTempLineThird"
                                          value={inputs.forgotPassTempLineThird}
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      <Pane className="language-list-child-block">
                                        <Text
                                          color={"#a8a4a4"}
                                          fontWeight={600}
                                        >
                                          Forgot Password Line Fourth
                                        </Text>
                                        <TextInput
                                          type="text"
                                          name="forgotPassTempLineFourth"
                                          value={
                                            inputs.forgotPassTempLineFourth
                                          }
                                          onChange={handleInput}
                                          marginBottom={7}
                                          width={250}
                                        />
                                      </Pane>

                                      {/* email template end -------------------------------------------- */}
                                    </Pane>
                                  </SimpleBar>
                                </Pane>
                              </Pane>
                            </Pane>
                            <Pane className="language-form-buttons-panel">
                              <Pane padding={16}>
                                <Heading size={600}>
                                  <Button
                                    className="cancel-button"
                                    data-customClass="custom-design"
                                    type="reset"
                                    onClick={resetLanguageClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="save-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.languageName ||
                                      !inputs.languageCode ||
                                      submittingSave
                                    }
                                    onClick={() => {
                                      setBtnLanguage(true);
                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    className="save-new-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.languageName ||
                                      !inputs.languageCode ||
                                      submittingSave
                                    }
                                  >
                                    Save &amp; Add New
                                  </Button>
                                </Heading>
                              </Pane>
                            </Pane>
                          </form>
                        </SideSheet>
                        <Button
                          className="create-language-button"
                          data-customClass="custom-design"
                          onClick={() => {
                            setIsShownLanguage(true);
                            setSelectedIndex(0);
                          }}
                          textTransform={"capitalize"}
                          title="create langauge"
                        >
                          create
                        </Button>
                      </React.Fragment>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane
                  className="select-dropdown-box-design"
                  data-customClass="custom-design"
                >
                  <Pane className="search-panel-bottom"></Pane>
                  <Pane
                    className="search-panel"
                    data-customClass="custom-design"
                    style={{ marginTop: "10px" }}
                  >
                    <TextInput
                      className="searchPod"
                      data-customClass="custom-design"
                      placeholder="Search..."
                      type="text"
                      onChange={(e) => setSearchBy(e.target.value)}
                    />
                    <SelectMenu
                      className="filter-menu"
                      height={132}
                      width={100}
                      data-customClass="custom-design"
                      options={["A to Z", "Z to A", "Newest", "Oldest"].map(
                        (label) => ({ label, value: label })
                      )}
                      selected={byOrder}
                      hasFilter={false}
                      hasTitle={false}
                      onSelect={(item) => {
                        setByOrder(item.value);
                        scrollToToplanguage();
                      }}
                      closeOnSelect={true}
                    >
                      <Button className="sort-filter">
                        <Icon icon={SortIcon} size={12} /> &nbsp;
                        {byOrder || "Sort by"}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>

              <Pane className="left-panel-content">
                <SimpleBar
                  style={{ maxHeight: "calc(100vh - 182px)" }}
                  scrollableNodeProps={{ ref: languageRef }}
                >
                  {languageLoader ? (
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={400}
                    >
                      <Spinner />
                    </Pane>
                  ) : (
                    renderLanguage()
                  )}
                </SimpleBar>
              </Pane>

              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownEditLanguage}
                    onCloseComplete={() => setIsShownEditLanguage(false)}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph className="language-form-header">
                      <Text className="language-form-heading">
                        Edit language
                      </Text>
                      <Text className="language-form-sub-heading">
                        Please fill the details and update language.
                      </Text>
                    </Paragraph>
                    <form
                      className="language-form-panel"
                      onSubmit={handleSubmitEditLanguage}
                    >
                      <Pane className="language-name">
                        <TextInputField
                          type="text"
                          name="languageName"
                          label="Language Name"
                          value={editInputs.languageName}
                          required
                          onChange={handleInputEdit}
                          width={350}
                          marginBottom={3}
                          disabled
                        />
                        <TextInputField
                          type="text"
                          name="languageCode"
                          label="Code"
                          value={editInputs.languageCode}
                          required
                          onChange={handleInputEdit}
                          width={120}
                          marginBottom={3}
                          maxlength={6}
                        />
                      </Pane>
                      <Tablist className="languageTablist-create formTab">
                        <Tab
                          aria-controls="panel-tab1"
                          isSelected={0 === selectedIndexE}
                          onSelect={() => {
                            setSelectedIndexE(0);
                            scrollToToplanguageEdit();
                          }}
                          title="Web App"
                        >
                          Web App
                        </Tab>
                        <Tab
                          aria-controls="panel-tab1"
                          isSelected={1 === selectedIndexE}
                          onSelect={() => {
                            setSelectedIndexE(1);
                            scrollToToplanguageEdit();
                          }}
                          title="Mobile App"
                        >
                          Mobile App
                        </Tab>
                        <Tab
                          aria-controls="panel-tab1"
                          isSelected={2 === selectedIndexE}
                          onSelect={() => {
                            setSelectedIndexE(2);
                            scrollToToplanguageEdit();
                          }}
                          title="Alert Message"
                        >
                          Alert Message
                        </Tab>
                        <Tab
                          aria-controls="panel-tab1"
                          isSelected={3 === selectedIndexE}
                          onSelect={() => {
                            setSelectedIndexE(3);
                            scrollToToplanguageEdit();
                          }}
                          title="Email Template"
                        >
                          Email Template
                        </Tab>
                      </Tablist>
                      <Pane className="select-language-container">
                        <Pane className="select-langauge-list-container">
                          <Table.Head
                            className="language-header"
                            background="#f1f1f1"
                            marginTop={40}
                          >
                            <Table.TextHeaderCell
                              color={"#a8a4a4"}
                              fontWeight={700}
                            >
                              English
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell
                              color={"black"}
                              fontWeight={700}
                            >
                              selected langauge
                            </Table.TextHeaderCell>
                          </Table.Head>

                          <SimpleBar
                            style={{ maxHeight: "calc(100vh - 310px)" }}
                            scrollableNodeProps={{ ref: languageEditRef }}
                          >
                            <Pane>
                              <Pane
                                aria-labelledby="tab1"
                                aria-hidden={0 !== selectedIndexE}
                                display={
                                  0 === selectedIndexE ? "block" : "none"
                                }
                                role="tabpanel"
                              >
                                <Pane className="languages-list-block">
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Home
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="home"
                                      value={editInputs.home}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcast"
                                      value={editInputs.podcast}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcasts
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcasts"
                                      value={editInputs.podcasts}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Dashboard
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="dashboard"
                                      value={editInputs.dashboard}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      library
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="library"
                                      value={editInputs.library}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      libraries
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="libraries"
                                      value={editInputs.libraries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      tenant
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="tenant"
                                      value={editInputs.tenant}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      tenants
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="tenants"
                                      value={editInputs.tenants}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      user
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="user"
                                      value={editInputs.user}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      users
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="users"
                                      value={editInputs.users}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="series"
                                      value={editInputs.series}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      serieses
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="serieses"
                                      value={editInputs.serieses}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      episode
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="episode"
                                      value={editInputs.episode}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      episodes
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="episodes"
                                      value={editInputs.episodes}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      language
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="language"
                                      value={editInputs.language}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      languages
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="languages"
                                      value={editInputs.languages}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      announcement
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="announcement"
                                      value={editInputs.announcement}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      announcements
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="announcements"
                                      value={editInputs.announcements}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      hello
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="hello"
                                      value={editInputs.hello}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      account settings
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="accountSettings"
                                      value={editInputs.accountSettings}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      logout
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="logout"
                                      value={editInputs.logout}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      now trending
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="nowTrending"
                                      value={editInputs.nowTrending}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      most listened
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="mostListened"
                                      value={editInputs.mostListened}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      new releases
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="newReleases"
                                      value={editInputs.newReleases}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      trending categories
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="trendingCategories"
                                      value={editInputs.trendingCategories}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastSeries"
                                      value={editInputs.podcastSeries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createSeries"
                                      value={editInputs.createSeries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      series info
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="seriesInfo"
                                      value={editInputs.seriesInfo}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create episode
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createEpisode"
                                      value={editInputs.createEpisode}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create episode description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createEpisodeDesc"
                                      value={editInputs.createEpisodeDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create category
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createCategory"
                                      value={editInputs.createCategory}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create user
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createUser"
                                      value={editInputs.createUser}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      type here to search
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="typeHereToSearch"
                                      value={editInputs.typeHereToSearch}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      created on
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createdOn"
                                      value={editInputs.createdOn}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      listening time
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="listeningTime"
                                      value={editInputs.listeningTime}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      listening level
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="listeningLevel"
                                      value={editInputs.listeningLevel}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      badge
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="badge"
                                      value={editInputs.badge}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      change password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="changePassword"
                                      value={editInputs.changePassword}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="password"
                                      value={editInputs.password}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      confirm password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="confirmPassword"
                                      value={editInputs.confirmPassword}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      password hint
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="passwordHint"
                                      value={editInputs.passwordHint}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      confirm password hint
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="confirmPasswordHint"
                                      value={editInputs.confirmPasswordHint}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      save
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="btnSave"
                                      value={editInputs.btnSave}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      save and add new
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="btnSaveAndAddNew"
                                      value={editInputs.btnSaveAndAddNew}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      cancel
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="btnCancel"
                                      value={editInputs.btnCancel}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      update
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="btnUpdate"
                                      value={editInputs.btnUpdate}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Import User
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="importUser"
                                      value={editInputs.importUser}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      import User description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="importUserDesc"
                                      value={editInputs.importUserDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      upload CSV
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="uploadCsv"
                                      value={editInputs.uploadCsv}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      upload CSV description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="uploadCsvDesc"
                                      value={editInputs.uploadCsvDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create Series description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createSeriesDesc"
                                      value={editInputs.createSeriesDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      title
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="title"
                                      value={editInputs.title}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      category
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="category"
                                      value={editInputs.category}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      series Description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="seriesDescription"
                                      value={editInputs.seriesDescription}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      banner Image
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="bannerImage"
                                      value={editInputs.bannerImage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      banner Image description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="bannerImageDesc"
                                      value={editInputs.bannerImageDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      series Image
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="seriesImage"
                                      value={editInputs.seriesImage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      series Image description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="seriesImageDesc"
                                      value={editInputs.seriesImageDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      select Category
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="selectCategory"
                                      value={editInputs.selectCategory}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editSeries"
                                      value={editInputs.editSeries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Series description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editSeriesDesc"
                                      value={editInputs.editSeriesDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create Category description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createCategoryDesc"
                                      value={editInputs.createCategoryDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      category Image
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="categoryImage"
                                      value={editInputs.categoryImage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      category Image description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="categoryImageDesc"
                                      value={editInputs.categoryImageDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Category
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editCategory"
                                      value={editInputs.editCategory}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Category description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editCategoryDesc"
                                      value={editInputs.editCategoryDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast Visibility
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastVisibility"
                                      value={editInputs.podcastVisibility}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      listened
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="listened"
                                      value={editInputs.listened}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Number Of Episode
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noOfEpisode"
                                      value={editInputs.noOfEpisode}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      duration
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="duration"
                                      value={editInputs.duration}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Episode
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editEpisode"
                                      value={editInputs.editEpisode}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Episode description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editEpisodeDesc"
                                      value={editInputs.editEpisodeDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast Duration
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastDuration"
                                      value={editInputs.podcastDuration}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast File
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastFile"
                                      value={editInputs.podcastFile}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast File description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastFileDesc"
                                      value={editInputs.podcastFileDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      poster Image
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="posterImage"
                                      value={editInputs.posterImage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      poster Image description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="posterImageDesc"
                                      value={editInputs.posterImageDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="delete"
                                      value={editInputs.delete}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="edit"
                                      value={editInputs.edit}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      news
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="news"
                                      value={editInputs.news}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Announcement
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editAnnouncement"
                                      value={editInputs.editAnnouncement}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Announcement description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editAnnouncementDesc"
                                      value={editInputs.editAnnouncementDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create Announcement
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createAnnouncement"
                                      value={editInputs.createAnnouncement}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create Announcement description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createAnnouncementDesc"
                                      value={editInputs.createAnnouncementDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="description"
                                      value={editInputs.description}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit News
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editNews"
                                      value={editInputs.editNews}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Podcast Update
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editPodcastUpdate"
                                      value={editInputs.editPodcastUpdate}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit User
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editUser"
                                      value={editInputs.editUser}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit User description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editUserDesc"
                                      value={editInputs.editUserDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text fontWeight={600}>first Name</Text>
                                    <TextInput
                                      type="text"
                                      name="firstName"
                                      value={editInputs.firstName}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text fontWeight={600}>last Name</Text>
                                    <TextInput
                                      type="text"
                                      name="lastName"
                                      value={editInputs.lastName}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      email
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="email"
                                      value={editInputs.email}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      profile Photo
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="profilePhoto"
                                      value={editInputs.profilePhoto}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete Title User
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="deleteTitleUser"
                                      value={editInputs.deleteTitleUser}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete Title Announcement
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="deleteTitleAnnouncement"
                                      value={editInputs.deleteTitleAnnouncement}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete Title Category
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="deleteTitleCategory"
                                      value={editInputs.deleteTitleCategory}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete Title Episode
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="deleteTitleEpisode"
                                      value={editInputs.deleteTitleEpisode}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      delete Title Series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="deleteTitleSeries"
                                      value={editInputs.deleteTitleSeries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      categories
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="categories"
                                      value={editInputs.categories}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      search
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="search"
                                      value={editInputs.search}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      badges
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="badges"
                                      value={editInputs.badges}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      account
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="account"
                                      value={editInputs.account}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      company Information
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="companyInformation"
                                      value={editInputs.companyInformation}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      sort By
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="sortBy"
                                      value={editInputs.sortBy}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create User description
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="createUserDesc"
                                      value={editInputs.createUserDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast Audio Player
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastAudioPlayer"
                                      value={editInputs.podcastAudioPlayer}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      no Series Message
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noSeriesMessage"
                                      value={editInputs.noSeriesMessage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      no Announcement Message
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noAnnouncementMessage"
                                      value={editInputs.noAnnouncementMessage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      no User Message
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noUserMessage"
                                      value={editInputs.noUserMessage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      News Image
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="newsImage"
                                      value={editInputs.newsImage}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Profile Photo
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editProfilePhoto"
                                      value={editInputs.editProfilePhoto}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      edit Profile Photo Desc
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="editProfilePhotoDesc"
                                      value={editInputs.editProfilePhotoDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      profile Photo Desc
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="profilePhotoDesc"
                                      value={editInputs.profilePhotoDesc}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      old Password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="oldPassword"
                                      value={editInputs.oldPassword}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      shared
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="share"
                                      value={editInputs.share}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      minute
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="minute"
                                      value={editInputs.minute}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      minutes
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="minutes"
                                      value={editInputs.minutes}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      hour
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="hour"
                                      value={editInputs.hour}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      hours
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="hours"
                                      value={editInputs.hours}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      a to z
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="atoz"
                                      value={editInputs.atoz}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      z to a
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="ztoa"
                                      value={editInputs.ztoa}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      newest
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="newest"
                                      value={editInputs.newest}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      oldest
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="oldest"
                                      value={editInputs.oldest}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      badge Details
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="badgeDetails"
                                      value={editInputs.badgeDetails}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      badge Level
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="badgeLevel"
                                      value={editInputs.badgeLevel}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      podcast Update
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="podcastUpdate"
                                      value={editInputs.podcastUpdate}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      create
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="create"
                                      value={editInputs.create}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      import
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="import"
                                      value={editInputs.import}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      submit
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="submit"
                                      value={editInputs.submit}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Log In
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="LogIn"
                                      value={editInputs.LogIn}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      forgot Password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="forgotPassword"
                                      value={editInputs.forgotPassword}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      beginner
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="beginner"
                                      value={editInputs.beginner}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      regular
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="regular"
                                      value={editInputs.regular}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      veteran
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="veteran"
                                      value={editInputs.veteran}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      professional
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="professional"
                                      value={editInputs.professional}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      advanced
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="advanced"
                                      value={editInputs.advanced}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      new Password
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="newPassword"
                                      value={editInputs.newPassword}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>

                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      no records
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noRecords"
                                      value={editInputs.noRecords}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      no records match
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="noRecordsMatch"
                                      value={editInputs.noRecordsMatch}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      select Item To View
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="selectItemToView"
                                      value={editInputs.selectItemToView}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      all Series
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="allSeries"
                                      value={editInputs.allSeries}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Activate Title
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="activateTitle"
                                      value={editInputs.activateTitle}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                  <Pane className="language-list-child-block">
                                    <Text color={"#a8a4a4"} fontWeight={600}>
                                      Activate
                                    </Text>
                                    <TextInput
                                      type="text"
                                      name="activate"
                                      value={editInputs.activate}
                                      onChange={handleInputEdit}
                                      marginBottom={7}
                                      width={250}
                                    />
                                  </Pane>
                                </Pane>
                              </Pane>

                              <Pane
                                aria-labelledby="tab1"
                                aria-hidden={1 !== selectedIndexE}
                                display={
                                  1 === selectedIndexE ? "block" : "none"
                                }
                                role="tabpanel"
                              >
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    downloads
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="downloads"
                                    value={editInputs.downloads}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    profile
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="profile"
                                    value={editInputs.profile}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    good evening
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="goodEvening"
                                    value={editInputs.goodEvening}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    recently played
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="recentlyPlayed"
                                    value={editInputs.recentlyPlayed}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    good Morning
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="goodMorning"
                                    value={editInputs.goodMorning}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    continue Listening
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="continueListening"
                                    value={editInputs.continueListening}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    favourite
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="favourite"
                                    value={editInputs.favourite}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    top Categories
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="topCategories"
                                    value={editInputs.topCategories}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    discover
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="discover"
                                    value={editInputs.discover}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    all
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="all"
                                    value={editInputs.all}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    all Episodes
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="allEpisodes"
                                    value={editInputs.allEpisodes}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    see More
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seeMore"
                                    value={editInputs.seeMore}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    my Library
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="myLibrary"
                                    value={editInputs.myLibrary}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    no Favourite
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noFavourite"
                                    value={editInputs.noFavourite}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    recent
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="recent"
                                    value={editInputs.recent}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    no Recent
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noRecent"
                                    value={editInputs.noRecent}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    no Downloads
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noDownloads"
                                    value={editInputs.noDownloads}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    achievements
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="achievements"
                                    value={editInputs.achievements}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    week
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="week"
                                    value={editInputs.week}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    month
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="month"
                                    value={editInputs.month}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    year
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="year"
                                    value={editInputs.year}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    average
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="average"
                                    value={editInputs.average}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    collection
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="labelCollection"
                                    value={editInputs.labelCollection}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    updates
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="updates"
                                    value={editInputs.updates}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    please Confirm
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="pleaseConfirm"
                                    value={editInputs.pleaseConfirm}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    confirm
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="confirm"
                                    value={editInputs.confirm}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    see All
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seeAll"
                                    value={editInputs.seeAll}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    logout Confirm description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="logoutConfirmDesc"
                                    value={editInputs.logoutConfirmDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notifications
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notifications"
                                    value={editInputs.notifications}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    clear All
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="clearAll"
                                    value={editInputs.clearAll}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    good Afternoon
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="goodAfternoon"
                                    value={editInputs.goodAfternoon}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    latest News
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="latestNews"
                                    value={editInputs.latestNews}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    enter Associated Email
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="enterAssociatedEmail"
                                    value={editInputs.enterAssociatedEmail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    welcome To
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="welcomeTo"
                                    value={editInputs.welcomeTo}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    reset Your Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="resetYourPassword"
                                    value={editInputs.resetYourPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    played
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="played"
                                    value={editInputs.played}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    get Started
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="getStarted"
                                    value={editInputs.getStarted}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    skip
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="skip"
                                    value={editInputs.skip}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    continue
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="continue"
                                    value={editInputs.continue}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    badge Collections
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="badgeCollections"
                                    value={editInputs.badgeCollections}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="updatedSuccess"
                                    value={editInputs.updatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Title First
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileOneTitle"
                                    value={editInputs.onBoardingMobileOneTitle}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Title Second
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileTwoTitle"
                                    value={editInputs.onBoardingMobileTwoTitle}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Title Third
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileThreeTitle"
                                    value={
                                      editInputs.onBoardingMobileThreeTitle
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Desc First
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileOneDesc"
                                    value={editInputs.onBoardingMobileOneDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Desc Second
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileTwoDesc"
                                    value={editInputs.onBoardingMobileTwoDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    on Boarding Mobile Desc Third
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="onBoardingMobileThreeDesc"
                                    value={editInputs.onBoardingMobileThreeDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    queue
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="queue"
                                    value={editInputs.queue}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    now playing
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="nowPlaying"
                                    value={editInputs.nowPlaying}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    scale up
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="scaleUp"
                                    value={editInputs.scaleUp}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    see Less
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seeLess"
                                    value={editInputs.seeLess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    download Started
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="downloadStarted"
                                    value={editInputs.downloadStarted}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    download Completed
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="downloadCompleted"
                                    value={editInputs.downloadCompleted}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    no News
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noNews"
                                    value={editInputs.noNews}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    no Updates
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noUpdates"
                                    value={editInputs.noUpdates}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    please Enter Input
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="pleaseEnterInput"
                                    value={editInputs.pleaseEnterInput}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    error Getting Data
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="errorGettingData"
                                    value={editInputs.errorGettingData}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    check Internet
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="checkInternet"
                                    value={editInputs.checkInternet}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    logging Out
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="loggingOut"
                                    value={editInputs.loggingOut}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Average Weekly
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="AverageWeekly"
                                    value={editInputs.AverageWeekly}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Average Monthly
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="AverageMonthly"
                                    value={editInputs.AverageMonthly}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Average Yearly
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="AverageYearly"
                                    value={editInputs.AverageYearly}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Already Downloaded
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="AlreadyDownloaded"
                                    value={editInputs.AlreadyDownloaded}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Internet
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noInternet"
                                    value={editInputs.noInternet}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Notify Update Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notifyUpdateDesc"
                                    value={editInputs.notifyUpdateDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Announcement Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noAnnouncementDesc"
                                    value={editInputs.noAnnouncementDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Discover Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noDiscoverDesc"
                                    value={editInputs.noDiscoverDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Downloads Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noDownloadsDesc"
                                    value={editInputs.noDownloadsDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Favourites Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noFavouritesDesc"
                                    value={editInputs.noFavouritesDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Internet Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noInternetDesc"
                                    value={editInputs.noInternetDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Explore Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noExploreDesc"
                                    value={editInputs.noExploreDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    No Series Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="noSeriesDesc"
                                    value={editInputs.noSeriesDesc}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    ISO Date
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="isoDate"
                                    value={editInputs.isoDate}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Try Another Keyword
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tryAnotherKeyword"
                                    value={editInputs.tryAnotherKeyword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                              </Pane>
                              <Pane
                                aria-labelledby="tab1"
                                aria-hidden={2 !== selectedIndexE}
                                display={
                                  2 === selectedIndexE ? "block" : "none"
                                }
                                role="tabpanel"
                              >
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification Announcement Title
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationAnnouncementTitle"
                                    value={
                                      editInputs.notificationAnnouncementTitle
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification Announcement Message
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationAnnouncementMessage"
                                    value={
                                      editInputs.notificationAnnouncementMessage
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification News Title
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationNewsTitle"
                                    value={editInputs.notificationNewsTitle}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification News Message
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationNewsMessage"
                                    value={editInputs.notificationNewsMessage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification Podcast Title
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationPodcastTitle"
                                    value={editInputs.notificationPodcastTitle}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    notification Podcast Message
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="notificationPodcastMessage"
                                    value={
                                      editInputs.notificationPodcastMessage
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    error Hashing Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="errorHashingPassword"
                                    value={editInputs.errorHashingPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Registration
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userRegistration"
                                    value={editInputs.userRegistration}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    tenant Registration
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantRegistration"
                                    value={editInputs.tenantRegistration}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Not Found
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userNotFound"
                                    value={editInputs.userNotFound}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    new Password Subject
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="newPasswordSubject"
                                    value={editInputs.newPasswordSubject}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Old Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredOldPassword"
                                    value={editInputs.requiredOldPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required New Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredNewPassword"
                                    value={editInputs.requiredNewPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    password Not Match Alert
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="passwordNotMatchAlert"
                                    value={editInputs.passwordNotMatchAlert}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    old Password Not Match
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="oldPasswordNotMatch"
                                    value={editInputs.oldPasswordNotMatch}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    bad Response
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="badResponse"
                                    value={editInputs.badResponse}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    password Updated
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="passwordUpdated"
                                    value={editInputs.passwordUpdated}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    change Password Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="changePasswordSuccess"
                                    value={editInputs.changePasswordSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    change Password Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="changePasswordFail"
                                    value={editInputs.changePasswordFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    upload Valid Image File
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="uploadValidImageFile"
                                    value={editInputs.uploadValidImageFile}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    upload All Files
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="uploadAllFiles"
                                    value={editInputs.uploadAllFiles}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    valid Profile Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="validProfileImage"
                                    value={editInputs.validProfileImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Firstname
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredFirstname"
                                    value={editInputs.requiredFirstname}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Lastname
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredLastname"
                                    value={editInputs.requiredLastname}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Email
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredEmail"
                                    value={editInputs.requiredEmail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Profile Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredProfileImage"
                                    value={editInputs.requiredProfileImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Exists
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailExists"
                                    value={editInputs.emailExists}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Confirm Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredConfirmPassword"
                                    value={editInputs.requiredConfirmPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Fields
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredFields"
                                    value={editInputs.requiredFields}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    password Not Match
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="passwordNotMatch"
                                    value={editInputs.passwordNotMatch}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    token Not Provided
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tokenNotProvided"
                                    value={editInputs.tokenNotProvided}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    upload Valid Csv Files
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="uploadValidCsvFiles"
                                    value={editInputs.uploadValidCsvFiles}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Import Csv File
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredImportCsvFile"
                                    value={editInputs.requiredImportCsvFile}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Device Token
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredDeviceToken"
                                    value={editInputs.requiredDeviceToken}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userCreatedSuccess"
                                    value={editInputs.userCreatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userCreatedFail"
                                    value={editInputs.userCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userUpdatedSuccess"
                                    value={editInputs.userUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userUpdatedFail"
                                    value={editInputs.userUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userDeletedSuccess"
                                    value={editInputs.userDeletedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userDeletedFail"
                                    value={editInputs.userDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Import Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userImportSuccess"
                                    value={editInputs.userImportSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Import Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userImportFail"
                                    value={editInputs.userImportFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Profile Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userProfileUpdatedSuccess"
                                    value={editInputs.userProfileUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    user Profile Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userProfileUpdatedFail"
                                    value={editInputs.userProfileUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    profile Picture Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="profilePictureUpdatedSuccess"
                                    value={
                                      editInputs.profilePictureUpdatedSuccess
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    profile Picture Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="profilePictureUpdatedFail"
                                    value={editInputs.profilePictureUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    token Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tokenUpdatedSuccess"
                                    value={editInputs.tokenUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    token Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tokenUpdatedFail"
                                    value={editInputs.tokenUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Title
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredTitle"
                                    value={editInputs.requiredTitle}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Description
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredDescription"
                                    value={editInputs.requiredDescription}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Category
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredCategory"
                                    value={editInputs.requiredCategory}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Banner Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredBannerImage"
                                    value={editInputs.requiredBannerImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Series Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredSeriesImage"
                                    value={editInputs.requiredSeriesImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    valid Series Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="validSeriesImage"
                                    value={editInputs.validSeriesImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    valid Banner Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="validBannerImage"
                                    value={editInputs.validBannerImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesCreatedSuccess"
                                    value={editInputs.seriesCreatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesCreatedFail"
                                    value={editInputs.seriesCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesUpdatedSuccess"
                                    value={editInputs.seriesUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesUpdatedFail"
                                    value={editInputs.seriesUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesDeletedSuccess"
                                    value={editInputs.seriesDeletedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    series Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="seriesDeletedFail"
                                    value={editInputs.seriesDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    upload Valid Audio File
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="uploadValidAudioFile"
                                    value={editInputs.uploadValidAudioFile}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Poster Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredPosterImage"
                                    value={editInputs.requiredPosterImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    valid Poster Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="validPosterImage"
                                    value={editInputs.validPosterImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Podcast File
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredPodcastFile"
                                    value={editInputs.requiredPodcastFile}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeCreatedSuccess"
                                    value={editInputs.episodeCreatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeCreatedFail"
                                    value={editInputs.episodeCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeUpdatedSuccess"
                                    value={editInputs.episodeUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeUpdatedFail"
                                    value={editInputs.episodeUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeDeletedSuccess"
                                    value={editInputs.episodeDeletedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    episode Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="episodeDeletedFail"
                                    value={editInputs.episodeDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    required Category Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="requiredCategoryImage"
                                    value={editInputs.requiredCategoryImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    valid Category Image
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="validCategoryImage"
                                    value={editInputs.validCategoryImage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryCreatedSuccess"
                                    value={editInputs.categoryCreatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryCreatedFail"
                                    value={editInputs.categoryCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryUpdatedSuccess"
                                    value={editInputs.categoryUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryUpdatedFail"
                                    value={editInputs.categoryUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryDeletedSuccess"
                                    value={editInputs.categoryDeletedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    category Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="categoryDeletedFail"
                                    value={editInputs.categoryDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementCreatedSuccess"
                                    value={
                                      editInputs.announcementCreatedSuccess
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementCreatedFail"
                                    value={editInputs.announcementCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementUpdatedSuccess"
                                    value={
                                      editInputs.announcementUpdatedSuccess
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementUpdatedFail"
                                    value={editInputs.announcementUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementDeletedSuccess"
                                    value={
                                      editInputs.announcementDeletedSuccess
                                    }
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    announcement Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="announcementDeletedFail"
                                    value={editInputs.announcementDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Created Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataCreatedSuccess"
                                    value={editInputs.dataCreatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Created Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataCreatedFail"
                                    value={editInputs.dataCreatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Updated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataUpdatedSuccess"
                                    value={editInputs.dataUpdatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Updated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataUpdatedFail"
                                    value={editInputs.dataUpdatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Deleted Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataDeletedSuccess"
                                    value={editInputs.dataDeletedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Deleted Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataDeletedFail"
                                    value={editInputs.dataDeletedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Get Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataGetSuccess"
                                    value={editInputs.dataGetSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    data Get Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="dataGetFail"
                                    value={editInputs.dataGetFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Required Recipient
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailRequiredRecipient"
                                    value={editInputs.emailRequiredRecipient}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Required Subject
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailRequiredSubject"
                                    value={editInputs.emailRequiredSubject}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Required Message
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailRequiredMessage"
                                    value={editInputs.emailRequiredMessage}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Sent Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailSentSuccess"
                                    value={editInputs.emailSentSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    email Sent Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="emailSentFail"
                                    value={editInputs.emailSentFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    internal Error
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="internalError"
                                    value={editInputs.internalError}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>

                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Activated Success
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userActivatedSuccess"
                                    value={editInputs.userActivatedSuccess}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Activated Fail
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userActivatedFail"
                                    value={editInputs.userActivatedFail}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                              </Pane>
                              <Pane
                                aria-labelledby="tab1"
                                aria-hidden={3 !== selectedIndexE}
                                display={
                                  3 === selectedIndexE ? "block" : "none"
                                }
                                role="tabpanel"
                              >
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Hello
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempHello"
                                    value={editInputs.tempHello}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Username
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempUsername"
                                    value={editInputs.tempUsername}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Password
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempPassword"
                                    value={editInputs.tempPassword}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Note
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempNote"
                                    value={editInputs.tempNote}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Regards
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempRegards"
                                    value={editInputs.tempRegards}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Team
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempTeam"
                                    value={editInputs.tempTeam}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Line Query
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempLineQuery"
                                    value={editInputs.tempLineQuery}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Dear
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tempDear"
                                    value={editInputs.tempDear}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Template Line First
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userTempLineFirst"
                                    value={editInputs.userTempLineFirst}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Template Line Second
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userTempLineSecond"
                                    value={editInputs.userTempLineSecond}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Template Line Third
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userTempLineThird"
                                    value={editInputs.userTempLineThird}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Template Line Fourth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userTempLineFourth"
                                    value={editInputs.userTempLineFourth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    User Template Line Fifth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="userTempLineFifth"
                                    value={editInputs.userTempLineFifth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line First
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineFirst"
                                    value={editInputs.tenantTempLineFirst}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line Second
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineSecond"
                                    value={editInputs.tenantTempLineSecond}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line Third
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineThird"
                                    value={editInputs.tenantTempLineThird}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line Fourth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineFourth"
                                    value={editInputs.tenantTempLineFourth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line Fifth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineFifth"
                                    value={editInputs.tenantTempLineFifth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Tenant Template Line Sixth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="tenantTempLineSixth"
                                    value={editInputs.tenantTempLineSixth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Forgot Password Line First
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="forgotPassTempLineFirst"
                                    value={editInputs.forgotPassTempLineFirst}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Forgot Password Line Second
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="forgotPassTempLineSecond"
                                    value={editInputs.forgotPassTempLineSecond}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Forgot Password Line Third
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="forgotPassTempLineThird"
                                    value={editInputs.forgotPassTempLineThird}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                                <Pane className="language-list-child-block">
                                  <Text color={"#a8a4a4"} fontWeight={600}>
                                    Forgot Password Line Fourth
                                  </Text>
                                  <TextInput
                                    type="text"
                                    name="forgotPassTempLineFourth"
                                    value={editInputs.forgotPassTempLineFourth}
                                    onChange={handleInputEdit}
                                    marginBottom={7}
                                    width={250}
                                  />
                                </Pane>
                              </Pane>
                            </Pane>
                          </SimpleBar>
                        </Pane>
                      </Pane>
                      <Pane className="language-form-buttons-panel">
                        <Pane padding={16}>
                          <Heading size={600}>
                            <Button
                              className="cancel-button"
                              data-customClass="custom-design"
                              type="reset"
                              onClick={() => setIsShownEditLanguage(false)}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="save-new-button"
                              data-customClass="custom-design"
                              type="submit"
                              disabled={
                                !editInputs.languageName ||
                                !editInputs.languageCode ||
                                submittingUpdate
                              }
                            >
                              Update
                            </Button>
                          </Heading>
                        </Pane>
                      </Pane>
                    </form>
                  </SideSheet>
                </React.Fragment>
              </Pane>
            </Pane>
            <Pane className="middle-panel">
              {languageInfoLoader ? (
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={400}
                >
                  <Spinner />
                </Pane>
              ) : (
                renderLanguageInfo()
              )}
            </Pane>
          </Pane>
          <Pane className="right-panel"> </Pane>
        </Pane>
      </Pane>
      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={450}
          isShown={isShownDeleteDialog}
          onCloseComplete={() => setIsShownDeleteDialog(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title="Do you really want to delete this language?"
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialog(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              Cancel
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteLanguage(deleteId)}
            >
              Delete
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default Language;
