import axios from "../../../helper/axiosInstance";
import React, { useEffect, useState, useCallback, useRef } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import {
  Pane,
  Button,
  TextInput,
  SelectField,
  MoreIcon,
  Checkbox,
  TrashIcon,
  Tablist,
  Tab,
  PlayIcon,
  PauseIcon,
  TextareaField,
  FileUploader,
  FileCard,
  SideSheet,
  Paragraph,
  Heading,
  TextInputField,
  Text,
  EditIcon,
  toaster,
  Popover,
  Position,
  Menu,
  Label,
  Spinner,
  MimeType,
  SelectMenu,
  Icon,
  SortIcon,
  Dialog,
  WarningSignIcon,
  ShareIcon,
} from "evergreen-ui";

import ReactSelect from "react-select";

import audioplayerbgimg from "../../../images/audio-player-bg-img.png";

import episodeposter from "../../../images/empty-podcast-poster.svg";

import calender from "../../../images/calendar.svg";
import timer from "../../../images/timer.svg";

import audioposter from "../../../images/player-poster.jpg";

import "./library.css";

function Library() {
  const [loading, setLoading] = useState(false);
  const [seriesLoader, setSeriesLoader] = useState(false);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [seriesInfoLoader, setSeriesInfoLoader] = useState(false);
  const [activeRenderList, setActiveRenderList] = useState(true);
  
  const [submittingSaveCategory, setSubmittingSaveCategory] = useState(false);
  const [submittingUpdateCategory, setSubmittingUpdateCategory] = useState(false);
  const [submittingSaveSeries, setSubmittingSaveSeries] = useState(false);
  const [submittingUpdateSeries, setSubmittingUpdateSeries] = useState(false);
  const [submittingSaveEpisode, setSubmittingSaveEpisode] = useState(false);
  const [submittingUpdateEpisode, setSubmittingUpdateEpisode] = useState(false);
  const [submittingSaveShare, setSubmittingSaveShare] = useState(false);
  const [submittingUpdateShare, setSubmittingUpdateShare] = useState(false);

  const [seriesListenedCount, setSeriesListenedCount] = useState();

  const playerRef = useRef();

  const seriesRef = useRef(null);
  const seriesInfoRef = useRef(null);
  const podcastRef = useRef(null);

  const scrollToTopSeries = () => {
    seriesRef.current.scrollTop = 0;
  };

  const scrollToTopSeriesInfo = () => {
    seriesInfoRef.current.scrollTop = 0;
  };

  const scrollToTopPodcast = () => {
    podcastRef.current.scrollTop = 0;
  };

  const [isCheckAllSeries, setIsCheckAllSeries] = useState(false);
  const [isCheckSeries, setIsCheckSeries] = useState([]);

  const replaceSeries = (error) => {
    error.target.src = "/default/series.png";
  };

  const replaceEpisode = (error) => {
    error.target.src = "/default/episode.png";
  };

  const replaceCategory = (error) => {
    error.target.src = "/default/category.png";
  };

  const replaceBanner = (error) => {
    error.target.src = "/default/banner.png";
  };

  const [activeSeries, setActiveSeries] = useState("");

  const [audioPlayerDuration, setAudioPlayerDuration] = useState();
  const [audioPlayerDurationEdit, setAudioPlayerDurationEdit] = useState();

  function checkDuration() {
    if (document.getElementById("audio_player")) {
      const tid = setTimeout(() => {
        clearTimeout(tid);
        let duration = document.getElementById("audio_player")?.duration;
        if (duration && duration !== isNaN) {
          setAudioPlayerDuration(parseInt(duration));
        }
      }, 1000);
    }
  }

  function checkDurationEdit() {
    if (document.getElementById("audio_player_edit")) {
      const tid = setTimeout(() => {
        clearTimeout(tid);
        let duration = document.getElementById("audio_player_edit")?.duration;
        if (duration && duration !== isNaN) {
          setAudioPlayerDurationEdit(parseInt(duration));
        }
      }, 1000);
    }
  }

  async function getTenants() {
    const response = await axios.get("/admin/tenants/");
    setAllTenents(response?.data?.data);
  }

  const [focusSeries, setFocusSeries] = useState(true);

  const activeSeriesTab = (id) => {
    if (activeSeries !== "") {
      if (id === activeSeries?._id && focusSeries) {
        return "second-line active";
      } else {
        return "second-line";
      }
    } else {
      return "second-line";
    }
  };

  const [player, setPlayer] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [activePodcast, setActivePodcast] = useState([]);
  const [activePlaylist, setActivePlaylist] = useState([]);
  const [playerSrc, setPlayerSrc] = useState(false);

  async function onClickDisplaySeriesById(id, index) {
    setFocusSeries(false);
    setSeriesInfoLoader(true);
    setPlayer(false);
    setSelectedIndex("tab1");

    await axios
      .get(`/admin/series/${id}`)
      .then((res) => {
        setSeriesTitle(res.data.data.title);
        setSeriesCategory(res.data.data?.category_id?.title);
        setSeriesId(id);
        setActiveSeries(res.data.data);
        setActivePodcast(res.data.data.podcasts);

        setFocusSeries(true);
        setSeriesInfoLoader(false);

        scrollToTopSeriesInfo();
        scrollToTopPodcast();
      })
      .catch((error) => {
        setFocusSeries(true);
        setSeriesInfoLoader(false);
        console.log(error);
      });

    await axios
      .get(`/admin/completedcount/series/${id}`)
      .then((res) => {
        setSeriesListenedCount(res?.data?.data?.[0]?.completedCount);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handlePlayerError(error) {
    console.log(error);
  }

  const handleClickAudioPlay = (id) => () => {
    setActivePlaylist(activePodcast);
    let audioId = id || 0;

    setTrackIndex(audioId);
    setPlayer(true);
    setPlayerSrc(true);
    playerRef.current.audio.current.play();
  };

  const handleClickAudioPause = (id) => () => {
    setActivePlaylist(activePodcast);
    let audioId = id || 0;
    setPlayer(true);
    setPlayerSrc(false);
    setTrackIndex(audioId);
    playerRef.current.audio.current.pause();
  };

  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? activePodcast.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < activePodcast.length - 1 ? currentTrack + 1 : 0
    );
  };

  const [selectedIndex, setSelectedIndex] = useState("tab1");

  const [btnCategory, setBtnCategory] = useState(false);
  const [btnEpisode, setBtnEpisode] = useState(false);
  const [btnSeries, setBtnSeries] = useState(false);

  const [seriesTitle, setSeriesTitle] = useState("");
  const [seriesCategory, setSeriesCategory] = useState("");
  const [seriesId, setSeriesId] = useState("");

  const [isShownSharedSeries, setIsShownSharedSeries] = useState(false);
  const [isShownEditSharedSeries, setIsShownEditSharedSeries] = useState(false);

  const [isShownSeries, setIsShownSeries] = useState(false);
  const [isShownEpisode, setIsShownEpisode] = useState(false);
  const [isShownCategory, setIsShownCategory] = useState(false);

  const [isShownEditSeries, setIsShownEditSeries] = useState(false);
  const [isShownEditEpisode, setIsShownEditEpisode] = useState(false);
  const [isShownEditCategory, setIsShownEditCategory] = useState(false);

  const [serieses, setSerieses] = useState([]);
  const [categories, setCategories] = useState([]);

  const [audioFile, setAudioFile] = useState([]);
  const [audioFileRejections, setAudioFileRejections] = useState([]);

  const [episodeFile, setEpisodeFile] = useState([]);
  const [episodeFileRejections, setEpisodeFileRejections] = useState([]);

  const [bannerFile, setBannerFile] = useState([]);
  const [bannerFileRejections, setBannerFileRejections] = useState([]);

  const [seriesFile, setSeriesFile] = useState([]);
  const [seriesFileRejections, setSeriesFileRejections] = useState([]);

  const [categoryFile, setCategoryFile] = useState([]);
  const [categoryFileRejections, setCategoryFileRejections] = useState([]);

  const [inputsPod, setInputsPod] = useState({
    podTitle: "",
    podDuration: "",
    podAudiopath: "",
    podDescription: "",
    podPosterImage: "",
  });

  const [editInputsPod, setEditInputsPod] = useState({
    podId: "",
    podTitle: "",
    podDuration: "",
    podAudiopath: "",
    podDescription: "",
    podPosterImage: "",
  });

  const handleInputPod = (e) => {
    setInputsPod((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputEditPod = (e) => {
    setEditInputsPod((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetPodcast = (e) => {
    setInputsPod({
      podTitle: "",
      podDuration: "",
      podAudiopath: "",
      podDescription: "",
      podPosterImage: "",
    });

    setEpisodeFile([]);
    setEpisodeFileRejections([]);

    setAudioFile([]);
    setAudioFileRejections([]);
    setAudioPlayerDuration();
  };

  const resetPodcastClose = (e) => {
    setInputsPod({
      podTitle: "",
      podDuration: "",
      podAudiopath: "",
      podDescription: "",
      podPosterImage: "",
    });

    setEpisodeFile([]);
    setEpisodeFileRejections([]);

    setAudioFile([]);
    setAudioFileRejections([]);
    setAudioPlayerDuration();

    setIsShownEpisode(false);
  };

  const resetEditPodcast = (e) => {
    setEditInputsPod({
      podId: "",
      podTitle: "",
      podDuration: "",
      podAudiopath: "",
      podDescription: "",
      podPosterImage: "",
    });

    setEpisodeFile([]);
    setEpisodeFileRejections([]);

    setAudioFile([]);
    setAudioFileRejections([]);
    setAudioPlayerDurationEdit();

    setIsShownEditEpisode(false);
  };

  const [inputsSeries, setInputsSeries] = useState({
    seriesTitle: "",
    seriesCategory: "",
    seriesDescription: "",
    seriesPodcasts: "",
    seriesPosterImage: "",
    seriesBannerImage: "",
  });

  const [editInputsSeries, setEditInputsSeries] = useState({
    seriesId: "",
    seriesTitle: "",
    seriesCategory: "",
    seriesDescription: "",
    seriesPodcasts: "",
    seriesPosterImage: "",
    seriesBannerImage: "",
  });

  const handleInputSeries = (e) => {
    setInputsSeries((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputEditSeries = (e) => {
    setEditInputsSeries((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetSeries = (e) => {
    setInputsSeries({
      seriesTitle: "",
      seriesCategory: "",
      seriesDescription: "",
      seriesPodcasts: "",
      seriesPosterImage: "",
      seriesBannerImage: "",
    });

    setSeriesFile([]);
    setSeriesFileRejections([]);

    setBannerFile([]);
    setBannerFileRejections([]);
  };

  const resetSeriesClose = (e) => {
    setInputsSeries({
      seriesTitle: "",
      seriesCategory: "",
      seriesDescription: "",
      seriesPodcasts: "",
      seriesPosterImage: "",
      seriesBannerImage: "",
    });

    setSeriesFile([]);
    setSeriesFileRejections([]);

    setBannerFile([]);
    setBannerFileRejections([]);

    setIsShownSeries(false);
  };

  const resetSharedSeriesClose = (e) => {
    setIsCheckSeries([]);
    setSelectedShareValues([]);
    setIsShownSharedSeries(false);
    setShareChecked(false);

    const selectedSeriesCheck = isCheckSeries.length === serieses.length;
    setIsCheckAllSeries(selectedSeriesCheck ? false : true);
  };

  const resetEditSharedSeriesClose = (e) => {
    setIsCheckSeries([]);
    setSelectedEditShareValues([]);
    setIsShownEditSharedSeries(false);
    setEditShareChecked(false);

    const selectedSeriesCheck = isCheckSeries.length === serieses.length;
    setIsCheckAllSeries(selectedSeriesCheck ? false : true);
  };

  const resetEditSeries = (e) => {
    setEditInputsSeries({
      seriesId: "",
      seriesTitle: "",
      seriesCategory: "",
      seriesDescription: "",
      seriesPodcasts: "",
      seriesPosterImage: "",
      seriesBannerImage: "",
    });

    setSeriesFile([]);
    setSeriesFileRejections([]);

    setBannerFile([]);
    setBannerFileRejections([]);

    setIsShownEditSeries(false);
  };

  const [inputs, setInputs] = useState({
    catTitle: "",
    catDescription: "",
    catPosterImage: "",
  });

  const [editInputs, setEditInputs] = useState({
    catId: "",
    catTitle: "",
    catDescription: "",
    catPosterImage: "",
  });

  const handleInput = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputEdit = (e) => {
    setEditInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetCategory = (e) => {
    setInputs({
      catTitle: "",
      catDescription: "",
      catPosterImage: "",
    });

    setCategoryFile([]);
    setCategoryFileRejections([]);
  };

  const resetCategoryClose = (e) => {
    setInputs({
      catTitle: "",
      catDescription: "",
      catPosterImage: "",
    });

    setCategoryFile([]);
    setCategoryFileRejections([]);

    setIsShownCategory(false);
  };

  const resetEditCategory = (e) => {
    setEditInputs({
      catId: "",
      catTitle: "",
      catDescription: "",
      catPosterImage: "",
    });

    setCategoryFile([]);
    setCategoryFileRejections([]);

    setIsShownEditCategory(false);
  };

  async function getSerieses() {
    setSeriesLoader(true);
    await axios
      .get("/admin/serieses/")
      .then((res) => {
        setSerieses(res?.data?.data);
        setSeriesLoader(false);
        setActiveRenderList(false);
      })
      .catch((error) => {
        setSeriesLoader(false);
        setActiveRenderList(false);
        console.log(error);
      });
  }

  async function getCategories() {
    setCategoriesLoader(true);
    await axios
      .get("/admin/categories/")
      .then((res) => {
        setCategories(res?.data?.data);
        setCategoriesLoader(false);
      })
      .catch((error) => {
        setCategoriesLoader(false);
      });
  }

  const handleChangeAudio = useCallback(
    (files) => setAudioFile([files[0]]),
    []
  );
  const handleRejectedAudio = useCallback(
    (audioFileRejections) => setAudioFileRejections([audioFileRejections[0]]),
    []
  );
  const handleRemoveAudio = useCallback(() => {
    setAudioFile([]);
    setAudioFileRejections([]);
    setAudioPlayerDuration();
    setAudioPlayerDurationEdit();
    const audio_player = document.getElementById("audio_player");
    if (audio_player) {
      audio_player.src = "";
    }
    const audio_player_edit = document.getElementById("audio_player_edit");
    if (audio_player_edit) {
      audio_player_edit.src = "";
    }
  }, []);

  const handleChangeEpisode = useCallback(
    (files) => setEpisodeFile([files[0]]),
    []
  );
  const handleRejectedEpisode = useCallback(
    (episodeFileRejections) =>
      setEpisodeFileRejections([episodeFileRejections[0]]),
    []
  );
  const handleRemoveEpisode = useCallback(() => {
    setEpisodeFile([]);
    setEpisodeFileRejections([]);
  }, []);

  const handleChangeBanner = useCallback(
    (files) => setBannerFile([files[0]]),
    []
  );
  const handleRejectedBanner = useCallback(
    (bannerFileRejections) =>
      setBannerFileRejections([bannerFileRejections[0]]),
    []
  );
  const handleRemoveBanner = useCallback(() => {
    setBannerFile([]);
    setBannerFileRejections([]);
  }, []);

  const handleChangeSeries = useCallback(
    (files) => setSeriesFile([files[0]]),
    []
  );
  const handleRejectedSeries = useCallback(
    (seriesFileRejections) =>
      setSeriesFileRejections([seriesFileRejections[0]]),
    []
  );
  const handleRemoveSeries = useCallback(() => {
    setSeriesFile([]);
    setSeriesFileRejections([]);
  }, []);

  const handleChangeCategory = useCallback(
    (files) => setCategoryFile([files[0]]),
    []
  );
  const handleRejectedCategory = useCallback(
    (categoryFileRejections) =>
      setCategoryFileRejections([categoryFileRejections[0]]),
    []
  );
  const handleRemoveCategory = useCallback(() => {
    setCategoryFile([]);
    setCategoryFileRejections([]);
  }, []);

  const handleClickOpenEditPodcast = (id) => () => {
    axios
      .get(`/admin/podcast/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputsPod({
          podId: response._id,
          podTitle: response.title,
          podDuration: response.duration,
          podDescription: response.description,
          podPosterImage: response.poster_image,
          podAudiopath: response.audiopath,
        });

        setIsShownEditEpisode(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleSubmitPodcast(e) {
    e.preventDefault();
    if (submittingSaveEpisode) {
      return;
    }
    setSubmittingSaveEpisode(true);
    setLoading(true);

    const url = "/admin/podcast";
    const formData = new FormData();
    formData.append("audiopath", audioFile[0]);
    formData.append("poster_image", episodeFile[0]);
    formData.append("series_id", seriesId);
    formData.append("title", inputsPod.podTitle);
    formData.append("duration", audioPlayerDuration);
    formData.append("description", inputsPod.podDescription);

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSaveEpisode(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });       
        getSerieses();
        onClickDisplaySeriesById(seriesId);

        if (btnEpisode) {
          resetPodcastClose();
          setBtnEpisode(false);
        } else {
          resetPodcast();
        }
      })
      .catch((error) => {
        setSubmittingSaveEpisode(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitEditPodcast(e) {
    e.preventDefault();
    if (submittingUpdateEpisode) {
      return;
    }
    setSubmittingUpdateEpisode(true);    
    setLoading(true);

    const id = editInputsPod.podId;

    const url = `/admin/podcast/${id}`;
    const formData = new FormData();

    if (audioFile[0] !== undefined) {
      formData.append("audiopath", audioFile[0]);
    }
    if (episodeFile[0] !== undefined) {
      formData.append("poster_image", episodeFile[0]);
    }
    formData.append("title", editInputsPod.podTitle);

    if (audioPlayerDurationEdit) {
      formData.append("duration", audioPlayerDurationEdit);
    }

    formData.append("description", editInputsPod.podDescription);
    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdateEpisode(false); 
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });       
        getSerieses();
        onClickDisplaySeriesById(seriesId); 
        resetEditPodcast();
      })
      .catch((error) => {
        setSubmittingUpdateEpisode(false); 
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteEpisode = (id) => () => {
    setLoading(true);
    axios
      .delete(`/admin/podcast/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 }); 
        getSerieses();
        onClickDisplaySeriesById(seriesId);
        setIsShownDeleteDialogEpisode(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  async function handleSubmitSharedSeries(e) {
    e.preventDefault();
    if (submittingSaveShare) {
      return;
    }
    setSubmittingSaveShare(true);
    setLoading(true);

    const url = "/admin/sharedseries";
    const selectedTenants = selectedShareValues.map((o) => o.value);

    const sharedData = {
      seriesId: isCheckSeries,
      tenants: selectedTenants,
    };

    await axios
      .post(url, sharedData)
      .then((res) => {
        setSubmittingSaveShare(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getSerieses();
        resetSharedSeriesClose();
      })
      .catch((error) => {
        setSubmittingSaveShare(false);
        setLoading(false);
        resetSharedSeriesClose();
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  async function handleSubmitEditSharedSeries(e) {
    e.preventDefault();
    if (submittingUpdateShare) {
      return;
    }
    setSubmittingUpdateShare(true);    
    setLoading(true);

    const url = "/admin/sharedseries";
    const selectedTenants = selectedEditShareValues.map((o) => o.value);

    const sharedData = {
      seriesId: isCheckSeries,
      tenants: selectedTenants,
    };

    await axios
      .post(url, sharedData)
      .then((res) => {
        setSubmittingUpdateShare(false); 
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getSerieses();
        resetEditSharedSeriesClose();
      })
      .catch((error) => {
        setSubmittingUpdateShare(false); 
        setLoading(false);
        resetEditSharedSeriesClose();
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitSeries(e) {
    e.preventDefault();
    if (submittingSaveSeries) {
      return;
    }
    setSubmittingSaveSeries(true);
    setLoading(true);

    const url = "/admin/series";
    const formData = new FormData();
    if (seriesFile[0] !== undefined) {
      formData.append("series_image", seriesFile[0]);
    }
    if (bannerFile[0] !== undefined) {
      formData.append("banner_image", bannerFile[0]);
    }
    formData.append("title", inputsSeries.seriesTitle);
    formData.append("category_id", inputsSeries.seriesCategory);
    formData.append("description", inputsSeries.seriesDescription);

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSaveSeries(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getSerieses();

        if (btnSeries) {
          resetSeriesClose();
          setBtnSeries(false);
        } else {
          resetSeries();
        }
      })
      .catch((error) => {
        setSubmittingSaveSeries(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const handleClickOpenManageShare = (id) => () => {
    setIsCheckSeries([id]);
    setLoading(true);
    axios
      .get(`/admin/sharedseries/${id}`)
      .then((res) => {
        const response = res.data?.data;
        if (!response) {
          setIsShownSharedSeries(true);
          setLoading(false);
        } else {
          const editTenants = response?.tenants;
          const data = allValues.filter((x) => editTenants.includes(x.value));
          setSelectedEditShareValues(data);
          setIsShownEditSharedSeries(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleClickOpenEditSeries = (id) => () => {
    axios
      .get(`/admin/series/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputsSeries({
          seriesId: response._id,
          seriesTitle: response.title,
          seriesCategory: response.category_id?._id,
          seriesDescription: response.description,
          seriesPosterImage: response.series_image,
          seriesBannerImage: response.banner_image,
        });

        setIsShownEditSeries(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  function handleSubmitEditSeries(e) {
    e.preventDefault();
    if (submittingUpdateSeries) {
      return;
    }
    setSubmittingUpdateSeries(true);    
    setLoading(true);

    const id = editInputsSeries.seriesId;

    const url = `/admin/series/${id}`;
    const formData = new FormData();
    if (seriesFile[0] !== undefined) {
      formData.append("series_image", seriesFile[0]);
    }
    if (bannerFile[0] !== undefined) {
      formData.append("banner_image", bannerFile[0]);
    }
    formData.append("title", editInputsSeries.seriesTitle);
    formData.append("category_id", editInputsSeries.seriesCategory);
    formData.append("description", editInputsSeries.seriesDescription);

    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdateSeries(false); 
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        resetEditSeries();
        getSerieses();
        setIsShownEditSeries(false);
        onClickDisplaySeriesById(id);
      })
      .catch((error) => {
        setSubmittingUpdateSeries(false); 
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteSeries = (id) => () => {
    setLoading(true);
    axios
      .delete(`/admin/series/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getSerieses();
        setActiveSeries("");
        setIsShownDeleteDialogSeries(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  function handleSubmitCategory(e) {
    e.preventDefault();
    if (submittingSaveCategory) {
      return;
    }
    setSubmittingSaveCategory(true);
    setLoading(true);

    const url = "/admin/category";

    const formData = new FormData();
    if (categoryFile[0] !== undefined) {
      formData.append("category_image", categoryFile[0]);
    }
    formData.append("title", inputs.catTitle);
    formData.append("description", inputs.catDescription);

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSaveCategory(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });

        getCategories();

        if (btnCategory) {
          resetCategoryClose();
          setBtnCategory(false);
        } else {
          resetCategory();
        }
      })
      .catch((error) => {
        setSubmittingSaveCategory(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const handleClickOpenEditCategory = (id) => () => {
    setLoading(true);
    axios
      .get(`/admin/category/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          catId: response._id,
          catTitle: response.title,
          catDescription: response.description,
          catPosterImage: response.category_image,
        });
        setIsShownEditCategory(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  function handleSubmitEditCategory(e) {
    e.preventDefault();
    
    if (submittingUpdateCategory) {
      return;
    }
    setSubmittingUpdateCategory(true);    
    setLoading(true);

    const id = editInputs.catId;

    const url = `/admin/category/${id}`;
    const formData = new FormData();
    if (categoryFile[0] !== undefined) {
      formData.append("category_image", categoryFile[0]);
    }
    formData.append("title", editInputs.catTitle);
    formData.append("description", editInputs.catDescription);

    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdateCategory(false);   
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        resetEditCategory();
        getCategories();
      })
      .catch((error) => {
        setSubmittingUpdateCategory(false);  
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteCategory = (id) => () => {
    setLoading(true);
    axios
      .delete(`/admin/category/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getCategories();
        setIsShownDeleteDialogCategory(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  const convertMinsToTime = (seconds) => {
    const d = Number(seconds);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);

    let hDisplay = h > 0 ? h + (h === 1 ? " Hr" : " Hrs") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " Min" : " Mins") : "0 Mins";

    if (h > 0) {
      return hDisplay + " : " + mDisplay;
    } else {
      return mDisplay;
    }
  };

  const SeriesCheckboxTag = ({ id, type, name, handleClick, isChecked }) => {
    return (
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        onClick={(event) => event.stopPropagation()}
        checked={isChecked}
      />
    );
  };

  const [allTenats, setAllTenents] = useState([]);

  const allValues = allTenats.map((tenant, index) => {
    return {
      value: tenant._id,
      label: tenant.title,
      key: index,
    };
  });

  const [byOrder, setByOrder] = useState(null);
  const [searchBy, setSearchBy] = useState("");

  function renderSeries() {
    if (activeRenderList) {
      return "";
    }
    const filteredOptions = serieses?.filter((series) =>
      series?.title?.toLowerCase().includes(searchBy.toLowerCase())
    );

    if (filteredOptions?.length > 0) {
      return filteredOptions
        .sort((a, b) => {
          if (byOrder === "A to Z")
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;

          if (byOrder === "Z to A")
            return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;

          if (byOrder === "Newest") return a.createdAt < b.createdAt ? 1 : -1;
          if (byOrder === "Oldest") return a.createdAt > b.createdAt ? 1 : -1;
        })
        .map((series, i) => {
          return (
            <Pane
              className={activeSeriesTab(series?._id)}
              tabIndex={i}
              key={series?._id}
              onClick={() => onClickDisplaySeriesById(series?._id, i)}
              style={{ cursor: "pointer" }}
            >
              <Pane
                className="podcast-series-items-list-panel-one"
                data-customClass="custom-design"
              >
                <Pane>
                  <SeriesCheckboxTag
                    type="checkbox"
                    name={series._id}
                    id={series._id}
                    handleClick={handleClickSeriesTag}
                    isChecked={isCheckSeries.includes(series._id)}
                  />
                </Pane>
                <Pane className="library-item-image">
                  <img
                    alt=""
                    className="library-img"
                    src={`${process.env.REACT_APP_API_URL}/${series.series_image}`}
                    onError={replaceSeries}
                    width="100%"
                    height="auto"
                  />
                </Pane>
                <Pane className="library-item-list-one">
                  <Pane className="podcast-text-name">
                    <Text className="text-one">
                      {series.category_id?.title}
                    </Text>

                    <Text className="text-two">{series.title}</Text>
                  </Pane>
                  <Pane
                    className="podcast-series-list-date-time"
                    data-customClass="custom-design"
                  >
                    <Text
                      className="inner-wrapper"
                      data-customClass="custom-design"
                    >
                      <img
                        alt=""
                        className="calender-img"
                        src={calender}
                        height="auto"
                      />
                      <Text
                        className="podcast-series-list-date-time"
                        data-customClass="custom-design"
                      >
                        {returnDate(series.createdAt)}
                      </Text>
                    </Text>
                    <Text
                      className="inner-wrapper"
                      data-customClass="custom-design"
                    >
                      <img
                        alt=""
                        className="calender-img"
                        src={timer}
                        height="auto"
                      />
                      <Text
                        className="podcast-series-list-date-time"
                        data-customClass="custom-design"
                      >
                        {convertMinsToTime(
                          series.podcasts &&
                            series.podcasts.reduce(
                              (a, b) => a + Number(b.duration),
                              0
                            )
                        )}
                      </Text>
                    </Text>
                  </Pane>
                </Pane>
              </Pane>

              <Pane className="podcast-series-items-list-panel-two">
                <Pane
                  className="category-list-item-icon"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Popover
                    minWidth="40px"
                    minHeight="40px"
                    padding="0px"
                    position={Position.BOTTOM_LEFT}
                    content={({ close }) => (
                      <Pane className="podcast-series-edit-delete-box">
                        <Menu>
                          <Menu.Group>
                            <Pane
                              onClick={handleClickOpenEditSeries(series._id)}
                            >
                              <Menu.Item
                                padding="0"
                                className="series-edit-container"
                                data-customClass="custom-design"
                                icon={<EditIcon size={12} color="white" />}
                                onSelect={close}
                              >
                                <Text
                                  className="series-edit-delete-text"
                                  data-customClass="custom-design"
                                >
                                  Edit
                                </Text>
                              </Menu.Item>
                            </Pane>
                            <Pane
                              onClick={handleClickOpenManageShare(series._id)}
                            >
                              <Menu.Item
                                padding="0"
                                className="series-edit-container"
                                data-customClass="custom-design"
                                icon={<ShareIcon size={12} color="white" />}
                                onSelect={close}
                              >
                                <Text
                                  className="series-edit-delete-text"
                                  data-customClass="custom-design"
                                >
                                  Manage Share
                                </Text>
                              </Menu.Item>
                            </Pane>
                            <Pane
                              onClick={handleClickOpenDeleteDialogSeries(
                                series?._id,
                                series?.title
                              )}
                            >
                              <Menu.Item
                                padding="0"
                                className="series-edit-container"
                                data-customClass="custom-design"
                                icon={<TrashIcon size={12} color="white" />}
                                onSelect={close}
                              >
                                <Text
                                  className="series-edit-delete-text"
                                  data-customClass="custom-design"
                                >
                                  Delete
                                </Text>
                              </Menu.Item>
                            </Pane>
                          </Menu.Group>
                        </Menu>
                      </Pane>
                    )}
                  >
                    <MoreIcon className="episode-list-more-icon" size={12} />
                  </Popover>
                </Pane>
              </Pane>
            </Pane>
          );
        });
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title="Select tenant to manage user data"
        >
          No record found.
        </Text>
      );
    }
  }

  const renderSeriesInfo = () => {
    if (activeSeries !== "") {
      return (
        <>
          <Pane className="serie-info">
            <Pane>
              <img
                alt=""
                className="serie-info-image"
                src={`${process.env.REACT_APP_API_URL}/${activeSeries?.series_image}`}
                onError={replaceSeries}
                width="100%"
                height="auto"
              />
            </Pane>
            <Pane className="serie-info-para">
              <p>{activeSeries?.description}</p>
            </Pane>
            <Pane className="serie-info-list-one">
              <Pane className="serie-info-list-one-a">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  Created on
                </p>
                <p className="serie-info-list-text-two">
                  {returnDate(activeSeries?.createdAt)}
                </p>
              </Pane>
              <Pane className="serie-info-list-one-b">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  Category
                </p>
                <p className="serie-info-list-text-two">
                  {activeSeries?.category_id?.title}
                </p>
              </Pane>
              <Pane className="serie-info-list-one-c">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  Duration
                </p>
                <p className="serie-info-list-text-two">
                  {convertMinsToTime(
                    activeSeries.podcasts &&
                      activeSeries.podcasts.reduce(
                        (a, b) => a + Number(b.duration),
                        0
                      )
                  )}
                </p>
              </Pane>
            </Pane>

            <Pane className="serie-info-list-one">
              <Pane className="serie-info-list-one-a">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  podcast Visibility
                </p>
                <p className="serie-info-list-text-two">
                  {activeSeries.isPublic ? "all" : "Tenant"}
                </p>
              </Pane>
              <Pane className="serie-info-list-one-b">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  Listened
                </p>
                <p className="serie-info-list-text-two">
                  {seriesListenedCount || 0}
                </p>
              </Pane>
              <Pane className="serie-info-list-one-c">
                <p
                  className="serie-info-list-text-one"
                  data-customClass="custom-design"
                >
                  no of episode
                </p>
                <p className="serie-info-list-text-two">
                  {activeSeries?.podcasts.length}
                </p>
              </Pane>
            </Pane>
          </Pane>
        </>
      );
    } else {
      return (
        <>
          <Pane className="series-empty-poster-container">
            <Pane className="series-empty-poster-circle">
              <img
                alt=""
                className="series-poster"
                src={episodeposter}
                width="100%"
                height="auto"
              />
            </Pane>

            <Text fontSize="12px" color="#343434">
              Click on series to view its information
            </Text>
          </Pane>
        </>
      );
    }
  };

  const [searchpod, setSearchpod] = useState("");
  const renderPodcasts = () => {
    if (activePodcast?.length > 0) {
      const filteredOptions = activePodcast?.filter((podcast) =>
        podcast?.title?.toLowerCase().includes(searchpod.toLowerCase())
      );

      if (filteredOptions?.length > 0) {
        return filteredOptions.map((podcast, i) => {
          return (
            <Pane className="episode-list-main-container" key={podcast?.title}>
              <Pane className="pane-one">
                <Pane>
                  <img
                    alt=""
                    className="eposide-img"
                    src={`${process.env.REACT_APP_API_URL}/${podcast?.poster_image}`}
                    onError={replaceEpisode}
                    width="100%"
                    height="auto"
                  />
                </Pane>
                <Pane>
                  <p className="episode-list-text-one">{podcast?.title}</p>
                  <p className="episode-list-text-two">
                    {returnDate(podcast?.createdAt)}
                  </p>
                </Pane>
              </Pane>
              <Pane className="pane-two">
                <Pane>
                  <img
                    alt=""
                    className="calender-img"
                    src={timer}
                    height="auto"
                  />
                  <span
                    className="pane-two-time"
                    style={{
                      display: "inline-block",
                      paddingLeft: 4,
                      position: "relative",
                      top: -1,
                    }}
                  >
                    {convertMinsToTime(podcast?.duration)}
                  </span>
                </Pane>
                <Pane
                  backgroundColor="black"
                  width="20px"
                  height="20px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  borderRadius="50%"
                >
                  {playerSrc &&
                  activePodcast[trackIndex]?._id == podcast?._id ? (
                    <PauseIcon
                      size={12}
                      style={{ cursor: "pointer" }}
                      className="playicon"
                      onClick={handleClickAudioPause(i)}
                    />
                  ) : (
                    <PlayIcon
                      size={12}
                      style={{ cursor: "pointer" }}
                      className="playicon"
                      onClick={handleClickAudioPlay(i)}
                    />
                  )}
                </Pane>
                <Pane className="podcast-series-edit-delete-box-container">
                  <Popover
                    minWidth="40px"
                    minHeight="40px"
                    padding="0px"
                    position={Position.BOTTOM_LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <Menu.Item
                            className="episode-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onClick={handleClickOpenEditPodcast(podcast?._id)}
                          >
                            <Text
                              className="episode-edit-text"
                              data-customClass="custom-design"
                            >
                              Edit
                            </Text>
                          </Menu.Item>
                          <Menu.Item
                            icon={<TrashIcon size={12} color="white" />}
                            className="episode-edit-container"
                            data-customClass="custom-design"
                            onClick={handleClickOpenDeleteDialogEpisode(
                              podcast?._id,
                              podcast?.title
                            )}
                          >
                            <Text
                              className="episode-delete-text"
                              data-customClass="custom-design"
                            >
                              Delete
                            </Text>
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <MoreIcon className="episode-list-more-icon" size={12} />
                  </Popover>
                </Pane>
              </Pane>
            </Pane>
          );
        });
      } else {
        return (
          <Text
            fontSize="12px"
            color="#343434"
            className="series-text"
            title="Select tenant to manage user data"
          >
            No record found.
          </Text>
        );
      }
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title="Select tenant to manage user data"
        >
          No record found.
        </Text>
      );
    }
  };

  function renderCategories() {
    return categories?.map((category, i) => {
      return (
        <Pane className="category-item-list" key={category?.title}>
          <Pane className="category-item-list-img-header">
            <Pane className="category-item-list-image">
              <img
                alt=""
                className="eposide-img"
                src={`${process.env.REACT_APP_API_URL}/${category.category_image}`}
                onError={replaceCategory}
                width="100%"
                height="auto"
              />
            </Pane>
            <Pane className="category-item-list-text">
              <p className="category-item-list-text-name">{category.title}</p>
              <p className="category-item-list-date">
                {returnDate(category.createdAt)}
              </p>
            </Pane>
          </Pane>
          <Pane className="category-list-item-icon">
            <Popover
              minWidth="40px"
              minHeight="40px"
              padding="0px"
              position={Position.BOTTOM_RIGHT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      padding="0"
                      className="episode-edit-container"
                      data-customClass="custom-design"
                      icon={<EditIcon size={12} color="white" />}
                      onClick={handleClickOpenEditCategory(category._id)}
                    >
                      <Text
                        className="category-edit-delete-text"
                        data-customClass="custom-design"
                      >
                        Edit
                      </Text>
                    </Menu.Item>
                    <Menu.Item
                      padding="0"
                      className="episode-edit-container"
                      data-customClass="custom-design"
                      icon={<TrashIcon size={12} color="white" />}
                      onClick={handleClickOpenDeleteDialogCategory(
                        category?._id,
                        category?.title
                      )}
                    >
                      <Text
                        className="category-edit-delete-text"
                        data-customClass="custom-design"
                      >
                        Delete
                      </Text>
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <MoreIcon className="second-box-moreicon" size={12} />
            </Popover>
          </Pane>
        </Pane>
      );
    });
  }

  function returnDate(data) {
    try {
      const date = new Date(data);
      const isoDate = date.toISOString().substring(0, 10);
      return isoDate;
    } catch (error) {
      console.log("invalid date");
    }
  }

  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isShownDeleteDialogSeries, setIsShownDeleteDialogSeries] =
    useState(false);
  const [isShownDeleteDialogEpisode, setIsShownDeleteDialogEpisode] =
    useState(false);
  const [isShownDeleteDialogCategory, setIsShownDeleteDialogCategory] =
    useState(false);

  const handleClickOpenDeleteDialogSeries = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialogSeries(true);
  };

  const handleClickOpenDeleteDialogEpisode = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialogEpisode(true);
  };

  const handleClickOpenDeleteDialogCategory = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialogCategory(true);
  };

  const handleSelectAllSeries = (e) => {
    setIsCheckAllSeries(!isCheckAllSeries);
    setIsCheckSeries(serieses?.map((li, i) => li._id));

    if (isCheckAllSeries) {
      setIsCheckSeries([]);
    }
  };

  const handleClickSeriesTag = (e) => {
    const { id, checked } = e.target;

    setIsCheckSeries([...isCheckSeries, id]);
    if (!checked) {
      setIsCheckSeries(isCheckSeries.filter((item) => item !== id));
    }
  };

  const [shareChecked, setShareChecked] = useState(false);
  const [selectedShareValues, setSelectedShareValues] = useState([]);

  const onChangeCheckbox = (e) => {
    const isChecked = !shareChecked;
    setShareChecked(isChecked);
    setSelectedShareValues(isChecked ? allValues : []);

    const selectedSeriesCheck = isCheckSeries.length === serieses.length;
    setIsCheckAllSeries(selectedSeriesCheck ? true : false);
  };

  const onChangeShare = (opt) => {
    const allOptionsSelected = opt.length === allValues.length;

    setShareChecked(allOptionsSelected ? true : false);
    setSelectedShareValues(opt);
  };

  const [editShareChecked, setEditShareChecked] = useState(false);
  const [selectedEditShareValues, setSelectedEditShareValues] = useState([]);

  const onChangeEditCheckbox = (e) => {
    const isEditChecked = !editShareChecked;
    setEditShareChecked(isEditChecked);
    setSelectedEditShareValues(isEditChecked ? allValues : []);
  };

  const onChangeEditShare = (opt) => {
    const allOptionsSelected = opt.length === allValues.length;

    setEditShareChecked(allOptionsSelected ? true : false);
    setSelectedEditShareValues(opt);
  };

  useEffect(() => {
    getTenants();
    getSerieses();
    getCategories();
  }, []);

  useEffect(() => {
    setActivePlaylist(activePodcast);
  }, [activePodcast, activePlaylist, trackIndex]);

  useEffect(() => {
    if (serieses.length > 0 && isCheckSeries.length === serieses.length) {
      setIsCheckAllSeries(true);
    } else {
      setIsCheckAllSeries(false);
    }
  }, [serieses, isCheckSeries]);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="left-panel">
              <Pane className="left-panel-header">
                <Pane className="first-line">
                  <Pane className="text-line">
                    <Label
                      className="podcast-series-header-name"
                      data-customClass="custom-design"
                      title="All Series"
                    >
                      All Series
                    </Label>
                  </Pane>
                  {/* CREATE SERIES SIDE PANEL */}
                  <Pane className="share-create-series-button-design">
                    <React.Fragment>
                      <SideSheet
                        preventBodyScrolling={true}
                        isShown={isShownSeries}
                        onCloseComplete={resetSeriesClose}
                        shouldCloseOnOverlayClick={false}
                      >
                        <Paragraph className="library-form-header">
                          <Text className="library-form-heading">
                            Create series
                          </Text>
                          <Text className="library-form-sub-heading">
                            Please fill the details and create series.
                          </Text>
                        </Paragraph>
                        <form
                          className="create-series-form"
                          data-customClass="custom-design"
                          onSubmit={handleSubmitSeries}
                          onReset={resetSeriesClose}
                        >
                          <SimpleBar
                            style={{
                              maxHeight: "calc(100vh - 139px)",
                            }}
                          >
                            <Pane className="series-main-container">
                              <Pane className="create-series-form-title">
                                <TextInputField
                                  inputWidth={350}
                                  type="text"
                                  label="Title"
                                  name="seriesTitle"
                                  value={inputsSeries.seriesTitle}
                                  onChange={handleInputSeries}
                                  required
                                />
                              </Pane>
                              <Pane className="create-series-form-title">
                                <SelectField
                                  label="Category"
                                  className="Select-category-title"
                                  name="seriesCategory"
                                  value={inputsSeries.seriesCategory}
                                  onChange={handleInputSeries}
                                  inputWidth={200}
                                  required
                                >
                                  <option value="">Select Category</option>
                                  {categories?.map((category) => {
                                    return (
                                      <option
                                        key={category._id}
                                        value={category._id}
                                      >
                                        {category.title}
                                      </option>
                                    );
                                  })}
                                </SelectField>
                              </Pane>
                            </Pane>

                            <Pane className="series-desc-series-img-container">
                              <Pane>
                                <TextareaField
                                  label="Description"
                                  className="global-text-area-padding"
                                  inputWidth={570}
                                  placeholder=""
                                  name="seriesDescription"
                                  value={inputsSeries.seriesDescription}
                                  onChange={handleInputSeries}
                                  required
                                  resize="none"
                                />
                              </Pane>
                              <Pane maxWidth={570}>
                                <FileUploader
                                  acceptedMimeTypes={[
                                    MimeType.jpeg,
                                    MimeType.png,
                                  ]}
                                  className="create-series-file-uploader"
                                  label="Banner Image *"
                                  description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 670 X 350 pixels."
                                  maxSizeInBytes={2 * 1024 ** 2}
                                  maxFiles={1}
                                  onChange={handleChangeBanner}
                                  onRejected={handleRejectedBanner}
                                  renderFile={(file) => {
                                    const { name, size, type } = file;
                                    const fileRejection =
                                      bannerFileRejections.find(
                                        (fileRejection) =>
                                          fileRejection.file === file
                                      );
                                    const { message } = fileRejection || {};
                                    return (
                                      <FileCard
                                        key={name}
                                        src={URL.createObjectURL(bannerFile[0])}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemoveBanner}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                      />
                                    );
                                  }}
                                  values={bannerFile}
                                  required
                                />
                              </Pane>
                              <Pane maxWidth={570}>
                                <FileUploader
                                  acceptedMimeTypes={[
                                    MimeType.jpeg,
                                    MimeType.png,
                                  ]}
                                  className="create-series-file-uploader"
                                  label="Series Image *"
                                  description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                                  maxSizeInBytes={2 * 1024 ** 2}
                                  maxFiles={1}
                                  onChange={handleChangeSeries}
                                  onRejected={handleRejectedSeries}
                                  renderFile={(file) => {
                                    const { name, size, type } = file;
                                    const fileRejection =
                                      seriesFileRejections.find(
                                        (fileRejection) =>
                                          fileRejection.file === file
                                      );
                                    const { message } = fileRejection || {};
                                    return (
                                      <>
                                        <FileCard
                                          src={URL.createObjectURL(
                                            seriesFile[0]
                                          )}
                                          key={name}
                                          isInvalid={fileRejection != null}
                                          name={name}
                                          onRemove={handleRemoveSeries}
                                          sizeInBytes={size}
                                          type={type}
                                          validationMessage={message}
                                        />
                                      </>
                                    );
                                  }}
                                  values={seriesFile}
                                  required
                                />
                              </Pane>
                            </Pane>
                          </SimpleBar>
                          <Pane className="library-form-buttons-panel">
                            <Pane padding={16}>
                              <Heading size={600}>
                                <Button
                                  className="cancel-button"
                                  data-customClass="custom-design"
                                  type="reset"
                                  onClick={resetSeriesClose}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="save-button"
                                  data-customClass="custom-design"
                                  type="submit"
                                  onClick={() => {
                                    setBtnSeries(true);
                                  }}
                                  disabled={
                                    !inputsSeries.seriesTitle ||
                                    !inputsSeries.seriesCategory ||
                                    !inputsSeries.seriesDescription ||
                                    !bannerFile[0] ||
                                    !seriesFile[0] || 
                                    submittingSaveSeries
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  className="save-new-button"
                                  data-customClass="custom-design"
                                  type="submit"
                                  disabled={
                                    !inputsSeries.seriesTitle ||
                                    !inputsSeries.seriesCategory ||
                                    !inputsSeries.seriesDescription ||
                                    !bannerFile[0] ||
                                    !seriesFile[0] || 
                                    submittingSaveSeries
                                  }
                                >
                                  Save &amp; Add New
                                </Button>
                              </Heading>
                            </Pane>
                          </Pane>
                        </form>
                      </SideSheet>

                      {/* PODCAST SHARE */}
                      <SideSheet
                        preventBodyScrolling={true}
                        isShown={isShownSharedSeries}
                        onCloseComplete={resetSharedSeriesClose}
                        shouldCloseOnOverlayClick={false}
                      >
                        <Paragraph className="library-form-header">
                          <Text className="library-form-heading">
                            Share series
                          </Text>
                          <Text className="library-form-sub-heading">
                            Please select tenants to share series.
                          </Text>
                        </Paragraph>
                        <form
                          className="create-series-form"
                          onSubmit={handleSubmitSharedSeries}
                          onReset={resetSharedSeriesClose}
                        >
                          <Pane className="share-body">
                            <Checkbox
                              label="Select All"
                              checked={shareChecked}
                              onChange={onChangeCheckbox}
                            />
                            <ReactSelect
                              isMulti
                              onChange={onChangeShare}
                              options={allValues}
                              value={selectedShareValues}
                              menuIsOpen={true}
                            />
                          </Pane>
                          <Pane className="library-form-buttons-panel">
                            <Pane padding={16}>
                              <Heading size={600}>
                                <Button
                                  className="cancel-button"
                                  data-customClass="custom-design"
                                  type="reset"
                                  onClick={resetSharedSeriesClose}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="save-button"
                                  data-customClass="custom-design"
                                  type="submit"
                                  disabled={
                                    !isCheckSeries.length > 0 ||
                                    !selectedShareValues.length > 0 || 
                                    submittingSaveShare
                                  }
                                >
                                  Share
                                </Button>
                              </Heading>
                            </Pane>
                          </Pane>
                        </form>
                      </SideSheet>

                      <Button
                        className="create-share-button-design"
                        data-customClass="custom-design"
                        onClick={() => setIsShownSharedSeries(true)}
                        disabled={!isCheckSeries.length > 0}
                        title="share series"
                      >
                        Share
                      </Button>
                      <Button
                        className="create-stories-button"
                        data-customClass="custom-design"
                        onClick={() => setIsShownSeries(true)}
                        title="create series"
                      >
                        Create
                      </Button>
                    </React.Fragment>
                  </Pane>
                </Pane>
                <Pane
                  className="select-dropdown-box-design"
                  data-customClass="custom-design"
                >
                  <Pane className="search-panel-bottom"></Pane>
                  <Pane
                    className="search-panel"
                    data-customClass="custom-design"
                    style={{ marginTop: "10px" }}
                  >
                    <TextInput
                      className="searchPod"
                      data-customClass="custom-design"
                      placeholder="Search..."
                      type="text"
                      onChange={(e) => setSearchBy(e.target.value)}
                    />
                    <SelectMenu
                      className="filter-menu"
                      height={132}
                      width={100}
                      data-customClass="custom-design"
                      options={["A to Z", "Z to A", "Newest", "Oldest"].map(
                        (label) => ({ label, value: label })
                      )}
                      selected={byOrder}
                      hasFilter={false}
                      hasTitle={false}
                      onSelect={(item) => {
                        setByOrder(item.value);
                        scrollToTopSeries();
                      }}
                      closeOnSelect={true}
                    >
                      <Button className="sort-filter">
                        <Icon icon={SortIcon} size={12} /> &nbsp;
                        {byOrder || "Sort by"}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="select-all-series-checkbox">
                <input
                  type="checkbox"
                  name="selectAllSeries"
                  id="selectAllSeries"
                  onChange={handleSelectAllSeries}
                  checked={isCheckAllSeries}
                />
                <Text fontSize="12px" color="#343434">
                  Select all
                </Text>
              </Pane>

              <Pane className="left-panel-content">
                <Pane className="podcast-content-wrapper">
                  <SimpleBar
                    className="scroll-bar-customize"
                    style={{
                      maxHeight: "calc(100vh - 200px)",
                    }}
                    scrollableNodeProps={{ ref: seriesRef }}
                  >
                    {seriesLoader ? (
                      <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={400}
                      >
                        <Spinner />
                      </Pane>
                    ) : (
                      renderSeries()
                    )}
                  </SimpleBar>
                </Pane>
                {/* edit series fragment */}

                <Pane>
                  <React.Fragment>
                    <SideSheet
                      isShown={isShownEditSeries}
                      onCloseComplete={resetEditSeries}
                      shouldCloseOnOverlayClick={false}
                    >
                      <Paragraph className="edit-series-form-header">
                        <Text className="edit-series-form-heading">
                          edit series
                        </Text>
                        <Text className="edit-series-form-sub-heading">
                          Please fill the details and edit series.
                        </Text>
                      </Paragraph>
                      <SimpleBar
                        style={{
                          maxHeight: "calc(100vh - 139px)",
                        }}
                      >
                        <form
                          onSubmit={handleSubmitEditSeries}
                          className="create-episode-form"
                          data-customClass="custom-design"
                          onReset={resetEditSeries}
                        >
                          <Pane className="edit-series-panel-one">
                            <Pane>
                              <TextInputField
                                label="Title"
                                type="text"
                                name="seriesTitle"
                                value={editInputsSeries.seriesTitle}
                                onChange={handleInputEditSeries}
                                required
                                inputWidth={370}
                              />
                            </Pane>
                            <Pane>
                              <SelectField
                                label="Category"
                                inputWidth={190}
                                name="seriesCategory"
                                value={editInputsSeries.seriesCategory}
                                onChange={handleInputEditSeries}
                                required
                              >
                                <option value=""> Select Category </option>
                                {categories?.map((category) => {
                                  return (
                                    <option
                                      key={category._id}
                                      value={category._id}
                                    >
                                      {category.title}
                                    </option>
                                  );
                                })}
                              </SelectField>
                            </Pane>
                          </Pane>
                          <Pane>
                            <TextareaField
                              className="global-text-area-padding"
                              label="Description"
                              placeholder=""
                              name="seriesDescription"
                              value={editInputsSeries.seriesDescription}
                              onChange={handleInputEditSeries}
                              required
                              resize="none"
                              inputWidth={570}
                            />
                          </Pane>
                          <Pane maxWidth={570}>
                            <FileUploader
                              acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                              className="create-series-file-uploader"
                              label="Banner Image"
                              description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 670 X 350 pixels."
                              maxSizeInBytes={2 * 1024 ** 2}
                              maxFiles={1}
                              onChange={handleChangeBanner}
                              onRejected={handleRejectedBanner}
                              renderFile={(file) => {
                                if (file) {
                                  const { name, size, type } = file;
                                  const fileRejection =
                                    bannerFileRejections.find(
                                      (fileRejection) =>
                                        fileRejection.file === file
                                    );
                                  const { message } = fileRejection || {};
                                  return (
                                    <FileCard
                                      key={name}
                                      src={URL.createObjectURL(bannerFile[0])}
                                      isInvalid={fileRejection != null}
                                      name={name}
                                      onRemove={handleRemoveBanner}
                                      sizeInBytes={size}
                                      type={type}
                                      validationMessage={message}
                                    />
                                  );
                                } else {
                                  setBannerFile([]);
                                }
                              }}
                              values={bannerFile}
                            />
                          </Pane>
                          {editInputsSeries.seriesBannerImage && (
                            <Pane
                              maxWidth={100}
                              className="defaultImageWrapper"
                            >
                              <img
                                alt=""
                                src={`${process.env.REACT_APP_API_URL}/${editInputsSeries.seriesBannerImage}`}
                                onError={replaceBanner}
                                className="banner-image"
                              />
                            </Pane>
                          )}
                          <Pane maxWidth={570}>
                            <FileUploader
                              acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                              className="create-episode-image-uploader"
                              label="Poster Image"
                              description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                              maxSizeInBytes={2 * 1024 ** 2}
                              maxFiles={1}
                              onChange={handleChangeSeries}
                              name="series_image"
                              values={seriesFile}
                              onRejected={handleRejectedSeries}
                              renderFile={(file) => {
                                if (file) {
                                  const { name, size, type } = file;
                                  const fileRejection =
                                    episodeFileRejections.find(
                                      (fileRejection) =>
                                        fileRejection.file === file
                                    );
                                  const { message } = fileRejection || {};
                                  return (
                                    <FileCard
                                      key={name}
                                      src={URL.createObjectURL(seriesFile[0])}
                                      isInvalid={fileRejection != null}
                                      name={name}
                                      value={name}
                                      onRemove={handleRemoveSeries}
                                      sizeInBytes={size}
                                      type={type}
                                      validationMessage={message}
                                    />
                                  );
                                } else {
                                  setSeriesFile([]);
                                }
                              }}
                            />
                          </Pane>
                          {editInputsSeries.seriesPosterImage && (
                            <Pane
                              maxWidth={100}
                              className="defaultImageWrapper"
                            >
                              <img
                                alt=""
                                src={`${process.env.REACT_APP_API_URL}/${editInputsSeries.seriesPosterImage}`}
                                onError={replaceSeries}
                                className="series-image"
                              />
                            </Pane>
                          )}
                          <Pane className="edit-series-form-panel">
                            <Pane padding={16}>
                              <Heading size={600}>
                                <Button
                                  className="cancel-button"
                                  data-customClass="custom-design"
                                  type="reset"
                                  onClick={resetEditSeries}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="save-new-button"
                                  data-customClass="custom-design"
                                  type="submit"
                                  disabled={
                                    !editInputsSeries.seriesTitle ||
                                    !editInputsSeries.seriesCategory ||
                                    !editInputsSeries.seriesDescription || 
                                    submittingUpdateSeries
                                  }
                                >
                                  Update
                                </Button>
                              </Heading>
                            </Pane>
                          </Pane>
                        </form>
                      </SimpleBar>
                    </SideSheet>
                  </React.Fragment>

                  <React.Fragment>
                    <SideSheet
                      preventBodyScrolling={true}
                      isShown={isShownEditSharedSeries}
                      onCloseComplete={resetEditSharedSeriesClose}
                      shouldCloseOnOverlayClick={false}
                    >
                      <Paragraph className="library-form-header">
                        <Text className="library-form-heading">
                          Edit share series
                        </Text>
                        <Text className="library-form-sub-heading">
                          Please select tenants to share series.
                        </Text>
                      </Paragraph>
                      <form
                        className="create-series-form"
                        onSubmit={handleSubmitEditSharedSeries}
                        onReset={resetEditSharedSeriesClose}
                      >
                        <Pane className="share-body">
                          <Checkbox
                            label="Select All"
                            checked={editShareChecked}
                            onChange={onChangeEditCheckbox}
                          />
                          <ReactSelect
                            required
                            isMulti
                            onChange={onChangeEditShare}
                            options={allValues}
                            value={selectedEditShareValues}
                            menuIsOpen={true}
                          />
                        </Pane>
                        <Pane className="library-form-buttons-panel">
                          <Pane padding={16}>
                            <Heading size={600}>
                              <Button
                                className="cancel-button"
                                data-customClass="custom-design"
                                type="reset"
                                onClick={resetEditSharedSeriesClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="save-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={!isCheckSeries.length > 0 || submittingUpdateShare }
                              >
                                Update
                              </Button>
                            </Heading>
                          </Pane>
                        </Pane>
                      </form>
                    </SideSheet>
                  </React.Fragment>
                </Pane>
                {/* end episode edit fragment */}
              </Pane>
            </Pane>

            <Pane className="middle-panel">
              <Pane className="middle-panel-content">
                {seriesInfoLoader ? (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={400}
                  >
                    <Spinner />
                  </Pane>
                ) : (
                  <>
                    {activeSeries !== "" ? (
                      <Pane>
                        <Pane className="create-episode-header-wrapper">
                          <Pane className="second-box-title-moreicon">
                            <Label
                              className="episode-header-name"
                              data-customClass="custom-design"
                              title="Series title"
                            >
                              {seriesTitle}
                            </Label>
                            <Pane className="second-box-moreicon">
                              <Popover
                                minWidth="40px"
                                minHeight="40px"
                                padding="0px"
                                position={Position.BOTTOM_LEFT}
                                content={
                                  <Pane className="podcast-series-edit-delete-box">
                                    <Menu>
                                      <Menu.Group>
                                        <Menu.Item
                                          padding-top="0 !important"
                                          className="series-edit-container"
                                          data-customClass="custom-design"
                                          icon={
                                            <EditIcon size={12} color="white" />
                                          }
                                          onClick={handleClickOpenEditSeries(
                                            activeSeries._id
                                          )}
                                        >
                                          <Text
                                            className="series-edit-delete-text"
                                            data-customClass="custom-design"
                                          >
                                            Edit
                                          </Text>
                                        </Menu.Item>
                                        <Menu.Item
                                          padding-top="0 !important"
                                          className="series-edit-container"
                                          data-customClass="custom-design"
                                          icon={
                                            <TrashIcon
                                              size={12}
                                              color="white"
                                            />
                                          }
                                          onClick={handleClickOpenDeleteDialogSeries(
                                            activeSeries?._id,
                                            activeSeries?.title
                                          )}
                                        >
                                          <Text
                                            className="series-edit-delete-text"
                                            data-customClass="custom-design"
                                          >
                                            Delete
                                          </Text>
                                        </Menu.Item>
                                      </Menu.Group>
                                    </Menu>
                                  </Pane>
                                }
                              >
                                <MoreIcon size={12} />
                              </Popover>
                            </Pane>
                          </Pane>
                          <Tablist className="podTablist episode-series-info-box">
                            <Tab
                              key="tab1"
                              id="tab1"
                              onSelect={() => setSelectedIndex("tab1")}
                              isSelected={"tab1" === selectedIndex}
                              aria-controls="panel-1"
                            >
                              Episodes
                            </Tab>
                            <Tab
                              key="tab2"
                              id="tab2"
                              onSelect={() => setSelectedIndex("tab2")}
                              isSelected={"tab2" === selectedIndex}
                              aria-controls="panel-2"
                            >
                              Series Info
                            </Tab>
                          </Tablist>
                          <Pane className="second-search-create-container">
                            <Pane>
                              <React.Fragment>
                                <SideSheet
                                  isShown={isShownEpisode}
                                  onCloseComplete={resetPodcastClose}
                                  shouldCloseOnOverlayClick={false}
                                >
                                  <Paragraph className="library-form-header">
                                    <Text className="library-form-heading">
                                      Create episode
                                    </Text>
                                    <Text className="library-form-sub-heading">
                                      Please fill the details and create
                                      episode.
                                    </Text>
                                  </Paragraph>
                                  <form
                                    onSubmit={handleSubmitPodcast}
                                    onReset={resetPodcastClose}
                                    className="create-episode-form"
                                    data-customClass="custom-design"
                                  >
                                    <SimpleBar
                                      style={{
                                        maxHeight: "calc(100vh - 132px)",
                                      }}
                                    >
                                      <Pane>
                                        <input
                                          type="hidden"
                                          name="seriesId"
                                          value={seriesId}
                                          required
                                        />
                                      </Pane>
                                      <Pane className="create-episode-form-title">
                                        <Pane>
                                          <TextInputField
                                            label="Title"
                                            type="text"
                                            name="podTitle"
                                            value={inputsPod.podTitle}
                                            onChange={handleInputPod}
                                            required
                                            inputWidth={570}
                                          />
                                        </Pane>
                                      </Pane>
                                      <Pane>
                                        <TextareaField
                                          className="global-text-area-padding"
                                          label="Description"
                                          name="podDescription"
                                          value={inputsPod.podDescription}
                                          onChange={handleInputPod}
                                          required
                                          resize="none"
                                          inputWidth={570}
                                        />
                                      </Pane>
                                      <Pane maxWidth={570}>
                                        <FileUploader
                                          acceptedMimeTypes={[MimeType.mp3]}
                                          className="create-episode-file-uploader"
                                          label="Podcast file (.mp3) *"
                                          description="You can upload .mp3 file formats."
                                          required
                                          maxFiles={1}
                                          onChange={handleChangeAudio}
                                          onRejected={handleRejectedAudio}
                                          renderFile={(file) => {
                                            const { name, size, type } = file;
                                            const fileRejection =
                                              audioFileRejections.find(
                                                (fileRejection) =>
                                                  fileRejection.file === file
                                              );
                                            const { message } =
                                              fileRejection || {};

                                            document.getElementById(
                                              "audio_player"
                                            ).src = URL.createObjectURL(
                                              audioFile[0]
                                            );
                                            return (
                                              <FileCard
                                                key={name}
                                                src={URL.createObjectURL(
                                                  audioFile[0]
                                                )}
                                                isInvalid={
                                                  fileRejection != null
                                                }
                                                name={name}
                                                value={name}
                                                onRemove={handleRemoveAudio}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                              />
                                            );
                                          }}
                                          name="audiopath"
                                          values={audioFile}
                                        />
                                      </Pane>
                                      <audio
                                        onCanPlayThrough={checkDuration()}
                                        src=""
                                        controls
                                        id="audio_player"
                                        style={{ display: "none" }}
                                      />
                                      <Pane maxWidth={570}>
                                        <FileUploader
                                          acceptedMimeTypes={[
                                            MimeType.jpeg,
                                            MimeType.png,
                                          ]}
                                          className="create-episode-image-uploader"
                                          label="Poster Image *"
                                          description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                                          maxSizeInBytes={2 * 1024 ** 2}
                                          maxFiles={1}
                                          onChange={handleChangeEpisode}
                                          name="poster_image"
                                          values={episodeFile}
                                          onRejected={handleRejectedEpisode}
                                          renderFile={(file) => {
                                            const { name, size, type } = file;
                                            const fileRejection =
                                              episodeFileRejections.find(
                                                (fileRejection) =>
                                                  fileRejection.file === file
                                              );
                                            const { message } =
                                              fileRejection || {};
                                            return (
                                              <FileCard
                                                key={name}
                                                src={URL.createObjectURL(
                                                  episodeFile[0]
                                                )}
                                                isInvalid={
                                                  fileRejection != null
                                                }
                                                name={name}
                                                value={name}
                                                onRemove={handleRemoveEpisode}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                              />
                                            );
                                          }}
                                        />
                                      </Pane>
                                      {inputsPod.podPosterImage && (
                                        <Pane
                                          maxWidth={100}
                                          className="defaultImageWrapper"
                                        >
                                          <img
                                            alt=""
                                            src={`${process.env.REACT_APP_API_URL}/${inputsPod.podPosterImage}`}
                                            onError={replaceBanner}
                                            className="banner-image"
                                          />
                                        </Pane>
                                      )}
                                    </SimpleBar>
                                    <Pane className="episode-form-buttons-panel">
                                      <Pane padding={16}>
                                        <Heading size={600}>
                                          <Button
                                            className="cancel-button"
                                            data-customClass="custom-design"
                                            type="reset"
                                            onClick={resetPodcastClose}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            className="save-button"
                                            data-customClass="custom-design"
                                            type="submit"
                                            onClick={() => {
                                              setBtnEpisode(true);
                                            }}
                                            disabled={
                                              !inputsPod.podTitle ||
                                              !audioPlayerDuration ||
                                              !inputsPod.podDescription ||
                                              !audioFile[0] ||
                                              !episodeFile[0] || 
                                              submittingSaveEpisode
                                            }
                                          >
                                            Save
                                          </Button>
                                          <Button
                                            className="save-new-button"
                                            data-customClass="custom-design"
                                            type="submit"
                                            disabled={
                                              !inputsPod.podTitle ||
                                              !audioPlayerDuration ||
                                              !inputsPod.podDescription ||
                                              !audioFile[0] ||
                                              !episodeFile[0] || 
                                              submittingSaveEpisode
                                            }
                                          >
                                            Save &amp; Add New
                                          </Button>
                                        </Heading>
                                      </Pane>
                                    </Pane>
                                  </form>
                                </SideSheet>
                              </React.Fragment>
                            </Pane>
                          </Pane>
                        </Pane>
                        <Pane className="episode-list-data" flex="1">
                          <Pane>
                            <Pane
                              key="tab1"
                              id="panel-1"
                              role="tabpanel"
                              aria-labelledby="tab1"
                              aria-hidden={"tab1" !== selectedIndex}
                              display={
                                "tab1" === selectedIndex ? "block" : "none"
                              }
                            >
                              {activeSeries !== "" ? (
                                <Pane>
                                  <Pane className="search-panel-bottom"></Pane>
                                  <Pane className="search-panel">
                                    <TextInput
                                      className="searchPod"
                                      data-customClass="custom-design"
                                      placeholder="Search..."
                                      type="text"
                                      onChange={(e) =>
                                        setSearchpod(e.target.value)
                                      }
                                    />
                                    <Button
                                      className="create-episode-language-button"
                                      data-customClass="custom-design"
                                      onClick={() => setIsShownEpisode(true)}
                                      title="create episode"
                                    >
                                      Create
                                    </Button>
                                  </Pane>
                                </Pane>
                              ) : (
                                ""
                              )}
                              <SimpleBar
                                style={{ maxHeight: "calc(100vh - 230px)" }}
                                scrollableNodeProps={{ ref: podcastRef }}
                              >
                                <Pane className="episode-list-container-wrapper">
                                  {renderPodcasts()}
                                </Pane>
                              </SimpleBar>
                            </Pane>
                          </Pane>
                          {/* edit episode fragment */}

                          <Pane>
                            <React.Fragment>
                              <SideSheet
                                isShown={isShownEditEpisode}
                                onCloseComplete={resetEditPodcast}
                                shouldCloseOnOverlayClick={false}
                              >
                                <Paragraph className="edit-episode-form">
                                  <Text className="edit-episode-form-heading">
                                    Edit Episode
                                  </Text>
                                  <Text className="edit-episode-sub-heading">
                                    Please fill the details and edit episode.
                                  </Text>
                                </Paragraph>
                                <form
                                  onSubmit={handleSubmitEditPodcast}
                                  className="edit-episode-form"
                                  onReset={resetEditPodcast}
                                >
                                  <SimpleBar
                                    style={{ maxHeight: "calc(100vh - 132px)" }}
                                  >
                                    <Pane>
                                      <input
                                        type="hidden"
                                        name="podId"
                                        value={editInputsPod.podId}
                                        onChange={handleInputEditPod}
                                        required
                                      />
                                    </Pane>
                                    <Pane className="create-episode-form-title">
                                      <Pane>
                                        <TextInputField
                                          label="Title"
                                          type="text"
                                          name="podTitle"
                                          value={editInputsPod.podTitle}
                                          onChange={handleInputEditPod}
                                          required
                                          inputWidth={570}
                                        />
                                      </Pane>
                                    </Pane>
                                    <Pane>
                                      <TextareaField
                                        className="global-text-area-padding"
                                        label="Description"
                                        name="podDescription"
                                        value={editInputsPod.podDescription}
                                        onChange={handleInputEditPod}
                                        required
                                        resize="none"
                                        inputWidth={570}
                                      />
                                    </Pane>
                                    <Pane maxWidth={570}>
                                      <FileUploader
                                        acceptedMimeTypes={[MimeType.mp3]}
                                        className="create-episode-file-uploader"
                                        label="Podcast file (.mp3)"
                                        description="You can upload .mp3 file formats."
                                        maxFiles={1}
                                        onChange={handleChangeAudio}
                                        onRejected={handleRejectedAudio}
                                        renderFile={(file) => {
                                          if (file) {
                                            const { name, size, type } = file;
                                            const fileRejection =
                                              audioFileRejections.find(
                                                (fileRejection) =>
                                                  fileRejection.file === file
                                              );
                                            const { message } =
                                              fileRejection || {};

                                            document.getElementById(
                                              "audio_player_edit"
                                            ).src = URL.createObjectURL(
                                              audioFile[0]
                                            );
                                            return (
                                              <FileCard
                                                key={name}
                                                src={URL.createObjectURL(
                                                  audioFile[0]
                                                )}
                                                isInvalid={
                                                  fileRejection != null
                                                }
                                                name={name}
                                                value={name}
                                                onRemove={handleRemoveAudio}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                              />
                                            );
                                          } else {
                                            setAudioFile([]);
                                          }
                                        }}
                                        name="audiopath"
                                        values={audioFile}
                                      />
                                    </Pane>
                                    <audio
                                      onCanPlayThrough={checkDurationEdit()}
                                      controls
                                      id="audio_player_edit"
                                      style={{ display: "none" }}
                                    />
                                    <Pane maxWidth={570}>
                                      <FileUploader
                                        acceptedMimeTypes={[
                                          MimeType.jpeg,
                                          MimeType.png,
                                        ]}
                                        className="create-episode-image-uploader"
                                        label="Poster Image"
                                        description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                                        maxSizeInBytes={2 * 1024 ** 2}
                                        maxFiles={1}
                                        onChange={handleChangeEpisode}
                                        name="poster_image"
                                        values={episodeFile}
                                        onRejected={handleRejectedEpisode}
                                        renderFile={(file) => {
                                          if (file) {
                                            const { name, size, type } = file;
                                            const fileRejection =
                                              episodeFileRejections.find(
                                                (fileRejection) =>
                                                  fileRejection.file === file
                                              );
                                            const { message } =
                                              fileRejection || {};
                                            return (
                                              <FileCard
                                                key={name}
                                                src={URL.createObjectURL(
                                                  episodeFile[0]
                                                )}
                                                isInvalid={
                                                  fileRejection != null
                                                }
                                                name={name}
                                                value={name}
                                                onRemove={handleRemoveEpisode}
                                                sizeInBytes={size}
                                                type={type}
                                                validationMessage={message}
                                              />
                                            );
                                          } else {
                                            setEpisodeFile([]);
                                          }
                                        }}
                                      />
                                    </Pane>

                                    {editInputsPod.podPosterImage && (
                                      <Pane
                                        maxWidth={100}
                                        className="defaultImageWrapper"
                                      >
                                        <img
                                          alt=""
                                          src={`${process.env.REACT_APP_API_URL}/${editInputsPod.podPosterImage}`}
                                          onError={replaceSeries}
                                          className="poster-image"
                                        />
                                      </Pane>
                                    )}
                                    <Pane className="edit-episode-form-buttons-panel">
                                      <Pane padding={16}>
                                        <Heading size={600}>
                                          <Button
                                            className="cancel-button"
                                            data-customClass="custom-design"
                                            type="reset"
                                            onClick={resetEditPodcast}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            className="save-new-button"
                                            data-customClass="custom-design"
                                            type="submit"
                                            disabled={
                                              !editInputsPod.podTitle ||
                                              !editInputsPod.podDescription || 
                                              submittingUpdateEpisode ||
                                              (audioFile[0] &&
                                                !audioPlayerDurationEdit)
                                            }
                                          >
                                            Update
                                          </Button>
                                        </Heading>
                                      </Pane>
                                    </Pane>
                                  </SimpleBar>
                                </form>
                              </SideSheet>
                            </React.Fragment>
                          </Pane>
                          {/* end episode edit fragment */}
                          <Pane
                            key="tab2"
                            id="panel-2"
                            role="tabpanel"
                            aria-labelledby="tab2"
                            aria-hidden={"tab2" !== selectedIndex}
                            display={
                              "tab2" === selectedIndex ? "block" : "none"
                            }
                          >
                            <SimpleBar
                              style={{ maxHeight: "calc(100vh - 256px)" }}
                              scrollableNodeProps={{ ref: seriesInfoRef }}
                            >
                              <Pane className="series-info-container">
                                {renderSeriesInfo()}
                              </Pane>
                            </SimpleBar>
                          </Pane>
                        </Pane>
                      </Pane>
                    ) : (
                      <Pane className="episode-empty-poster-container">
                        <Pane className="episode-empty-poster-circle">
                          <img
                            alt=""
                            className="empty-poster"
                            src={episodeposter}
                            width="100%"
                            height="auto"
                          />
                        </Pane>

                        <Text
                          fontSize="12px"
                          color="#343434"
                          className="series-text"
                          title="Select item to view data."
                        >
                          Select item to view data.
                        </Text>
                      </Pane>
                    )}
                  </>
                )}
              </Pane>
            </Pane>
            <Pane className="right-panel">
              <Pane className="right-panel-category">
                <Pane className="right-panel-category-header">
                  <Pane className="category">
                    <Label
                      className="category-heading-name"
                      data-customClass="custom-design"
                      title="category"
                    >
                      Category
                    </Label>
                    <Pane>
                      <React.Fragment>
                        <SideSheet
                          preventBodyScrolling={true}
                          isShown={isShownCategory}
                          onCloseComplete={resetCategoryClose}
                          shouldCloseOnOverlayClick={false}
                        >
                          <Paragraph className="library-form-header">
                            <Text className="library-form-heading">
                              Create category
                            </Text>
                            <Text className="library-form-sub-heading">
                              Please fill the details and create category.
                            </Text>
                          </Paragraph>
                          <form
                            className="create-category-form"
                            data-customClass="custom-design"
                            onSubmit={handleSubmitCategory}
                            onReset={resetCategoryClose}
                          >
                            <Pane className="create-series-form-title">
                              <Pane>
                                <TextInputField
                                  inputWidth={569}
                                  label="Title"
                                  type="text"
                                  name="catTitle"
                                  value={inputs.catTitle}
                                  onChange={handleInput}
                                  required
                                />
                              </Pane>
                            </Pane>
                            <TextareaField
                              className="global-text-area-padding"
                              label="Description"
                              marginTop={6}
                              marginBottom={6}
                              name="catDescription"
                              value={inputs.catDescription}
                              onChange={handleInput}
                              required
                              resize="none"
                              inputWidth={570}
                            />
                            <Pane maxWidth={570}>
                              <FileUploader
                                acceptedMimeTypes={[
                                  MimeType.jpeg,
                                  MimeType.png,
                                ]}
                                className="create-category-image-uploader"
                                label="Category Image *"
                                description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                                maxSizeInBytes={2 * 1024 ** 2}
                                maxFiles={1}
                                onChange={handleChangeCategory}
                                onRejected={handleRejectedCategory}
                                renderFile={(file) => {
                                  const { name, size, type } = file;
                                  const fileRejection =
                                    categoryFileRejections.find(
                                      (fileRejection) =>
                                        fileRejection.file === file
                                    );
                                  const { message } = fileRejection || {};
                                  return (
                                    <FileCard
                                      key={name}
                                      src={URL.createObjectURL(categoryFile[0])}
                                      isInvalid={fileRejection != null}
                                      name={name}
                                      onRemove={handleRemoveCategory}
                                      sizeInBytes={size}
                                      type={type}
                                      validationMessage={message}
                                    />
                                  );
                                }}
                                name="category_image"
                                values={categoryFile}
                                required
                              />
                            </Pane>

                            <Pane className="category-form-buttons-panel">
                              <Pane padding={16}>
                                <Heading size={600}>
                                  <Button
                                    className="cancel-button"
                                    data-customClass="custom-design"
                                    type="reset"
                                    onClick={resetCategoryClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="save-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    onClick={() => {
                                      setBtnCategory(true);
                                    }}
                                    disabled={
                                      !inputs.catTitle ||
                                      !inputs.catDescription ||
                                      !categoryFile[0] || 
                                      submittingSaveCategory
                                    }
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    className="save-new-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.catTitle ||
                                      !inputs.catDescription ||
                                      !categoryFile[0] || 
                                      submittingSaveCategory
                                    }
                                  >
                                    Save &amp; Add New
                                  </Button>
                                </Heading>
                              </Pane>
                            </Pane>
                          </form>
                        </SideSheet>
                        <Button
                          className="create-category-button"
                          data-customClass="custom-design"
                          onClick={() => setIsShownCategory(true)}
                          title="create category"
                        >
                          Create
                        </Button>
                      </React.Fragment>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane className="right-panel-category-content">
                  <SimpleBar
                    style={{
                      maxHeight: "calc(100vh - 345px)",
                      height: "100%",
                    }}
                  >
                    <Pane className="category-list-item-container">
                      {categoriesLoader ? (
                        <Pane
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={400}
                        >
                          <Spinner />
                        </Pane>
                      ) : (
                        renderCategories()
                      )}
                    </Pane>
                  </SimpleBar>

                  {/*  edit fragment */}

                  <Pane>
                    <React.Fragment>
                      <SideSheet
                        isShown={isShownEditCategory}
                        onCloseComplete={resetEditCategory}
                        shouldCloseOnOverlayClick={false}
                      >
                        <Paragraph className="edit-category-form">
                          <Text className="edit-category-form-heading">
                            edit category
                          </Text>
                          <Text className="edit-category-sub-heading">
                            Please fill the details and edit category.
                          </Text>
                        </Paragraph>
                        <SimpleBar
                          style={{
                            maxHeight: "calc(100vh - 139px)",
                          }}
                        >
                          <form
                            className="create-category-form"
                            data-customClass="custom-design"
                            onSubmit={handleSubmitEditCategory}
                            onReset={resetEditCategory}
                          >
                            <Pane className="create-series-form-title">
                              <Pane>
                                <TextInput
                                  type="hidden"
                                  name="Id"
                                  placeholder=""
                                  value={editInputs.catId}
                                />
                              </Pane>
                            </Pane>
                            <Pane className="create-series-form-title">
                              <Pane>
                                <TextInputField
                                  inputWidth={569}
                                  label="Title"
                                  type="text"
                                  name="catTitle"
                                  value={editInputs.catTitle}
                                  onChange={handleInputEdit}
                                  required
                                />
                              </Pane>
                            </Pane>
                            <TextareaField
                              className="global-text-area-padding"
                              label="Description"
                              marginTop={6}
                              marginBottom={6}
                              name="catDescription"
                              value={editInputs.catDescription}
                              onChange={handleInputEdit}
                              required
                              resize="none"
                              inputWidth={570}
                            />
                            <Pane maxWidth={570}>
                              <FileUploader
                                acceptedMimeTypes={[
                                  MimeType.jpeg,
                                  MimeType.png,
                                ]}
                                className="create-category-image-uploader"
                                label="Category image"
                                description="Maximum upload file size: 2MB. (jpg, png format). Image dimension must be 350 X 350 pixels."
                                maxSizeInBytes={2 * 1024 ** 2}
                                maxFiles={1}
                                onChange={handleChangeCategory}
                                onRejected={handleRejectedCategory}
                                renderFile={(file) => {
                                  if (file) {
                                    const { name, size, type } = file;
                                    const fileRejection =
                                      categoryFileRejections.find(
                                        (fileRejection) =>
                                          fileRejection.file === file
                                      );
                                    const { message } = fileRejection || {};
                                    return (
                                      <FileCard
                                        key={name}
                                        src={URL.createObjectURL(
                                          categoryFile[0]
                                        )}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemoveCategory}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                      />
                                    );
                                  } else {
                                    setCategoryFile([]);
                                  }
                                }}
                                name="category_image"
                                values={categoryFile}
                                required
                              />
                            </Pane>
                            {editInputs.catPosterImage && (
                              <Pane
                                maxWidth={100}
                                className="defaultImageWrapper"
                              >
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_API_URL}/${editInputs.catPosterImage}`}
                                  onError={replaceCategory}
                                  className="category-image"
                                />
                              </Pane>
                            )}
                            <Pane className="category-edit-buton-panel">
                              <Pane padding={16}>
                                <Heading size={600}>
                                  <Button
                                    className="cancel-button"
                                    data-customClass="custom-design"
                                    type="reset"
                                    onClick={resetEditCategory}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="save-new-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !editInputs.catTitle ||
                                      !editInputs.catDescription || 
                                      submittingUpdateCategory
                                    }
                                  >
                                    Update
                                  </Button>
                                </Heading>
                              </Pane>
                            </Pane>
                          </form>
                        </SimpleBar>
                      </SideSheet>
                    </React.Fragment>
                  </Pane>
                  {/* edit fragment end here */}
                </Pane>
              </Pane>
              <Pane className="right-panel-audio">
                <Pane
                  className="audio-player"
                  style={{ backgroundImage: `url(${audioplayerbgimg})` }}
                >
                  {player && activePodcast.length >= 0 ? (
                    <Pane>
                      <Pane className="first-box">
                        <h3 className="audio-player-heading">{seriesTitle}</h3>
                        <p className="audio-player-para">{seriesCategory}</p>
                        <Pane className="audio-player-img-box">
                          <img
                            alt=""
                            className="audio-player-img"
                            src={`${process.env.REACT_APP_API_URL}/${activePodcast[trackIndex]?.poster_image}`}
                            onError={replaceEpisode}
                            width="100%"
                            height="auto"
                          />
                          <h3 className="audio-player-img-para">
                            {activePodcast[trackIndex]?.title}
                          </h3>
                        </Pane>
                      </Pane>
                      <Pane className="audio-second-box">
                        <Pane className="App">
                          <AudioPlayer
                            ref={playerRef}
                            autoPlay={true}
                            style={{ borderRadius: "1rem", left: "5%" }}
                            src={`${process.env.REACT_APP_API_URL}/${activePodcast[trackIndex]?.audiopath}`}
                            showSkipControls={true}
                            showJumpControls={false}
                            onClickPrevious={handleClickPrevious}
                            onClickNext={handleClickNext}
                            onEnded={handleClickNext}
                            onPlayError={handlePlayerError}
                            onPause={(e) => setPlayerSrc(false)}
                            onPlay={(e) => setPlayerSrc(true)}
                          />
                        </Pane>
                      </Pane>
                    </Pane>
                  ) : (
                    <Pane>
                      <Pane className="audio-player-poster-panel">
                        <img
                          alt=""
                          className="audio-empty-poster"
                          src={audioposter}
                          width="100%"
                          height="auto"
                        />
                      </Pane>
                      <Text
                        className="podcast-player-title"
                        data-customClass="custom-design"
                      >
                        podcast audio player
                      </Text>
                    </Pane>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      {/* delete library */}
      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownDeleteDialogSeries}
          onCloseComplete={() => setIsShownDeleteDialogSeries(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title="Do you really want to delete this series?"
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialogSeries(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              Cancel
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteSeries(deleteId)}
            >
              Delete
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      {/* delete episode */}
      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownDeleteDialogEpisode}
          onCloseComplete={() => setIsShownDeleteDialogEpisode(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title="Do you really want to delete this episode?"
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialogEpisode(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              Cancel
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteEpisode(deleteId)}
            >
              Delete
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      {/* delete category */}

      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownDeleteDialogCategory}
          onCloseComplete={() => setIsShownDeleteDialogCategory(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title="Do you really want to delete this category?"
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialogCategory(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              Cancel
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteCategory(deleteId)}
            >
              Delete
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default Library;
