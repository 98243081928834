import React, { useState } from "react";
import Router from "./Router";
import axios from "axios";
import { AuthContextProvider } from "./context/AuthContext";
import "./App.css";
import "./global-font.css";
import "./font-family-file.css";
import "./global-color.css";

axios.defaults.withCredentials = true;

function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 10);
  }

  return (
    !loading && (
      <AuthContextProvider>
        <Router />
      </AuthContextProvider>
    )
  );
}

export default App;
