import axios from "axios";

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

Axios.interceptors.request.use(function (config) {
  const token =
    localStorage.getItem("Token") === null ? "" : localStorage.getItem("Token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default Axios;
