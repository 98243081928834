import React, { useContext, memo } from "react";
import { NavLink } from "react-router-dom";
import { Pane, Menu } from "evergreen-ui";
import pic from "../../images/logo-scroll.png";

import "./sidebar.css";

import AuthContext from "../../context/AuthContext";

function Sidebar() {
  const { loggedIn, userRole, localization, companyLogo } =
    useContext(AuthContext);

  return (
    <>
      <Pane id="sidebar-left" className="column">
        <Pane className="sidebar-container">
          <Menu>
            <Pane className="Logo">
              <img
                className="logo-img"
                src={
                  companyLogo
                    ? `${process.env.REACT_APP_API_URL}/${companyLogo}`
                    : pic
                }
                alt="logo"
                width="70%"
                height="auto"
              />
            </Pane>
            <Pane className="menu-item-list">
              <NavLink to="/dashboard">
                <Menu.Item
                  className="left-sidebar-buttons"
                  data-customClass="custom-design"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.195"
                    height="17.5"
                    viewBox="0 0 20.195 17.5"
                  >
                    <path
                      id="speedometer"
                      d="M18.387,6.343A10.105,10.105,0,0,0,3.676,20.185l.008.008c.014.015.027.03.042.044.031.035.066.075.108.117a1.734,1.734,0,0,0,2.541-.018,6.616,6.616,0,0,1,9.737,0,1.734,1.734,0,0,0,2.553,0l.135-.147.008-.008a10.1,10.1,0,0,0-.421-13.842Zm-7.819.411a.674.674,0,0,1,1.347,0V8.1a.674.674,0,0,1-1.347,0Zm-4.716,7.41H4.5a.674.674,0,1,1,0-1.347H5.852a.674.674,0,0,1,0,1.347Zm2.055-4.009a.674.674,0,0,1-.952,0L6,9.2a.674.674,0,0,1,.953-.953l.952.953A.674.674,0,0,1,7.907,10.155Zm6.286.972-2,3.179a1.305,1.305,0,0,1-.295.295,1.268,1.268,0,1,1-1.474-2.063l3.179-2a.431.431,0,0,1,.493,0,.423.423,0,0,1,.1.589Zm1.335-.972a.674.674,0,0,1-.952-.952l.952-.953a.674.674,0,1,1,.953.953ZM18.3,19.738Zm-.324-5.574H16.631a.674.674,0,1,1,0-1.347h1.347a.674.674,0,0,1,0,1.347Z"
                      transform="translate(-1.153 -3.4)"
                      fill="#949494"
                    />
                  </svg>
                  {localization.dashboard
                    ? localization.dashboard
                    : "Dashboard"}
                </Menu.Item>
              </NavLink>
              <NavLink to="/library">
                <Menu.Item
                  className="left-sidebar-buttons"
                  data-customClass="custom-design"
                >
                  <Pane className="sidebar-library-design">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.195"
                      height="17.5"
                      viewBox="0 0 12.714 21.803"
                    >
                      <path
                        id="speedometer"
                        d="M18.993,2.25H11.727A2.638,2.638,0,0,0,9,4.8V6.143h7.549v1.8H9v2.092h7.549v1.8H9V13.93h7.549v1.8H9v2.034a2.631,2.631,0,0,0,2.727,2.54h1.5v3.747h4.271V20.306h1.5a2.629,2.629,0,0,0,2.722-2.54V15.731h-2.93v-1.8h2.93V11.838h-2.93v-1.8h2.93V7.944h-2.93v-1.8h2.93V4.8A2.637,2.637,0,0,0,18.993,2.25Z"
                        transform="translate(-9 -2.25)"
                        fill="#949494"
                      />
                    </svg>
                    {localization.library ? localization.library : "Library"}
                  </Pane>
                </Menu.Item>
              </NavLink>

              {loggedIn === true && userRole === "superadmin" && (
                <>
                  <NavLink to="/tenant">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="android-contacts"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 18.871 17.232"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 37"
                          d="M10.978,24.183a5.152,5.152,0,0,1-5.076,0A5.993,5.993,0,0,0,2.25,28.567H14.631a5.992,5.992,0,0,0-3.652-4.384Z"
                          transform="translate(-2.25 -11.334)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 38"
                          d="M9.6,8.44a4.459,4.459,0,1,0,4.46,4.459A4.459,4.459,0,0,0,9.6,8.44Zm0,7.855a3.447,3.447,0,0,1-3.117-2.128h6.234A3.445,3.445,0,0,1,9.6,16.3Z"
                          transform="translate(-3.408 -5.023)"
                          fill="#949494"
                        />
                        <g
                          id="speedometer"
                          data-name="Group 84"
                          transform="translate(9.197 0)"
                        >
                          <path
                            id="speedometer"
                            data-name="Path 39"
                            d="M21,24.292h6.63a5.512,5.512,0,0,0-3.652-4.047,5.154,5.154,0,0,1-4.315.353,5.9,5.9,0,0,1-1.178,1.47A5.72,5.72,0,0,1,21,24.292Z"
                            transform="translate(-17.955 -9.756)"
                            fill="#949494"
                          />
                          <path
                            id="speedometer"
                            data-name="Path 40"
                            d="M19.969,9.522H24.2a3.437,3.437,0,0,1-3.117,2.118,3.2,3.2,0,0,1-1.069-.19A4.462,4.462,0,1,0,17.6,4.8C18.666,6.631,19.65,7.963,19.969,9.522Z"
                            transform="translate(-17.601 -2.736)"
                            fill="#949494"
                          />
                        </g>
                      </svg>
                      {localization.tenant ? localization.tenant : "Tenant"}
                    </Menu.Item>
                  </NavLink>

                  <NavLink to="/user">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="android-contact"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 15.495 15.495"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 41"
                          d="M15.277,23.78a6.15,6.15,0,0,1-6.059,0A6.592,6.592,0,0,0,4.5,28.21H20A6.591,6.591,0,0,0,15.277,23.78Z"
                          transform="translate(-4.5 -12.715)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 42"
                          d="M14.047,4.5a5.326,5.326,0,1,0,5.324,5.327A5.326,5.326,0,0,0,14.047,4.5Zm0,9.321a3.986,3.986,0,0,1-3.721-2.542h7.442a3.986,3.986,0,0,1-3.721,2.542Z"
                          transform="translate(-6.299 -4.5)"
                          fill="#949494"
                        />
                      </svg>
                      {localization.user ? localization.user : "User"}
                    </Menu.Item>
                  </NavLink>

                  <NavLink to="/language">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="language"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 18.565 17.24"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 43"
                          d="M27.214,23.8l-3.73-9.034a.912.912,0,0,0-1.686,0L18.069,23.8a.912.912,0,0,0,1.686.7l.759-1.84h4.255l.76,1.839a.911.911,0,1,0,1.685-.7Zm-5.947-2.968L22.641,17.5l1.374,3.329Z"
                          transform="translate(-8.717 -7.821)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 44"
                          d="M12.023,15.6a.912.912,0,0,0-.2-1.272,11.9,11.9,0,0,1-1.512-1.439,21.377,21.377,0,0,0,2.953-5.946H14.6a.912.912,0,0,0,0-1.824H9.792V4.287a.912.912,0,1,0-1.824,0v.829H3.162a.912.912,0,1,0,0,1.824h8.174a19.772,19.772,0,0,1-2.229,4.49A15.627,15.627,0,0,1,7.318,8.576a.912.912,0,0,0-1.681.7,17.4,17.4,0,0,0,2.191,3.478l.114.146a16.946,16.946,0,0,1-3.889,3.346.912.912,0,1,0,.873,1.6A17.87,17.87,0,0,0,9.136,14.3a12.484,12.484,0,0,0,1.613,1.5.912.912,0,0,0,1.274-.2Z"
                          transform="translate(-2.25 -3.375)"
                          fill="#949494"
                        />
                      </svg>
                      {localization.language
                        ? localization.language
                        : "Language"}
                    </Menu.Item>
                  </NavLink>

                  <NavLink to="/announcement">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="speakerphone"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 17.031 17.027"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 49"
                          d="M3.729,9.807a3.78,3.78,0,0,1,2.3-3.479H5.74A3.211,3.211,0,0,0,2.658,9.187a.586.586,0,0,0-.407.612.557.557,0,0,0,.441.6,3.129,3.129,0,0,0,2.65,2.615h0a.8.8,0,0,1,.159.034c.042.008.084.015.125.026a3.763,3.763,0,0,1-1.9-3.273Z"
                          transform="translate(-2.251 -4.123)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 50"
                          d="M20.574,4.493c-.042-.11-.088-.213-.133-.316a4.182,4.182,0,0,0-.889-1.315A2.081,2.081,0,0,0,18.13,2.25a1.9,1.9,0,0,0-.916.243h0c-.045.026-.091.049-.137.076a13.528,13.528,0,0,1-6.181,1.878c-.247.015-.494.008-.741.008H8.543a3.836,3.836,0,0,0-.425,6.752c.494.414,1.585.445,1.521.908a8.675,8.675,0,0,1-.3,1.167,21.861,21.861,0,0,0-.962,5.5c0,.076,0,.141.008.251s.084.243.323.243H11.63c.251,0,.369-.125.354-.354-.008-.072-.023-.163-.03-.247a20.785,20.785,0,0,1-.125-3.1,13.419,13.419,0,0,1,.266-2.33,4.284,4.284,0,0,1,.46-1.152,16.985,16.985,0,0,1,4.375,1.1s.274.129.384.2a1.859,1.859,0,0,0,.821.194.932.932,0,0,0,.1,0,2.333,2.333,0,0,0,1.319-.741,4.071,4.071,0,0,0,.889-1.315c.045-.107.091-.213.133-.327a9.542,9.542,0,0,0,.563-3.349,9.479,9.479,0,0,0-.567-3.353ZM19.7,11.2c-.3.7-.692,1.266-1.121,1.266s-.821-.559-1.118-1.266a9.021,9.021,0,0,1-.567-3.349,8.973,8.973,0,0,1,.57-3.361c.3-.7.688-1.121,1.114-1.121s.817.425,1.118,1.121a8.973,8.973,0,0,1,.57,3.361A9.021,9.021,0,0,1,19.7,11.2Z"
                          transform="translate(-4.111 -2.25)"
                          fill="#949494"
                        />
                      </svg>
                      {localization.announcement
                        ? localization.announcement
                        : "Announcement"}
                    </Menu.Item>
                  </NavLink>
                </>
              )}
              {loggedIn === true && userRole === "tenantadmin" && (
                <>
                  <NavLink to="/user">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="android-contact"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 15.495 15.495"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 41"
                          d="M15.277,23.78a6.15,6.15,0,0,1-6.059,0A6.592,6.592,0,0,0,4.5,28.21H20A6.591,6.591,0,0,0,15.277,23.78Z"
                          transform="translate(-4.5 -12.715)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 42"
                          d="M14.047,4.5a5.326,5.326,0,1,0,5.324,5.327A5.326,5.326,0,0,0,14.047,4.5Zm0,9.321a3.986,3.986,0,0,1-3.721-2.542h7.442a3.986,3.986,0,0,1-3.721,2.542Z"
                          transform="translate(-6.299 -4.5)"
                          fill="#949494"
                        />
                      </svg>
                      {localization.user ? localization.user : "User"}
                    </Menu.Item>
                  </NavLink>

                  <NavLink to="/announcement">
                    <Menu.Item
                      className="left-sidebar-buttons"
                      data-customClass="custom-design"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="speakerphone"
                        width="15.195"
                        height="17.5"
                        viewBox="0 0 17.031 17.027"
                      >
                        <path
                          id="speedometer"
                          data-name="Path 49"
                          d="M3.729,9.807a3.78,3.78,0,0,1,2.3-3.479H5.74A3.211,3.211,0,0,0,2.658,9.187a.586.586,0,0,0-.407.612.557.557,0,0,0,.441.6,3.129,3.129,0,0,0,2.65,2.615h0a.8.8,0,0,1,.159.034c.042.008.084.015.125.026a3.763,3.763,0,0,1-1.9-3.273Z"
                          transform="translate(-2.251 -4.123)"
                          fill="#949494"
                        />
                        <path
                          id="speedometer"
                          data-name="Path 50"
                          d="M20.574,4.493c-.042-.11-.088-.213-.133-.316a4.182,4.182,0,0,0-.889-1.315A2.081,2.081,0,0,0,18.13,2.25a1.9,1.9,0,0,0-.916.243h0c-.045.026-.091.049-.137.076a13.528,13.528,0,0,1-6.181,1.878c-.247.015-.494.008-.741.008H8.543a3.836,3.836,0,0,0-.425,6.752c.494.414,1.585.445,1.521.908a8.675,8.675,0,0,1-.3,1.167,21.861,21.861,0,0,0-.962,5.5c0,.076,0,.141.008.251s.084.243.323.243H11.63c.251,0,.369-.125.354-.354-.008-.072-.023-.163-.03-.247a20.785,20.785,0,0,1-.125-3.1,13.419,13.419,0,0,1,.266-2.33,4.284,4.284,0,0,1,.46-1.152,16.985,16.985,0,0,1,4.375,1.1s.274.129.384.2a1.859,1.859,0,0,0,.821.194.932.932,0,0,0,.1,0,2.333,2.333,0,0,0,1.319-.741,4.071,4.071,0,0,0,.889-1.315c.045-.107.091-.213.133-.327a9.542,9.542,0,0,0,.563-3.349,9.479,9.479,0,0,0-.567-3.353ZM19.7,11.2c-.3.7-.692,1.266-1.121,1.266s-.821-.559-1.118-1.266a9.021,9.021,0,0,1-.567-3.349,8.973,8.973,0,0,1,.57-3.361c.3-.7.688-1.121,1.114-1.121s.817.425,1.118,1.121a8.973,8.973,0,0,1,.57,3.361A9.021,9.021,0,0,1,19.7,11.2Z"
                          transform="translate(-4.111 -2.25)"
                          fill="#949494"
                        />
                      </svg>
                      {localization.announcement
                        ? localization.announcement
                        : "Announcement"}
                    </Menu.Item>
                  </NavLink>
                </>
              )}
            </Pane>
          </Menu>
        </Pane>
      </Pane>
    </>
  );
}

export default memo(Sidebar);
