import axios from "../helper/axiosInstance";
import jwt from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
const AuthContext = createContext();

function AuthContextProvider(props) {
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState();
  const [userRole, setUserRole] = useState();
  const [localization, setLocalization] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setEmail] = useState("");
  const [avatarImg, setAvatarImg] = useState("");
  const [brandingPrimary, setBrandingPrimary] = useState("");
  const [brandingSecondary, setBrandingSecondary] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");

  async function getLocalization(languageId) {
    const response = await axios.get(`tenants/language/${languageId}`);
    setLocalization(response?.data?.data);
  }

  async function getTenantInfo(tenantId) {
    const response = await axios.get(`tenants/tenant/${tenantId}`);
    const tenantResponse = response.data.data;
    const languageId = tenantResponse.language_id._id;
    getLocalization(languageId);
    const primary = tenantResponse?.brandingPrimary;
    const secondary = tenantResponse?.brandingSecondary;
    const tenantLogo = tenantResponse?.company_logo;

    setBrandingPrimary(primary);
    setBrandingSecondary(secondary);
    setCompanyLogo(tenantLogo);
  }

  async function getLoggedIn() {
    setLoading(true);
    const token =
      localStorage.getItem("Token") === null
        ? ""
        : localStorage.getItem("Token");

    if (token) {
      const user = jwt(token);
      setLoggedIn(true);
      setUserRole(user?.role["name"]);
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setUserId(user?._id);
      setEmail(user?.email);
      setAvatarImg(user?.profile_image);

      if (user?.role["name"] == "tenantadmin") {
        getTenantInfo(user?.tenant_id);
      }
    } else {
      setLoggedIn(undefined);
      setUserRole(undefined);
      setLocalization([]);
    }
    setLoading(false);
  }

  useEffect(() => {
    getLoggedIn();
  }, []);  

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        userRole,
        firstName,
        lastName,
        avatarImg,
        userEmail,
        userId,
        loading,
        getLoggedIn,
        localization,
        brandingPrimary,
        brandingSecondary,
        companyLogo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
