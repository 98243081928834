import axios from "../../../helper/axiosInstance";
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import {
  Pane,
  Button,
  MoreIcon,
  TrashIcon,
  CalendarIcon,
  Label,
  FileUploader,
  FileCard,
  SideSheet,
  Paragraph,
  Heading,
  TextInput,
  TextInputField,
  Text,
  Popover,
  Position,
  Menu,
  EditIcon,
  TextareaField,
  toaster,
  Dialog,
  Spinner,
  InfoSignIcon,
  UpdatedIcon,
  SelectMenu,
  Icon,
  SortIcon,
  WarningSignIcon,
  MimeType,
} from "evergreen-ui";

import "./announcement.css";

import userPoster from "../../../images/empty-podcast-poster.svg";

import AuthContext from "../../../context/AuthContext";

function Announcement() {
  const { userRole, localization } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [annoLoader, setAnnoLoader] = useState(false);
  const [annoInfoLoader, setAnnoInfoLoader] = useState(false);
  const [activeRenderList, setActiveRenderList] = useState(true);

  const [submittingSave, setSubmittingSave] = useState(false);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);

  const annoRef = useRef(null);
  const annoInfoRef = useRef(null);

  const scrollToTopAnno = () => {
    annoRef.current.scrollTop = 0;
  };

  const scrollToTopAnnoInfo = () => {
    annoInfoRef.current.scrollTop = 0;
  };

  const replaceImage = (error) => {
    error.target.src = "/default/news.png";
  };

  const [announcements, setAnnouncements] = useState([]);
  const [btnAnnoncement, setBtnAnnoncement] = useState(false);

  const [announcementType, setAnnouncementType] = useState("");

  const [isShownAnnouncement, setIsShownAnnouncement] = useState(false);
  const [isShownEditAnnouncement, setIsShownEditAnnouncement] = useState(false);

  const [newsFile, setNewsFile] = useState([]);
  const [newsFileRejections, setNewsFileRejections] = useState([]);

  const handleChangeNews = useCallback((files) => setNewsFile([files[0]]), []);
  const handleRejectedNews = useCallback(
    (newsFileRejections) => setNewsFileRejections([newsFileRejections[0]]),
    []
  );
  const handleRemoveNews = useCallback(() => {
    setNewsFile([]);
    setNewsFileRejections([]);
  }, []);

  const currentUserRole = userRole === "superadmin" ? "admin" : "tenants";
  const AnnouncementTypes = {
    announcement: "announcement",
    news: "news",
    podcast: "podcast",
  };

  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    news_image: "",
  });

  const [editInputs, setEditInputs] = useState({
    id: "",
    title: "",
    description: "",
    news_image: "",
  });

  const handleInput = useCallback((e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleInputEdit = (e) => {
    setEditInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetAnnouncement = (e) => {
    setInputs({
      title: "",
      description: "",
      news_image: "",
    });

    setNewsFile([]);
    setNewsFileRejections([]);
  };

  const resetAnnouncementClose = (e) => {
    setInputs({
      title: "",
      description: "",
      news_image: "",
    });

    setNewsFile([]);
    setNewsFileRejections([]);

    setIsShownAnnouncement(false);
  };

  const resetEditAnnouncementClose = (e) => {
    setEditInputs({
      id: "",
      title: "",
      description: "",
      news_image: "",
    });

    setNewsFile([]);
    setNewsFileRejections([]);

    setIsShownEditAnnouncement(false);
  };

  const handleClickOpenEditAnnouncement = (id) => () => {
    setLoading(true);
    axios
      .get(`/${currentUserRole}/announcement/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          id: response?._id,
          title: response?.title,
          description: response?.description,
          announcementType: response?.announcementType,
          news_image: response?.news_image,
        });
        setIsShownEditAnnouncement(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  function handleSubmitAnnouncement(e) {
    e.preventDefault();
    if (submittingSave) {
      return;
    }
    setSubmittingSave(true);
    setLoading(true);

    const url = `/${currentUserRole}/announcement`;
    const formData = new FormData();

    if (newsFile[0] !== undefined) {
      formData.append("news_image", newsFile[0]);
    }

    formData.append("title", inputs.title);
    formData.append("description", inputs.description);
    formData.append("announcementType", announcementType);
    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingSave(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getAnnouncements();
        if (btnAnnoncement) {
          resetAnnouncementClose();
          setBtnAnnoncement(false);
        } else {
          resetAnnouncement();
        }
      })
      .catch((error) => {
        setSubmittingSave(false);
        setLoading(false);
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitEditAnnouncement(e) {
    e.preventDefault();
    if (submittingUpdate) {
      return;
    }
    setSubmittingUpdate(true);    
    setLoading(true);

    const id = editInputs.id;

    const url = `/${currentUserRole}/announcement/${id}`;
    const formData = new FormData();

    if (newsFile[0] !== undefined) {
      formData.append("news_image", newsFile[0]);
    }

    formData.append("title", editInputs.title);
    formData.append("description", editInputs.description);

    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdate(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getAnnouncements();
        onClickDisplayAnnouncementById(id);
        resetEditAnnouncementClose();
      })
      .catch((error) => {
        setSubmittingUpdate(false);
        setLoading(false);
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  const deleteAnnouncement = (id) => () => {
    setLoading(true);
    axios
      .delete(`/${currentUserRole}/announcement/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getAnnouncements();
        setActiveAnnouncement("");
        setIsShownDeleteDialog(false);
      })
      .catch((error) => {
        setLoading(false);
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialog(true);
  };

  function returnDate(data) {
    try {
      const date = new Date(data);
      const isoDate = date.toISOString().substring(0, 10);
      return isoDate;
    } catch (error) {
      console.log("date error");
    }
  }

  const [activeAnnouncement, setActiveAnnouncement] = useState("");
  const [focusAnnouncement, setFocusAnnouncement] = useState(true);

  const activeAnnouncementTab = (id) => {
    if (activeAnnouncement !== "" && focusAnnouncement) {
      if (id === activeAnnouncement?._id) {
        return "announcement-list-panel active";
      } else {
        return "announcement-list-panel";
      }
    } else {
      return "announcement-list-panel";
    }
  };

  async function onClickDisplayAnnouncementById(id) {
    setFocusAnnouncement(false);
    setAnnoInfoLoader(true);

    await axios
      .get(`/${currentUserRole}/announcement/${id}`)
      .then((res) => {
        const response = res?.data?.data;
        setActiveAnnouncement(response);
        setAnnoInfoLoader(false);
        setFocusAnnouncement(true);
        scrollToTopAnnoInfo();
      })
      .catch((error) => {
        setAnnoInfoLoader(false);
        setFocusAnnouncement(true);
        console.log(error);
      });
  }

  const renderAnnouncementInfo = () => {
    if (activeAnnouncement !== "") {
      return (
        <>
          <Pane className="announcement-header-panel">
            <Pane className="announcement-header-list-panel">
              <Pane className="announcement-header-heading">
                <Text
                  className="announcement-header-name"
                  data-customClass="custom-design"
                >
                  {activeAnnouncement.title}
                </Text>
              </Pane>
              <Pane className="annoucement-edit-main-container">
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  padding="0px"
                  position={Position.BOTTOM_LEFT}
                  content={
                    <Menu>
                      <Menu.Group>
                        {activeAnnouncement.announcementType ===
                          AnnouncementTypes.announcement && (
                          <Menu.Item
                            padding="0"
                            className="announcement-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onClick={handleClickOpenEditAnnouncement(
                              activeAnnouncement?._id
                            )}
                          >
                            <Text
                              className="announcement-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.edit ? localization.edit : "Edit"}
                            </Text>
                          </Menu.Item>
                        )}
                        {activeAnnouncement.announcementType ===
                          AnnouncementTypes.news && (
                          <Menu.Item
                            padding="0"
                            className="announcement-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onClick={handleClickOpenEditAnnouncement(
                              activeAnnouncement?._id
                            )}
                          >
                            <Text
                              className="announcement-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.edit ? localization.edit : "Edit"}
                            </Text>
                          </Menu.Item>
                        )}
                        {activeAnnouncement.announcementType ===
                          AnnouncementTypes.podcast && (
                          <Menu.Item
                            padding="0"
                            className="announcement-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onClick={handleClickOpenEditAnnouncement(
                              activeAnnouncement?._id
                            )}
                          >
                            <Text
                              className="announcement-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.edit ? localization.edit : "Edit"}
                            </Text>
                          </Menu.Item>
                        )}
                        <Menu.Item
                          padding="0"
                          className="announcement-edit-container"
                          data-customClass="custom-design"
                          icon={<TrashIcon size={12} color="white" />}
                          onClick={handleClickOpenDeleteDialog(
                            activeAnnouncement?._id,
                            activeAnnouncement?.title
                          )}
                        >
                          <Text
                            className="announcement-edit-delete-text"
                            data-customClass="custom-design"
                          >
                            {localization.delete
                              ? localization.delete
                              : "Delete"}
                          </Text>
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <MoreIcon
                    className="middle-panel-header-moreicon"
                    size={12}
                  />
                </Popover>
              </Pane>
            </Pane>
          </Pane>
          <SimpleBar
            style={{ maxHeight: "calc(100vh - 170px)" }}
            scrollableNodeProps={{ ref: annoInfoRef }}
          >
            <Pane className="announcement-content-panel">
              <Pane className="announcement-content-desc-inner">
                {activeAnnouncement?.news_image && (
                  <img
                    className="announcement-circle"
                    src={`${process.env.REACT_APP_API_URL}/${activeAnnouncement?.news_image}`}
                    alt="news"
                  />
                )}
                <Text className="announcement-content-description">
                  <p
                    className="announcement-desc"
                    data-customClass="custom-design"
                  >
                    {activeAnnouncement.description}
                  </p>
                </Text>
              </Pane>
            </Pane>
          </SimpleBar>
        </>
      );
    } else {
      return (
        <>
          <Pane className="series-empty-poster-container">
            <Pane className="series-empty-poster-circle">
              <img
                className="series-poster"
                src={userPoster}
                alt="user"
                width="100%"
                height="auto"
              />
            </Pane>
            <Text fontSize="12px" color="#343434">
              {localization.selectItemToView
                ? localization.selectItemToView
                : "Select item to view data."}
            </Text>
          </Pane>
        </>
      );
    }
  };

  const [byOrder, setByOrder] = useState();
  const [byOrderLabel, setByOrderLabel] = useState();

  const [searchBy, setSearchBy] = useState("");

  function renderAnnouncements() {
    if (activeRenderList) {
      return "";
    }
    const filteredOptions = announcements?.filter((announcement) =>
      announcement?.title?.toLowerCase().trim().includes(searchBy.toLowerCase())
    );

    if (filteredOptions?.length > 0) {
      return filteredOptions
        .sort((a, b) => {
          if (byOrder === "A to Z")
            return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
          if (byOrder === "Z to A")
            return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
          if (byOrder === "Newest") return a.createdAt < b.createdAt ? 1 : -1;
          if (byOrder === "Oldest") return a.createdAt > b.createdAt ? 1 : -1;
        })
        .map((announcement, i) => {
          return (
            <Pane
              tabIndex={i}
              className={activeAnnouncementTab(announcement?._id)}
              key={announcement._id}
              onClick={() => onClickDisplayAnnouncementById(announcement._id)}
              style={{ cursor: "pointer" }}
            >
              <Pane
                className="announcement-inner-container"
                data-customClass="custom-design"
              >
                <Pane className="announcement-inner-wrapper">
                  <Text
                    className="announcement-name"
                    data-customClass="custom-design"
                  >
                    {announcement.title}
                  </Text>
                  <Pane className="date-time">
                    <p>
                      <CalendarIcon
                        size={10}
                        marginRight={5}
                        marginTop={5}
                        marginBottom={-1}
                      />
                      {returnDate(announcement.createdAt)}
                    </p>
                    <p>
                      {announcement.announcementType ===
                        AnnouncementTypes.announcement && (
                        <InfoSignIcon
                          size={10}
                          marginRight={5}
                          marginTop={5}
                          marginBottom={-1}
                        />
                      )}
                      {announcement.announcementType ===
                        AnnouncementTypes.podcast && (
                        <UpdatedIcon
                          size={10}
                          marginRight={5}
                          marginTop={5}
                          marginBottom={-1}
                        />
                      )}
                      {announcement.announcementType ===
                        AnnouncementTypes.news && (
                        <EditIcon
                          size={10}
                          marginRight={5}
                          marginTop={5}
                          marginBottom={-1}
                        />
                      )}
                      {localization[
                        AnnouncementTypes[announcement.announcementType]
                      ]
                        ? localization[
                            AnnouncementTypes[announcement.announcementType]
                          ]
                        : AnnouncementTypes[announcement.announcementType]}
                    </p>
                  </Pane>
                </Pane>
              </Pane>

              <Pane
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Popover
                  minWidth="40px"
                  minHeight="40px"
                  padding="0px"
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <Menu>
                      <Menu.Group>
                        {announcement.announcementType ===
                          AnnouncementTypes.announcement && (
                          <Pane
                            onClick={handleClickOpenEditAnnouncement(
                              announcement._id
                            )}
                          >
                            <Menu.Item
                              padding="0"
                              className="announcement-edit-container"
                              data-customClass="custom-design"
                              icon={<EditIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="announcement-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                {localization.edit ? localization.edit : "Edit"}
                              </Text>
                            </Menu.Item>
                          </Pane>
                        )}
                        {announcement.announcementType ===
                          AnnouncementTypes.news && (
                          <Pane
                            onClick={handleClickOpenEditAnnouncement(
                              announcement._id
                            )}
                          >
                            <Menu.Item
                              padding="0"
                              className="announcement-edit-container"
                              data-customClass="custom-design"
                              icon={<EditIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="announcement-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                {localization.edit ? localization.edit : "Edit"}
                              </Text>
                            </Menu.Item>
                          </Pane>
                        )}
                        {announcement.announcementType ===
                          AnnouncementTypes.podcast && (
                          <Pane
                            onClick={handleClickOpenEditAnnouncement(
                              announcement._id
                            )}
                          >
                            <Menu.Item
                              padding="0"
                              className="announcement-edit-container"
                              data-customClass="custom-design"
                              icon={<EditIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="announcement-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                {localization.edit ? localization.edit : "Edit"}
                              </Text>
                            </Menu.Item>
                          </Pane>
                        )}
                        <Pane
                          onClick={handleClickOpenDeleteDialog(
                            announcement?._id,
                            announcement?.title
                          )}
                        >
                          <Menu.Item
                            padding="0"
                            className="announcement-edit-container"
                            data-customClass="custom-design"
                            icon={<TrashIcon size={12} color="white" />}
                            onSelect={close}
                          >
                            <Text
                              className="announcement-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.delete
                                ? localization.delete
                                : "Delete"}
                            </Text>
                          </Menu.Item>
                        </Pane>
                      </Menu.Group>
                    </Menu>
                  )}
                >
                  <MoreIcon className="announcement-list-more-icon" size={12} />
                </Popover>
              </Pane>
            </Pane>
          );
        });
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title={
            localization.noRecords ? localization.noRecords : "No record found."
          }
        >
          {localization.noRecords ? localization.noRecords : "No record found."}
        </Text>
      );
    }
  }

  async function getAnnouncements() {
    setAnnoLoader(true);
    await axios
      .get(`/${currentUserRole}/announcements/`)
      .then((response) => {
        setAnnouncements(response?.data?.data);
        setAnnoLoader(false);
        setActiveRenderList(false);
      })
      .catch((error) => {
        setAnnoLoader(false);
        setActiveRenderList(false);
      });
  }

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="left-panel">
              <Pane className="left-panel-header">
                <Pane className="left-panel-header-first-line">
                  <Label
                    className="announcement-header-title"
                    data-customClass="custom-design"
                  >
                    {localization.announcement
                      ? localization.announcement
                      : "Announcement"}
                  </Label>
                  <Pane>
                    <Pane>
                      <React.Fragment>
                        <SideSheet
                          preventBodyScrolling={true}
                          isShown={isShownAnnouncement}
                          onCloseComplete={resetAnnouncementClose}
                          shouldCloseOnOverlayClick={false}
                        >
                          <Paragraph
                            className="announcement-form-header"
                            data-customClass="custom-design"
                          >
                            <Text
                              className="announcement-form-heading"
                              data-customClass="custom-design"
                            >
                              {localization.createAnnouncement
                                ? localization.createAnnouncement
                                : "Create announcement"}
                            </Text>
                            <Text className="announcement-form-sub-heading">
                              {localization.createAnnouncementDesc
                                ? localization.createAnnouncementDesc
                                : "Please fill the details and create announcement."}
                            </Text>
                          </Paragraph>
                          <form
                            className="announcement-form-panel"
                            onSubmit={handleSubmitAnnouncement}
                            onReset={resetAnnouncementClose}
                          >
                            <Pane className="announcement-form-container-inner">
                              <SimpleBar
                                style={{ maxHeight: "calc(100vh - 124px)" }}
                              >
                                <Pane padding={16} background="tint1" flex="1">
                                  <Pane
                                    className="announcement-detail-form"
                                    data-customClass="custom-design"
                                  >
                                    <Pane
                                      className="announcement-form-heading"
                                      data-customClass="custom-design"
                                    >
                                      <Pane>
                                        <TextInputField
                                          type="text"
                                          name="title"
                                          label={
                                            localization.title
                                              ? localization.title
                                              : "Title"
                                          }
                                          value={inputs.title}
                                          onChange={handleInput}
                                          required
                                          width={570}
                                          marginBottom={3}
                                        />
                                      </Pane>
                                      <Pane>
                                        <TextareaField
                                          className="global-text-area-padding"
                                          label={
                                            localization.description
                                              ? localization.description
                                              : "Description"
                                          }
                                          id="forSeriesDescription"
                                          name="description"
                                          value={inputs.description}
                                          onChange={handleInput}
                                          required
                                          resize="none"
                                          inputWidth={570}
                                        />
                                      </Pane>

                                      {announcementType ===
                                        AnnouncementTypes.news && (
                                        <Pane maxWidth={570}>
                                          <FileUploader
                                            acceptedMimeTypes={[
                                              MimeType.jpeg,
                                              MimeType.png,
                                            ]}
                                            label={
                                              localization.newsImage
                                                ? localization.newsImage
                                                : "News Image"
                                            }
                                            className="create-series-file-uploader"
                                            description=""
                                            maxSizeInBytes={2 * 1024 ** 2}
                                            maxFiles={1}
                                            onChange={handleChangeNews}
                                            onRejected={handleRejectedNews}
                                            renderFile={(file) => {
                                              const { name, size, type } = file;
                                              const fileRejection =
                                                newsFileRejections.find(
                                                  (fileRejection) =>
                                                    fileRejection.file === file
                                                );
                                              const { message } =
                                                fileRejection || {};
                                              return (
                                                <FileCard
                                                  key={name}
                                                  src={URL.createObjectURL(
                                                    newsFile[0]
                                                  )}
                                                  isInvalid={
                                                    fileRejection != null
                                                  }
                                                  name={
                                                    name ? name : "invalidfile"
                                                  }
                                                  onRemove={handleRemoveNews}
                                                  sizeInBytes={size}
                                                  type={type}
                                                  validationMessage={message}
                                                />
                                              );
                                            }}
                                            values={newsFile}
                                          />
                                        </Pane>
                                      )}
                                    </Pane>
                                  </Pane>
                                </Pane>
                              </SimpleBar>
                            </Pane>
                            <Pane className="announcement-form-buttons-panel">
                              <Pane padding={16}>
                                <Heading size={600}>
                                  <Button
                                    className="cancel-button"
                                    data-customClass="custom-design"
                                    type="reset"
                                    onClick={resetAnnouncementClose}
                                  >
                                    {localization.btnCancel
                                      ? localization.btnCancel
                                      : "Cancel"}
                                  </Button>
                                  <Button
                                    className="save-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={
                                      !inputs.title || !inputs.description || submittingSave
                                    }
                                    onClick={() => {
                                      setBtnAnnoncement(true);
                                    }}
                                  >
                                    {localization.btnSave
                                      ? localization.btnSave
                                      : "Save"}
                                  </Button>
                                  <Button
                                    className="save-new-button"
                                    data-customClass="custom-design"
                                    type="submit"
                                    disabled={!inputs.title || !inputs.description || submittingSave}
                                  >
                                    {localization.btnSaveAndAddNew
                                      ? localization.btnSaveAndAddNew
                                      : "Save & Add New"}
                                  </Button>
                                </Heading>
                              </Pane>
                            </Pane>
                          </form>
                        </SideSheet>

                        <Popover
                          minWidth="40px"
                          minHeight="40px"
                          padding="0px"
                          position={Position.BOTTOM_LEFT}
                          content={({ close }) => (
                            <Menu>
                              <Menu.Group>
                                <Pane
                                  onClick={(e) => {
                                    setIsShownAnnouncement(true);
                                    setAnnouncementType("announcement");
                                  }}
                                >
                                  <Menu.Item
                                    padding="0"
                                    className="announcement-edit-container"
                                    data-customClass="custom-design"
                                    icon={
                                      <InfoSignIcon size={12} color="white" />
                                    }
                                    onSelect={close}
                                  >
                                    <Text
                                      className="announcement-edit-delete-text"
                                      data-customClass="custom-design"
                                    >
                                      {localization.announcement
                                        ? localization.announcement
                                        : "Announcement"}
                                    </Text>
                                  </Menu.Item>
                                </Pane>
                                <Pane
                                  onClick={(e) => {
                                    setIsShownAnnouncement(true);
                                    setAnnouncementType("news");
                                  }}
                                >
                                  <Menu.Item
                                    padding="0"
                                    className="announcement-edit-container"
                                    data-customClass="custom-design"
                                    icon={<EditIcon size={12} color="white" />}
                                    onSelect={close}
                                  >
                                    <Text
                                      className="announcement-edit-delete-text"
                                      data-customClass="custom-design"
                                    >
                                      {localization.news
                                        ? localization.news
                                        : "News"}
                                    </Text>
                                  </Menu.Item>
                                </Pane>
                                <Pane
                                  onClick={(e) => {
                                    setIsShownAnnouncement(true);
                                    setAnnouncementType("podcast");
                                  }}
                                >
                                  <Menu.Item
                                    padding="0"
                                    className="announcement-edit-container"
                                    data-customClass="custom-design"
                                    icon={
                                      <UpdatedIcon size={12} color="white" />
                                    }
                                    onSelect={close}
                                  >
                                    <Text
                                      className="announcement-edit-delete-text"
                                      data-customClass="custom-design"
                                    >
                                      {localization.podcastUpdate
                                        ? localization.podcastUpdate
                                        : "Podcast Update"}
                                    </Text>
                                  </Menu.Item>
                                </Pane>
                              </Menu.Group>
                            </Menu>
                          )}
                        >
                          <Button
                            className="create-announcement-button"
                            id="create-announcement-button-design"
                            data-customClass="custom-design"
                            textTransform={"capitalize"}
                            title={
                              localization.createAnnouncement
                                ? localization.createAnnouncement
                                : "create announcement"
                            }
                          >
                            {localization.create
                              ? localization.create
                              : "create"}
                          </Button>
                        </Popover>
                      </React.Fragment>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane
                  className="select-dropdown-box-design"
                  data-customClass="custom-design"
                >
                  <Pane className="search-panel-bottom"></Pane>
                  <Pane
                    className="search-panel"
                    data-customClass="custom-design"
                    style={{ marginTop: "10px" }}
                  >
                    <TextInput
                      className="searchPod"
                      data-customClass="custom-design"
                      placeholder={
                        localization.search ? localization.search : "Search..."
                      }
                      type="text"
                      onChange={(e) => setSearchBy(e.target.value)}
                    />
                    <SelectMenu
                      className="filter-menu"
                      data-customClass="custom-design"
                      height={132}
                      width={100}
                      options={[
                        {
                          value: "A to Z",
                          label: localization.atoz
                            ? localization.atoz
                            : "A to Z",
                        },
                        {
                          value: "Z to A",
                          label: localization.ztoa
                            ? localization.ztoa
                            : "Z to A",
                        },
                        {
                          value: "Newest",
                          label: localization.newest
                            ? localization.newest
                            : "Newest",
                        },
                        {
                          value: "Oldest",
                          label: localization.oldest
                            ? localization.oldest
                            : "Oldest",
                        },
                      ].map((option) => ({
                        label: option["label"],
                        value: option["value"],
                      }))}
                      selected={byOrder}
                      hasFilter={false}
                      hasTitle={false}
                      onSelect={(item) => {
                        setByOrder(item.value);
                        setByOrderLabel(item.label);
                        scrollToTopAnno();
                      }}
                      closeOnSelect={true}
                    >
                      <Button className="sort-filter">
                        <Icon icon={SortIcon} size={12} /> &nbsp;
                        {byOrderLabel ||
                          `${
                            localization.sortBy
                              ? localization.sortBy
                              : "Sort by"
                          }`}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>

              <Pane className="left-panel-content">
                <SimpleBar
                  style={{ maxHeight: "calc(100vh - 182px)" }}
                  scrollableNodeProps={{ ref: annoRef }}
                >
                  {annoLoader ? (
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={400}
                    >
                      <Spinner />
                    </Pane>
                  ) : (
                    renderAnnouncements()
                  )}
                </SimpleBar>
              </Pane>
              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownEditAnnouncement}
                    onCloseComplete={resetEditAnnouncementClose}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="announcement-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="announcement-form-heading"
                        data-customClass="custom-design"
                      >
                        {localization.editAnnouncement
                          ? localization.editAnnouncement
                          : "Edit announcement"}
                      </Text>
                      <Text
                        className="announcement-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        {localization.editAnnouncementDesc
                          ? localization.editAnnouncementDesc
                          : " Please fill the details and edit announcement."}
                      </Text>
                    </Paragraph>
                    <form
                      className="edit-announcement-form-panel"
                      data-customClass="custom-design"
                      onSubmit={handleSubmitEditAnnouncement}
                      onReset={resetEditAnnouncementClose}
                    >
                      <SimpleBar style={{ maxHeight: "calc(100vh - 124px)" }}>
                        <Pane
                          className="announcement-form-heading"
                          data-customClass="custom-design"
                        >
                          <Pane>
                            <TextInputField
                              type="text"
                              name="title"
                              label={
                                localization.title
                                  ? localization.title
                                  : "Title"
                              }
                              value={editInputs.title}
                              onChange={handleInputEdit}
                              required
                              width={570}
                              marginBottom={3}
                            />
                          </Pane>
                        </Pane>
                        <Pane>
                          <TextareaField
                            className="global-text-area-padding"
                            label={
                              localization.description
                                ? localization.description
                                : "Description"
                            }
                            name="description"
                            value={editInputs.description}
                            onChange={handleInputEdit}
                            resize="none"
                            inputWidth={570}
                          />
                        </Pane>
                        {editInputs.announcementType ===
                          AnnouncementTypes.news && (
                          <>
                            <Pane maxWidth={570}>
                              <FileUploader
                                acceptedMimeTypes={[
                                  MimeType.jpeg,
                                  MimeType.png,
                                ]}
                                className="create-series-file-uploader"
                                label="News Image"
                                description=""
                                maxSizeInBytes={2 * 1024 ** 2}
                                maxFiles={1}
                                onChange={handleChangeNews}
                                onRejected={handleRejectedNews}
                                renderFile={(file) => {
                                  if (file) {
                                    const { name, size, type } = file;
                                    const fileRejection =
                                      newsFileRejections.find(
                                        (fileRejection) =>
                                          fileRejection.file === file
                                      );
                                    const { message } = fileRejection || {};
                                    return (
                                      <FileCard
                                        key={name}
                                        src={URL.createObjectURL(newsFile[0])}
                                        isInvalid={fileRejection != null}
                                        name={name}
                                        onRemove={handleRemoveNews}
                                        sizeInBytes={size}
                                        type={type}
                                        validationMessage={message}
                                      />
                                    );
                                  } else {
                                    setNewsFile([]);
                                  }
                                }}
                                values={newsFile}
                              />
                            </Pane>
                            {editInputs.news_image && (
                              <Pane
                                maxWidth={100}
                                className="defaultImageWrapper"
                                data-customClass="custom-design"
                              >
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/${editInputs.news_image}`}
                                  alt="news"
                                  onError={replaceImage}
                                  className="defaultImage"
                                />
                              </Pane>
                            )}
                          </>
                        )}
                      </SimpleBar>
                      <Pane className="announcement-form-buttons-panel">
                        <Pane padding={16}>
                          <Heading size={600}>
                            <Button
                              marginRight={16}
                              padding={10}
                              type="reset"
                              onClick={resetEditAnnouncementClose}
                              className="cancel-button"
                              data-customClass="custom-design"
                            >
                              {localization.btnCancel
                                ? localization.btnCancel
                                : "Cancel"}
                            </Button>
                            <Button
                              marginRight={16}
                              backgroundColor="#a6ced6"
                              padding={10}
                              name="update"
                              type="submit"
                              className="save-button"
                              data-customClass="custom-design"
                              disabled={
                                !editInputs.title || !editInputs.description || submittingUpdate
                              }
                            >
                              {localization.btnUpdate
                                ? localization.btnUpdate
                                : "Update"}
                            </Button>
                          </Heading>
                        </Pane>
                      </Pane>
                    </form>
                  </SideSheet>
                </React.Fragment>
              </Pane>
            </Pane>
            <Pane className="middle-panel">
              <Pane className="middle-panner-wrapper">
                {annoInfoLoader ? (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={400}
                  >
                    <Spinner />
                  </Pane>
                ) : (
                  renderAnnouncementInfo()
                )}
              </Pane>
            </Pane>
          </Pane>
          <Pane className="right-panel"></Pane>
        </Pane>
      </Pane>

      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownDeleteDialog}
          onCloseComplete={() => setIsShownDeleteDialog(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title={
            localization.deleteTitleAnnouncement
              ? localization.deleteTitleAnnouncement
              : "Do you really want to delete this announcement?"
          }
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialog(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              {localization.btnCancel ? localization.btnCancel : "Cancel"}
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteAnnouncement(deleteId)}
            >
              {localization.delete ? localization.delete : "Delete"}
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default Announcement;
