import axios from "../../../helper/axiosInstance";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import {
  Pane,
  Text,
  HeadsetIcon,
  PersonIcon,
  MusicIcon,
  Spinner,
} from "evergreen-ui";
import "./tenantDashboard.css";

import AuthContext from "../../../context/AuthContext";
import newreleasebgimg from "../../../images/released-bg-img.png";
import noBanner from "../../../images/default/nobanner.png";

function TenantDashboard() {
  const { localization } = useContext(AuthContext);

  const replaceSeries = (error) => {
    error.target.src = "/default/series.png";
  };

  const replaceEpisode = (error) => {
    error.target.src = "/default/episode.png";
  };

  const replaceCategory = (error) => {
    error.target.src = "/default/category.png";
  };

  const replaceBanner = (error) => {
    error.target.src = "/default/banner.png";
  };

  const [loading, setLoading] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [seriesLoader, setSeriesLoader] = useState(false);
  const [trendingSerieseLoader, setTrendingSerieseLoader] = useState(true);

  const [serieses, setSerieses] = useState([]);
  const [trendingCategories, setTrendingCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [mostListened, setMostListened] = useState([]);
  const [recentSerieses, setRecentSerieses] = useState([]);
  const [trendingSeriese, setTrendingSeriese] = useState([]);

  async function getSerieses() {
    const seriesRes = await axios.get("/tenants/serieses/");
    const sharedSeriesRes = await axios.get("/tenants/sharedseries/");

    const array1 = seriesRes?.data?.data;
    const sharedSeriesData = sharedSeriesRes?.data?.data;

    setSerieses(array1.concat(sharedSeriesData));
  }

  async function getTrendingSerieses() {
    try {
      const response = await axios.get("/tenants/trending/serieses/");
      setTrendingSeriese(response?.data?.data);
      setTrendingSerieseLoader(false);
    } catch (error) {
      setTrendingSerieseLoader(false);
      console.log(error);
    }
  }
  async function getRecentSerieses() {
    try {
      const response = await axios.get("/tenants/recent/serieses/");
      setRecentSerieses(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getMostListenedSeries() {
    setBannerLoader(true);
    try {
      const response = await axios.get("/tenants/mostlistened/podcasts/");
      setMostListened(response?.data?.data);
      setBannerLoader(false);
    } catch (error) {
      setBannerLoader(false);
      console.log(error);
    }
  }

  async function getTrendingCategories() {
    try {
      const response = await axios.get("/tenants/trending/categories/");
      setTrendingCategories(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUsers() {
    try {
      const response = await axios.get("/tenants/users/");
      setUsers(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  const renderBanner = () => {
    if (trendingSeriese && trendingSeriese.length > 0) {
      return trendingSeriese?.slice(0, 4)?.map((series, i) => {
        return (
          <div key={series?.title}>
            <img
              className="carousel-banner"
              src={`${process.env.REACT_APP_API_URL}/${series?.banner_image}`}
              alt={series?.title}
              onError={replaceBanner}
            />
          </div>
        );
      });
    } else {
      return (
        <div>
          <img
            className="carousel-banner"
            src={noBanner}
            alt="banner image"
            onError={replaceBanner}
          />
        </div>
      );
    }
  };

  const matrixSeries = mostListened
    ?.slice(0, mostListened.length > 4 ? 4 : mostListened.length)
    ?.reduce(function (rows, key, index) {
      return (
        (index % 4 === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows
      );
    }, []);

  const convertMinsToTime = (seconds) => {
    const d = Number(seconds);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);

    let hDisplay = h > 0 ? h + (h === 1 ? " Hr" : " Hrs") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " Min" : " Mins") : "0 Mins";

    if (h > 0) {
      return hDisplay + " : " + mDisplay;
    } else {
      return mDisplay;
    }
  };

  function renderMostListenedSeries() {
    if (matrixSeries.length > 0) {
      return matrixSeries.map((rows, i) => {
        return (
          <Pane className="second-grid-container-first-panel" key={i}>
            {rows.map((series, j) => (
              <Pane className="right-wrapper" key={series?.title}>
                <Pane className="right-wrapper-img">
                  <Link to="/library">
                    <img
                      className="first-img"
                      src={`${process.env.REACT_APP_API_URL}/${series?.series_image}`}
                      alt={series?.title}
                      onError={replaceSeries}
                      width="100%"
                      height="auto"
                    />
                  </Link>
                </Pane>
                <Pane
                  className="right-wrapper-data"
                  data-customClass="custom-design"
                >
                  <Link to="/library">
                    <Pane className="right-wrapper-inner-text">
                      <Text
                        className="right-wrapper-title"
                        data-customClass="custom-design"
                      >
                        {series?.title}
                      </Text>
                      <Text
                        className="right-wrapper-sub-title"
                        data-customClass="custom-design"
                      >
                        {series?.category_id?.title}
                      </Text>
                    </Pane>
                    <Pane className="icon-text">
                      <HeadsetIcon size={14} />
                      <Text className="right-wrapper-icon-title">
                        {convertMinsToTime(series?.totalSeconds)}
                      </Text>
                    </Pane>
                  </Link>
                </Pane>
              </Pane>
            ))}
          </Pane>
        );
      });
    } else {
      return (
        <Pane className="noDataFound">
          {localization.noRecords ? localization.noRecords : "No record found."}
        </Pane>
      );
    }
  }

  function renderRecentSeries() {
    if (recentSerieses.length > 0) {
      return recentSerieses?.slice(0, 4)?.map((series, i) => {
        return (
          <Link to="/library" key={series?.title}>
            <Pane className="new-releases-data-list-wrapper">
              <Pane className="release-data-img-container">
                <Pane className="new-container">
                  <Pane className="release-data-img">
                    <img
                      className="release-img"
                      src={`${process.env.REACT_APP_API_URL}/${series.series_image}`}
                      alt={series.title}
                      onError={replaceSeries}
                      width="100%"
                      height="auto"
                    />
                  </Pane>

                  <Pane className="release-data-title">
                    <Text
                      className="release-heading-title"
                      data-customClass="custom-design"
                    >
                      {series.title}
                    </Text>
                    <Text
                      className="release-sub-heading-title"
                      data-customClass="custom-design"
                    >
                      {series.category_id.title}
                    </Text>
                  </Pane>
                </Pane>
              </Pane>
              <Pane className="release-data-series">
                <Text
                  className="release-data-series-count"
                  data-customClass="custom-design"
                >
                  {series.podcasts.length}
                </Text>
                <Text
                  className="release-data-series-title"
                  data-customClass="custom-design"
                >
                  {series.podcasts.length > 1 ? "Episodes" : "Episode"}
                </Text>
              </Pane>
            </Pane>
          </Link>
        );
      });
    } else {
      return (
        <Pane className="noDataFound">
          {localization.noRecords ? localization.noRecords : "No record found."}
        </Pane>
      );
    }
  }

  function renderTrendingCategories() {
    if (trendingCategories.length > 0) {
      return trendingCategories?.slice(0, 4)?.map((category, i) => {
        return (
          <Pane
            className="trending-box-item-list-wrapper-inner"
            key={category?.title}
          >
            <Pane className="release-data-img">
              <img
                className="release-img"
                src={`${process.env.REACT_APP_API_URL}/${category.category_image}`}
                alt={category.title}
                onError={replaceCategory}
                width="100%"
                height="auto"
              />
            </Pane>
            <Pane className="trending-box-item-list-wrapper-inner-text">
              <Text
                className="trending-box-item-list-text"
                data-customClass="custom-design"
              >
                {category.title}
              </Text>
              {/* <Text className="trending-box-item-list-points">0 podcasts</Text> */}
            </Pane>
          </Pane>
        );
      });
    } else {
      return (
        <Pane className="noDataFound">
          {localization.noRecords ? localization.noRecords : "No record found."}
        </Pane>
      );
    }
  }

  function renderSerieses() {
    return serieses?.slice(0, 4)?.map((series, i) => {
      return (
        <img
          key={series?.title}
          className="img-row"
          src={`${process.env.REACT_APP_API_URL}/${series.series_image}`}
          alt={series.title}
          onError={replaceEpisode}
          width="100%"
          height="auto"
        />
      );
    });
  }

  useEffect(() => {
    getSerieses();
    getTrendingCategories();
    getUsers();
    getMostListenedSeries();
    getRecentSerieses();
    getTrendingSerieses();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="dashboard-inner-container">
            <Pane className="dashboard-top-container">
              <Pane className="left-side-grid">
                <Pane className="left-side-grid-box">
                  <Pane className="left-side-grid-header">
                    <Text
                      className="left-side-grid-header-text"
                      data-customClass="custom-design"
                    >
                      {localization.nowTrending
                        ? localization.nowTrending
                        : "Now Trending"}
                    </Text>
                  </Pane>
                  <Pane className="left-side-grid-container">
                    {trendingSerieseLoader ? (
                      <Pane
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="200px"
                      >
                        <Spinner />
                      </Pane>
                    ) : (
                      <Carousel
                        showArrows={false}
                        showThumbs={false}
                        showStatus={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={2000}
                      >
                        {renderBanner()}
                      </Carousel>
                    )}
                  </Pane>
                </Pane>
                <Pane className="right-grid-box">
                  <Pane className="second-grid-header">
                    <Text
                      className="right-side-grid-header-text"
                      data-customClass="custom-design"
                    >
                      {localization.mostListened
                        ? localization.mostListened
                        : "Most Listened"}
                    </Text>
                  </Pane>
                  <Pane className="second-grid-container">
                    {renderMostListenedSeries()}
                  </Pane>
                </Pane>
              </Pane>
            </Pane>

            <Pane className="dashboard-bottom-container">
              <Pane className="second-grid">
                <Pane className="counting-box">
                  <Pane className="counting-box-data-list">
                    <Link to="/user">
                      <Pane className="counting-box-data-list-inner">
                        <Pane className="icon">
                          <PersonIcon color="white" size={12} />
                        </Pane>
                        <Pane className="text-number">
                          <Text
                            className="comunity-Name"
                            data-customClass="custom-design"
                          >
                            {localization.users ? localization.users : "Users"}
                          </Text>
                          <Text className="coutning">{users.length > 0 ? users.length - 1 : users.length}</Text>
                        </Pane>
                      </Pane>
                    </Link>
                    <Link to="/library">
                      <Pane className="counting-box-data-list-inner">
                        <Pane className="icon">
                          <MusicIcon color="white" size={12} />
                        </Pane>
                        <Pane className="text-number">
                          <Text
                            className="comunity-Name"
                            data-customClass="custom-design"
                          >
                            {localization.series
                              ? localization.series
                              : "Series"}
                          </Text>
                          <Text className="coutning">
                            {serieses.length || 0}
                          </Text>
                        </Pane>
                        <Pane className="img-list">
                          <Pane className="img-list-container">
                            {renderSerieses()}
                          </Pane>
                          <Text
                            className="img-counting-number"
                            data-customClass="custom-design"
                          >
                            {serieses.length > 4
                              ? `${serieses.length - 4}+`
                              : ""}
                          </Text>
                        </Pane>
                      </Pane>
                    </Link>
                  </Pane>
                  {/* </SimpleBar> */}
                </Pane>

                <Pane
                  className="new-releases-box"
                  id="new-released-bg-poster"
                  style={{ backgroundImage: `url(${newreleasebgimg})` }}
                >
                  <Pane className="new-releases-box-header">
                    <Text
                      className="new-releases-box-header-text"
                      data-customClass="custom-design"
                    >
                      {localization.newReleases
                        ? localization.newReleases
                        : "New Releases"}
                    </Text>
                  </Pane>
                  <Pane className="new-releases-box-data">
                    <Pane className="new-releases-data-list">
                      {renderRecentSeries()}
                    </Pane>
                    {/* </SimpleBar> */}
                  </Pane>
                </Pane>

                <Pane className="trending-box">
                  <Pane className="trending-box-box-header">
                    <Text
                      className="trending-box-box-header-text"
                      data-customClass="custom-design"
                    >
                      {localization.trendingCategories
                        ? localization.trendingCategories
                        : "Trending Categories"}
                    </Text>
                  </Pane>
                  <Pane className="trending-box-box-data">
                    <Pane className="trending-box-item-list-wrapper">
                      {renderTrendingCategories()}
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  );
}

export default TenantDashboard;
