import axios from "../../../helper/axiosInstance";
import React, { useEffect, useContext, useState, useCallback } from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";

import {
  Pane,
  Text,
  SideSheet,
  Paragraph,
  Button,
  FileUploader,
  Heading,
  FileCard,
  toaster,
  MimeType,
  EditIcon,
  Spinner,
} from "evergreen-ui";

import "./accountsettings.css";

import AuthContext from "../../../context/AuthContext";

function AccountSettings() {
  const { localization, userId, userRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  
  const [submittingUpdate, setSubmittingUpdate] = useState(false);

  const replaceImage = (error) => {
    error.target.src = "./default/user.png";
  };

  const [activeUser, setActiveUser] = useState("");
  const currentUserRole = userRole === "superadmin" ? "admin" : "tenants";

  async function getUsers() {
    setUserLoader(true);
    await axios
      .get(`/${currentUserRole}/user/${userId}`)
      .then((response) => {
        setActiveUser(response?.data?.data);
        setUserLoader(false);
      })
      .catch((error) => {
        setUserLoader(false);
      });
  }

  const [isShownUser, setIsShownUser] = useState(false);

  const [profileFile, setProfileFile] = useState([]);
  const [profileFileRejections, setProfileFileRejections] = useState([]);

  const handleChangeProfile = useCallback(
    (files) => setProfileFile([files[0]]),
    []
  );
  const handleRejectedProfile = useCallback(
    (profileFileRejections) =>
      setProfileFileRejections([profileFileRejections[0]]),
    []
  );
  const handleRemoveProfile = useCallback(() => {
    setProfileFile([]);
    setProfileFileRejections([]);
  }, []);

  const resetUserClose = (e) => {
    setProfileFile([]);
    setProfileFileRejections([]);

    setIsShownUser(false);
  };

  function handleSubmitUser(e) {
    e.preventDefault();
    if (submittingUpdate) {
      return;
    }
    setSubmittingUpdate(true);    
    setLoading(true);

    const url = `/${currentUserRole}/user/profilepicture/`;
    const formData = new FormData();

    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingUpdate(false);  
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        setProfileFile([]);
        setProfileFileRejections([]);
        getUsers();
        setIsShownUser(false);
      })
      .catch((error) => {
        setSubmittingUpdate(false);  
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="right-panel">
              <Pane
                className="language-container"
                data-customClass="custom-design"
              >
                <Pane
                  className="language-header-panel"
                  data-customClass="custom-design"
                >
                  <Pane className="languagea-header-list-panel">
                    <Pane className="language-header-heading">
                      <Text
                        className="language-header-name"
                        data-customClass="custom-design"
                      >
                        {localization.accountSettings
                          ? localization.accountSettings
                          : "Account Details"}
                      </Text>
                    </Pane>
                  </Pane>
                </Pane>

                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownUser}
                    onCloseComplete={resetUserClose}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="user-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="user-form-heading-title"
                        data-customClass="custom-design"
                      >
                        {localization.editProfilePhoto
                          ? localization.editProfilePhoto
                          : "Edit Profile Photo *"}
                      </Text>
                      <Text
                        className="user-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        {localization.editProfilePhotoDesc
                          ? localization.editProfilePhotoDesc
                          : "Please fill the details and update profile."}
                      </Text>
                    </Paragraph>

                    <form
                      className="user-form-panel"
                      data-customClass="custom-design"
                      onSubmit={handleSubmitUser}
                      onReset={resetUserClose}
                    >
                      <Pane className="user-detail-form">
                        <Pane maxWidth={578}>
                          <FileUploader
                            acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                            label={
                              localization.profilePhoto
                                ? localization.profilePhoto
                                : "Profile Photo *"
                            }
                            description={
                              localization.profilePhotoDesc
                                ? localization.profilePhotoDesc
                                : "You can upload up to 1 file. Maximum upload file size: 2MB. (jpg, png format)."
                            }
                            className="create-series-file-uploader"
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeProfile}
                            onRejected={handleRejectedProfile}
                            renderFile={(file) => {
                              const { name, size, type } = file;
                              const fileRejection = profileFileRejections.find(
                                (fileRejection) => fileRejection.file === file
                              );
                              const { message } = fileRejection || {};
                              return (
                                <FileCard
                                  key={name}
                                  src={URL.createObjectURL(profileFile[0])}
                                  isInvalid={fileRejection != null}
                                  name={name}
                                  onRemove={handleRemoveProfile}
                                  sizeInBytes={size}
                                  type={type}
                                  validationMessage={message}
                                />
                              );
                            }}
                            values={profileFile}
                          />
                        </Pane>
                      </Pane>
                      <Pane className="user-form-buttons-panel">
                        <Pane padding={16}>
                          <Heading size={600}>
                            <Button
                              className="cancel-button"
                              data-customClass="custom-design"
                              type="reset"
                              onClick={resetUserClose}
                            >
                              {localization.btnCancel
                                ? localization.btnCancel
                                : "Cancel"}
                            </Button>
                            <Button
                              className="save-button"
                              data-customClass="custom-design"
                              type="submit"
                              disabled={!profileFile[0] || submittingUpdate }
                            >
                              {localization.btnUpdate
                                ? localization.btnUpdate
                                : "Update"}
                            </Button>
                          </Heading>
                        </Pane>
                      </Pane>
                    </form>
                  </SideSheet>
                </React.Fragment>
                <Pane className="account-setting-main-wrapper">
                  <Pane>
                    <Pane>
                      {userLoader ? (
                        <Pane
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height={400}
                        >
                          <Spinner />
                        </Pane>
                      ) : (
                        <Pane
                          className="account-setting-inner"
                          data-customClass="custom-design"
                        >
                          <Pane
                            className="account-setting-details"
                            data-customClass="custom-design"
                          >
                            <Text
                              color={"#a8a4a4"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {localization.firstName
                                ? localization.firstName
                                : "First Name"}
                            </Text>
                            <Text
                              color={"black"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {activeUser?.["firstName"]}
                            </Text>
                          </Pane>
                          <Pane
                            className="account-setting-details"
                            data-customClass="custom-design"
                          >
                            <Text
                              color={"#a8a4a4"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {localization.lastName
                                ? localization.lastName
                                : "Last Name"}
                            </Text>
                            <Text
                              color={"black"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {activeUser["lastName"]}
                            </Text>
                          </Pane>
                          <Pane
                            className="account-setting-details"
                            data-customClass="custom-design"
                          >
                            <Text
                              color={"#a8a4a4"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {localization.email
                                ? localization.email
                                : "Email"}
                            </Text>
                            <Text
                              color={"black"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {activeUser["email"]}
                            </Text>
                          </Pane>
                          <Pane
                            className="account-setting-details"
                            data-customClass="custom-design"
                          >
                            <Text
                              color={"#a8a4a4"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              {localization.profilePhoto
                                ? localization.profilePhoto
                                : "Profile Photo"}
                            </Text>
                            <Text
                              color={"black"}
                              fontWeight={600}
                              fontSize={12}
                            >
                              <img
                                alt=""
                                className="account-profile-img"
                                src={`${process.env.REACT_APP_API_URL}/${activeUser["profile_image"]}`}
                                onError={replaceImage}
                                width="100%"
                                height="auto"
                              />
                              <EditIcon
                                color="muted"
                                onClick={() => setIsShownUser(true)}
                                className="edit-icon"
                                style={{ cursor: "pointer" }}
                              />
                            </Text>
                          </Pane>
                        </Pane>
                      )}
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default AccountSettings;
