import axios from "../../helper/axiosInstance";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo-scroll.png";
import {
  Pane,
  TextInputField,
  Button,
  toaster,
  Text,
  Spinner,
} from "evergreen-ui";

import "./login.css";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const history = useNavigate();

  async function forgotPassword(e) {
    setLoading(true);
    e.preventDefault();

    const userData = {
      email,
    };

    await axios
      .post("/auth/forgotpasswordweb", userData)
      .then((res) => {
        setLoading(false);
        if (res) {
          toaster.success("Please check your email to update password", {
            duration: 2,
          });
          setTimeout(() => {
            history("/login");
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message);
        }
        toaster.warning(error.response.data.message);
      });
  }

  return (
    <>
      <Pane className="flex-container">
        <Pane className="flex-item">
          <Pane className="login-wrapper">
            <Pane className="login-wrapper-inner-body">
              <Pane className="login-logo-wrapper">
                <img
                  className="login-logo-img"
                  src={Logo}
                  alt="logo"
                  width="100%"
                  height="auto"
                />
              </Pane>
              <p className="Login-text">Forgot Password</p>
              <Text className="Login-sub-text">Welcome to RadioSkills</Text>
              <form
                sx={{ mt: 1 }}
                onSubmit={forgotPassword}
                className="login-form"
              >
                <TextInputField
                  className="input-field"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={"Email"}
                  label="Enter register email address"
                  required
                />
                <Link to="/login" className="frm-btm-link">
                  Back to login
                </Link>
                <Button
                  className="Login-form-button"
                  type="submit"
                  width="300px"
                  textTransform="capitalize"
                >
                  SUBMIT
                </Button>
              </form>
            </Pane>
          </Pane>
        </Pane>
        <Pane className="right-side-container">
          <Pane className="login-img-right-side">
            <Pane className="img-container">
              <Pane className="login-right-side-img"></Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default ForgotPassword;
