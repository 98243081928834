import axios from "../../../helper/axiosInstance";
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import Header from "../../layout/Header";
import Sidebar from "../../layout/Sidebar";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import userPoster from "../../../images/empty-podcast-poster.svg";
import {
  Pane,
  Button,
  MoreIcon,
  TrashIcon,
  CalendarIcon,
  Dialog,
  Label,
  FileUploader,
  FileCard,
  SideSheet,
  Paragraph,
  Heading,
  TextInputField,
  Text,
  Popover,
  Position,
  Menu,
  EditIcon,
  toaster,
  WarningSignIcon,
  MimeType,
  Spinner,
  TextInput,
  SelectMenu,
  Icon,
  SortIcon,
  Badge,
} from "evergreen-ui";

import dashboard from "../../../images/speedometer1.png";
import hearing from "../../../images/hearing.svg";

import "./user.css";

import AuthContext from "../../../context/AuthContext";

function User() {
  const [loading, setLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [userInfoLoader, setUserInfoLoader] = useState(false);
  const [activeRenderList, setActiveRenderList] = useState(true);
  
  const [submittingSave, setSubmittingSave] = useState(false);
  const [submittingUpdate, setSubmittingUpdate] = useState(false);
  const [submittingImport, setSubmittingImport] = useState(false);

  const { localization } = useContext(AuthContext);

  const userRef = useRef(null);
  const userInfoRef = useRef(null);

  const scrollToTopUser = () => {
    userRef.current.scrollTop = 0;
  };

  const scrollToTopUserInfo = () => {
    userInfoRef.current.scrollTop = 0;
  };

  const [btnUser, setBtnUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [listeningTime, setListeningTime] = useState();
  const [listeningLevel, setListeningLevel] = useState(1);

  const listeningLevelIds = {
    1: "beginner",
    2: "regular",
    3: "veteran",
    4: "professional",
    5: "advanced",
  };

  const convertMinsToTime = (seconds) => {
    const d = Number(seconds);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);

    let hDisplay = h > 0 ? h + (h === 1 ? " Hr" : " Hrs") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " Min" : " Mins") : "0 Mins";

    if (h > 0) {
      return hDisplay + " : " + mDisplay;
    } else {
      return mDisplay;
    }
  };

  const [isShownUser, setIsShownUser] = useState(false);
  const [isShownEditUser, setIsShownEditUser] = useState(false);
  const [isShownImportUser, setIsShownImportUser] = useState(false);

  const replaceImage = (error) => {
    error.target.src = "/default/user.png";
  };

  const replaceBadge = (error) => {
    error.target.src = "/default/badge1.svg";
  };

  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileImage: "",
  });

  const [editInputs, setEditInputs] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    profileImage: "",
  });

  const handleInput = (e) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputEdit = (e) => {
    setEditInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const resetUser = (e) => {
    setInputs({
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);
  };

  const resetUserClose = (e) => {
    setInputs({
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);

    setIsShownUser(false);
  };

  const resetEditUser = (e) => {
    setEditInputs({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      profileImage: "",
    });

    setProfileFile([]);
    setProfileFileRejections([]);

    setIsShownEditUser(false);
  };

  const resetImportUsersClose = (e) => {
    setCsvFile([]);
    setIsShownImportUser(false);
  };

  const [profileFile, setProfileFile] = useState([]);
  const [profileFileRejections, setProfileFileRejections] = useState([]);

  const handleChangeProfile = useCallback(
    (files) => setProfileFile([files[0]]),
    []
  );
  const handleRejectedProfile = useCallback(
    (profileFileRejections) =>
      setProfileFileRejections([profileFileRejections[0]]),
    []
  );
  const handleRemoveProfile = useCallback(() => {
    setProfileFile([]);
    setProfileFileRejections([]);
  }, []);

  const [csvFile, setCsvFile] = useState([]);
  const [csvFileRejections, setCsvFileRejections] = useState([]);

  const handleChangeCsv = useCallback((files) => setCsvFile([files[0]]), []);
  const handleRejectedCsv = useCallback(
    (csvFileRejections) => setCsvFileRejections([csvFileRejections[0]]),
    []
  );
  const handleRemoveCsv = useCallback(() => {
    setCsvFile([]);
    setCsvFileRejections([]);
  }, []);

  
  const [activateEmail, setActivateEmail] = useState("");
  const [isShownActivateDialog, setIsShownActivateDialog] = useState(false);

  function handleClickOpenActivateDialog(email) {
    setActivateEmail(email);
    setIsShownActivateDialog(true);
  }

  const handleClickOpenEditUser = (id) => () => {
    setLoading(true);
    axios
      .get(`/tenants/user/${id}`)
      .then((res) => {
        const response = res.data.data;
        setEditInputs({
          id: response._id,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          profile_image: response.profile_image,
        });
        setIsShownEditUser(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleSubmitUser(e) {
    e.preventDefault();
    if (submittingSave) {
      return;
    }
    setSubmittingSave(true);
    setLoading(true);

    const url = "/tenants/user/";
    const formData = new FormData();

    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    formData.append("firstName", inputs.firstName);
    formData.append("lastName", inputs.lastName);
    formData.append("email", inputs.email);

    axios
      .post(url, formData)
      .then((res) => {
        if (res.data.statusCode == 10002) {
          setSubmittingSave(false);
          setLoading(false);
          handleClickOpenActivateDialog(inputs.email);
        } else {
        setSubmittingSave(false);
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });

        setProfileFile([]);
        setProfileFileRejections([]);
        getUsers();

        if (btnUser) {
          resetUserClose();
          setBtnUser(false);
        } else {
          resetUser();
        }
      }
      })
      .catch((error) => {
        setSubmittingSave(false);
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitEditUser(e) {
    e.preventDefault();
    if (submittingUpdate) {
      return;
    }
    setSubmittingUpdate(true);    
    setLoading(true);

    const id = editInputs.id;

    const url = `/tenants/user/${id}`;
    const formData = new FormData();

    if (profileFile[0] !== undefined) {
      formData.append("profile_image", profileFile[0]);
    }

    formData.append("firstName", editInputs.firstName);
    formData.append("lastName", editInputs.lastName);

    axios
      .put(url, formData)
      .then((res) => {
        setSubmittingUpdate(false); 
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        resetEditUser();
        setEditInputs({});
        setProfileFile([]);
        setProfileFileRejections([]);

        getUsers();
        onClickDisplayUserById(id);
        setIsShownEditUser(false);
      })
      .catch((error) => {
        setSubmittingUpdate(false); 
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  }

  function handleSubmitImportUsers(e) {
    e.preventDefault();
    if (submittingImport) {
      return;
    }
    setSubmittingImport(true);    
    setLoading(true);

    const url = "/tenants/bulkimport";
    const formData = new FormData();
    formData.append("bulk_users_csv", csvFile[0]);

    axios
      .post(url, formData)
      .then((res) => {
        setSubmittingImport(false); 
        setLoading(false);
        getUsers();
        toaster.success(res.data.message, { duration: 2 });
        getUsers();
        setIsShownImportUser(false);
      })
      .catch((error) => {
        setSubmittingImport(false); 
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      })
      .finally(() => {
        setCsvFile([]);
      });
  }

  function resetImportUser(e) {
    setCsvFile([]);
  }

  const [deleteTitle, setDeleteTitle] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isShownDeleteDialog, setIsShownDeleteDialog] = useState(false);

  const handleClickOpenDeleteDialog = (id, title) => () => {
    setDeleteId(id);
    setDeleteTitle(title);
    setIsShownDeleteDialog(true);
  };

  function returnDate(data) {
    try {
      const date = new Date(data);
      const isoDate = date.toISOString().substring(0, 10);
      return isoDate;
    } catch (error) {
      console.log("date format incorrect");
    }
  }

  const [activeUser, setActiveUser] = useState("");
  const [badges, setBadges] = useState("");
  const [activeBadges, setActiveBadges] = useState("");
  const [badgeInfo, setBadgeInfo] = useState("");

  const [focusUser, setFocusUser] = useState(true);

  const activeUserTab = (id) => {
    if (activeUser !== "") {
      if (id === activeUser?._id && focusUser) {
        return "user-list-panel active";
      } else {
        return "user-list-panel";
      }
    } else {
      return "user-list-panel";
    }
  };

  async function onClickDisplayUserById(id) {
    setFocusUser(false);
    setUserInfoLoader(true);

    const response = await axios.get(`/tenants/user/${id}`);
    const badgesResponse = await axios.get(`/tenants/user/${id}/badges`);
    const badgesRes = badgesResponse?.data?.data;
    setActiveBadges(badgesRes.map((badge, index) => badge?.badgeId?.badgeId));
    setActiveUser(response?.data?.data);

    const listeningresponse = await axios.get(
      `tenants/user/${id}/listeninghours`
    );
    setListeningTime(listeningresponse?.data?.data?.listenedSeconds);

    const listeningLevelresponse = await axios.get(
      `tenants/user/${id}/listeninglevel`
    );

    setListeningLevel(listeningLevelresponse?.data?.data[0]?.level_id);

    setBadgeInfo("");

    setUserInfoLoader(false);
    setFocusUser(true);
    scrollToTopUserInfo();
  }

  async function onClickDisplayBadgeById(id) {
    const badgeInfoRes = badges.find((badge) => badge._id === id);
    setBadgeInfo(badgeInfoRes);
  }

  const deleteUser = (id) => () => {
    setLoading(true);
    axios
      .delete(`/tenants/user/${id}`)
      .then((res) => {
        setLoading(false);
        toaster.danger(res.data.message, { duration: 2 });
        getUsers();
        setActiveUser("");
        setIsShownDeleteDialog(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };

  
  const activateUser = (email) => () => {
    const updateData = {
      email,
      isDeleted: false,
    };
    setLoading(true);
    axios
      .put(`/tenants/activate/user`, updateData)
      .then((res) => {
        setLoading(false);
        toaster.success(res.data.message, { duration: 2 });
        getUsers();
        setIsShownActivateDialog(false);       
        resetUserClose();
      })
      .catch((error) => {
        setLoading(false);     
        resetUserClose();
        if (error.message) {
          toaster.warning(error.message, { duration: 2 });
        }
        toaster.warning(error.response.data.message, { duration: 2 });
      });
  };


  function renderBadges() {
    if (badges.length > 0) {
      return badges.map((badge, i) => {
        return (
          <Pane className="badge-inner" key={badge?._id}>
            <img
              title={badge?.badgeId?.title}
              className={
                activeBadges.includes(badge?.badgeId) ? "badge" : "badge-active"
              }
              src={`${process.env.REACT_APP_API_URL}/badges/${badge?.icon}`}
              onError={replaceBadge}
              onClick={() => onClickDisplayBadgeById(badge?._id)}
              width="100%"
              height="auto"
              alt=""
            />
          </Pane>
        );
      });
    } else {
      return <p>Badge is not available. </p>;
    }
  }

  const renderBadgeInfo = () => {
    if (badgeInfo) {
      if (badgeInfo !== "") {
        return (
          <Pane className="right-panel">
            <Pane className="badge-info-container">
              <p className="badge-header">
                {localization.badgeDetails
                  ? localization.badgeDetails
                  : "Badge Details"}
              </p>
              <p className="badge-name"> {badgeInfo.title} </p>
              <p className="badge-desc"> {badgeInfo.badge_criteria} </p>
              <img
                className={
                  activeBadges.includes(badgeInfo?.badgeId)
                    ? "active-badge-info"
                    : "badge-info"
                }
                src={`${process.env.REACT_APP_API_URL}/badges/${badgeInfo.icon}`}
                onError={replaceImage}
                width="100%"
                height="auto"
                alt=""
              />
              {badgeInfo.onScreenContent?.map((item) => {
                return <p className="badge-content"> {item} </p>;
              })}
            </Pane>
          </Pane>
        );
      }
    }
  };

  const renderUserInfo = () => {
    if (activeUser !== "") {
      return (
        <>
          <Pane className="user-header-panel">
            <Pane className="user-header-list-panel">
              <Pane className="tenant-header-img">
                <img
                  className="user-header-panle-img"
                  src={`${process.env.REACT_APP_API_URL}/${activeUser?.profile_image}`}
                  onError={replaceImage}
                  width="100%"
                  height="auto"
                  alt=""
                />
              </Pane>
              <Pane className="user-header-heading">
                <Text className="user-header-name">
                  {activeUser?.firstName + " " + activeUser?.lastName}
                </Text>
              </Pane>
              <Pane>
                {activeUser?.role?.name == "user" && (
                  <Popover
                    minWidth="40px"
                    minHeight="40px"
                    padding="0px"
                    position={Position.BOTTOM_LEFT}
                    content={
                      <Menu>
                        <Menu.Group>
                          <Menu.Item
                            padding="0"
                            className="user-edit-container"
                            data-customClass="custom-design"
                            icon={<EditIcon size={12} color="white" />}
                            onClick={handleClickOpenEditUser(activeUser?._id)}
                          >
                            <Text
                              className="series-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.edit ? localization.edit : "Edit"}
                            </Text>
                          </Menu.Item>
                          <Menu.Item
                            padding="0"
                            className="user-edit-container"
                            data-customClass="custom-design"
                            icon={<TrashIcon size={12} color="white" />}
                            onClick={handleClickOpenDeleteDialog(
                              activeUser?._id,
                              activeUser?.email
                            )}
                          >
                            <Text
                              className="series-edit-delete-text"
                              data-customClass="custom-design"
                            >
                              {localization.delete
                                ? localization.delete
                                : "Delete"}
                            </Text>
                          </Menu.Item>
                        </Menu.Group>
                      </Menu>
                    }
                  >
                    <MoreIcon
                      className="middle-panel-header-moreicon"
                      size={12}
                    />
                  </Popover>
                )}
              </Pane>
            </Pane>
          </Pane>
          <Pane className="user-content-panel">
            <Pane className="user-content-description-list">
              <Pane>
                <p className="user-content-description-list-one">
                  {localization.createdOn
                    ? localization.createdOn
                    : "Created on"}
                </p>
                <p className="user-content-description-list-two">
                  {returnDate(activeUser?.createdAt)}
                </p>
              </Pane>
              <Pane>
                <p className="user-content-description-list-one">
                  {localization.email ? localization.email : "Email"}
                </p>
                <p className="user-content-description-list-two">
                  {activeUser?.email}
                </p>
              </Pane>
            </Pane>
            {activeUser?.role?.name == "user" && (
              <>
                <Pane className="user-content-count-list">
                  <Pane className="user-count">
                    <Pane
                      className="user-icon-one"
                      backgroundColor="var(--accent-color)"
                      width={30}
                      height={30}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      borderRadius={50}
                    >
                      <img
                        className="user-desc-list-icon"
                        src={hearing}
                        onError={replaceImage}
                        width="20%"
                        height="auto"
                        alt=""
                      />
                    </Pane>
                    <Pane className="user-content-text">
                      <Text
                        color="#343434"
                        fontSize={10}
                        fontWeight={500}
                        textTransform="capitalize"
                      >
                        {localization.listeningTime
                          ? localization.listeningTime
                          : "Listening time"}
                      </Text>
                      <Text
                        display={"flex"}
                        flexDirection={"column"}
                        fontSize={14}
                        fontWeight={700}
                        color="black"
                        textTransform={"capitalize"}
                      >
                        {convertMinsToTime(listeningTime)}
                      </Text>
                    </Pane>
                  </Pane>
                  <Pane className="user-podcast-count">
                    <Pane
                      className="user-icon-one"
                      backgroundColor="var(--accent-color)"
                      width={30}
                      height={30}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      borderRadius={50}
                    >
                      <img
                        className="user-desc-list-icon"
                        src={dashboard}
                        onError={replaceImage}
                        width="20%"
                        height="auto"
                        alt=""
                      />
                    </Pane>
                    <Pane className="user-content-text">
                      <Text
                        color="#343434"
                        fontSize={10}
                        fontWeight={500}
                        textTransform={"capitalize"}
                      >
                        {localization.listeningLevel
                          ? localization.listeningLevel
                          : "listening level"}
                      </Text>
                      <Text
                        display={"flex"}
                        flexDirection={"column"}
                        fontSize={14}
                        fontWeight={700}
                        color="black"
                        textTransform={"capitalize"}
                      >
                        {listeningLevel
                          ? localization[listeningLevelIds[listeningLevel]]
                          : localization[listeningLevelIds["1"]]}
                      </Text>
                    </Pane>
                  </Pane>
                </Pane>

                <Pane className="user-badge-container">
                  <p className="badge-title">
                    {localization.badge ? localization.badge : "Badge"}
                  </p>
                  <Pane className="user-badge-list-panel">
                    {renderBadges()}
                  </Pane>
                </Pane>
              </>
            )}
          </Pane>
        </>
      );
    } else {
      return (
        <>
          <Pane className="series-empty-poster-container">
            <Pane className="series-empty-poster-circle">
              <img
                alt=""
                className="series-poster"
                src={userPoster}
                width="100%"
                height="auto"
              />
            </Pane>

            <Text fontSize="12px" color="#343434">
              {localization.selectItemToView
                ? localization.selectItemToView
                : " Select item to view data."}
            </Text>
          </Pane>
        </>
      );
    }
  };

  const [byOrder, setByOrder] = useState();
  const [byOrderLabel, setByOrderLabel] = useState();
  const [searchBy, setSearchBy] = useState("");

  function renderUsers() {
    if (activeRenderList) {
      return "";
    }
    const filteredOptions = users?.filter((user) =>
      (user?.firstName + user?.lastName)
        .toLowerCase()
        .includes(searchBy.toLowerCase())
    );
    if (filteredOptions?.length > 0) {
      return filteredOptions
        .sort((a, b) => {
          if (byOrder === "A to Z")
            return a.firstName.toLowerCase() > b.firstName.toLowerCase()
              ? 1
              : -1;
          if (byOrder === "Z to A")
            return a.firstName.toLowerCase() < b.firstName.toLowerCase()
              ? 1
              : -1;
          if (byOrder === "Newest") return a.createdAt < b.createdAt ? 1 : -1;
          if (byOrder === "Oldest") return a.createdAt > b.createdAt ? 1 : -1;
        })
        .map((user, i) => {
          return (
            <Pane
              className={activeUserTab(user?._id)}
              tabIndex={i}
              key={user?._id}
              onClick={() => onClickDisplayUserById(user?._id)}
              style={{ cursor: "pointer" }}
            >
              <Pane className="user-list-img">
                <img
                  className="user-img"
                  src={`${process.env.REACT_APP_API_URL}/${user.profile_image}`}
                  onError={replaceImage}
                  width="100%"
                  height="auto"
                  alt=""
                />
              </Pane>
              <Pane className="user-inner-panel-container">
                <Text className="user-email">{user.email}</Text>
                <Pane className="user-name-date-time-wrapper">
                  <Pane>
                    <p className="user-name-list">
                      {user.firstName + " " + user.lastName}
                    </p>
                  </Pane>
                  <Pane className="date-time">
                    <p>
                      <CalendarIcon
                        size={12}
                        marginRight={5}
                        marginTop={5}
                        marginBottom={-1}
                      />
                      {returnDate(user.createdAt)}
                    </p>
                    {user?.role?.name == "tenantadmin" && (
                      <Badge
                        color="yellow"
                        marginRight={8}
                        className="role-admin-icon"
                      >
                        {localization.admin ? localization.admin : "admin"}
                      </Badge>
                    )}
                  </Pane>
                </Pane>
              </Pane>

              {user?.role?.name == "user" && (
                <Pane
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Popover
                    minWidth="40px"
                    minHeight="40px"
                    padding="0px"
                    position={Position.BOTTOM_LEFT}
                    content={({ close }) => (
                      <Menu>
                        <Menu.Group>
                          <Pane onClick={handleClickOpenEditUser(user._id)}>
                            <Menu.Item
                              padding="0"
                              className="user-edit-container"
                              data-customClass="custom-design"
                              icon={<EditIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="series-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                {localization.edit ? localization.edit : "Edit"}
                              </Text>
                            </Menu.Item>
                          </Pane>
                          <Pane
                            onClick={handleClickOpenDeleteDialog(
                              user?._id,
                              user?.email
                            )}
                          >
                            <Menu.Item
                              padding="0"
                              className="user-edit-container"
                              data-customClass="custom-design"
                              icon={<TrashIcon size={12} color="white" />}
                              onSelect={close}
                            >
                              <Text
                                className="series-edit-delete-text"
                                data-customClass="custom-design"
                              >
                                {localization.delete
                                  ? localization.delete
                                  : "Delete"}
                              </Text>
                            </Menu.Item>
                          </Pane>
                        </Menu.Group>
                      </Menu>
                    )}
                  >
                    <MoreIcon className="user-list-more-icon" size={12} />
                  </Popover>
                </Pane>
              )}
            </Pane>
          );
        });
    } else {
      return (
        <Text
          fontSize="12px"
          color="#343434"
          className="series-text"
          title={
            localization.noRecords ? localization.noRecords : "No record found."
          }
        >
          {localization.noRecords ? localization.noRecords : "No record found."}
        </Text>
      );
    }
  }

  async function getUsers() {
    setUserLoader(true);
    try {
      const response = await axios.get("tenants/users/");

      setUsers(response?.data?.data);
      const userId = response?.data?.data?.[0]?._id;

      const badgesResponse = await axios.get(`/tenants/user/${userId}/badges`);

      const badgesRes = badgesResponse?.data?.data;
      setActiveBadges(badgesRes.map((badge, index) => badge?.badgeId?.badgeId));

      const listeningResponse = await axios.get(
        `tenants/user/${userId}/listeninghours`
      );
      setListeningTime(listeningResponse?.data?.data?.listenedSeconds);

      const listeningLevelResponse = await axios.get(
        `tenants/user/${userId}/listeninglevel`
      );
      setListeningLevel(listeningLevelResponse?.data?.data[0]?.level_id);

      setUserLoader(false);
      setActiveRenderList(false);
    } catch (error) {
      setUserLoader(false);
      setActiveRenderList(false);
    }
  }

  async function getBadges() {
    const response = await axios.get("tenants/badges/");
    setBadges(response?.data?.data);
  }

  useEffect(() => {
    getUsers();
    getBadges();
  }, []);

  return (
    <>
      <Sidebar />
      <Pane id="content-box">
        <Header />
        <Pane className="content-wrapper">
          <Pane className="content-inner">
            <Pane className="left-panel">
              <Pane className="left-panel-header">
                <Pane className="user-header-container">
                  <Label
                    className="user-header-title"
                    data-customClass="custom-design"
                  >
                    {localization.user ? localization.user : "User"}
                  </Label>
                  <Pane>
                    <Pane>
                      <React.Fragment>
                        <SideSheet
                          preventBodyScrolling={true}
                          isShown={isShownUser}
                          onCloseComplete={resetUserClose}
                          shouldCloseOnOverlayClick={false}
                        >
                          <Paragraph
                            className="user-form-header"
                            data-customClass="custom-design"
                          >
                            <Text
                              className="user-form-heading-title"
                              data-customClass="custom-design"
                            >
                              {localization.createUser
                                ? localization.createUser
                                : "Create User"}
                            </Text>
                            <Text
                              className="user-form-sub-heading"
                              data-customClass="custom-design"
                            >
                              {localization.createUserDesc
                                ? localization.createUserDesc
                                : " Please fill the details and create user."}
                            </Text>
                          </Paragraph>
                          <SimpleBar
                            style={{ maxHeight: "calc(100vh - 154px)" }}
                          >
                            <form
                              className="user-form-panel"
                              data-customClass="custom-design"
                              onSubmit={handleSubmitUser}
                              onReset={resetUserClose}
                            >
                              <Pane
                                className="user-form-heading"
                                data-customClass="custom-design"
                              >
                                <TextInputField
                                  inputWidth={270}
                                  type="text"
                                  name="firstName"
                                  label={
                                    localization.firstName
                                      ? localization.firstName
                                      : "First Name"
                                  }
                                  value={inputs.firstName}
                                  onChange={handleInput}
                                  required
                                />
                                <TextInputField
                                  inputWidth={270}
                                  type="text"
                                  name="lastName"
                                  label={
                                    localization.lastName
                                      ? localization.lastName
                                      : "Last Name"
                                  }
                                  value={inputs.lastName}
                                  onChange={handleInput}
                                  required
                                />
                              </Pane>
                              <Pane
                                className="user-form-heading"
                                data-customClass="custom-design"
                              >
                                <Pane>
                                  <TextInputField
                                    inputWidth={580}
                                    type="email"
                                    name="email"
                                    label={
                                      localization.email
                                        ? localization.email
                                        : "Email"
                                    }
                                    value={inputs.email}
                                    onChange={handleInput}
                                    required
                                  />
                                </Pane>
                              </Pane>
                              <Pane
                                className="user-detail-form"
                                data-customClass="custom-design"
                              >
                                <Pane maxWidth={578}>
                                  <FileUploader
                                    acceptedMimeTypes={[
                                      MimeType.jpeg,
                                      MimeType.png,
                                    ]}
                                    label={
                                      localization.profilePhoto
                                        ? localization.profilePhoto
                                        : "Profile Photo"
                                    }
                                    description="You can upload up to 1 file. Maximum upload file size: 2MB. (jpg, png format)."
                                    className="create-series-file-uploader"
                                    maxSizeInBytes={2 * 1024 ** 2}
                                    maxFiles={1}
                                    onChange={handleChangeProfile}
                                    onRejected={handleRejectedProfile}
                                    renderFile={(file) => {
                                      const { name, size, type } = file;
                                      const fileRejection =
                                        profileFileRejections.find(
                                          (fileRejection) =>
                                            fileRejection.file === file
                                        );
                                      const { message } = fileRejection || {};
                                      return (
                                        <FileCard
                                          key={name}
                                          src={URL.createObjectURL(
                                            profileFile[0]
                                          )}
                                          isInvalid={fileRejection != null}
                                          name={name}
                                          onRemove={handleRemoveProfile}
                                          sizeInBytes={size}
                                          type={type}
                                          validationMessage={message}
                                        />
                                      );
                                    }}
                                    values={profileFile}
                                  />
                                </Pane>
                              </Pane>
                              <Pane
                                className="user-form-buttons-panel"
                                data-customClass="custom-design"
                              >
                                <Pane padding={16}>
                                  <Heading size={600}>
                                    <Button
                                      className="cancel-button"
                                      data-customClass="custom-design"
                                      type="reset"
                                      onClick={resetUserClose}
                                    >
                                      {localization.btnCancel
                                        ? localization.btnCancel
                                        : "Cancel"}
                                    </Button>
                                    <Button
                                      className="save-button"
                                      data-customClass="custom-design"
                                      type="submit"
                                      disabled={
                                        !inputs.firstName ||
                                        !inputs.lastName ||
                                        !inputs.email || 
                                        submittingSave
                                      }
                                      onClick={() => {
                                        setBtnUser(true);
                                      }}
                                    >
                                      {localization.btnSave
                                        ? localization.btnSave
                                        : "Save"}
                                    </Button>
                                    <Button
                                      className="save-new-button"
                                      data-customClass="custom-design"
                                      type="submit"
                                      disabled={
                                        !inputs.firstName ||
                                        !inputs.lastName ||
                                        !inputs.email || 
                                        submittingSave
                                      }
                                    >
                                      {localization.btnSaveAndAddNew
                                        ? localization.btnSaveAndAddNew
                                        : "Save & Add New"}
                                    </Button>
                                  </Heading>
                                </Pane>
                              </Pane>
                            </form>
                          </SimpleBar>
                        </SideSheet>
                        <Pane className="user-button-create-import">
                          <Button
                            className="create-user-button"
                            data-customClass="custom-design"
                            onClick={() => setIsShownUser(true)}
                            textTransform={"capitalize"}
                            title={
                              localization.createUser
                                ? localization.createUser
                                : "Create User"
                            }
                          >
                            {localization.create
                              ? localization.create
                              : "Create"}
                          </Button>
                          <Button
                            onClick={() => setIsShownImportUser(true)}
                            className="import-user-button-design"
                            data-customClass="custom-design"
                            title={
                              localization.importUser
                                ? localization.importUser
                                : " Import User"
                            }
                          >
                            {localization.import
                              ? localization.import
                              : "Import"}
                          </Button>
                        </Pane>
                      </React.Fragment>
                    </Pane>
                  </Pane>
                </Pane>
                <Pane
                  className="select-dropdown-box-design"
                  data-customClass="custom-design"
                >
                  <Pane className="search-panel-bottom"></Pane>
                  <Pane className="search-panel" style={{ marginTop: "10px" }}>
                    <TextInput
                      className="searchPod"
                      data-customClass="custom-design"
                      placeholder={
                        localization.search ? localization.search : "Search"
                      }
                      type="text"
                      onChange={(e) => setSearchBy(e.target.value)}
                    />
                    <SelectMenu
                      className="filter-menu"
                      height={132}
                      width={100}
                      data-customClass="custom-design"
                      options={[
                        {
                          value: "A to Z",
                          label: localization.atoz
                            ? localization.atoz
                            : "A to Z",
                        },
                        {
                          value: "Z to A",
                          label: localization.ztoa
                            ? localization.ztoa
                            : "Z to A",
                        },
                        {
                          value: "Newest",
                          label: localization.newest
                            ? localization.newest
                            : "Newest",
                        },
                        {
                          value: "Oldest",
                          label: localization.oldest
                            ? localization.oldest
                            : "Oldest",
                        },
                      ].map((option) => ({
                        label: option["label"],
                        value: option["value"],
                      }))}
                      selected={byOrder}
                      hasFilter={false}
                      hasTitle={false}
                      onSelect={(item) => {
                        setByOrder(item.value);
                        setByOrderLabel(item.label);
                        scrollToTopUser();
                      }}
                      closeOnSelect={true}
                    >
                      <Button className="sort-filter">
                        <Icon icon={SortIcon} size={12} /> &nbsp;
                        {byOrderLabel ||
                          `${
                            localization.sortBy
                              ? localization.sortBy
                              : "Sort by"
                          }`}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>

              <Pane className="left-panel-content">
                <SimpleBar
                  style={{ maxHeight: "calc(100vh - 183px)" }}
                  scrollableNodeProps={{ ref: userRef }}
                >
                  {userLoader ? (
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={400}
                    >
                      <Spinner />
                    </Pane>
                  ) : (
                    renderUsers()
                  )}
                </SimpleBar>
              </Pane>
              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownEditUser}
                    onCloseComplete={resetEditUser}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="edit-user-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="edit-user-form-heading"
                        data-customClass="custom-design"
                      >
                        {localization.editUser
                          ? localization.editUser
                          : "edit user"}
                      </Text>
                      <Text
                        className="edit-user-form-sub-heading"
                        data-customClass="custom-design"
                      >
                        {localization.editUserDesc
                          ? localization.editUserDesc
                          : "Please fill the details and edit user."}
                      </Text>
                    </Paragraph>
                    <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                      <form
                        className="user-form-panel"
                        data-customClass="custom-design"
                        onSubmit={handleSubmitEditUser}
                        onReset={resetEditUser}
                      >
                        <Pane className="user-form-heading">
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="firstName"
                            label={
                              localization.firstName
                                ? localization.firstName
                                : "First Name"
                            }
                            value={editInputs.firstName}
                            onChange={handleInputEdit}
                            required
                          />
                          <TextInputField
                            inputWidth={270}
                            type="text"
                            name="lastName"
                            label={
                              localization.lastName
                                ? localization.lastName
                                : "Last Name"
                            }
                            value={editInputs.lastName}
                            onChange={handleInputEdit}
                            required
                          />
                        </Pane>
                        <Pane
                          className="user-form-heading"
                          data-customClass="custom-design"
                        >
                          <Pane>
                            <TextInputField
                              inputWidth={580}
                              type="email"
                              name="email"
                              label={
                                localization.email
                                  ? localization.email
                                  : "Email"
                              }
                              value={editInputs.email}
                              onChange={handleInputEdit}
                              required
                              disabled
                            />
                          </Pane>
                        </Pane>
                        <Pane maxWidth={580}>
                          <FileUploader
                            acceptedMimeTypes={[MimeType.jpeg, MimeType.png]}
                            label={
                              localization.profilePhoto
                                ? localization.profilePhoto
                                : "Profile Photo"
                            }
                            description="Maximum upload file size: 2MB. (jpg, png format)."
                            className="create-series-file-uploader"
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeProfile}
                            onRejected={handleRejectedProfile}
                            renderFile={(file) => {
                              if (file) {
                                const { name, size, type } = file;
                                const fileRejection =
                                  profileFileRejections.find(
                                    (fileRejection) =>
                                      fileRejection.file === file
                                  );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    src={URL.createObjectURL(profileFile[0])}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveProfile}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              } else {
                                setProfileFile([]);
                              }
                            }}
                            values={profileFile}
                          />
                        </Pane>
                        {editInputs.profile_image && (
                          <Pane
                            maxWidth={100}
                            className="defaultImageWrapper"
                            data-customClass="custom-design"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${editInputs.profile_image}`}
                              onError={replaceImage}
                              className="defaultImage"
                              alt=""
                            />
                          </Pane>
                        )}
                        <Pane
                          className="user-form-buttons-panel"
                          data-customClass="custom-design"
                        >
                          <Pane padding={16}>
                            <Heading size={600}>
                              <Button
                                className="cancel-button"
                                data-customClass="custom-design"
                                type="reset"
                                onClick={resetEditUser}
                              >
                                {localization.btnCancel
                                  ? localization.btnCancel
                                  : "Cancel"}
                              </Button>
                              <Button
                                className="save-new-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={
                                  !editInputs.firstName ||
                                  !editInputs.lastName ||
                                  !editInputs.email || 
                                  submittingUpdate
                                }
                              >
                                {localization.btnUpdate
                                  ? localization.btnUpdate
                                  : "Update"}
                              </Button>
                            </Heading>
                          </Pane>
                        </Pane>
                      </form>
                    </SimpleBar>
                  </SideSheet>
                </React.Fragment>
              </Pane>
              <Pane>
                <React.Fragment>
                  <SideSheet
                    preventBodyScrolling={true}
                    isShown={isShownImportUser}
                    onCloseComplete={resetImportUsersClose}
                    shouldCloseOnOverlayClick={false}
                  >
                    <Paragraph
                      className="edit-user-form-header"
                      data-customClass="custom-design"
                    >
                      <Text
                        className="edit-user-form-heading"
                        data-customClass="custom-design"
                      >
                        {localization.importUser
                          ? localization.importUser
                          : "Import Users"}
                      </Text>
                    </Paragraph>
                    <SimpleBar style={{ maxHeight: "calc(100vh - 154px)" }}>
                      <form
                        className="user-form-panel"
                        data-customClass="custom-design"
                        onSubmit={handleSubmitImportUsers}
                        onReset={resetImportUser}
                      >
                        <Pane maxWidth={570}>
                          <FileUploader
                            className="upload-csv-file-design create-series-file-uploader"
                            acceptedMimeTypes={[MimeType.csv]}
                            label={
                              localization.uploadCsv
                                ? localization.uploadCsv
                                : "Upload CSV file "
                            }
                            description={
                              localization.uploadCsvDesc
                                ? localization.uploadCsvDesc
                                : "Maximum upload file size: 2MB. (.csv format)."
                            }
                            maxSizeInBytes={2 * 1024 ** 2}
                            maxFiles={1}
                            onChange={handleChangeCsv}
                            onRejected={handleRejectedCsv}
                            renderFile={(file) => {
                              if (file) {
                                const { name, size, type } = file;
                                const fileRejection = csvFileRejections.find(
                                  (fileRejection) => fileRejection.file === file
                                );
                                const { message } = fileRejection || {};
                                return (
                                  <FileCard
                                    key={name}
                                    isInvalid={fileRejection != null}
                                    name={name}
                                    onRemove={handleRemoveCsv}
                                    sizeInBytes={size}
                                    type={type}
                                    validationMessage={message}
                                  />
                                );
                              } else {
                                setCsvFile([]);
                              }
                            }}
                            values={csvFile}
                          />
                        </Pane>
                        {editInputs.profile_image && (
                          <Pane maxWidth={100} className="defaultImageWrapper">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${editInputs.profile_image}`}
                              onError={replaceImage}
                              className="defaultImage"
                              alt=""
                            />
                          </Pane>
                        )}
                        <Pane className="user-form-buttons-panel">
                          <Pane padding={16}>
                            <Heading size={600}>
                              <Button
                                className="cancel-button"
                                data-customClass="custom-design"
                                type="reset"
                                onClick={resetImportUsersClose}
                              >
                                {localization.btnCancel
                                  ? localization.btnCancel
                                  : "Cancel"}
                              </Button>
                              <Button
                                className="save-new-button"
                                data-customClass="custom-design"
                                type="submit"
                                disabled={!csvFile[0] || submittingImport}
                              >
                                {localization.importUser
                                  ? localization.importUser
                                  : "Import Users"}
                              </Button>
                            </Heading>
                          </Pane>
                        </Pane>
                      </form>
                    </SimpleBar>
                  </SideSheet>
                </React.Fragment>
              </Pane>
            </Pane>
            <Pane className="middle-panel">
              <Pane className="middle-info-inner-wrapper">
                {userInfoLoader ? (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={400}
                  >
                    <Spinner />
                  </Pane>
                ) : (
                  renderUserInfo()
                )}
              </Pane>
            </Pane>
            {renderBadgeInfo()}
          </Pane>
        </Pane>
      </Pane>

      <Pane className="delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownDeleteDialog}
          onCloseComplete={() => setIsShownDeleteDialog(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title={
            localization.deleteTitleUser
              ? localization.deleteTitleUser
              : "Do you really want to delete this user?"
          }
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{deleteTitle}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownDeleteDialog(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              {localization.btnCancel ? localization.btnCancel : "Cancel"}
            </Button>
            <Button
              marginRight={16}
              className="delete-button"
              data-customClass="custom-design"
              onClick={deleteUser(deleteId)}
            >
              {localization.delete ? localization.delete : "Delete"}
            </Button>
          </Pane>
        </Dialog>
      </Pane>
      
      <Pane className="activate-user delete-box-main-container">
        <Dialog
          className="delete-dialog-box"
          width={480}
          isShown={isShownActivateDialog}
          onCloseComplete={() => setIsShownActivateDialog(false)}
          hasFooter={false}
          hasClose={false}
          shouldCloseOnOverlayClick={false}
          title={
            localization.activateTitle
              ? localization.activateTitle
              : "This email is deactivated. Do you want to activate it?"
          }
        >
          <Pane className="delete-box-container-wrapper">
            <Pane className="warning-sign-icon">
              <WarningSignIcon size={30} color="#d14343" />
            </Pane>
            <Pane className="delete-header-text-inner-wrapper">
              <Pane className="delete-header-title">
                <Text>{activateEmail}</Text>
              </Pane>
            </Pane>
          </Pane>
          <Pane className="delete-box-button">
            <Button
              marginRight={16}
              onClick={() => setIsShownActivateDialog(false)}
              className="cancel-button"
              data-customClass="custom-design"
            >
              {localization.btnCancel ? localization.btnCancel : "Cancel"}
            </Button>
            <Button
              marginRight={16}
              className="save-button"
              data-customClass="custom-design"
              onClick={activateUser(activateEmail)}
            >              
              {localization.activate ? localization.activate : "Activate"}
            </Button>
          </Pane>
        </Dialog>
      </Pane>

      {loading && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          className="main-spinner"
        >
          <Spinner />
        </Pane>
      )}
    </>
  );
}

export default User;
