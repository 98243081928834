import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import ForgotPassword from "./components/auth/forgotPassword";

import Dashboard from "./components/pages/dashboard/Dashboard";
import Library from "./components/pages/library/Library";
import Tenant from "./components/pages/tenant/Tenant";
import Announcement from "./components/pages/announcement/Announcement";
import AccountSettings from "./components/pages/accountSettings/AccountSettings";
import ChangePassword from "./components/pages/changePassword/changePassword";
import Language from "./components/pages/language/Language";

import TenantDashboard from "./components/pages/tenantDashboard/TenantDashboard";
import TenantLibrary from "./components/pages/tenantLibrary/TenantLibrary";
import User from "./components/pages/user/User";
import ManageUser from "./components/pages/manageUser/manageUser";

import AuthContext from "./context/AuthContext";

function Router() {
  const { loggedIn, userRole, brandingPrimary, brandingSecondary } =
    useContext(AuthContext);
  document.documentElement.style.setProperty("--accent-color", brandingPrimary);
  document.documentElement.style.setProperty(
    "--accent-color-inverted",
    brandingSecondary
  );

  return (
    <BrowserRouter>
      <Routes>
        {loggedIn === true && userRole === "superadmin" && (
          <>
            <Route
              exact
              path="/"
              element={
                <>
                  <Dashboard />
                </>
              }
            />
            <Route
              exact
              path="/dashboard"
              element={
                <>
                  <Dashboard />
                </>
              }
            />
            <Route
              exact
              path="/library"
              element={
                <>
                  <Library />
                </>
              }
            />
            <Route
              exact
              path="/announcement"
              element={
                <>
                  <Announcement />
                </>
              }
            />
            <Route
              exact
              path="/account-settings"
              element={
                <>
                  <AccountSettings />
                </>
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                <>
                  <ChangePassword />
                </>
              }
            />
            <Route
              exact
              path="/user"
              element={
                <>
                  <ManageUser />
                </>
              }
            />
            <Route
              exact
              path="/language"
              element={
                <>
                  <Language />
                </>
              }
            />
            <Route
              exact
              path="/tenant"
              element={
                <>
                  <Tenant />
                </>
              }
            />
            <Route exact path="*" element={<Dashboard />} />
          </>
        )}
        {loggedIn === true && userRole === "tenantadmin" && (
          <>
            <Route
              exact
              path="/"
              element={
                <>
                  <TenantDashboard />
                </>
              }
            />
            <Route
              exact
              path="/dashboard"
              element={
                <>
                  <TenantDashboard />
                </>
              }
            />
            <Route
              exact
              path="/library"
              element={
                <>
                  <TenantLibrary />
                </>
              }
            />
            <Route
              exact
              path="/account-settings"
              element={
                <>
                  <AccountSettings />
                </>
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                <>
                  <ChangePassword />
                </>
              }
            />
            <Route
              exact
              path="/user"
              element={
                <>
                  <User />
                </>
              }
            />
            <Route
              exact
              path="/announcement"
              element={
                <>
                  <Announcement />
                </>
              }
            />
            <Route exact path="*" element={<TenantDashboard />} />
          </>
        )}
        {loggedIn === undefined && userRole === undefined && (
          <>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
